<template>
    <div v-loading="loading">
        <ul
            v-for="step1 in userSteps"
            :key="step1._id"
            class="user_steps"
        >
            <strong>
                {{ step1.name }}
                <small>({{ $formatDate(step1.created_at) }})</small>
            </strong>
            <template v-if="!!step1.children">
                <li
                    v-for="step2 in step1.children"
                    :key="step2._id"
                >
                    {{ step2.name }} <small>({{ $formatDate(step2.created_at) }})</small>
                    <ul v-if="!!step2.children">
                        <li
                            v-for="step3 in step2.children"
                            :key="step3._id"
                        >
                            {{ step3.name }} <small>({{ $formatDate(step3.created_at) }})</small>
                        </li>
                    </ul>
                </li>
            </template>
        </ul>
    </div>
</template>

<script setup>
import {ref} from 'vue'
import ssUserApi from '~/modules/ss_user/ssUserApi'

const props = defineProps({
    userId: {
        type: String,
        required: true
    }
})

const userSteps = ref([])
const loading = ref(false)

fetchSteps()

async function fetchSteps() {
    loading.value = true
    const {data} = await ssUserApi.getStepsByUser(props.userId)
    userSteps.value = data
    loading.value = false
}
</script>
