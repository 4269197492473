<template>
    <div class="page-breadcrumbs">
        <el-breadcrumb
            separator="/"
            class="breadcrumb"
        >
            <el-breadcrumb-item :to="{path: '/'}">
                ..
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="{name: 'notifications.index'}">
                Notifications
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                Add notification
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <h2 class="page-title">
        Add notification
    </h2>
    <notification-form
        :initial-form="formData"
        @saved="gotoEditPage"
        @cancel="router.push({name: 'notifications.index'})"
    />
</template>

<script setup>
import {ref} from 'vue'
import NotificationForm from './form/NotificationForm'
import {useRoute, useRouter} from 'vue-router'

const route = useRoute()
const router = useRouter()
const formData = ref(null)


function gotoEditPage(id) {
    router.push({name: 'notifications.edit', params: {id: id}})
}
</script>
