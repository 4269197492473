<template>
    <div class="page-breadcrumbs">
        <el-breadcrumb
            separator="/"
            class="breadcrumb"
        >
            <el-breadcrumb-item :to="{path: '/'}">
                ..
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                Products Submission
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>

    <h2 class="page-title">
        Products Submission | <i>({{ totalProducts }} products in db)</i>
    </h2>

    <div class="products-submission">
        <el-row
            class="product-submission__row product-submission__row--border-bottom"
        >
            <el-col
                :span="24"
                class="product-submission__col product-submission__col--no-padding"
            >
                <el-row
                    :gutter="10"
                    align="middle"
                >
                    <el-col
                        :xs="24"
                        :span="24"
                    >
                        <div class="page-section page-section--bordered-form page-section--product-filters-space pb-0">
                            <product-filter
                                v-model="filters"
                                class="page-section page-section--bordered-form"
                            />
                        </div>

                        <div
                            v-loading.fullscreen.lock="loading"
                            class="page-section min-100"
                        >
                            <div
                                v-if="products.length"
                                class="products-list"
                            >
                                <div
                                    v-for="product in products"
                                    :key="product._id"
                                    class="products-list__item"
                                >
                                    <product-info
                                        :product-prop="product"
                                        :show-check-boxes="false"
                                        :show-product-mapping="false"
                                        @click="editProduct(product)"
                                    />
                                </div>
                            </div>
                            <el-pagination
                                v-if="laravelData"
                                v-model:current-page="laravelData.current_page"
                                :page-size="laravelData.per_page"
                                :total="laravelData.total"
                                :small="windowWidth < 1025"
                                layout="prev, pager, next, ->, total"
                                class="float-right mt-3 mb-3"
                                @current-change="getProducts"
                                @size-change="getProducts"
                            />
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {ElMessage, ElMessageBox} from 'element-plus'
import ProductFilter from './filter/ProductFilter'
import ProductInfo from './filter/ProductInfo'
import liProductsApi from '~/modules/labelinsight-products/liProductsApi'
import productSubmissionApi from '~/modules/product-submission/productSubmissionApi'
import bus from '~/includes/Event'


const route = useRoute()
const router = useRouter()

const loading = ref(false)
const totalProducts = ref(0)
const products = ref([])
const laravelData = ref(null)
const windowWidth = ref(window.innerWidth)
const filters = ref({
    search: '',
    category_flats: [],
    sugarFilters: [],
    store: '',
    categorizationtype: 'uncategorized',
})

onMounted(() => {
    getProducts()
    window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth
    })
})

watch(
    () => filters.value,
    () => {
        applySearch()
    },
    { deep: true }
)

const applySearch = _.debounce( function() {
    getProducts()
}, 600)

function editProduct(product) {
    router.push({
        name: 'products-submission.edit',
        params: {
            id: product.upc
        }
    })
}

function getProducts (page = 1) {
    loading.value = true
    let params = {
        page: page,
        pageSize: 12,
        unreviewed: true,
        ...filters.value
    }
    liProductsApi.getProducts(params).then(response => {
        laravelData.value = response.data
        totalProducts.value = laravelData.value.total
        products.value = response.data.data
        // if (selectAllSelected.value) {
        //     products.value = products.value.map(product => {
        //         if(selected_products.value.some(item => item._id === product._id)) {
        //             product.selected = true
        //         }
        //         return product
        //     })
        // }
        loading.value = false
    })
}

// const addSelectAllFiltered = () => {
//     loading.value = true
//     selectAllSelected.value = true
//     const params = {
//         unlimited: true,
//         ...filters.value
//     }
//     liProductsApi.getProducts(params).then(response => {
//         selected_products.value = response.data
//         products.value = products.value.map(product => {
//             product.selected = true
//             return product
//         })
//         loading.value = false
//     })
// }
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.products-submission {
    $block-name: &;
    &__row {
        &--border-bottom {
            margin-bottom: 20px;
            border-bottom: 1px solid darken($white-second, 5%);
            padding-bottom: 20px;
        }
        &--offset-bottom {
            margin-bottom: 20px;
        }
    }
    &__col {
        padding: 0 10px;
        &-auto {
            flex: 0 0 auto;
        }
        &--border-left {
            border-right: 1px solid darken($white-second, 5%);
        }
        &--buttons {
            button {
                margin: 10px 0;
                &:not(:last-child) {
                    margin: 10px 10px 10px 0;
                }
            }
        }
        &--no-padding {
            padding: 0;
        }
    }
    &__barcode-scanner {
        border-top: 1px solid darken($white-second, 5%);
        padding: 10px 0 0;
        text-align: right;
        &-close {
            margin-bottom: 10px;
        }
    }
    &__list {
        margin: 10px 0 0;
        border-top: 1px solid darken($white-second, 5%);
        padding: 0;
        list-style: none;
        &-item {
            padding: 10px 0;
            &:not(:first-child) {
                border-top: 1px solid darken($white-second, 5%);
            }
        }
    }
}

.pb-0 {
    padding-bottom: 0;
}

.products-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    &__item {
        width: 100%;
        margin-bottom: 20px;
        padding: 0 10px;
        @media all and (min-width: $s) {
            width: calc(100% / 2);
        }
        @media all and (min-width: $m) {
            width: calc(100% / 3);
        }
        @media all and (min-width: $l) {
            width: calc(100% / 4);
        }
    }
}

.product-section {
    &__product {
        margin-bottom: 10px;
        &-remove {
            margin-bottom: 10px;
            &--align-right {
                text-align: right;
            }
        }
    }
}
</style>
