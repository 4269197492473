<template>
    <section id="notification">
        <div class="page-breadcrumbs">
            <el-breadcrumb
                separator="/"
                class="breadcrumb"
            >
                <el-breadcrumb-item :to="{path: '/'}">
                    ..
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    Notifications
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <h2 class="page-title">
            Notifications
        </h2>

        <notification-filter
            v-model="filters"
            class="page-section page-section--bordered-form"
        />

        <div class="page-section">
            <el-dropdown
                v-if="$auth.check('notifications.create')"
                trigger="click"
                type="primary"
                icon="plus"
                class="float-right"
            >
                <el-button
                    type="primary"
                    icon="plus"
                >
                    Add
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item @click="$router.push({name: 'notifications.add', query: {active: 'app'}})">
                            App
                        </el-dropdown-item>
                        <el-dropdown-item @click="$router.push({name: 'notifications.add', query: {active: 'email'}})">
                            Email
                        </el-dropdown-item>
                        <el-dropdown-item @click="$router.push({name: 'notifications.add', query: {active: 'sms'}})">
                            Sms
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
            <el-button
                v-if="$auth.check('schedules.view')"
                type="primary"
                icon="tickets"
                @click="$router.push({name: 'schedules.index'})"
            >
                Schedule list
            </el-button>
        </div>

        <!-- table -->
        <el-table
            v-loading="notificationsLoading"
            :data="notifications"
            highlight-current-row
            class="w-100 el-table--notifications"
        >
            <el-table-column type="expand">
                <template #default="props">
                    <el-row :gutter="20">
                        <grid-item
                            v-if="props.row.app"
                            :item="props.row.app"
                        >
                            App
                        </grid-item>
                        <grid-item
                            v-if="props.row.email"
                            :item="props.row.email"
                        >
                            Email
                        </grid-item>
                        <grid-item
                            v-if="props.row.sms"
                            :item="props.row.sms"
                        >
                            Sms
                        </grid-item>
                    </el-row>
                </template>
            </el-table-column>
            <el-table-column
                prop="name"
                label="Name"
                min-width="200"
            />
            <el-table-column
                prop="type"
                label="Type"
                min-width="110"
            />
            <el-table-column
                prop="department"
                label="Department"
                min-width="110"
            />
            <el-table-column
                label="Actions"
                min-width="180"
                align="right"
            >
                <template #default="scope">
                    <el-button
                        v-if="$auth.check('notifications.edit')"
                        size="small"
                        icon="edit"
                        @click="$router.push({name: 'notifications.edit', params: {id: scope.row._id}})"
                    >
                        Edit
                    </el-button>
                    <el-button
                        v-if="$auth.check('notifications.delete')"
                        type="danger"
                        size="small"
                        icon="delete"
                        @click="handleDelete(scope.row)"
                    >
                        Delete
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            v-model:current-page="page"
            :page-size="per_page"
            :total="total"
            hide-on-single-page
            layout="prev, pager, next, ->, total"
            class="el-pagination--align-right"
            @current-change="fetchData"
            @size-change="fetchData"
        />
    </section>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import notificationApi from '../notificationApi'
import NotificationFilter from './NotificationFilter'
import GridItem from './GridItem'

const notificationsLoading = ref(false)
const notifications = ref(null)
const total = ref(0)
const filters = ref({})
const page = ref(1)
const per_page = ref(10)

onMounted(() => fetchData())

watch(
    () => filters.value,
    val => applySearch(),
    { deep: true }
)

async function fetchData() {
    notificationsLoading.value = true
    let params = {
        page: page.value,
        ...filters.value
    }
    const {data} = await notificationApi.all(params)
    notifications.value = data.data
    total.value = data.total
    per_page.value = data.per_page
    notificationsLoading.value = false
}

function handleDelete(row) {
    ElMessageBox.confirm('This will permanently delete the notification. Continue?', 'Warning', {
        type: 'warning'
    }).then(() => {
        notificationApi.destroy(row._id).then((response) => {
            ElMessage.success( 'Deleted successfully')
            fetchData()
        })
    })
}

const applySearch = _.debounce( function() {
    fetchData()
}, 300)
</script>
