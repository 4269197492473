<template>
    <div class="page-breadcrumbs">
        <page-breadcrumbs :list="breadcrumbs" />
    </div>
    <h2 class="page-title">
        Edit lesson
    </h2>
    <el-row :gutter="20">
        <el-col >
            <lesson-form
                v-loading="loading"
                :initial-form="formData"
                @cancel0="$router.push({name: 'chapters.edit', params: {lm_id: $route.params.lm_id, chapter_id: $route.params.chapter_id}})"
            />
        </el-col>
        <el-col
            class="mb-3"
            v-show="!loading"
        >
            <references />
        </el-col>
        <el-col
            v-show="!loading"
        >
            <questions />
        </el-col>
    </el-row>
</template>

<script setup>

import { ref, onMounted, computed } from 'vue'
import LessonForm from './LessonForm'
import References from '../reference/References'
import Questions from '../question/Question'
import {useRoute, useRouter} from 'vue-router'
import lessonApi from '../../lessonApi'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'
import { LEARNING_SS } from "~/store/mutation-types";
import store from "~/store";

const route = useRoute()
const router = useRouter()
const formData = ref(null)
const learningTabValue = computed(() => store.getters['learnings/learningTabValue'])
const loading = ref(false)
const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: learningTabValue.value === LEARNING_SS ? 'Learning modules' : 'Coach modules',
        name: 'learning_modules.index'
    },
    {
        title: 'Chapters',
        name: 'learning_modules.edit',
        params: {lm_id: route.params.lm_id},
    },
    {
        title: 'Lessons',
        name: 'chapters.edit',
        params: {lm_id: route.params.lm_id, chapter_id: route.params.chapter_id},
    },
    {
        title: 'Edit lesson',
    }
])

onMounted(() => fetchData())

async function fetchData() {
    loading.value = true
    const {data} = await lessonApi.get(route.params.lesson_id)
    formData.value = data
    formData.value.lessonID = data._id
    loading.value = false
}
</script>
