import axios from 'axios'

const API_ENDPOINT = window.config.ssURL + 'search-words'

export default {

    all() {
        return axios.get(API_ENDPOINT + '')
    },
    get(id) {
        return axios.get(API_ENDPOINT + '/' + id)
    },
    update(model) {
        return axios.post(API_ENDPOINT + '/save', model)
    },
    destroy(model) {
        return axios.post(API_ENDPOINT + '/delete', model)
    },
    export() {
        return axios.get(API_ENDPOINT + '/export', {responseType: 'blob'})
    },
    import(data) {
        return axios.post(API_ENDPOINT + '/import', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }
}
