import PageIndex from "~/modules/email/components/PageIndex.vue";
import TemplatesIndex from "~/modules/email/components/TemplatesIndex.vue";
import EmailEdit from "~/modules/email/components/EmailEdit.vue";

export default [
    {
        path: 'email-pages',
        component: PageIndex,
        name: 'email-pages.index',
        meta: {
            auth: 'email-pages.view',
            pageTitle: 'Email pages',
        },
    },
    {
        path: 'email-pages/:page/templates',
        component: TemplatesIndex,
        name: 'email-pages.templates',
        meta: {
            auth: 'email-pages.templates',
            pageTitle: 'Email templates',
        },
    },
    {
        path: 'email-pages/:page/templates/:id?/edit',
        component: EmailEdit,
        name: 'email-pages.templates.edit',
        meta: {
            auth: 'email-pages.templates.edit',
            pageTitle: 'Edit email template',
        },
    },
]
