<template>
    <el-row>
        <el-tag
            v-for="department in departmentsData"
            :key="department._id"
            class="mr-10 mb-10"
            size="large"
            closable
            :disable-transitions="false"
            @close="removeDepartment(department._id)"
        >
            {{ department.name }}
        </el-tag>
        <template v-if="(departmentsData.length < 6)">
            <el-button
                v-if="!showDepartmentInput"
                type="primary"
                icon="plus"
                class="mr-10 mb-10"
                @click="showDepartmentInput = true"
            >
                Add department
            </el-button>
            <el-autocomplete
                v-if="showDepartmentInput"
                ref="autogeneralsAutocomplete"
                v-model="autocompleteInput"
                class="mr-10 mb-10"
                :fetch-suggestions="fetchAutocompleteData"
                :debounce="900"
                @select="selectItem"
            />
            <el-button
                v-if="showAddNewDepartment"
                type="primary"
                @click="addDepartment"
            >
                Add
            </el-button>
        </template>
    </el-row>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import { ElMessage } from 'element-plus'
import {Errors} from '~/includes/Errors'
import departmentsApi from '../departmentsApi'

const emit = defineEmits()

const props = defineProps({
    departmentIds: {
        type: Array,
        default: null,
    },
})

const departmentsData = ref([])
const showError = ref('')
const showAddNewDepartment = ref(false)
const autocompleteInput = ref('')
const department_ids = ref([])
const showDepartmentInput = ref(false)

watch(
    () => props.departmentIds,
    val => {
        department_ids.value = Object.values(val)
        departmentsData.value = []
        fetchData()
    },
    { deep: true }
)

onMounted(() => {
    fetchData()
})

const fetchData = () => {
    for(let i in department_ids.value) {
        departmentsApi.get(department_ids.value[i]).then((response) => {
            departmentsData.value.push(response.data)
        })
    }
}

const addDepartment = () => {
    departmentsApi.create({
        name: autocompleteInput.value,
    }).then((response) => {
        let data = response.data.data
        departmentsData.value.push(data)
        department_ids.value.push(data._id)
        autocompleteInput.value = ''
        showAddNewDepartment.value = false
        updateParent()
    }).catch((error) => {
        showError.value = error.response.data.message
    })

    showDepartmentInput.value = false
}

const selectItem = (obj) => {
    if (department_ids.value.indexOf(obj._id) === -1) {
        departmentsData.value.push(obj)
        department_ids.value.push(obj._id)
        updateParent()
    }
    showAddNewDepartment.value = false
    showDepartmentInput.value = false

}

const onInput = (obj) => {
    autocompleteInput.value = obj
}

const onAjaxLoaded = (obj) => {
    showAddNewDepartment.value = (obj.length === 0)
}

const removeDepartment = (id) => {
    let index = department_ids.value.indexOf(id)
    department_ids.value.splice(index, 1)
    departmentsData.value.splice(index, 1)
    updateParent()
}

const fetchAutocompleteData = (searchString, cb) => {
    if (searchString.length < 3) {
        cb([])
        return
    }
    departmentsApi.list({
        search: searchString,
    }).then((response) => {
        // this.autocompleteData = response.data.data
        let data = response.data
        data.forEach((item) => {
            item.value = item.name
        })
        cb( data)
        showAddNewDepartment.value = (data.length === 0)
    }).catch((error) => {
        // this.showError = error.response.data.message
    })
}

const updateParent = () => {
    emit('updateDepartmentIds', department_ids.value)
}

</script>
