<template>
    <section id="email">
        <div class="page-breadcrumbs">
            <el-breadcrumb
                separator="/"
                class="breadcrumb"
            >
                <el-breadcrumb-item :to="{path: '/'}">
                    ..
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    Pages
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <h2 class="page-title">
            Pages for senders
        </h2>
        <el-table
            :data="pages"
            highlight-current-row
            class="w-100"
        >
            <el-table-column
                prop="page"
                label="Page"
                min-width="200"
            />
            <el-table-column
                label="Actions"
                width="130"
                align="right"
            >
                <template #default="scope">
                    <el-button
                        v-if="$auth.check('email-pages.templates')"
                        size="small"
                        icon="edit"
                        @click="$router.push({name: 'email-pages.templates', params: {page: scope.row.page_tag}})"
                    >
                        Templates
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </section>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import emailApi from '../emailApi'

const pages = ref([
    {
        page: 'Invited SS Users',
        page_tag: 'user_invite'
    },
    {
        page: 'Invited Coaches',
        page_tag: 'coach_invite'
    }
])

</script>
