import Index from './components/Index'

export default [
    {
        path: 'products-categorization/:search?',
        component: Index,
        name: 'products-categorization.index',
        meta: {
            auth: 'label_insight_products.view',
            pageTitle: 'Products Categorization',
        },
    },
]
