<template>
    <div class="page-breadcrumbs">
        <el-breadcrumb
            separator="/"
            class="breadcrumb"
        >
            <el-breadcrumb-item :to="{path: '/'}">
                ..
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="{name: 'products-submission.index'}">
                Products Submission
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                {{ actionName }} Products Submission <template v-if="!savedChanges">
                    - (Unsaved)
                </template>
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>

    <h2 class="page-title">
        {{ actionName }} Products Submission
    </h2>

    <div
        v-if="form._id"
        class="product-submission"
    >
        <el-form
            label-width="160px"
        >
            <el-row
                class="product-submission__row"
            >
                <el-col>
                    <el-form-item
                        label="UPC"
                        prop="upc"
                    >
                        <el-input
                            v-model="form.upc"
                            type="text"
                            placeholder="UPC"
                            name="upc"
                            maxlength="16"
                            input-style="width: 17ch;"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row
                class="product-submission__row"
            >
                <el-col>
                    <el-form-item
                        label="Product Title"
                        prop="productTitle"
                    >
                        <el-input
                            v-model="form.productTitle"
                            type="text"
                            placeholder="Product Title"
                            name="productTitle"
                            maxlength="50"
                            input-style="width: 51ch;"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row
                class="product-submission__row"
            >
                <el-col>
                    <el-form-item
                        label="Brand"
                        prop="brand"
                    >
                        <el-input
                            v-model="form.brand"
                            type="text"
                            placeholder="Brand"
                            name="brand"
                            maxlength="25"
                            input-style="width: 26ch;"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row
                class="product-submission__row"
            >
                <el-col>
                    <el-form-item
                        label="Ingredients"
                        prop="ingredients"
                    >
                        <el-input
                            v-model="form.ingredients.declaration"
                            type="text"
                            placeholder="Ingredients"
                            name="ingredients"
                            input-style="width: 51ch;"
                        />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row
                class="product-submission__row"
            >
                <nutrition-facts v-model="form.factPanel.nutrition" />
            </el-row>
        </el-form>
        <el-row
            class="product-submission__row"
        >
            <el-col
                class="product-submission__col-auto"
            >
                <label for="frontImage">Front Image:</label>
                <el-image
                    :key="photoKey"
                    :src="frontImage"
                    alt="front image"
                />
            </el-col>
            <el-col
                class="product-submission__col-auto"
            >
                <UploadPhoto
                    :key="photoKey"
                    :product="form"
                    :photo-type="'front'"
                    @photoUploaded="photoUploaded"
                />
            </el-col>
        </el-row>
        <el-row
            class="product-submission__row "
        >
            <el-col
                class="product-submission__col-auto"
            >
                <label for="nutritionFactImage">Nutrition Fact Image:</label>
                <el-image
                    :src="nutritionImage"
                    alt="nutrition fact image"
                />
            </el-col>
        </el-row>
        <el-row
            class="product-submission__row "
        >
            <el-button
                v-if="form._id"
                v-loading.fullscreen.lock="loading"
                type="success"
                @click="saveProductSubmission"
            >
                Save For Later
            </el-button>
            <el-button
                type="primary"
                :disabled="!savedChanges"
                @click="approveProductSubmission"
            >
                Approve Product
            </el-button>
            <el-button
                type="danger"
                :disabled="!savedChanges"
                @click="confirmRejectProductSubmission"
            >
                Reject Product
            </el-button>
        </el-row>
    </div>
</template>

<script setup>
import {computed, onMounted, ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {ElMessage, ElMessageBox} from 'element-plus'
import UploadPhoto from './UploadPhoto'
import productSubmissionApi from '../productSubmissionApi'
import liProductsApi from '../../labelinsight-products/liProductsApi'
import NutritionFacts from './filter/NutritionFacts'
import {Errors} from '~/includes/Errors'

const route = useRoute()
const router = useRouter()
const loading = ref(false)
const mappingData = ref(null)

const form = ref({
    _id: null,
    upc: '',
    productTitle: '',
    brand: '',
    gtinFormats: {},
    factPanel: {},
    sizes: {},
    completed: false,
    ingredients: {
        declaration: '',
    },
    ssImagePath: '',
})
const formErrors = new Errors()

const savedChanges = ref(true)
const actionName = ref('Add')
const photoKey = ref(0)

const frontImage = computed(() => {
    if (form.value.ssImagePath) {
        return form.value.ssImagePath
    }

    return form.value.userImages && form.value.userImages.frontImage && form.value.userImages.frontImage.url
        ? form.value.userImages.frontImage.url
        : '/images/ImageUnavailable2.png'
})

const nutritionImage = computed(() => {
    return form.value.userImages && form.value.userImages.nutritionFactImage && form.value.userImages.nutritionFactImage.url
        ? form.value.userImages.nutritionFactImage.url
        : '/images/ImageUnavailable2.png'
})

watch(form, async (newValue, oldValue) => {
    if (oldValue._id !== null) {
        savedChanges.value = false
    }
}, {deep: true})

onMounted(() => fetchData())

async function fetchData() {
    if (route.params.id) {
        loading.value = true
        const {data} = await liProductsApi.getProductByUpc(route.params.id)
        form.value = data
        if(form.value.ingredients === undefined || form.value.ingredients.declaration === undefined) {
            form.value.ingredients = {
                declaration: '',
            }
        }
        loading.value = false
        savedChanges.value = true
        actionName.value = 'Edit'
    }
}
function approveProductSubmission() {
    loading.value = true
    productSubmissionApi.approveProductSubmission(form.value)
        .then((response) => {
            form.value = response.data

            ElMessage({
                message: 'Product Approved',
                type: 'success',
            })
            router.push({name: 'products-submission.index'})
        })
        .catch((error) => {
            console.log(error)
            if (error.response.data.errors) {
                formErrors.record(error.response.data.errors)
            }
        })
        .finally(() => {
            loading.value = false
        })
}

function confirmRejectProductSubmission() {
    ElMessageBox.confirm('Are you sure to reject this product? Product data will be deleted from both servers', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning',
    }).then(() => {
        rejectProductSubmission()
    }).catch(() => {
        ElMessage({
            type: 'info',
            message: 'Rejected canceled',
        })
    })
}

function rejectProductSubmission() {
    loading.value = true
    productSubmissionApi.rejectProductSubmission(form.value)
        .then((response) => {
            form.value = response.data

            ElMessage({
                message: 'Product Rejected',
                type: 'success',
            })
            router.push({name: 'products-submission.index'})
        })
        .catch((error) => {
            console.log(error)
            if (error.response.data.errors) {
                formErrors.record(error.response.data.errors)
            }
        })
        .finally(() => {
            loading.value = false
        })
}
function getProductByUpc(upc) {
    liProductsApi.getProductByUpc(upc).then(response => {
        let product = response.data

        // debugger
        savedChanges.value = false
        console.log(savedChanges.value)

        if (searchMode.value === 'source') {
            form.value.sources.push(product)
        } else {
            form.value.targets.push(product)
        }
        // searchMode = ''
    }).catch(response => {
        ElMessageBox.alert('Product Not Found In DB', 'Error')
    })
}


function saveProductSubmission() {
    loading.value = true
    formErrors.clear()
    // debugger
    console.log(form.value)
    productSubmissionApi.saveProductSubmission(form.value)
        .then((response) => {
            // this.form = response.data
            form.value = response.data
            savedChanges.value = true

            ElMessage({
                message: 'Product Saved',
                type: 'success',
            })
            router.push({name: 'products-submission.index'})
        })
        .catch((error) => {
            if (error.response.data.errors) {
                formErrors.record(error.response.data.errors)
            }
        }).
        finally(() => {
            loading.value = false
        })
}

function photoUploaded(product) {
    form.value.ssImagePath = product.ssImagePath
    photoKey.value++
}

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.product-submission33 {
    $block-name: &;
    &__row {
        margin-bottom: 10px;
        &--border-bottom {
            margin-bottom: 20px;
            border-bottom: 1px solid darken($white-second, 5%);
            padding-bottom: 20px;
        }
    }
    &__col {
        padding: 0 10px;
        &-auto {
            flex: 0 0 auto;
        }
        &--border-left {
            border-right: 1px solid darken($white-second, 5%);
        }
        &--buttons {
            button {
                margin: 10px 0;
                &:not(:last-child) {
                    margin: 10px 10px 10px 0;
                }
            }
        }
    }
    &__barcode-scanner {
        border-top: 1px solid darken($white-second, 5%);
        padding: 10px 0 0;
        text-align: right;
        &-close {
            margin-bottom: 10px;
        }
    }
    &__list {
        margin: 10px 0 0;
        border-top: 1px solid darken($white-second, 5%);
        padding: 0;
        list-style: none;
        &-item {
            padding: 10px 0;
            &:not(:first-child) {
                border-top: 1px solid darken($white-second, 5%);
            }
        }
    }
}

.product-section {
    &__product {
        margin-bottom: 10px;
        &-remove {
            margin-bottom: 10px;
        }
    }
}
</style>
