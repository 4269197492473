<template>
    <el-form
        :model="form"
        label-width="150px"
        @submit.prevent
    >
        <el-form-item
            label="Frequency"
            prop="value"
            :error="errors.get(provider + '.frequency.value')"
        >
            <el-select
                v-model="form.value"
                class="w-100"
                placeholder="Select frequency"
            >
                <el-option
                    v-for="frequency in list.frequencies"
                    :key="frequency"
                    :label="frequency"
                    :value="frequency"
                />
            </el-select>
        </el-form-item>
        <!--    Specific Date    -->
        <template v-if="form.value === 'Specific Date'">
            <el-form-item
                label="Specific date"
                prop="time_data.day"
                :error="errors.get('time_data.day')"
            >
                <el-date-picker
                    v-model="form.time_data.day"
                    type="date"
                    placeholder="Date from"
                    :value-format="DATE_DB_FORMAT"
                    :disabled-date="disabledDate"
                    class="w-100"
                />
            </el-form-item>
            <el-form-item
                v-if="form.time_data.day"
                label="Time"
                prop="time_data.time"
                :error="errors.get('time_data.time')"
            >
                <el-time-picker
                    v-model="form.time_data.time"
                    class="mb-2"
                />
            </el-form-item>
        </template>
        <!--    Daily    -->
        <template v-if="form.value === 'Daily'">
            <el-form-item
                label="Time"
                prop="time_data.time"
                :error="errors.get('time_data.time')"
            >
                <el-time-picker
                    v-model="form.time_data.time"
                    class="mb-2"
                />
            </el-form-item>
        </template>
        <!--    Twice a Week    -->
        <template v-if="form.value === 'Twice a Week'">
            <el-form-item
                label="First day of week"
                prop="time_data.day1"
                :error="errors.get('time_data.day1')"
            >
                <el-select
                    v-model="form.time_data.day1"
                    class="w-100"
                    placeholder="Select day of week"
                >
                    <el-option
                        v-for="day in weekdays"
                        :key="day"
                        :label="day"
                        :value="day"
                    />
                </el-select>
            </el-form-item>
            <el-form-item
                v-if="form.time_data.day1"
                label="First Weekday Time"
                prop="time_data.time1"
                :error="errors.get('time_data.time1')"
            >
                <el-time-picker
                    v-model="form.time_data.time1"
                    class="mb-2"
                />
            </el-form-item>
            <el-form-item
                label="Second day of week"
                prop="time_data.day2"
                :error="errors.get('time_data.day2')"
            >
                <el-select
                    v-model="form.time_data.day2"
                    class="w-100"
                    placeholder="Select day of week"
                >
                    <el-option
                        v-for="day in weekdays"
                        :key="day"
                        :label="day"
                        :value="day"
                    />
                </el-select>
            </el-form-item>
            <el-form-item
                v-if="form.time_data.day2"
                label="Second Weekday Time"
                prop="time_data.time2"
                :error="errors.get('time_data.time2')"
            >
                <el-time-picker
                    v-model="form.time_data.time2"
                    class="mb-2"
                />
            </el-form-item>
        </template>
        <!--    Weekly    -->
        <template v-if="form.value === 'Weekly'">
            <el-form-item
                label="Day of week"
                prop="time_data.day"
                :error="errors.get('time_data.day')"
            >
                <el-select
                    v-model="form.time_data.day"
                    class="w-100"
                    placeholder="Select day of week"
                >
                    <el-option
                        v-for="day in weekdays"
                        :key="day"
                        :label="day"
                        :value="day"
                    />
                </el-select>
            </el-form-item>
            <el-form-item
                v-if="form.time_data.day"
                label="Time"
                prop="time_data.time"
                :error="errors.get('time_data.time')"
            >
                <el-time-picker
                    v-model="form.time_data.time"
                    class="mb-2"
                />
            </el-form-item>
        </template>
        <!--    Bi Month    -->
        <template v-if="form.value === 'Bi Monthly'">
            <el-form-item
                label="First day"
                prop="time_data.day1"
                :error="errors.get('time_data.day1')"
            >
                <el-select
                    v-model="form.time_data.day1"
                    class="w-100"
                    placeholder="Select day of month"
                >
                    <el-option
                        v-for="day in monthdays"
                        :key="day"
                        :label="day"
                        :value="day"
                    />
                </el-select>
            </el-form-item>
            <el-form-item
                v-if="form.time_data.day1"
                label="First Day Time"
                prop="time_data.time1"
                :error="errors.get('time_data.time1')"
            >
                <el-time-picker
                    v-model="form.time_data.time1"
                    class="mb-2"
                />
            </el-form-item>
            <el-form-item
                label="Second day"
                prop="time_data.day2"
                :error="errors.get('time_data.day2')"
            >
                <el-select
                    v-model="form.time_data.day2"
                    class="w-100"
                    placeholder="Select day of month"
                >
                    <el-option
                        v-for="day in monthdays"
                        :key="day"
                        :label="day"
                        :value="day"
                    />
                </el-select>
            </el-form-item>
            <el-form-item
                v-if="form.time_data.day2"
                label="Second Day Time"
                prop="time_data.time2"
                :error="errors.get('time_data.time2')"
            >
                <el-time-picker
                    v-model="form.time_data.time2"
                    class="mb-2"
                />
            </el-form-item>
        </template>
        <!--    Monthly    -->
        <template v-if="form.value === 'Monthly'">
            <el-form-item
                label="Day"
                prop="time_data.day"
                :error="errors.get('time_data.day')"
            >
                <el-select
                    v-model="form.time_data.day"
                    class="w-100"
                    placeholder="Select day of month"
                >
                    <el-option
                        v-for="day in monthdays"
                        :key="day"
                        :label="day"
                        :value="day"
                    />
                </el-select>
            </el-form-item>
            <el-form-item
                v-if="form.time_data.day"
                label="Time"
                prop="time_data.time"
                :error="errors.get('time_data.time')"
            >
                <el-time-picker
                    v-model="form.time_data.time"
                    class="mb-2"
                />
            </el-form-item>
        </template>
    </el-form>
</template>

<script setup>
import {inject} from 'vue'
import {useModelWrapper} from '~/includes/composable/modelWrapper'
import {DATE_DB_FORMAT} from '~/base/constants/time.constants'

const emit = defineEmits()

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
    provider: {
        type: String,
        required: true
    },
    errors: {
        type: Object,
        default: {}
    },
})

const list = inject('list')

const form = useModelWrapper(props, emit, 'modelValue')

const weekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
]

const monthdays = [..._.range(1, 29), 'Last day of month']

const disabledDate = time => time.getTime() < Date.now()

</script>
