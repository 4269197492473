<template>
    <section
        id="user-form"
        class="max-width-600-tablet-desktop"
    >
        <el-form
            ref="formRef"
            :model="form"
            label-width="100px"
            :rules="rules"
            @submit.prevent="saveSubmit"
        >
            <el-form-item
                label="Name"
                prop="name"
                :error="errors.get('name')"
            >
                <el-input
                    v-model="form.name"
                    suffix-icon="edit-pen"
                    @change="errors.clear('name')"
                />
            </el-form-item>
            <el-form-item
                label="Email"
                prop="email"
                :error="errors.get('email')"
            >
                <el-input
                    v-model="form.email"
                    suffix-icon="message"
                    @change="errors.clear('email')"
                />
            </el-form-item>
            <el-form-item
                label="Role"
                prop="role"
                :error="errors.get('role')"
            >
                <el-select
                    v-model="form.role"
                    class="w-100"
                    @change="errors.clear('role')"
                >
                    <el-option
                        v-for="role in roles"
                        :key="role"
                        :label="role"
                        :value="role"
                    />
                </el-select>
            </el-form-item>
            <el-form-item
                v-if="$route.name === 'users.add'"
                label="Password"
                prop="password"
                :error="errors.get('password')"
                required
            >
                <el-input
                    v-model="form.password"
                    type="password"
                    show-password
                    @change="errors.clear('password')"
                />
            </el-form-item>
        </el-form>
        <div>
            <el-button
                @click="cancel"
            >
                Cancel
            </el-button>
            <el-button
                :loading="formLoading"
                type="success"
                @click="saveSubmit"
            >
                Save
            </el-button>
        </div>
    </section>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import { ElMessage } from 'element-plus'
import {Errors} from '~/includes/Errors'
import userApi from '../userApi'
import roleApi from '~/modules/role/roleApi'

const emit = defineEmits()

const props = defineProps({
    initialForm: {
        type: Object,
        default: {}
    },
})

const errors = new Errors()
const formLoading = ref(false)
const form = ref({
    name: '',
    email: '',
    role: '',
})
const rules = {
    name: [
        { required: true, message: 'Name is required' },
    ],
    role: [
        { required: true, message: 'Role is required', trigger: 'blur' },
    ],
    email: [
        { required:true, message: 'Please enter your email', trigger: 'blur' },
        { type: 'email', message: 'Invalid email', trigger: 'blur' }
    ],
    password: [
        { validator: checkPassForNewUser, trigger: ['change', 'blur'] }
    ],
}

const roles = ref(null)

onMounted(() => fetchRoles())

async function fetchRoles() {
    const {data} = await roleApi.fetchRolesList()
    roles.value = data
}

onMounted(() => form.value = Object.assign({}, props.initialForm))

watch(
    () => props.initialForm,
    val => form.value = Object.assign({}, val),
    { deep: true }
)

const formRef = ref()

function saveSubmit() {
    formRef.value.validate((valid) => {
        if (valid) {
            formLoading.value = true
            errors.clear()
            let action = form.value._id ? userApi.update : userApi.create

            action(form.value).then((response) => {
                ElMessage.success( 'Saved successfully')
                emit('saved')
            }).catch(error => {
                if (error.response.data.errors) errors.record(error.response.data.errors)
            }).finally(() => formLoading.value = false)
        }
    })
}

function cancel() {
    clearForm()
    emit('cancel')
}

function clearForm() {
    errors.clear()
    if (formRef.value) formRef.value.resetFields()
}

function checkPassForNewUser(rule, value, callback) {
    if (!value && !form.value._id) {
        return callback(new Error('Password is required'))
    } else {
        callback()
    }
}
</script>
