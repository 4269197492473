<template>
    <el-form
        ref="loginForm"
        :model="form"
        :rules="rules"
        class="auth-form"
        @keyup.enter="onSubmit"
    >
        <el-form-item
            prop="email"
            class="el-form-item--default"
            :error="formErrors.get('email')"
        >
            <el-input
                v-model="form.email"
                name="_email"
                type="email"
                placeholder="Email"
                autocomplete="email"
            />
        </el-form-item>
        <el-form-item
            prop="password"
            class="el-form-item--default"
            :error="formErrors.get('password')"
        >
            <el-input
                v-model="form.password"
                name="password"
                type="password"
                placeholder="Password"
                autocomplete="new-password"
                show-password
            />
        </el-form-item>
        <el-form-item
            v-if="forgotLink"
            class="el-form-item--default"
        >
            <el-row>
                <el-col :span="12">
                    <router-link
                        to="/forgot/password"
                        class="el-link"
                    >
                        Forgot password
                    </router-link>
                </el-col>
            </el-row>
        </el-form-item>
        <el-form-item
            class="el-form-item--default"
        >
            <el-button
                :loading="loading"
                type="primary"
                round
                @click="onSubmit"
            >
                Log In
            </el-button>
        </el-form-item>
    </el-form>
</template>

<script>
import {Errors} from '~/includes/Errors'

export default {
    name: 'LoginForm',
    props: {
        errors: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        forgotLink: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            form: {
                email: '',
                password: '',
                remember: true,
                device_name: this.$config.deviceName
            },
            rules: {
                email:    [{required: true, message: 'Please enter your email', trigger: 'blur'}],
                password: [{required: true, message: 'Please enter your password', trigger: 'blur'}],
            },
            formErrors: new Errors(),
            showPassword: false,
        }
    },
    watch: {
        errors: function () {
            this.formErrors.record(this.errors)
        }
    },
    methods: {
        onSubmit(e) {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.formErrors.clear()
                    this.$emit('submit', {
                        ...this.form
                    })
                }
            })
        },
    },
}
</script>
