<template>
    <section id="nielseniq-form">
        <div class="page-breadcrumbs">
            <el-breadcrumb
                separator="/"
                class="breadcrumb"
            >
                <el-breadcrumb-item :to="{path: '/'}">
                    ..
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{name: 'pmo.index'}">
                    PMO Categories
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    Edit NielsenIQ Categories
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <h2
            v-if="formData"
            class="page-title"
        >
            Edit &quot;{{ formData.title }}&quot;
        </h2>

        <div
            v-if="formData"
            class="page-section"
        >
            <el-row :gutter="10">
                <el-col
                    :span="10"
                >
                    <div class="section-title">
                        PMO Categories
                    </div>
                </el-col>
                <el-col
                    :span="4"
                />
                <el-col
                    :span="10"
                >
                    <div class="section-title">
                        NielsenIQ Categories
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col
                    :span="10"
                    class="section-col"
                >
                    <el-tree
                        ref="pmoTree"
                        class="section-col__tree"
                        :data="formData.nielsenIQ_categories"
                        show-checkbox
                        :props="treeProps"
                    />
                </el-col>
                <el-col
                    :span="4"
                    class="section-col section-col--buttons"
                >
                    <el-row>
                        <el-button
                            class="w-100"
                            icon="arrow-left-bold"
                            :disabled="fullTree.length === 0"
                            @click="addCategories"
                        >
                            <template v-if="btnLabel === 'visible'">
                                Add Categories
                            </template>
                        </el-button>
                    </el-row>
                    <el-row>
                        <el-button
                            class="w-100"
                            :disabled="fullTree.length === 0"
                            @click="removeCategories"
                        >
                            <template v-if="btnLabel === 'visible'">
                                Remove Categories
                            </template>
                            <el-icon>
                                <ArrowRightBold />
                            </el-icon>
                        </el-button>
                    </el-row>
                </el-col>
                <el-col
                    :span="10"
                    class="section-col"
                >
                    <el-tree
                        ref="tree"
                        class="section-col__tree"
                        :data="treeData"
                        show-checkbox
                        :props="treeProps"
                        lazy
                        :load="loadNode"
                    />
                </el-col>
            </el-row>
        </div>
        <div>
            <el-button
                @click="cancel"
            >
                Cancel
            </el-button>
            <el-button
                :loading="formLoading"
                type="success"
                @click="saveSubmit"
            >
                Save
            </el-button>
        </div>
    </section>
</template>

<script setup>
import {onMounted, ref, watch, computed} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import { ElMessage } from 'element-plus'
import {Errors} from '~/includes/Errors'
import pmoApi from '../pmoApi'
import nielseniqApi from '../nielseniqApi'

const emit = defineEmits()

const route = useRoute()
const router = useRouter()

const props = defineProps({
    initialForm: {
        type: Object,
        default: {}
    },
})

const errors = new Errors()
const formData = ref(null)
const loading = ref(false)

const fullTree = ref([])
const treeProps = {
    children: 'children',
    label: 'name',
    isLeaf: 'isLeaf',
}
const treeData = ref([])

const tree = ref(null)
const pmoTree = ref(null)

const addCategoriesArray = ref([])
const removeCategoriesArray = ref([])
const windowWidth = ref(window.innerWidth)

const btnLabel = computed(() => {
    if(windowWidth.value > 768) {
        return 'visible'
    } else {
        return 'hidden'
    }
})

onMounted(() => {
    fetchData()
    getTree()
})

async function fetchData() {
    if (route.params.id) {
        loading.value = true
        const {data} = await pmoApi.get(route.params.id)
        formData.value = data

        if (!formData.value.nielsenIQ_categories) {
            formData.value.nielsenIQ_categories = []
        }

        loading.value = false
    } else if (route.params.type) {
        formData.value = {
            type: route.params.type
        }
    }
    setAddCategoriesArray(formData.value.nielsenIQ_categories)
}

function saveSubmit() {
    pmoApi.saveNielsen(formData.value).then((response) => {
        ElMessage.success( 'Saved successfully')
        // emit('saved')
        router.push({name: 'pmo.index'})
    }).catch(error => {
        if (error.response.data.errors) errors.record(error.response.data.errors)
    }).finally(() => loading.value = false)
}

function cancel() {
    // // clearForm()
    // emit('cancel')

    router.push({name: 'pmo.index'})
}

function getTree() {
    nielseniqApi.all({'per_page': 10000}).then((response) => {
        fullTree.value = response.data
    })
}

function addCategories() {
    let nodes = tree.value.getCheckedNodes()

    nodes.forEach( node => {
        addToCategoriesArray(node)
    })

    setNielsenIQCategories()
}

function removeCategories() {
    addCategoriesArray.value = []
    setAddCategoriesArray(formData.value.nielsenIQ_categories)

    let nodes = pmoTree.value.getCheckedNodes()

    nodes.forEach( node => {
        if (removeCategoriesArray.value.indexOf(node.path) == -1) {
            removeCategoriesArray.value.push(node.path)
        }
    })

    removeCategoriesArray.value.forEach((removeItemPath) => {
        let index = addCategoriesArray.value.indexOf(removeItemPath)
        if ((index + 1) > 0) {
            addCategoriesArray.value.splice(index, 1)
        }
    })
    setNielsenIQCategories()
    removeCategoriesArray.value = []
}

function setNielsenIQCategories() {
    formData.value.nielsenIQ_categories = []

    fullTree.value.forEach((item) => {
        let children = getItemChildren(item)
        let newItem = {
            name: item.name,
            path: item.path,
            raw_name: item.name,
            slug: item.slug,
            opened: true,
            children: children,
        }
        if (checkIfPathInArray(item.path)) {
            formData.value.nielsenIQ_categories.push(Object.assign(newItem))
        }

    })
}

function checkIfPathInArray(path) {
    let exists = false

    let pathParts = path.split('/')

    addCategoriesArray.value.forEach((itemPath) => {
        if (exists) return
        let itemPathParts = itemPath.split('/')

        let count = 0
        for ( let i in pathParts) {
            if (pathParts[i]==itemPathParts[i]) {
                count++
            }
        }

        if (count == pathParts.length) {
            exists = true
        }
    })

    return exists
}

function getItemChildren(item) {
    let children = []
    item.children.forEach((child) => {
        if (child.path == undefined) return

        let newItem = {
            name: child.name,
            path: child.path,
            raw_name: child.name,
            slug: child.slug,
            opened: true,
        }

        if (child.children && child.children.length) {
            let childChildren = getItemChildren(child)
            if (childChildren && childChildren.length) {
                newItem.children = childChildren
            }
        }
        if (checkIfPathInArray(child.path)) {
            children.push(Object.assign(newItem))
        }
    })

    return children

}

function addToCategoriesArray(item) {
    if (addCategoriesArray.value.indexOf(item.path) == -1) {
        addCategoriesArray.value.push(item.path)
    }
}

async function loadNode(node, resolve) {
    const lastLevel = 4

    let path = node.data.path||''
    let pathLevel = 0
    if (path) {
        pathLevel = path.split('/').length
    }

    if (pathLevel > lastLevel) {
        resolve([])
        return
    }

    let response = await nielseniqApi.getByParents(path)
    let data = response.data

    data = data.map((item) => {

        item.label = item.name
        item.isroot = (pathLevel == 0)
        item.isLeaf = (pathLevel == lastLevel)

        return item
    })

    resolve(data)
}

function setAddCategoriesArray(categoriesArray) {

    categoriesArray.forEach((item) => {
        if (addCategoriesArray.value.indexOf(item.path) == -1) {
            addCategoriesArray.value.push(item.path)
        }
        if (item.children&&item.children.length) {
            setAddCategoriesArray(item.children)
        }
    })

}

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
#nielseniq-form {
    min-height: 100%;
    .section-col {
        &__tree {
            max-height: 440px;
            overflow-y: auto;
        }
        &--buttons {
            .el-row {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
        }
    }
}
</style>
