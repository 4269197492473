import axios from 'axios'

const API_ENDPOINT = window.config.ssURL + 'users'

export default {

    registeredUsers(params, signal) {
        return axios.get(API_ENDPOINT + '/reg-user-list', {params, signal})
    },

    getStepsByUser(user_id) {
        return axios.get(API_ENDPOINT + '/steps-by-user/' + user_id)
    },

    getLogsByUser(user_id) {
        return axios.get(API_ENDPOINT + '/logs-by-user/' + user_id)
    },

    invitedUsers(params, signal) {
        return axios.get(API_ENDPOINT + '/user-list', {params, signal})
    },

    toggleEnable(params) {
        return axios.post(API_ENDPOINT + '/update-status', params)
    },

    toggleCoaching(params) {
        return axios.post(API_ENDPOINT + '/toggle-coaching', params)
    },

    toggleDirectedSpend(params) {
        return axios.post(API_ENDPOINT + '/toggle-directed-spend', params)
    },

    destroyBulk(user_ids) {
        return axios.post(API_ENDPOINT + '/destroy-bulk', {user_ids})
    },

    sendInvite(params) {
        return axios.post(API_ENDPOINT + '/send-invite', params)
    },

    reSendInvite(params) {
        return axios.post(API_ENDPOINT + '/re-send-invite', params)
    },

    getUserById(user_id) {
        return axios.get(API_ENDPOINT + '/get-user-by-id/' + user_id)
    },

    updateUser(params) {
        return axios.post(API_ENDPOINT + '/update-user', params)
    },
}
