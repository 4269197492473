<template>
    <section id="chapter">
        <h3 class="section-title">
            Chapters
        </h3>

        <div
            v-if="$auth.check('lm_chapters.create')"
            class="page-section"
        >
            <el-button
                v-if="$route.params.lm_id"
                type="primary"
                icon="plus"
                @click="$router.push({name: 'chapters.add', params: {lm_id: $route.params.lm_id}})"
            >
                Add chapter
            </el-button>
        </div>
        <draggable
            v-loading="chaptersLoading"
            :list="chapters"
            item-key="_id"
            class="learning-module__table"
            handle=".learning-module__table-col--handle"
            @start="dragging = true"
            @end="updateItemOrder"
        >
            <template #item="{ element }">
                <el-row
                    :gutter="20"
                    align="middle"
                    justify="space-between"
                    class="learning-module__table-row"
                >
                    <el-col
                        :xs="2"
                        :sm="1"
                        class="learning-module__table-col learning-module__table-col--handle"
                    >
                        <i class="fa fa-align-justify" />
                    </el-col>
                    <el-col
                        :xs="5"
                        :sm="3"
                        class="learning-module__table-col learning-module__table-col--icon"
                    >
                        <el-avatar
                            :size="50"
                            :src="element.icon_url"
                        />
                    </el-col>
                    <el-col
                        :xs="17"
                        :sm="6"
                        class="learning-module__table-col learning-module__table-col--title"
                    >
                        {{ element.title }}
                    </el-col>
                    <el-col
                        :xs="24"
                        :sm="8"
                        class="learning-module__table-col learning-module__table-col--description"
                    >
                        {{ element.description }}
                    </el-col>
                    <el-col
                        :xs="24"
                        :sm="6"
                        class="learning-module__table-col learning-module__table-col--buttons"
                    >
                        <el-button
                            v-if="$auth.check('lm_chapters.edit')"
                            size="small"
                            icon="edit"
                            @click="$router.push({name: 'chapters.edit', params: {lm_id: $route.params.lm_id, chapter_id: element._id}})"
                        >
                            Edit
                        </el-button>
                        <el-button
                            v-if="$auth.check('lm_chapters.delete')"
                            type="danger"
                            size="small"
                            icon="delete"
                            @click="handleDelete(element)"
                        >
                            Delete
                        </el-button>
                    </el-col>
                </el-row>
            </template>
        </draggable>

        <el-pagination
            v-model:current-page="page"
            v-model:page-size="per_page"
            :total="total"
            :page-sizes="[5, 15, 30]"
            hide-on-single-page
            layout="sizes, prev, pager, next, ->, total"
            class="el-pagination--align-right"
            @current-change="fetchData"
            @size-change="fetchData"
        />
    </section>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import draggable from 'vuedraggable'
import { ElMessage, ElMessageBox } from 'element-plus'
import chapterApi from '../../chapterApi'
import {useRoute} from 'vue-router'

const page = ref(1)
const per_page = ref(5)
const total = ref(0)
const route = useRoute()

const chaptersLoading = ref(false)
const chapters = ref(null)

onMounted(() => fetchData())

async function fetchData() {
    chaptersLoading.value = true
    const params = {
        page: page.value,
        limit: per_page.value,
    }

    const {data} = await chapterApi.all(route.params.lm_id, params)
    chapters.value = data.data
    total.value = data.total
    per_page.value = data.per_page
    chaptersLoading.value = false
}

function handleDelete(row) {
    ElMessageBox.confirm('This will permanently delete the chapter. Continue?', 'Warning', {
        type: 'warning'
    }).then(() => {
        chapterApi.destroy(row._id).then((response) => {
            ElMessage.success( 'Deleted successfully')
            fetchData()
        })
    })
}

const applySearch = _.debounce( function() {
    fetchData()
}, 300)

const dragging = ref(false)

function updateItemOrder() {
    let items = chapters.value.map(function(element, index) {
        return { id: element._id, priority: index }
    })
    chapterApi.priority(items).then(response => {
        if(response.data.success) {
            ElMessage.success( 'Saved successfully')
        }
    }).finally(() => dragging.value = false)
}

const cutString = (str) => _.truncate(str, {'length': 24})
</script>
