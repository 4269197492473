<template>
    <div class="page-breadcrumbs">
        <page-breadcrumbs :list="breadcrumbs" />
    </div>
    <h2
        v-if="route.params.id"
        class="page-title"
    >
        {{ editUserTitle }}
    </h2>
    <ss-user-form
        v-loading="loading"
        :initial-form="formData"
        @saved="router.push({name: 'ss-users.registered'})"
        @cancel="router.push({name: 'ss-users.registered'})"
    />
</template>

<script setup>
import {ref, onMounted, computed} from 'vue'
import SsUserForm from './SsUserForm'
import {useRoute, useRouter} from 'vue-router'
import ssUserApi from '../ssUserApi'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'

const route = useRoute()
const router = useRouter()
const formData = ref(null)
const loading = ref(false)
const editUserTitle = ref('Edit user')
const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: 'Registered Users',
        name: 'ss-users.registered'
    },
    {
        title: `Edit user`,
    }
])

onMounted(() => fetchData())

async function fetchData() {
    if (route.params.id) {
        loading.value = true
        const {data} = await ssUserApi.getUserById(route.params.id)
        formData.value = data
        loading.value = false
    }
}
</script>