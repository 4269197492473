import * as ACTIONS from './actions.constants'

export const ACTION_LIST_1 = [
    ACTIONS.INVITED_NOT_REGISTERED,
    ACTIONS.REGISTERED,
    ACTIONS.INVITE_FDM,
    ACTIONS.STARTED_ASPIRATION,
    ACTIONS.FINISHED_MOTIVATION,
    ACTIONS.STARTED_MODULE,
    ACTIONS.FINISHED_MODULE,
    ACTIONS.STARTED_PMO_CATEGORY,
    ACTIONS.FINISHED_PMO_CATEGORY,
    ACTIONS.NOT_VISITED_MY_ITEMS
]

export const ACTION_LIST_2 = [
    ACTIONS.REGISTERED,
    ACTIONS.INVITED_FDM_NOT_REGISTERED,
    ACTIONS.STARTED_ASPIRATION,
    ACTIONS.FINISHED_MOTIVATION,
    ACTIONS.STARTED_MODULE,
    ACTIONS.FINISHED_MODULE,
    ACTIONS.STARTED_PMO_CATEGORY,
    ACTIONS.FINISHED_PMO_CATEGORY,
    ACTIONS.NOT_VISITED_MY_ITEMS,
    ACTIONS.DIRECT_SPEND_NOT_UTILIZED,
    ACTIONS.FIRST_RATE_PRODUCT
]

export const ACTION_LIST_3 = [
    ACTIONS.INVITED_FDM_NOT_REGISTERED,
    ACTIONS.STARTED_ASPIRATION,
    ACTIONS.FINISHED_MOTIVATION,
    ACTIONS.STARTED_MODULE,
    ACTIONS.FINISHED_MODULE,
    ACTIONS.STARTED_PMO_CATEGORY,
    ACTIONS.FINISHED_PMO_CATEGORY,
    ACTIONS.NOT_VISITED_MY_ITEMS,
    ACTIONS.DIRECT_SPEND_NOT_UTILIZED,
    ACTIONS.FIRST_RATE_PRODUCT
]