import axios from 'axios'

const API_ENDPOINT = 'profile'

export default {
    edit(data) {
        return axios.post(API_ENDPOINT + '/update', data)
    },

    changePassword(data) {
        return axios.patch(API_ENDPOINT + '/change-password', data)
    },
}
