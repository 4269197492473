<template>
    <div class="page-breadcrumbs">
        <el-breadcrumb
            separator="/"
            class="breadcrumb"
        >
            <el-breadcrumb-item :to="{path: '/'}">
                ..
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="{name: 'roles.index'}">
                Roles
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                Edit role
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <h2 class="page-title">
        Edit role
    </h2>
    <div class="page-tree">
        <el-tree
            ref="treeRef"
            v-loading="loading"
            :data="permissionsTree"
            show-checkbox
            node-key="id"
            highlight-current
            :props="defaultProps"
            :default-checked-keys="form.permissions"
        />
    </div>
    <div class="page-buttons">
        <el-button
            @click="cancel"
        >
            Cancel
        </el-button>
        <el-button
            type="success"
            @click="saveSubmit"
        >
            Save
        </el-button>
    </div>
</template>

<script setup>
import {ref, onMounted, computed} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import roleApi from '../roleApi'
import {ElMessage} from 'element-plus'

const route = useRoute()
const router = useRouter()
const loading = ref(true)
const role = ref(null)
const form = ref({id: null, permissions: []})
const permissions = ref(null)

onMounted(() => {
    fetchData()
    fetchPermissions()
})

async function fetchData() {
    const {data} = await roleApi.get(route.params.id)
    role.value = data
    form.value.id = data._id
    form.value.permissions = _.map(data.permissions, o => o.name)
}
async function fetchPermissions() {
    loading.value = true
    const {data} = await roleApi.fetchPermissions()
    permissions.value = data
    loading.value = false
}

const treeRef = ref()

const defaultProps = {
    children: 'children',
    label: 'label',
}

const permissionsTree = computed(
    () => _.map(_.groupBy(permissions.value, p => p.split('.')[0]), (o, id) => {
        const children = _.map(o, p => ({id: p, label: p}))
        const label = _.capitalize(id)
            .split('_').join(' ')
            .replace('Lm ', 'LM ')
            .replace('ss ', 'SS ')
            .replace('Pmo', 'PMO')

        return {id, label, children}
    })
)

function saveSubmit() {
    form.value.permissions = treeRef.value.getCheckedKeys(true)
    roleApi.update(form.value)
    ElMessage.success( 'Saved successfully')
    router.push({name: 'roles.index'})
}

function cancel() {
    router.push({name: 'roles.index'})
}
</script>
