import axios from 'axios'

const API_ENDPOINT = window.config.ssURL + 'learning-modules/lessons'

export default {

    all(lesson_id, params) {
        return axios.get(API_ENDPOINT + '/references/' + lesson_id, { params })
    },

    get(id) {
        return axios.get(API_ENDPOINT + '/references/show/' + id)
    },

    add(form) {
        return axios.post(API_ENDPOINT + '/addreference', form)
    },

    update(form) {
        return axios.post(API_ENDPOINT + '/updateRef', form)
    },

    destroy(id) {
        return axios.get(API_ENDPOINT + '/deleteref/' + id)
    },

    priority(items) {
        return axios.post(API_ENDPOINT + '/references/update-priority', {items})
    }
}
