import axios from 'axios'

const API_ENDPOINT = window.config.ssURL + 'categories'

export default {

    all(data) {
        return axios.get(API_ENDPOINT + '/tree', {params: data})
    },

    getByParents(path) {
        return axios.get(API_ENDPOINT + '/get-by-parents/' + path)
    },

    getHierarchy(path) {
        return axios.get(API_ENDPOINT + '/get-hierarchy/' + path)
    },
}
