import {createAuth, useAuth} from '@websanova/vue-auth'
import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js'
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js'
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js'

driverAuthBearer.request = function (req, token) {
    this.drivers.http.setHeaders.call(this, req, {
        Authorization: 'Bearer ' + token
    })

    this.drivers.http.setHeaders.call(this, req, {
        accept: 'application/json'
    })

    const user = this.$vm.state.data
    if (user && req.url.includes(window.config.ssURL)) {
        if (!req.params) req.params = {}
        req.withCredentials = false
        req.params.token = user.ss_token
    }

    if (user && req.url.includes(window.config.retailerUrl)) {
        if (!req.params) req.params = {}
        req.withCredentials = false
        req.params.token = user.retailer_token
    }

    if (user && req.url.includes(window.config.coachingUrl)) {
        if (!req.params) req.params = {}
        req.withCredentials = false
        req.params.token = user.coaching_token
    }
}

export default (app) => {
    app.use(createAuth({
        plugins: {
            http: app.axios,
            router: app.router,
        },
        drivers: {
            http: driverHttpAxios,
            auth: driverAuthBearer,
            router: driverRouterVueRouter,
        },
        options: {
            loginData: {
                url: window.config.baseUrl + '/api/login',
                redirect: {name: 'Dashboard'}
            },
            logoutData: {
                url: window.config.baseUrl + '/api/logout',
                redirect: {name: 'Login'},
                makeRequest: true
            },
            fetchData: {
                url: window.config.baseUrl + '/api/me',
                method: 'POST'
            },
            refreshData: {enabled: false},
            rolesKey: 'permits',
            parseUserData: function (data) {
                return data.data || {}
            },
        }
    }))
}

// export { useAuth }
