import Index from './components/Index'
import Add from './components/Add'
import Edit from './components/Edit'

export default [
    {
        path: 'client-levels/:parent_id?',
        component: Index,
        name: 'client-levels.index',
        meta: {
            auth: 'client_levels.view',
            pageTitle: 'Client Levels',
        },
    },
    {
        path: 'client-levels/add/:parent_id?',
        component: Add,
        name: 'client-levels.add',
        meta: {
            auth: 'client_levels.create',
            pageTitle: 'Add Client Level',
        },
    },
    {
        path: 'client-levels/edit/:id',
        component: Edit,
        name: 'client-levels.edit',
        meta: {
            auth: 'client_levels.edit',
            pageTitle: 'Edit Client Level',
        },
    },
]
