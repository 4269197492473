<template>
    <div v-if="deltaLog">
        <el-row v-loading="loading">
            <el-col
                v-if="props.deltaLog.newData.deltaEvent === 'MODIFIED'"
                :span="24"
                class="scroll"
            >
                <h3>Diff Data</h3>
                <el-row
                    :gutter="10"
                    class="w-100 delta_changes delta_header"
                >
                    <el-col :span="5">
                        Path
                    </el-col>
                    <el-col :span="2">
                        Kind
                        <el-popover
                            placement="left-start"
                            :width="350"
                            trigger="hover"
                        >
                            <template #reference>
                                <el-icon><InfoFilled /></el-icon>
                            </template>
                            <template #default>
                                <h3>kind - indicates the kind of change</h3>
                                <p>
                                    N - indicates a newly added property/element <br>
                                    D - indicates a property/element was deleted <br>
                                    E - indicates a property/element was edited <br>
                                    A - indicates a change occurred within an array
                                </p>
                            </template>
                        </el-popover>
                    </el-col>
                    <el-col :span="8">
                        Lhs
                    </el-col>
                    <el-col :span="8">
                        Rhs
                    </el-col>
                </el-row>
                <el-row
                    v-for="(change, i) in changes"
                    :key="i"
                    :gutter="10"
                    class="w-100 delta_changes"
                >
                    <el-col :span="5">
                        {{ change.path.join(' / ') }}
                    </el-col>
                    <el-col :span="2">
                        {{ change.kind }}
                    </el-col>
                    <el-col :span="8">
                        <pre>{{ change.lhs }}</pre>
                    </el-col>
                    <el-col :span="8">
                        <pre>{{ change.rhs }}</pre>
                    </el-col>
                </el-row>
            </el-col>
            <el-col
                v-if="props.deltaLog.newData.deltaEvent === 'MODIFIED'"
                :span="12"
                class="scroll"
            >
                <h3>Old Product</h3>
                <json-viewer
                    :value="oldProduct"
                    :expand-depth="1"
                    copyable
                    sort
                />
            </el-col>
            <el-col
                :span="12"
                class="scroll"
            >
                <h3>New Product</h3>
                <json-viewer
                    :value="newProduct"
                    :expand-depth="1"
                    copyable
                    sort
                />
            </el-col>
        </el-row>
    </div>
</template>

<script setup>
import {computed, ref} from 'vue'
import JsonViewer from 'vue-json-viewer'
import DeepDiff from 'deep-diff'
import liProductsApi from '~/modules/labelinsight-products/liProductsApi'

const props = defineProps({
    deltaLog: {
        type: Object,
        required: true,
    }
})

const loading = ref(false)
const newProduct = ref(null)
const oldProduct = ref(null)

getProduct()

async function getProduct() {
    loading.value = true
    const {data} = await liProductsApi.getProductByUpc(props.deltaLog.newData.product.upc)
    newProduct.value = _.cloneDeep(data)
    oldProduct.value = _.cloneDeep(data)

    dataToRemove(oldProduct.value, props.deltaLog.newData.product)
    dataToRemove(newProduct.value, props.deltaLog.oldData)

    dataToAdd(oldProduct.value, props.deltaLog.oldData)
    dataToAdd(newProduct.value, props.deltaLog.newData.product)

    loading.value = false
}

function dataToRemove(prod, data) {
    for (const property in data) {
        delete prod[property]
    }
}

function dataToAdd(prod, data) {
    for (const property in data) {
        prod[property] = data[property]
    }
}

const changes = computed(() => DeepDiff(props.deltaLog.oldData, props.deltaLog.newData.product))
</script>
<style lang="scss" scoped>
.delta_changes {
    border-bottom: solid 1px #EEEEEE;
    margin-bottom: 10px;
    &.delta_header {
        font-weight: bold;
    }
}
</style>
