<template>
    <div class="page-breadcrumbs">
        <page-breadcrumbs :list="breadcrumbs" />
    </div>
    <h2 class="page-title">
        {{ title }}
    </h2>

    <el-tabs
        v-model="learningTabValue"
        type="border-card"
        class="learning-tabs"
    >
        <el-tab-pane
            v-for="tab in tabsData"
            :key="tab.name"
            :label="tab.label"
            :name="tab.name"
            :disabled="tab.name !== learningTabValue"
        >
            <el-row :gutter="20">
                <el-col :md="route.params.lm_id ? 12 : 24">
                    <lm-form
                        v-loading="loading"
                        :initial-form="formData"
                        @saved="goToEdit"
                        @cancel0="router.push({name: 'learning_modules.index'})"
                    />
                </el-col>
                <el-col
                    v-show="!loading"
                    :md="12"
                >
                    <chapters/>
                </el-col>
            </el-row>
        </el-tab-pane>
    </el-tabs>
</template>

<script setup>
import {ref, onMounted, computed} from 'vue'
import LmForm from './LmForm'
import {useRoute, useRouter} from 'vue-router'
import lmApi from '../../lmApi'
import Chapters from '../chapter/Chapters'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'
import { LEARNING_COACHES, LEARNING_SS } from "~/store/mutation-types";
import store from "~/store";

const route = useRoute()
const router = useRouter()
const formData = ref(null)
const tabsData = [
    {
        label: 'SeekingSimple',
        name: LEARNING_SS
    },
    {
        label: 'Coaches',
        name: LEARNING_COACHES
    },
]

const title = computed(() => {
    const checked = _.cloneDeep(tabsData).find(item => item.name === learningTabValue.value)
    if (checked) {
        return `${actionName.value} learning module for ${checked.label}`
    }
    return `${actionName.value} learning module for SeekingSimple`
})

const learningTabValue = computed({
    get: () => store.getters['learnings/learningTabValue'],
    set: (value) => store.commit('learnings/switchTab', value)
})
const loading = ref(false)
const actionName = computed(() => route.params.lm_id ? 'Edit' : 'Add')
const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: learningTabValue.value === LEARNING_SS ? 'Learning modules' : 'Coach modules',
        name: 'learning_modules.index'
    },
    {
        title: `${actionName.value} learning module`,
    }
])

onMounted(() => fetchData())

async function fetchData() {
    if (route.params.lm_id) {
        loading.value = true
        const {data} = await lmApi.get(route.params.lm_id)
        formData.value = data
        loading.value = false
    }
}

function goToEdit(id) {
    if (route.name === 'learning_modules.add') {
        router.push({name: 'learning_modules.edit', params: {lm_id: id}})
    }
}
</script>
