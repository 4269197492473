<template>
    <div class="el-row">
        <filter-charts
            :filters="filters"
            quartiles-type="sugar"
            class="el-col el-col-12"
        />
        <filter-charts
            :filters="filters"
            quartiles-type="totalNetCarb"
            class="el-col el-col-12"
        />
    </div>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue'
import FilterCharts from './FilterCharts'
import {useModelWrapper} from '~/includes/composable/modelWrapper'
import bus from '~/includes/Event'

const emit = defineEmits()

const props = defineProps({
    filters: {
        type: Object,
        required: true
    },
})

const filters = useModelWrapper(props, emit, 'filters')

</script>
