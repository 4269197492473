<template>
    <div
        id="scandit-barcode-scanner"
        class="barcode-scanner"
    />
</template>

<script>
import {Barcode, BarcodePicker, ScanSettings, configure} from 'scandit-sdk'

export default {
    name: 'BarcodeScanner',
    data: () => ({
        licenseKey: config.scanditKey,
        configurationOptions: {
            engineLocation: 'https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/',
        },
        scanSettings: {
            enabledSymbologies: [
                Barcode.Symbology.EAN8,
                Barcode.Symbology.EAN13,
                Barcode.Symbology.UPCA,
                Barcode.Symbology.UPCE
            ],
            codeDuplicateFilter: 3000,
        },
    }),
    mounted() {
        let component = this
        configure(this.licenseKey, this.configurationOptions)
            .then(() => {
                return BarcodePicker.create(document.getElementById('scandit-barcode-scanner'), {
                    // enable some common symbologies
                    scanSettings: new ScanSettings(component.scanSettings),
                })
            })
            .then((barcodePicker) => {
                component.barcodePicker = barcodePicker
                this.$emit('barcodePicker', barcodePicker)
                // barcodePicker is ready here, show a message every time a barcode is scanned
                barcodePicker.on('scan', (scanResult) => {
                    this.$emit('scan', scanResult)
                    this.stopScanner()
                })
            })
    },
    unmounted() {
        this.stopScanner()
    },
    methods: {
        stopScanner() {
            if (this.barcodePicker) {
                this.barcodePicker.accessCamera = false
                this.barcodePicker.destroy()
            }
        }
    }
}
</script>

<style lang="scss">
    .barcode-scanner {
        max-width: 1280px;
        height:100%;
        margin:0 auto;
        .scandit {
            .scandit-logo {
                display: none;
            }
            .scandit-laser {
                img {
                    display: none;
                }
            }
            .scandit-barcode-picker {
                max-width: 100% !important;
                &:before {
                    content: "";
                    position: absolute;
                    z-index: 5;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    background-image: url("/images/scaner-bg.png");
                    background-size: cover;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
                .scandit-video {
                    object-fit: cover !important;
                }
            }
        }
    }
</style>
