import axios from 'axios'

const API_ENDPOINT = window.config.ssURL + 'learning-modules/chapters'

export default {

    all(lm_id, params) {
        return axios.get(API_ENDPOINT + '/' + lm_id, { params })
    },

    get(id) {
        return axios.get(API_ENDPOINT + '/get/' + id)
    },

    add(form) {
        return axios.post(API_ENDPOINT + '/add', form)
    },

    update(form) {
        return axios.post(API_ENDPOINT + '/update', form)
    },

    destroy(id) {
        return axios.post(API_ENDPOINT + '/delete/' + id)
    },

    priority(items) {
        return axios.post(API_ENDPOINT + '/update-priority', {items})
    }
}
