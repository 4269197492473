<template>
    <div class="ss-switcher">
        <el-select
            v-model="current_url"
            placeholder="Select action"
        >
            <el-option
                v-for="url in urls"
                :key="url"
                :label="url"
                :value="url"
            />
        </el-select>
    </div>
</template>

<script setup>
import {ref, watch} from 'vue'

const urls = [
    window.config.ssURL1,
    window.config.ssURL2,
]

const current_url = ref(window.config.ssURL)

watch(
    () => current_url.value,
    val => {
        localStorage.setItem('ssURL', val)
        window.location.reload()
    }
)
</script>

<style lang="scss">
.ss-switcher {
    display: flex;
    align-items: center;
}
</style>
