<template>
    <el-button
        type="primary"
        class="w-100"
        plain
        @click="openModal"
    >
        Categorize
    </el-button>
    <el-dialog
        v-model="modalShow"
        title="Categorize"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :destroy-on-close="false"
        @close="handleClose"
    >
        <div>
            <el-switch
                v-model="shortViewCategories"
                v-loading="loadTree"
                class="mb-2"
                active-text="Short view"
                inactive-text="Tree view"
                @change="switchTreeView"
            />
        </div>
        <drop-down-categories
            v-if="shortViewCategories"
            v-model="drop_down_pathes"
            :key="dropDownCategoriesKey"
        />
        <el-tree
            v-else
            ref="treeRef"
            v-loading="loadTree"
            :data="treeData"
            node-key="path"
            :props="treeProps"
            check-strictly="true"
        >
            <template #default="{ node }">
                <span v-if="isLeaf(node)">
                    <el-checkbox
                        v-model="node.checked"
                        class="last-child-checkbox"
                        :checked="chosenCategory === node.data"
                        @change="setCategory(node)"
                    />
                    <span>{{ node.label }}</span>
                </span>
                <span v-else>
                    <el-checkbox
                        class="last-child-checkbox"
                        disabled
                    />
                    <span>{{ node.label }}</span>
                </span>
            </template>
        </el-tree>

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="handleClose">
                    Cancel
                </el-button>
                <el-button
                    type="success"
                    @click="handleOk"
                >
                    Apply
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, computed } from 'vue'
import store from '~/store'
import nielseniqApi from '~/modules/pmo/nielseniqApi'
import DropDownCategories from '~/modules/labelinsight-products/components/filter/DropDownCategories'

const emit = defineEmits()

const modalShow = ref(false)
const chosenCategory = ref(null)
const loadTree = ref(false)
const dropDownCategoriesKey = ref(0);

const shortViewCategories = ref(true)
const drop_down_pathes = ref([])

const treeData = ref([])
const treeRef = ref(null)
const treeProps = {
    children: 'children',
    label: 'name',
    isLeaf: 'isLeaf',
}
const storeTreeData = ref('')
const disableCheckbox = (node) => {
    return chosenCategory.value && chosenCategory.value.path !== node.data.path
}

const isLeaf = (node) => {
    return node.childNodes.length === 0
}

async function loadNode() {
    const { data } = await nielseniqApi.all()
    treeData.value = data
    loadTree.value = false
}

const reset = () => {
    loadTree.value = true
    treeData.value = []
}

const setCategory = (node) => {
    if (chosenCategory.value && chosenCategory.value.path !== node.data.path) {
        const previousNode = treeRef.value.getNode(chosenCategory.value.path)
        if (previousNode) {
            previousNode.checked = false
        }
        node.checked = true
        chosenCategory.value = node.data
    } else if (chosenCategory.value) {
        chosenCategory.value = null
    } else {
        node.checked = true
        chosenCategory.value = node.data
    }
}

const openModal = async () => {
    storeTreeData.value = store.getters['categorization/chosenCategory']
    drop_down_pathes.value = null
    loadTree.value = true
    modalShow.value = true
    dropDownCategoriesKey.value = dropDownCategoriesKey.value + 1

    if(storeTreeData.value && storeTreeData.value.path) {
        drop_down_pathes.value = storeTreeData.value.path
    }
    // chosenCategory.value = null
    if (!treeData.value.length) {
        await loadNode()
    }
    if (storeTreeData.value && storeTreeData.value.path && treeRef.value) {
        chosenCategory.value = storeTreeData.value
        setTimeout(() => {
            treeRef.value.setCurrentNode((storeTreeData.value))
            treeRef.value.setCheckedKeys([storeTreeData.value.path])
            loadTree.value = false
        }, 0)
    } else {
        loadTree.value = false
    }
}

const switchTreeView = () => {
    if (! shortViewCategories.value) {
        setTimeout(() => {
            openModal()
        }, 0)
    }
}

const handleClose = () => {
    modalShow.value = false
}

const handleOk = () => {
    modalShow.value = false

    if ( shortViewCategories.value ) {
        //getHierarchy
        if (!drop_down_pathes.value.length) {
            return
        }
        nielseniqApi.getHierarchy(drop_down_pathes.value[0]).then(response => {
            let categoryData = response.data
            chosenCategory.value = categoryData.segment||{}

            let categoryHierarchy = {
                department: categoryData.department?.raw_name,
                superCategory: categoryData.superCategory?.raw_name,
                category: categoryData.category?.raw_name,
                subCategory: categoryData.subCategory?.raw_name,
                segment: categoryData.segment?.raw_name,
                original_name: chosenCategory.value?.raw_name,
            }

            store.commit('categorization/setChosenCategory',  chosenCategory.value)
            emit('setCategorize', categoryHierarchy)
        }).catch(error => {
            console.log(error)
        })

    } else {
        store.commit('categorization/setChosenCategory',  chosenCategory.value)
        emit('setCategorize', convertToCategorizeFormat())
    }

}

const convertToCategorizeFormat = () => {
    const categoryData = chosenCategory.value?.path?.split('/')
    const departmentValue = _.cloneDeep(treeData.value)?.find(category => category.slug === categoryData[0]) || ''
    const superCategoryValue = departmentValue?.children?.find(category => category.slug === categoryData[1]) || ''
    const categoryValue = superCategoryValue?.children?.find(category => category.slug === categoryData[2]) || ''
    const subCategoryValue = categoryValue?.children?.find(category => category.slug === categoryData[3]) || ''
    const segmentValue = subCategoryValue?.children?.find(category => category.slug === categoryData[4]) || ''
    return {
        department: departmentValue?.raw_name,
        superCategory: superCategoryValue?.raw_name,
        category: categoryValue?.raw_name,
        subCategory: subCategoryValue?.raw_name,
        segment: segmentValue?.raw_name,
        original_name: chosenCategory.value?.raw_name,
    }
}

</script>

<style scoped>
.last-child-checkbox {
    margin-right: 10px;
}
</style>
