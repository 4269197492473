import Index from './components/Index'
import EditName from './components/EditName'
import EditSources from './components/EditSources'
import EditTargets from './components/EditTargets'

export default [
    {
        path: 'product-mapping-tool',
        component: Index,
        name: 'product-mapping-tool.index',
        meta: {
            auth: 'product_mapping_tool.view',
            pageTitle: 'Product Mapping Tool',
        },
    },
    {
        path: 'product-mapping-tool/add',
        component: EditName,
        name: 'product-mapping-tool.add',
        meta: {
            auth: 'product_mapping_tool.create',
            pageTitle: 'Add product mapping',
        },
    },
    {
        path: 'product-mapping-tool/edit/:id',
        component: EditName,
        name: 'product-mapping-tool.edit',
        meta: {
            auth: 'product_mapping_tool.edit',
            pageTitle: 'Edit product mapping',
        },
    },
    {
        path: 'product-mapping-tool/edit-sources/:id',
        component: EditSources,
        name: 'product-mapping-tool.edit-sources',
        meta: {
            auth: 'product_mapping_tool.edit',
            pageTitle: 'Edit product mapping',
        },
    },
    {
        path: 'product-mapping-tool/edit-targets/:id',
        component: EditTargets,
        name: 'product-mapping-tool.edit-targets',
        meta: {
            auth: 'product_mapping_tool.edit',
            pageTitle: 'Edit product mapping',
        },
    },

]
