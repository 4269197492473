<template>
    <section id="invited_user">
        <div class="page-breadcrumbs">
            <page-breadcrumbs :list="breadcrumbs" />
        </div>
        <h2 class="page-title">
            Invited Coaches
        </h2>
        <div class="page-section">
            <el-button
                type="primary"
                icon="top-right"
                @click="$router.push({name: 'coaching-users.invite'})"
            >
                Invite
            </el-button>
        </div>
        <div class="page-section page-section--bordered-form">
            <coaching-user-filter
                v-model="filters"
            />
        </div>

        <!-- table -->
        <el-table
            v-loading="usersLoading"
            :data="users"
            highlight-current-row
            class="w-100"
        >
            <el-table-column
                prop="name"
                label="Name"
                min-width="180"
            />
            <el-table-column
                prop="email"
                label="Email"
                min-width="200"
            />
            <el-table-column
                prop="client_level"
                label="Client Level"
                min-width="200"
            >
                <template #default="scope">
                    {{ scope.row.client_level }}
                </template>
            </el-table-column>
            <el-table-column
                label="Invite date"
                prod="created_at"
                min-width="100"
            >
                <template #default="scope">
                    {{ $formatDate(scope.row.created_at) }}
                </template>
            </el-table-column>
            <el-table-column
                prop="status"
                label="Invite status"
                min-width="120"
                class-name="el-table__cell--capitalize"
            />
            <el-table-column
                label="Re-Invite"
                min-width="120"
            >
                <template #default="scope">
                    <el-button
                        v-if="$auth.check('invited_coaching_users.invite') && scope.row.status !== 'Registered'"
                        :loading="sendingInvite[scope.row._id]"
                        type="primary"
                        size="small"
                        icon="top-right"
                        @click="reInvite(scope.row._id)"
                    >
                        Re-Invite
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column
                label="Registered date"
                prod="created_at"
                min-width="100"
            >
                <template #default="scope">
                    <span v-if="scope.row.status === 'Registered'">
                        {{ $formatDate(scope.row.updated_at) }}
                    </span>
                </template>
            </el-table-column>
        </el-table>

        <!-- pagination -->
        <el-pagination
            v-model:current-page="page"
            :page-size="per_page"
            :total="total"
            hide-on-single-page
            layout="prev, pager, next, ->, total"
            class="el-pagination--align-right"
            @current-change="fetchData"
            @size-change="fetchData"
        />
    </section>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import coachingUserApi from '../coaсhingUserApi'
import CoachingUserFilter from './CoachingUserFilter'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'
import ClientLevelsApi from "~/modules/client_levels/clientLevelsApi";

const usersLoading = ref(false)
const users = ref(null)
const total = ref(0)
const filters = ref({})
const page = ref(1)
const per_page = ref(10)
const clientLevels = ref([])
const isClientLevelLoaded = ref(false)
const isUsersLoaded = ref(false)
const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: 'Invited Coaches',
    }
])

onMounted(() => {
    fetchClientLevels()
    fetchData()
})

watch(
    () => filters.value,
    val => applySearch(),
    { deep: true }
)

function fetchClientLevels() {
    ClientLevelsApi.all().then((response) => {
        clientLevels.value = response.data
        isClientLevelLoaded.value = true
        getClientLevelPath()
    })
}

async function fetchData() {
    usersLoading.value = true
    isUsersLoaded.value = false
    let filter = {...filters.value}
    let params = {
        page: page.value,
        ...filter
    }
    const {data} = await coachingUserApi.invitedUsers(params)
    users.value = data.data
    total.value = data.total
    per_page.value = data.per_page
    usersLoading.value = false
    isUsersLoaded.value = true
    getClientLevelPath()
}

const applySearch = _.debounce( function() {
    fetchData()
}, 300)


const sendingInvite = ref([])

function reInvite(id) {
    sendingInvite.value[id] = true
    coachingUserApi.reSendInvite({user_invite_id: id}).then(() => {
        ElMessage.success('Invite was sent')
        sendingInvite.value[id] = false
    })
}

function getClientLevelPath() {
    if (!(isClientLevelLoaded.value && isUsersLoaded.value)) {
        return false
    }
    users.value = users.value.map(user => {
        let pathArr = []

        if (user.client_level_id == null) {
            return user
        }
        const userClientLevel = clientLevels.value.find(level => level._id === user.client_level_id)
        if (userClientLevel == null) {
            return user
        }

        pathArr.push(userClientLevel.name)
        let parentLevelId = userClientLevel.parent_id

        while (parentLevelId !== null && parentLevelId !== undefined) {
            const parentLevel = clientLevels.value.find(level => level._id === parentLevelId)
            const parentName = parentLevel?.name
            parentLevelId = parentLevel?.parent_id
            pathArr = [...pathArr, parentName]
        }

        user.client_level = pathArr.reverse().join(' > ')
        return user
    })
    usersLoading.value = false
}
</script>
