import axios from 'axios'

const API_ENDPOINT = 'labelinsight/product-mapping'

export default {

    all(params) {
        return axios.get(API_ENDPOINT + '', {params})
    },
    get(id) {
        return axios.get(API_ENDPOINT + '/' + id)
    },
    delete(id) {
        let params = {
            _id: id
        }
        return axios.post(API_ENDPOINT + '/delete', params)
    },
    completeProductMapping(params) {
        return axios.post(API_ENDPOINT + '/complete', params)
    },
    saveProductMapping(params) {
        return axios.post(API_ENDPOINT + '/save', params)
    },
    export() {
        return axios.get(API_ENDPOINT + '/export', {responseType: 'blob'})
    },
    import(data) {
        return axios.post(API_ENDPOINT + '/import', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    deleteMappedProducts(params) {
        // params = {
        //     gtin14: gtin14,
        //     productMappingGtin14: productMappingGtin14,
        // }
        return axios.post(API_ENDPOINT + '/deleteMappedProducts', params)
    },
    addSelectAllFiltered(params) {
        return axios.post(API_ENDPOINT + '/addAllFiltered', params)
    }
}
