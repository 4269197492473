<template>
    <div class="page-breadcrumbs">
        <page-breadcrumbs :list="breadcrumbs" />
    </div>
    <h2 class="page-title">
        {{ actionName }} company
    </h2>
    <company-form
        v-loading="loading"
        :initial-form="formData"
        @saved="router.push({name: 'companies.index'})"
        @cancel="router.push({name: 'companies.index'})"
    />
</template>

<script setup>
import {ref, onMounted, computed} from 'vue'
import CompanyForm from './CompanyForm'
import {useRoute, useRouter} from 'vue-router'
import companyApi from '../companyApi'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'

const route = useRoute()
const router = useRouter()
const formData = ref(null)

const loading = ref(false)

const actionName = computed(() => route.params.id ? 'Edit' : 'Add')

const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: 'Companies',
        name: 'companies.index'
    },
    {
        title: `${actionName.value} company`,
    }
])

onMounted(() => fetchData())

async function fetchData() {
    if (route.params.id) {
        loading.value = true
        const {data} = await companyApi.get(route.params.id)
        formData.value = data
        loading.value = false
    }
}
</script>
