
<template>
    <div class="page-breadcrumbs">
        <page-breadcrumbs :list="breadcrumbs" />
    </div>
    <h2 class="page-title">
        {{ title }}
    </h2>

    <div>
        <cl-form
            v-loading="loading"
            :initial-form="formData"
            @saved="goToEdit"
            @cancel0="cancel"
        />
    </div>
</template>

<script setup>
import {ref, onMounted, computed} from 'vue'
import ClForm from './ClForm'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'
import {useRoute, useRouter} from 'vue-router'
import clientLevelsApi from "../clientLevelsApi";

const route = useRoute()
const router = useRouter()
const formData = ref(null)

const title = 'Edit client level'

const loading = ref(false)

const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: 'Client levels',
        name: 'client-levels.index', params: {parent_id: route.params.parent_id}
    },
    {
        title: 'Edit client level',
    }
])

onMounted(() => fetchData())

async function fetchData() {
    if (route.params.id) {
        loading.value = true
        const {data} = await clientLevelsApi.get(route.params.id)
        formData.value = data
        formData.value.has_starts_points_system =  data.has_starts_points_system || false
        loading.value = false

        updateBreadcrumb()
    }
}

function goToEdit(id) {
    router.push({name: 'client-levels.index', params: {parent_id: formData.value.parent_id}})
}

function getParents(id) {
    return clientLevelsApi.getParents(id)
}

function updateBreadcrumb() {
    if (formData.value.parent_id) {
        getParents(formData.value._id).then((parents) => {
            let parentsData = parents.data;
            parentsData = parentsData.reverse();
            breadcrumbs.value = [
                {
                    title: '..',
                    path: '/'
                },
                {
                    title: 'Client levels',
                    path: '/client-levels'
                },
                ...parentsData.map((parent) => {
                    return {
                        title: parent.name,
                        path: `/client-levels/${parent._id}`
                    }
                }),
                {
                    title: 'Edit client level',
                }
            ]
        })
    }
}

function cancel() {
    router.push({name: 'client-levels.index', params: {parent_id: formData.value.parent_id}})
}
</script>
