<template>
    <el-button @click="exportData">
        Export
    </el-button>
    <el-button @click="showImportModal = true">
        Import
    </el-button>
    <el-dialog
        v-model="showImportModal"
        title="Import Product Mapping"
        width="30%"
        @close="showImportModal = false"
    >
        <el-form
            ref="importForm"
            :model="importForm"
        >
            <div>
                <p>Warning: Importing will <strong>overwrite all existing data</strong>.</p>
                <p>Make sure you have a backup of your data before importing.</p>
            </div>
            <el-form-item label="File">
                <el-upload
                    ref="upload"
                    :auto-upload="false"
                    :file-list="fileList"
                    :limit="1"
                    :show-file-list="true"
                    accept=".json"
                    :on-change="onChangeHandler"
                >
                    <el-button
                        size="small"
                        type="primary"
                    >
                        Select File
                    </el-button>
                    <template #tip>
                        <div class="el-upload__tip">
                            Only .json files are allowed
                        </div>
                    </template>
                </el-upload>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="showImportModal = false">Cancel</el-button>
                <el-button
                    v-loading.fullscreen.lock="loading"
                    type="primary"
                    @click="importData"
                >Import</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref } from 'vue'
import {ElMessage, ElMessageBox} from 'element-plus'
import productMappingApi from '../productMappingApi'

const emit = defineEmits()

const showImportModal = ref(false)
const importForm = ref({})
const upload = ref(null)
const fileList = ref([])

const fileData = ref(null)
const loading = ref(false)

const exportData = () => {
    productMappingApi.export().then((response) => {
        const href = URL.createObjectURL(response.data)
        let filename = response.headers['content-disposition'].split('filename=')[1]
        filename = filename.replace(/"/g, '')

        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(href)
    })
}

const importData = () => {
    if(!fileData.value) {
        return
    }

    const data = new FormData()
    data.append('file', fileData.value)

    loading.value = true

    productMappingApi.import(data).then((response) => {
        fileList.value = []
        fileData.value = null

        setTimeout(() => {
            showImportModal.value = false

            loading.value = false
            ElMessage({
                message: 'Imported successfully',
                type: 'success'
            })

            emit('refresh')
        }, 20000)

    })
}

const onChangeHandler = (file) => {
    fileData.value = file.raw
}
</script>
