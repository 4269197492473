<template>
    <div class="page-breadcrumbs">
        <el-breadcrumb
            separator="/"
            class="breadcrumb"
        >
            <el-breadcrumb-item :to="{path: '/'}">
                ..
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="{name: 'notifications.index'}">
                Notifications
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                Edit notification
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <h2 class="page-title">
        Edit notification
    </h2>
    <notification-form
        v-loading="loading"
        :initial-form="formData"
        @cancel="router.push({name: 'notifications.index'})"
    />
</template>

<script setup>
import {ref, onMounted} from 'vue'
import NotificationForm from './form/NotificationForm'
import {useRoute, useRouter} from 'vue-router'
import notificationApi from '../notificationApi'

const route = useRoute()
const router = useRouter()
const formData = ref(null)
const loading = ref(false)

onMounted(() => fetchData())

async function fetchData() {
    loading.value = true
    const {data} = await notificationApi.get(route.params.id)
    formData.value = data.data
    loading.value = false
}
</script>
