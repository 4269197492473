<template>
    <section id="registered_user">
        <div class="page-breadcrumbs">
            <page-breadcrumbs :list="breadcrumbs" />
        </div>
        <h2 class="page-title">
            Registered Coaches
        </h2>
        <div class="page-section page-section--bordered-form">
            <coaching-user-registered-filter
                v-model="filters"
            />
        </div>
        <div
            v-if="$auth.check('registered_coaching_users.delete') && userSelected.length > 0"
            class="page-section"
        >
            <el-button
                :loading="deleting"
                type="danger"
                plain
                icon="delete"
                @click="handleDelete"
            >
                Delete selected users
            </el-button>
        </div>

        <!-- table -->
        <el-table
            ref="usersTableRef"
            v-loading="usersLoading"
            :data="users"
            highlight-current-row
            class="w-100"
            @selection-change="handleSelectionChange"
        >
            <el-table-column
                type="selection"
                width="55"
            />
            <el-table-column
                prop="name"
                label="Name"
                min-width="180"
            />
            <el-table-column
                prop="email"
                label="Email"
                min-width="200"
            />
            <el-table-column
                prop="client_level"
                label="Client Level"
                min-width="200"
            >
                <template #default="scope">
                    {{ scope.row.client_level }}
                </template>
            </el-table-column>
            <el-table-column
                label="Step"
                min-width="150"
                show-overflow-tooltip
            >
                <template #default="scope">
                    <template v-if="scope.row.last_step.path != null">
                        <div
                            class="el-link--wrap"
                            @click="handleShowSteps(scope.row._id)"
                            v-html="modifyString(scope.row.last_step.path, '/')"
                        />
                    </template>
                    <template v-else>
                        {{ scope.row.last_step.path }}
                    </template>
                </template>
            </el-table-column>
            <el-table-column
                prop="points"
                label="Points"
                min-width="120"
            />
            <el-table-column
                prop="log"
                label="Log"
                min-width="100"
            >
                <template #default="scope">
                    <el-button
                        type="primary"
                        size="small"
                        icon="tickets"
                        @click="handleShowLog(scope.row._id)"
                    >
                        Show
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column
                prop="assign"
                label="Assign"
                min-width="180"
            >
                <template #default="scope">
                    <el-button
                        v-if="$auth.check('registered_coaching_users.assign')"
                        size="small"
                        icon="edit"
                        @click="onAssignClientLevels(scope.row)"
                    >
                        Assign Client Levels
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column
                prop="status"
                label="Enabled"
                min-width="100"
            >
                <template #default="scope">
                    <el-switch
                        v-if="$auth.check('registered_coaching_users.disable')"
                        v-model="scope.row.status"
                        :loading="enabling[scope.row._id]"
                        style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                        @change="toggleEnable(scope.row)"
                    />
                </template>
            </el-table-column>
        </el-table>

        <!-- pagination -->
        <el-pagination
            v-model:current-page="page"
            :page-size="perPage"
            :total="total"
            hide-on-single-page
            layout="prev, pager, next, ->, total"
            class="el-pagination--align-right"
            @current-change="fetchData"
            @size-change="fetchData"
        />

        <el-dialog
            v-model="showStepsModal"
            destroy-on-close
            title="Visited pages"
        >
            <UserSteps :user-id="userId" />
        </el-dialog>

        <el-dialog
            v-model="showLogModal"
            destroy-on-close
            title="Logs"
        >
            <UserLogs :user-id="userId" />
        </el-dialog>
    </section>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import coachingUserApi from '../coaсhingUserApi'
import CoachingUserRegisteredFilter from './CoachingUserRegisteredFilter'
import UserLogs from './UserLogs'
import UserSteps from './UserSteps'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'
import { useRouter } from "vue-router";
import ClientLevelsApi from "~/modules/client_levels/clientLevelsApi";

const usersLoading = ref(false)
const users = ref(null)
const total = ref(0)
const filters = ref({currently_on: true})
const page = ref(1)
const perPage = ref(10)
const usersTableRef = ref()
const userSelected = ref([])
const clientLevels = ref([])
const isClientLevelLoaded = ref(false)
const isUsersLoaded = ref(false)
const deleting = ref(false)
const router = useRouter()
const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: 'Registered Coaches',
    }
])

onMounted(() => {
    fetchClientLevels()
    fetchData()
})

watch(
    () => filters.value,
    val => applySearch(),
    { deep: true }
)

function fetchClientLevels() {
    ClientLevelsApi.all().then((response) => {
        clientLevels.value = response.data
        isClientLevelLoaded.value = true
        getClientLevelPath()
    })
}

async function fetchData() {
    usersLoading.value = true
    isUsersLoaded.value = false
    let params = {
        page: page.value,
        ...filters.value
    }
    const {data} = await coachingUserApi.registeredUsers(params)
    users.value = data.data
    total.value = data.total
    perPage.value = data.per_page
    usersLoading.value = false
    isUsersLoaded.value = true
    getClientLevelPath()
}

const applySearch = _.debounce( function() {
    fetchData()
}, 300)

function handleSelectionChange(val) {
    userSelected.value = val
}

function handleDelete() {
    let user_w_subusers = []

    _.forEach(userSelected.value, u => {
        if (u.subusers.length > 0) {
            user_w_subusers.push(u)
        }
    })

    const user_mailes = userSelected.value.map(o => o.email).join(', ')

    ElMessageBox.confirm('This will permanently delete coach(s): <br>' + user_mailes, 'Attention', {
        type: 'warning',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        dangerouslyUseHTMLString: true
    }).then(() => {
        usersLoading.value = true
        getAuthCode()
    })
}

async function fetchCreateBooking(id) {
    usersLoading.value = true
    const responseData = (await coachingUserApi.getAuthCode()).data
    navigator.clipboard.writeText(responseData.user_code)
    window.open(responseData.verification_uri)
    ElMessageBox.confirm(responseData.message).then(async () => {
        await coachingUserApi.createBooking(id)
        usersLoading.value = false
    }).catch((error) => {
        ElMessage.error('Canceled')
    })
}

async function fetchCreateStaff(id) {
    usersLoading.value = true
    const responseData = (await coachingUserApi.getAuthCode()).data
    navigator.clipboard.writeText(responseData.user_code)
    window.open(responseData.verification_uri)
    ElMessageBox.confirm(responseData.message).then(async () => {
        await coachingUserApi.createStaff(id)
        usersLoading.value = false
    }).catch((error) => {
        ElMessage.error('Canceled')
    })
}

function onAssignClientLevels(row) {
    if (row.ms_creating == 'true') {
        ElMessageBox.alert('A booking for this coach is creating! Please wait a couple of minute and reload the page.', 'Forbidden', {
            type: 'warning',
            confirmButtonText: 'Ok',
        })
    } else if (!row.booking_id && !row.staff_id) {
        ElMessageBox.confirm('The coach doesn\'t have a booking and a staff!', 'Forbidden', {
            type: 'warning',
            confirmButtonText: 'Create Booking',
            cancelButtonText: 'Cancel',
        }).then(() => {
            fetchCreateBooking(row._id)
        })
    } else if (!row.staff_id) {
        ElMessageBox.confirm('The coach doesn\'t have a staff in a his booking!', 'Forbidden', {
            type: 'warning',
            confirmButtonText: 'Create Staff',
            cancelButtonText: 'Cancel'
        }).then(() => {
            fetchCreateStaff(row._id)
        })
    } else {
        router.push({ name: 'coaching-users.assign', params: { id: row._id } })
    }
}
function getAuthCode() {
    coachingUserApi.getAuthCode().then(response => {
        usersLoading.value = false
        navigator.clipboard.writeText(response.data.user_code)
        window.open(response.data.verification_uri)
        const verifyText = `Please enter this code "${response.data.user_code}" on the "https://login.microsoftonline.com/common/oauth2/deviceauth" page in order to completely remove the coach from the Booking page.`
        ElMessageBox.alert(verifyText, '', {
            customStyle: 'min-width: 550px'
        }).then(() => {
            usersLoading.value = true
            removeCoach()
        }).catch((error) => {
            usersLoading.value = false
            ElMessage.error('Canceled')
        })
    })
}

function removeCoach() {
    deleting.value = true
    const user_emails = userSelected.value.map(u => u.email)
    coachingUserApi.removeCoach({ emails: user_emails }).then(() => {
        fetchData()
    }).catch(error => {
        console.error(error)
    }).finally(() =>
        deleting.value = false
    )
}

const showStepsModal = ref(false)
const userId = ref(null)

function handleShowSteps(user_id) {
    showStepsModal.value = true
    userId.value = user_id
}

const showLogModal = ref(false)

function handleShowLog(user_id) {
    showLogModal.value = true
    userId.value = user_id
}

const enabling = ref([])

function toggleEnable(user) {
    enabling.value[user._id] = true
    coachingUserApi.toggleEnable({
        user_email: user.email,
        status: user.status
    }).then(() => {
        enabling.value[user._id] = false
    })
}

const enablingCoaching = ref([])

function getUserRole(user) {
    let role = user.type === 'normal' ? 'Primary' : 'Sub Account'
    return role += user.is_fdm === true || user.is_fdm === 'true' ? '+ FDM' : ''
}

function modifyString(str, separator) {
    return  str.split(separator).join(' <span>/</span> ')
}

function getClientLevelPath() {
    if (!(isClientLevelLoaded.value && isUsersLoaded.value)) {
        return false
    }
    users.value = users.value.map(user => {
        let pathArr = []

        if (user.client_level_id == null) {
            return user
        }
        const userClientLevel = clientLevels.value.find(level => level._id === user.client_level_id)
        if (userClientLevel == null) {
            return user
        }

        pathArr.push(userClientLevel.name)
        let parentLevelId = userClientLevel.parent_id

        while (parentLevelId !== null && parentLevelId !== undefined) {
            const parentLevel = clientLevels.value.find(level => level._id === parentLevelId)
            const parentName = parentLevel?.name
            parentLevelId = parentLevel?.parent_id
            pathArr = [...pathArr, parentName]
        }

        user.client_level = pathArr.reverse().join(' > ')
        return user
    })
    usersLoading.value = false
}
</script>
