<template>
    <section id="dash_user">
        <div class="page-breadcrumbs">
            <page-breadcrumbs :list="breadcrumbs" />
        </div>
        <h2 class="page-title">
            Dashboards {{ pluralizeRole }}
        </h2>
        <el-row
            :gutter="15"
            justify="space-between"
        >
            <el-col
                class="col-auto"
            >
                <dash-user-filter
                    v-model="filters"
                />
            </el-col>
            <el-col
                v-if="$auth.check(addPermission)"
                class="col-auto"
            >
                <el-button
                    type="primary"
                    icon="plus"
                    @click="$router.push({name: addRouteName})"
                >
                    {{ props.role === 'Coach' ? 'Invite' : 'Add' }}
                </el-button>
            </el-col>
        </el-row>

        <!-- table -->
        <el-table
            v-loading="usersLoading"
            :data="users"
            highlight-current-row
            class="w-100"
        >
            <el-table-column
                prop="name"
                label="Name"
                min-width="180"
            >
                <template #default="scope">
                    {{ scope.row.first_name }} {{ scope.row.last_name }}
                </template>
            </el-table-column>
            <el-table-column
                prop="email"
                label="Email"
                min-width="200"
            />
            <el-table-column
                :sort-orders="sortOrders"
                prop="updated_at"
                label="Updated"
                width="130"
            >
                <template #default="updated_at">
                    {{ $formatDate(updated_at.row.updated_at) }}
                </template>
            </el-table-column>
            <el-table-column
                label="Actions"
                min-width="180"
                align="right"
            >
                <template #default="scope">
                    <el-button
                        v-if="$auth.check(editPermission)"
                        size="small"
                        icon="edit"
                        @click="$router.push({name: editRouteName, params: {id: scope.row._id}})"
                    >
                        Edit
                    </el-button>
                    <el-button
                        v-if="$auth.check(deletePermission)"
                        type="danger"
                        size="small"
                        icon="delete"
                        @click="handleDelete(scope.row)"
                    >
                        Delete
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- pagination -->
        <el-pagination
            v-model:current-page="page"
            :page-size="per_page"
            :total="total"
            hide-on-single-page
            layout="prev, pager, next, ->, total"
            class="el-pagination--align-right"
            @current-change="fetchData"
            @size-change="fetchData"
        />
    </section>
</template>

<script setup>
import {ref, onMounted, watch, computed} from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import dashUserApi from '../dashUserApi'
import DashUserFilter from './DashUserFilter'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'

const usersLoading = ref(false)
const users = ref(null)
const total = ref(0)
const filters = ref({search: ''})
const page = ref(1)
const per_page = ref(10)

const props = defineProps({
    role: {
        type: String,
        required: true,
    },
})

const pluralizeRole = computed(() => _.toLower(props.role) + (props.role === 'Coach' ? 'es' : 's'))

const addRouteName = computed(() => 'dashboards-' + pluralizeRole.value + '.add')
const addPermission = computed(() => 'dashboards_' + pluralizeRole.value + '.create')
const editRouteName = computed(() => 'dashboards-' + pluralizeRole.value + '.edit')
const editPermission = computed(() => 'dashboards_' + pluralizeRole.value + '.edit')
const deletePermission = computed(() => 'dashboards_' + pluralizeRole.value + '.delete')
const assignRouteName = computed(() => 'dashboards-' + pluralizeRole.value + '.assign')
const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: `Dashboards ${pluralizeRole.value}`,
    }
])

onMounted(() => fetchData())

watch(
    () => filters.value,
    val => applySearch(),
    { deep: true }
)

async function fetchData() {
    usersLoading.value = true
    let params = {
        page: page.value,
        role: props.role,
        ...filters.value
    }
    const {data} = await dashUserApi.all(params)
    users.value = data.data
    total.value = data.total
    per_page.value = data.per_page
    usersLoading.value = false
}

function deleteCoachSS(row) {
    const payload = {
        email: row.email
    }

    dashUserApi.removeCoachSS(payload)
        .then(async (response) => {
            await dashUserApi.destroy(row._id).then(async () => {
                await fetchData()
            })

            if (response.status === 200) {
                ElMessage.success('There was an error when deleting a coach from the Booking service. Check to see if the coach has been deleted from your Booking page.')
            } else {
                ElMessage.success('Deleted successfully')
            }
        })
        .catch(err => {
            usersLoading.value = false
            console.log('err', err.response)
        })
}

function handleDelete(row) {
    ElMessageBox.confirm('This will permanently delete the user. Continue?', 'Warning', {
        type: 'warning'
    }).then(() => {
        if (pluralizeRole.value === 'coaches') {
            usersLoading.value = true
            dashUserApi.getAuthCode().then(response => {
                usersLoading.value = false
                navigator.clipboard.writeText(response.data.user_code)
                window.open(response.data.verification_uri)
                const verifyText = `Please enter this code "${response.data.user_code}" on the "https://login.microsoftonline.com/common/oauth2/deviceauth" page in order to completely remove the coach from the Booking page.`
                ElMessageBox.alert(verifyText, '', {
                    customStyle: 'min-width: 550px'
                }).then(() => {
                    usersLoading.value = true
                    deleteCoachSS(row)
                }).catch((error) => {
                    usersLoading.value = false
                    ElMessage.error('Canceled')
                })
            })
        } else {
            dashUserApi.destroy(row._id).then(() => {
                ElMessage.success('Deleted successfully')
                fetchData()
            })
                .catch(err => {
                    console.log('err', err.response)
                })
        }
    })
}

const applySearch = _.debounce( function() {
    fetchData()
}, 300)
</script>
