<template>
    <el-form
        v-if="form"
        :model="form"
        label-width="150px"
        @submit.prevent
    >
        <el-form-item
            label="Active"
            prop="is_active"
            :error="errors.get(provider + '.is_active')"
        >
            <el-switch v-model="form.is_active" />
        </el-form-item>
        <el-form-item
            v-if="provider === 'app'"
            label="Page"
            prop="page"
            :error="errors.get('page')"
        >
            <el-select
                v-model="form.page"
                class="w-100"
                placeholder="Select page"
                :error="errors.get(provider + '.page')"
            >
                <el-option
                    v-for="page in pages"
                    :key="page"
                    :label="page"
                    :value="page"
                />
            </el-select>
        </el-form-item>
        <frequency
            v-model="form.frequency"
            :provider="provider"
            :errors="errors"
        />
        <el-form-item
            prop="start_date"
            :error="errors.get(provider + '.start_date')"
        >
            <template #label>
                Start date
                <el-tooltip
                    effect="dark"
                    content="<strong>Start Date</strong> to set the day, since when the action is done by user and he becomes a target group to receive the notifications."
                    placement="top"
                    raw-content
                >
                    <el-icon><InfoFilled /></el-icon>
                </el-tooltip>
            </template>
            <el-date-picker
                v-model="form.start_date"
                type="date"
                class="w-100"
                placeholder="Select date"
                :value-format="DATE_DB_FORMAT"
            />
        </el-form-item>
        <el-form-item
            prop="end_date"
            :error="errors.get(provider + '.end_date')"
        >
            <template #label>
                End date
                <el-tooltip
                    effect="dark"
                    content="<strong>End Date</strong> to set the day, since when notifications stop being sent for this campaign."
                    placement="top"
                    raw-content
                >
                    <el-icon><InfoFilled /></el-icon>
                </el-tooltip>
            </template>
            <el-date-picker
                v-model="form.end_date"
                type="date"
                class="w-100"
                placeholder="Select date"
                :value-format="DATE_DB_FORMAT"
            />
        </el-form-item>
        <el-form-item
            v-if="provider === 'email'"
            label="Subject"
            prop="subject"
            :error="errors.get(provider + '.subject')"
        >
            <el-input
                v-model="form.subject"
            />
        </el-form-item>
        <el-form-item
            label="Notification content"
            prop="text"
            :error="errors.get(provider + '.text')"
        >
            <el-input
                v-model="form.text"
                type="textarea"
            />
        </el-form-item>
    </el-form>
</template>

<script setup>
import Frequency from './Frequency'

import {DATE_DB_FORMAT} from '~/base/constants/time.constants'
import {useModelWrapper} from '~/includes/composable/modelWrapper'

const emit = defineEmits()

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
    provider: {
        type: String,
        required: true
    },
    errors: {
        type: Object,
        default: {}
    },
    pages: {
        type: Array,
        default: []
    },
})

const form = useModelWrapper(props, emit, 'modelValue')

</script>
