<template>
    <el-col
        :span="8"
        class="grid-item"
    >
        <h4><slot /></h4>
        <p
            class="grid-item__status"
            :class="{
                'grid-item__status--red': color === 'red',
                'grid-item__status--green': color === 'green'
            }"
        >
            <SwitchButton class="grid-item__icon" />
            <span
                class="grid-item__status-text"
            >
                {{ status }}
            </span>
        </p>
        <p v-if="item.frequency.value">
            Frequency: {{ item.frequency.value }}
        </p>
        <p v-if="item.frequency.time_data.day">
            Day: {{ item.frequency.time_data.day }}
        </p>
        <p v-if="item.frequency.time_data.time">
            Time: {{ item.frequency.time_data.time }}
        </p>
        <p v-if="item.frequency.time_data.day1">
            Day1: {{ item.frequency.time_data.day1 }}
        </p>
        <p v-if="item.frequency.time_data.time1">
            Time1: {{ item.frequency.time_data.time1 }}
        </p>
        <p v-if="item.frequency.time_data.day2">
            Day2: {{ item.frequency.time_data.day2 }}
        </p>
        <p v-if="item.frequency.time_data.time2">
            Time2: {{ item.frequency.time_data.time2 }}
        </p>
    </el-col>
</template>

<script setup>
import {computed} from 'vue'

const props = defineProps({
    item: {
        type: Object,
        required: true
    }
})

const color = computed(() => props.item.is_active ? 'green' : 'red')
const status = computed(() => props.item.is_active ? 'On' : 'Off')
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.grid-item {
    &__status {
        display: flex;
        align-items: center;
        &-text {
            font-weight: bold;
        }
        &--green {
            color: darken($green, 20%);
        }
        &--red {
            color: lighten($maroon, 20%);
        }
    }
    &__icon {
        width: 1em;
        height: 1em;
        margin-right: 3px
    }
}
</style>
