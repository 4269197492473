import axios from 'axios'

const API_ENDPOINT = window.config.coachingUrl + 'users'

export default {

    get(user_id) {
        return axios.get(API_ENDPOINT + '/get/' + user_id)
    },

    registeredUsers(params, signal) {
        return axios.get(API_ENDPOINT + '/reg-user-list', {params, signal})
    },

    getStepsByUser(user_id) {
        return axios.get(API_ENDPOINT + '/steps-by-user/' + user_id)
    },

    getLogsByUser(user_id) {
        return axios.get(API_ENDPOINT + '/logs-by-user/' + user_id)
    },

    invitedUsers(params, signal) {
        return axios.get(API_ENDPOINT + '/user-list', {params, signal})
    },

    toggleEnable(params) {
        return axios.post(API_ENDPOINT + '/update-status', params)
    },

    removeCoach(payload) {
        return axios.post(API_ENDPOINT + '/delete-coach', payload)
    },

    sendInviteToCoach(payload) {
        return axios.post(window.config.coachingUrl + 'user_invite/send-invite', payload)
    },

    reSendInvite(payload) {
        return axios.post(window.config.coachingUrl + 'user_invite/re-send-invite', payload)
    },

    assign(model) {
        return axios.post(API_ENDPOINT + '/' + model._id + '/assign', model)
    },

    getAuthCode() {
        return axios.get(window.config.coachingUrl + 'get-auth-code')
    },

    createBooking(id) {
        return axios.post(API_ENDPOINT + `/${id}/create-booking`)
    },

    createStaff(id) {
        return axios.post(API_ENDPOINT + `/${id}/create-staff`)
    },
}
