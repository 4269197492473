import axios from 'axios'

const API_ENDPOINT = window.config.ssURL + 'learning-modules'

export default {

    all(params) {
        return axios.get(API_ENDPOINT + '/grid', { params })
    },

    get(id) {
        return axios.get(API_ENDPOINT + '/' + id)
    },

    add(form) {
        return axios.post(API_ENDPOINT + '/add', form)
    },

    update(form) {
        return axios.post(API_ENDPOINT + '/update', form)
    },

    stitchToggle(form) {
        return axios.post(API_ENDPOINT + '/switch-toggle', form)
    },

    destroy(params) {
        return axios.post(API_ENDPOINT + '/delete', params)
    },

    visible(lmId, payload) {
        return axios.post(API_ENDPOINT + `/${lmId}/visible`, payload)
    },

    priority(items) {
        return axios.post(API_ENDPOINT + '/update-priority', {items})
    },

    fetchLMList(params) {
        return axios.get(API_ENDPOINT + '/modules-list', { params })
    },

    fetchLessonsList(params) {
        return axios.get(API_ENDPOINT + '/lessons-list', { params })
    },
    export(params) {
        return axios.get(API_ENDPOINT + '/export', {  params: params, responseType: 'blob'})
    },
    import(data) {
        return axios.post(API_ENDPOINT + '/import', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }
}
