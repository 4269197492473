// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_FIELD = 'UPDATE_USER_FIELD'
export const LEARNING_SS = 'user'
export const LEARNING_COACHES = 'coach'
export const LEARNING_GENERAL_COMPANY = 'general'
