<template>
    <el-container class="auth-page">
        <el-main>
            <div class="auth-page__top">
                <div class="auth-page__image-wrap">
                    <img
                        class="auth-page__image"
                        src="/images/auth/seekingsimple-logo.svg"
                        alt=""
                    >
                </div>
            </div>
            <div class="auth-page__title">
                Reset Password
            </div>
            <el-card>
                <PasswordResetForm
                    :token="token"
                    :email="email"
                    button="Reset"
                />
            </el-card>
        </el-main>
    </el-container>
</template>

<script>
import PasswordResetForm from './PasswordResetForm'

export default {
    name: 'PasswordReset',
    components: { PasswordResetForm },
    props: {
        token: {
            type: String,
            default: ''
        },
        email: {
            type: String,
            default: ''
        },
    },
}
</script>
