import UserIndex from './components/UserIndex'
import UserEdit from './components/UserEdit'

export default [
    {
        path: 'users',
        component: UserIndex,
        name: 'users.index',
        meta: {
            auth: 'users.view',
            pageTitle: 'Users',
        },
    },
    {
        path: 'users/add',
        component: UserEdit,
        name: 'users.add',
        meta: {
            auth: 'users.create',
            pageTitle: 'Add user',
        },
    },
    {
        path: 'users/edit/:id',
        component: UserEdit,
        name: 'users.edit',
        meta: {
            auth: 'users.edit',
            pageTitle: 'Edit user',
        },
    },
]
