<template>
    <section
        id="learning_module-form"
        class="max-width-600-tablet-desktop page-section--offset-bottom-middle"
    >
        <el-form
            ref="formRef"
            :model="form"
            label-width="100px"
            :rules="rules"
            @submit.prevent="saveSubmit"
        >
            <el-form-item
                label="Icon"
                prop="icon"
                :error="errors.get('icon')"
            >
                <el-upload
                    class="image-placeholder"
                    action="''"
                    :show-file-list="false"
                    :auto-upload="false"
                    accept="image/*"
                    :on-change="beforeIconUpload"
                >
                    <img
                        v-if="form.icon_url"
                        :src="form.icon_url"
                        alt="image"
                        height="100"
                    >
                    <el-button
                        v-else
                        type="primary"
                        icon="plus"
                        plain
                    >
                        Add icon
                    </el-button>
                </el-upload>
            </el-form-item>
            <el-form-item
                label="Title"
                prop="title"
                :error="errors.get('title')"
            >
                <el-input
                    v-model="form.title"
                    suffix-icon="edit-pen"
                    @change="errors.clear('title')"
                />
            </el-form-item>
            <el-form-item
                label="Description"
                prop="description"
                :error="errors.get('description')"
            >
                <el-input
                    v-model="form.description"
                    type="textarea"
                    :rows="2"
                    @change="errors.clear('description')"
                />
            </el-form-item>
            <el-form-item
                label="Quiz (JSON)"
                prop="quiz"
                :error="errors.get('quiz')"
            >
                <el-input
                    v-model="form.quiz"
                    type="textarea"
                    :rows="8"
                    @change="errors.clear('quiz')"
                />
            </el-form-item>
        </el-form>
        <div>
            <el-button
                @click="cancel"
            >
                Cancel
            </el-button>
            <el-button
                :loading="formLoading"
                type="success"
                @click="saveSubmit"
            >
                Save
            </el-button>
        </div>
    </section>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { ElMessage } from 'element-plus'
import {Errors} from '~/includes/Errors'
import lmApi from '../../lmApi'
import store from "~/store";

const emit = defineEmits()

const props = defineProps({
    initialForm: {
        type: Object,
        default: {}
    },
})

const learningTabValue = computed(() => store.getters['learnings/learningTabValue'])
const selectedClientLevel = computed(() => store.getters['learnings/learningClientLevel'])
const errors = new Errors()
const formLoading = ref(false)
const form = ref({name: ''})
const rules = {
    title: [
        { required: true, message: 'Title is required' },
    ],
    description: [
        { required: true, message: 'Description is required' },
    ],
    icon: [
        { required: true, message: 'Icon is required' },
    ],
}

onMounted(() => form.value = Object.assign({}, props.initialForm))

watch(
    () => props.initialForm,
    val => form.value = Object.assign({}, val),
    { deep: true }
)

const formRef = ref()

function saveSubmit() {
    formRef.value.validate((valid) => {
        if (valid) {
            formLoading.value = true
            errors.clear()

            const action = form.value._id ? lmApi.update : lmApi.add
            action(prepareFormData()).then((response) => {
                ElMessage.success( 'Saved successfully')
                emit('saved', response.data.data._id)
            }).catch(error => {
                if (error.response.data.errors) errors.record(error.response.data.errors)
            }).finally(() => formLoading.value = false)
        }
    })
}

function cancel() {
    clearForm()
    emit('cancel0')
}

function clearForm() {
    errors.clear()
    if (formRef.value) formRef.value.resetFields()
}

function prepareFormData() {
    let formData = new FormData()
    for (let field in form.value) {
        if (form.value[field] === null) continue

        if (form.value[field] instanceof File) {
            formData.set(field, form.value[field])
        } else if (form.value[field] instanceof Object) {
            formData.set(field, JSON.stringify(form[field]))
        } else {
            formData.append(field, form.value[field])
        }
    }
    formData.append('type', learningTabValue.value)
    formData.append('client_level_id', selectedClientLevel.value)

    if (_.isString(form.value['icon'])) {
        formData.delete('icon')
    }

    return formData
}

function beforeIconUpload(file) {
    if (fileIsTooBig(file)) {
        return false
    }

    form.value.icon_url = URL.createObjectURL(file.raw)
    form.value.icon = file.raw
    return true
}

function fileIsTooBig(file, sizeMb = 2) {
    if (file.size / 1024 / 1024 > sizeMb) {
        ElMessage.error('This file should be less then ' + sizeMb + ' Mb')
        return true
    }
    return false
}
</script>
