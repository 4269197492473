<template>
    <div class="page-breadcrumbs">
        <el-breadcrumb
            separator="/"
            class="breadcrumb"
        >
            <el-breadcrumb-item :to="{path: '/'}">
                ..
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="{name: 'pmo.index'}">
                PMO Categories
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="{name: 'pmo.reference.index', params: {pmo_id: $route.params.pmo_id}}">
                References
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                Edit reference
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <h2 class="page-title">
        Edit reference
    </h2>
    <el-row :gutter="20">
        <el-col>
            <reference-form
                v-loading="loading"
                :initial-form="formData"
                @saved="goToEdit"
                @cancel="router.push({name: 'pmo.reference.index', params: {pmo_id: $route.params.pmo_id}})"
            />
        </el-col>
    </el-row>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import ReferenceForm from './ReferenceForm'
import {useRoute, useRouter} from 'vue-router'
import referenceApi from '../../referenceApi'

const route = useRoute()
const router = useRouter()
const formData = ref(null)

const loading = ref(false)

onMounted(() => fetchData())

async function fetchData() {
    loading.value = true
    const {data} = await referenceApi.get(route.params.pmo_id, route.params.reference_id)
    formData.value = data
    formData.value.id = data._id
    formData.value.pmo_id = route.params.pmo_id
    loading.value = false
}

function goToEdit() {
    router.push({name: 'pmo.reference.index', params: {pmo_id: route.params.pmo_id}})
}
</script>
