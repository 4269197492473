import axios from 'axios'

const API_ENDPOINT_LABELINSIGHT = '/labelinsight/products'
const API_ENDPOINT_SYNDIGO = '/syndigo'

export default {
    setCategoryToProducts(payload) {
        return axios.post(API_ENDPOINT_LABELINSIGHT + '/categorization', payload)
    },

    getByPath(path) {
        return axios.get(API_ENDPOINT_SYNDIGO + '/categories/get-by-path', {
            params: {
                path: path
            }
        })
    },
}
