<template>
    <div class="page-breadcrumbs">
        <page-breadcrumbs :list="breadcrumbs" />
    </div>
    <h2 class="page-title">
        {{ actionName }} white label
    </h2>
    <white-label-form
        v-loading="loading"
        :initial-form="formData"
        :clientLevels="clientLevels"
        @saved="router.push({name: 'white-label.index'})"
        @cancel-form="router.push({name: 'white-label.index'})"
    />
</template>

<script setup>
import {computed, onMounted, ref} from 'vue'
import WhiteLabelForm from './WhiteLabelForm'
import {useRoute, useRouter} from 'vue-router'
import whiteLabelApi from '../whiteLabelApi'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'
import clientLevelsApi from "~/modules/client_levels/clientLevelsApi";

const route = useRoute()
const router = useRouter()
const formData = ref(null)
const clientLevels = ref([])

const loading = ref(false)

const actionName = computed(() => route.name === 'white-label.add' ? 'Add' : 'Edit')

const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: 'White Label',
        name: 'white-label.index'
    },
    {
        title: `${actionName.value} white label`,
    }
])

onMounted(() => {
    fetchData()
    fetchClientLevelsData()
})

async function fetchData() {
    if (route.name === 'white-label.add') {
        loading.value = true
        formData.value = { client_level_id: route.params.id}
        loading.value = false
    } else {
        loading.value = true
        const { data } = await whiteLabelApi.get(route.params.id)
        formData.value = data
        loading.value = false
    }
}

async function fetchClientLevelsData() {
    loading.value = true
    const { data } = await clientLevelsApi.all({unlimited: true})
    clientLevels.value = data
    loading.value = false
}

</script>
