<template>
    <section id="reference" class="page-section--offset-bottom-middle">
        <h3 class="section-title">
            References
        </h3>
        <div
            v-if="$auth.check('lm_references.create')"
            class="page-section"
        >
            <el-button
                type="primary"
                icon="plus"
                @click="$router.push({name: 'references.add', params: {lm_id: $route.params.lm_id, chapter_id: $route.params.chapter_id, lesson_id: $route.params.lesson_id}})"
            >
                Add reference
            </el-button>
        </div>
        <draggable
            v-loading="referencesLoading"
            :list="references"
            item-key="_id"
            class="learning-module__table"
            handle=".learning-module__table-col--handle"
            @start="dragging = true"
            @end="updateItemOrder"
        >
            <template #item="{ element }">
                <el-row
                    :gutter="20"
                    align="middle"
                    justify="space-between"
                    class="learning-module__table-row"
                >
                    <el-col
                        :xs="2"
                        :sm="1"
                        class="learning-module__table-col learning-module__table-col--handle"
                    >
                        <i class="fa fa-align-justify" />
                    </el-col>
                    <el-col
                        :xs="22"
                        :sm="8"
                        class="learning-module__table-col learning-module__table-col--title"
                    >
                        {{ element.title }}
                    </el-col>
                    <el-col
                        :xs="24"
                        :sm="9"
                        class="learning-module__table-col learning-module__table-col--description"
                    >
                        {{ element.url }}
                    </el-col>
                    <el-col
                        :xs="24"
                        :sm="6"
                        class="learning-module__table-col learning-module__table-col--buttons"
                    >
                        <el-button
                            v-if="$auth.check('lm_references.edit')"
                            size="small"
                            icon="edit"
                            @click="$router.push({name: 'references.edit', params: {lm_id: $route.params.lm_id, chapter_id: $route.params.chapter_id, lesson_id: $route.params.lesson_id, reference_id: element._id}})"
                        >
                            Edit
                        </el-button>
                        <el-button
                            v-if="$auth.check('lm_references.delete')"
                            type="danger"
                            size="small"
                            icon="delete"
                            @click="handleDelete(element)"
                        >
                            Delete
                        </el-button>
                    </el-col>
                </el-row>
            </template>
        </draggable>

        <el-pagination
            v-model:current-page="page"
            v-model:page-size="per_page"
            :total="total"
            :page-sizes="[5, 15, 30]"
            hide-on-single-page
            layout="sizes, prev, pager, next, ->, total"
            class="el-pagination--align-right"
            @current-change="fetchData"
            @size-change="fetchData"
        />
    </section>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import draggable from 'vuedraggable'
import { ElMessage, ElMessageBox } from 'element-plus'
import referenceApi from '../../referenceApi'
import {useRoute} from 'vue-router'

const page = ref(1)
const per_page = ref(5)
const total = ref(0)
const route = useRoute()

const referencesLoading = ref(false)
const references = ref(null)

onMounted(() => fetchData())

async function fetchData() {
    referencesLoading.value = true
    const params = {
        page: page.value,
        limit: per_page.value,
    }

    const {data} = await referenceApi.all(route.params.lesson_id, params)
    references.value = data.data
    total.value = data.total
    per_page.value = data.per_page
    referencesLoading.value = false
}

function handleDelete(row) {
    ElMessageBox.confirm('This will permanently delete the reference. Continue?', 'Warning', {
        type: 'warning'
    }).then(() => {
        referenceApi.destroy(row._id).then((response) => {
            ElMessage.success( 'Deleted successfully')
            fetchData()
        })
    })
}

const applySearch = _.debounce( function() {
    fetchData()
}, 300)

const dragging = ref(false)

function updateItemOrder() {
    let items = references.value.map(function(element, index) {
        return { id: element._id, priority: index }
    })
    referenceApi.priority(items).then(response => {
        if(response.data.success) {
            ElMessage.success( 'Saved successfully')
        }
    }).finally(() => dragging.value = false)
}

const cutString = (str) => _.truncate(str, {'length': 24})
</script>
