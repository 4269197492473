<template>
    <div
        v-if="item"
        class="nutritional-properties-list"
    >
        <div
            class="nutritional-properties-list__row"
            :class="getBgColor(item.name)"
        >
            <div class="nutritional-properties-list__name">
                {{ item.name }}
            </div>
            <div class="nutritional-properties-list__value">
                <div
                    v-if="item.uom"
                    class="nutritional-properties-list__value-col"
                >
                    <!-- {{ item.value }} {{ item.uom }} -->
                    <el-input
                        v-model="item.value"
                        type="number"
                        placeholder="Value"
                        name="value"
                        size="mini"
                        maxlength="3"
                        min="0"
                        max="999"
                        @keyup="checkMaxLenght"
                    />
                    <uoms
                        v-model="item.uom"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useModelWrapper } from '~/includes/composable/modelWrapper'
import Uoms from './Uoms'

const emit = defineEmits()

const props = defineProps({
    item: {
        type: Object,
        required: true
    },
})

console.log('props', props)

const item = useModelWrapper(props, emit, 'item')

function getBgColor(name) {
    const color = {
        'Calories': 'nutritional-properties-list__row--centered-value',
        'Calories From Fat': 'nutritional-properties-list__row--centered-value nutritional-properties-list__row--middle-border-bottom',
        'Total Fat': 'nutritional-properties-list__row--yellow nutritional-properties-list__row--bold',
        'Trans Fat': 'nutritional-properties-list__row--yellow',
        'Monounsaturated Fat': 'nutritional-properties-list__row--yellow',
        'Polyunsaturated Fat': 'nutritional-properties-list__row--yellow',
        'Low Fat': 'nutritional-properties-list__row--yellow',
        'Saturated Fat': 'nutritional-properties-list__row--yellow',
        'Cholesterol': 'nutritional-properties-list__row--green nutritional-properties-list__row--bold',
        'Sodium': 'nutritional-properties-list__row--green nutritional-properties-list__row--bold',
    }
    return color[name] ?? ''
}

function checkMaxLenght(e) {
    if ( e.target.value.length > e.target.maxLength ) {
        e.target.value = e.target.value.slice(0, e.target.maxLength)
    }
}

const item_uom = ref('asdsad')

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";

.nutritional-properties-list {
    display: flex;
    width: 90%;
    $block-name: &;
    &__text {
        border-bottom: 1px solid $dark;
        padding: 6px 0;
        font-size: 13px;
        font-weight: 600;
        text-align: right;
    }
    &__row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $dark;
        font-size: 16px;
        &--green {
            background-color: $green;
        }
        &--yellow {
            background-color: $yellow;
        }
        &--bold {
            font-weight: 600;
        }
        &--centered-value {
            #{$block-name} {
                &__value-col {
                    // width: 110px;
                    text-align: center;
                    &--percentage-empty {
                        width: unset;
                        padding: 0;
                    }
                }
            }
        }
        &--middle-border-bottom {
            border-width: 2px;
        }
        .el-input {
            width: 64px;
            margin-right: 10px;
        }
        .el-select {
            width: 100px;
        }
    }
    &__name {
        overflow: hidden;
        padding: 10px 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        @media all and (min-width: $s) {
            padding: 10px;
        }
    }
    &__value {
        display: flex;
        align-items: center;
        &-col {
            padding: 10px;
            white-space: nowrap;
            &--percentage,
            &--percentage-empty {
                width: 60px;
                @media all and (min-width: $s) {
                    width: 70px;
                }
            }
            &--percentage {
                padding: 10px 5px;
                text-align: center;
                white-space: nowrap;
                color: $white;
                background-color: $indigo;
                @media all and (min-width: $s) {
                    padding: 10px;
                }
            }
        }
    }
}
</style>
