<template>
    <section id="schedule">
        <div class="page-breadcrumbs">
            <el-breadcrumb
                separator="/"
                class="breadcrumb"
            >
                <el-breadcrumb-item :to="{path: '/'}">
                    ..
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{name: 'notifications.index'}">
                    Notifications
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    Schedules List
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <h2 class="page-title">
            Schedules List
        </h2>
        <schedule-filter
            v-model="filters"
            class="page-section page-section--bordered-form"
        />

        <!-- table -->
        <el-table
            v-loading="schedulesLoading"
            :data="schedules"
            highlight-current-row
            class="w-100"
        >
            <el-table-column type="expand">
                <template #default="props">
                    <p
                        v-for="(to, i) in props.row.to"
                        :key="i"
                    >
                        {{ to }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                prop="cron"
                label="Cron"
                min-width="120"
            />
            <el-table-column
                prop="frequency"
                label="Frequency"
                min-width="130"
            />
            <el-table-column
                prop="provider"
                label="Provider"
                min-width="100"
            />
            <el-table-column
                prop="description"
                label="Description"
                min-width="200"
            >
                <template #default="scope">
                    <router-link :to="{name: 'notifications.edit', params: {id: scope.row.notification_id}}">
                        {{ scope.row.description }}
                    </router-link>
                </template>
            </el-table-column>
            <el-table-column
                prop="prev"
                label="Prev"
                min-width="160"
            />
            <el-table-column
                prop="next"
                label="Next"
                min-width="160"
            />
        </el-table>
    </section>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue'
import notificationApi from '../notificationApi'
import ScheduleFilter from './ScheduleFilter'

const schedulesLoading = ref(false)
const schedules = ref(null)
const total = ref(0)
const filters = ref({})
const page = ref(1)

onMounted(() => fetchData())

watch(
    () => filters.value,
    val => applySearch(),
    { deep: true }
)

async function fetchData() {
    schedulesLoading.value = true
    let params = {
        ...filters.value
    }
    const {data} = await notificationApi.tasks(params)
    schedules.value = data.data
    total.value = data.total
    schedulesLoading.value = false
}

const applySearch = _.debounce( function() {
    fetchData()
}, 300)
</script>
