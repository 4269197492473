<template>
    <div
        v-loading="loading"
        class="drop-down-categories"
    >
        <el-select
            v-for="pathCategory in pathCategories"
            :key="'pathCategory.level' + pathCategory.level"
            v-model="selectedPathes[pathCategory.level]"
            class="drop-down-categories-select"
            placeholder="Select"
            size="default"
            @change="loadCategories(pathCategory.level + 1)"
        >
            <el-option
                v-for="item in pathCategory.categories"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
        </el-select>
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import liProductsApi from '../../liProductsApi'
import {useModelWrapper} from '~/includes/composable/modelWrapper'

const emit = defineEmits()

const props = defineProps({
    modelValue: {
        type: Array,
        required: true
    },
})

const loading = ref(false)
const category_paths = useModelWrapper(props, emit, 'modelValue')

const pathCategories = ref([])
const selectedPathes = ref([])

onMounted(() => {
    fillStartData()
})

async function fillStartData() {
    if (category_paths.value && category_paths.value.length && category_paths.value[0] !== '') {
        let elements
        if (typeof category_paths.value === 'object') {
            elements = category_paths.value[0].split("/");
        } else {
            elements = category_paths.value.split("/");
        }

        const resultArray = elements.map((_, index) => elements.slice(0, index + 1).join("/"));
        await loadCategories(0)
        for (let [index, path] of resultArray.entries()) {
            selectedPathes.value.push(path)
            await loadCategories(index + 1, path);
        }

    } else {
        loadCategories(0)
    }
}

async function loadCategories(level) {
    let pathStr = level == 0 ? '' : selectedPathes.value[level - 1]

    category_paths.value = [ pathStr ]
    if (level > 4) {
        return
    }

    loading.value = true
    pathCategories.value = pathCategories.value.slice(0, level)
    selectedPathes.value = selectedPathes.value.slice(0, level)

    await liProductsApi.getByParents(pathStr).then(response => {
        let data = response.data
        loading.value = false

        let categoryOptions = data.map(item => {
            return {
                value: item.path,
                label: item.name
            }
        })

        pathCategories.value[level] = {
            categories: categoryOptions,
            level: level
        }

    }).catch(error => {
        loading.value = false
        console.log(error)
    })


}


</script>

<style scoped>
.drop-down-categories-select {
    width: 20%;
}
</style>
