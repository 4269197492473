import axios from 'axios'

const API_ENDPOINT = window.config.ssURL + 'pmo-ssupr/categories'

export default {

    all(pmo_id, data) {
        return axios.get(API_ENDPOINT + '/' + pmo_id + '/references', {params: data})
    },

    get(pmo_id, id) {
        return axios.get(API_ENDPOINT + '/' + pmo_id + '/references/' + id)
    },

    // upload() {
    //     return axios.post(API_ENDPOINT + '/upload', form)
    // },

    //const create = (categoryID, model) => axios.post(ssUrl + 'api/pmo-ssupr/categories/'+categoryID+'/references', model)
    // const update = (categoryID, model) => axios.put(ssUrl + 'api/pmo-ssupr/categories/'+categoryID+'/references/' + model._id, model)
    create(form) {
        let pmo_id = form.pmo_id
        return axios.post(API_ENDPOINT+ '/' + pmo_id + '/references', form)
    },
    update(form) {
        let pmo_id = form.pmo_id
        let ref_id = form._id
        return axios.put(API_ENDPOINT+ '/' + pmo_id + '/references/' + ref_id, form)
    },

    destroy(pmo_id, ref_id) {
        return axios.delete(API_ENDPOINT + '/' + pmo_id + '/references/' + ref_id)
    },

    // sort(items) {
    //     return axios.post(API_ENDPOINT + '/sort', {items})
    // }
}
