<template>
    <div class="page-breadcrumbs">
        <el-breadcrumb
            separator="/"
            class="breadcrumb"
        >
            <el-breadcrumb-item :to="{path: '/'}">
                ..
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="{name: 'delta-logs.index'}">
                Delta Logs
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                Delta Logs {{ date }}
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>

    <h2 class="page-title">
        Delta Logs for {{ date }}
    </h2>
    <div
        v-loading="loading"
        class="page-section"
    >
        <template v-if="deltaLogs">
            <el-table
                :data="deltaLogs.data"
                highlight-current-row
                class="w-100"
            >
                <el-table-column
                    prop="newData.product.upc"
                    label="UPC"
                    min-width="130"
                >
                    <template #default="scope">
                        <router-link :to="{ name: 'labelinsight-products.index', params: { search: scope.row.newData.product.upc } }">
                            {{ scope.row.newData.product.upc }}
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="newData.deltaEvent"
                    label="Delta Event"
                    min-width="100"
                />
                <el-table-column
                    label="Actions"
                    min-width="90"
                    align="right"
                >
                    <template #default="scope">
                        <el-button
                            v-if="$auth.check('delta_logs.view')"
                            size="small"
                            icon="view"
                            @click="handleView(scope)"
                        >
                            Show Diff
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                v-model:current-page="deltaLogs.page"
                :page-size="deltaLogs.per_page"
                :total="deltaLogs.total"
                hide-on-single-page
                layout="prev, pager, next, ->, total"
                class="el-pagination--align-right"
                @current-change="fetchData"
                @size-change="fetchData"
            />
        </template>
    </div>

    <el-dialog
        v-model="showDeltaLog"
        title="Delta Log"
        destroy-on-close
        center
        width="85%"
    >
        <view-delta-log :delta-log="deltaLog" />
    </el-dialog>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import deltaLogsApi from '../deltaLogsApi'
import ViewDeltaLog from './ViewDeltaLog'

const route = useRoute()
const router = useRouter()
const loading = ref(false)
const deltaLogs = ref(null)
const date = ref(route.params.date)

onMounted(() => fetchData())


async function fetchData(page = 1) {
    if (route.params.date) {
        loading.value = true

        const params = {
            page: page,
            pageSize: 20,
        }

        const {data} = await deltaLogsApi.get(route.params.date, params)
        deltaLogs.value = data
        loading.value = false
    }
}

const showDeltaLog = ref(false)

const deltaLog = ref(null)
function handleView(scope) {
    deltaLog.value = scope.row
    showDeltaLog.value = true
}

</script>
<style lang="scss" scoped>
.scroll {
    overflow-y: auto;
}
</style>
