<template>
    <div class="page-breadcrumbs">
        <el-breadcrumb
            separator="/"
            class="breadcrumb"
        >
            <el-breadcrumb-item :to="{path: '/'}">
                ..
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="{name: 'pmo.index'}">
                PMO Categories
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                {{ actionName }} PMO
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <h2 class="page-title">
        {{ actionName }} PMO
    </h2>
    <pmo-form
        v-loading="loading"
        :initial-form="formData"
        @saved="router.push({name: 'pmo.index'})"
        @cancel="router.push({name: 'pmo.index'})"
    />
</template>

<script setup>
import {ref, onMounted, computed} from 'vue'
import PmoForm from './PmoForm'
import {useRoute, useRouter} from 'vue-router'
import pmoApi from '../pmoApi'

const route = useRoute()
const router = useRouter()
const formData = ref(null)

const loading = ref(false)

const actionName = computed(() => route.params.id ? 'Edit' : 'Add')

onMounted(() => fetchData())

async function fetchData() {
    if (route.params.id) {
        loading.value = true
        const {data} = await pmoApi.get(route.params.id)
        formData.value = data
        loading.value = false
    } else if (route.params.type) {
        formData.value = {
            type: route.params.type
        }
    }
}
</script>
