import DashUserEdit from './components/DashUserEdit'
import DashUserIndex from './components/DashUserIndex'

export default [

    // Admin
    {
        path: 'dashboards-admins',
        component: DashUserIndex,
        name: 'dashboards-admins.index',
        meta: {
            auth: 'dashboards_admins.view',
            pageTitle: 'Dashboards admins',
        },
        props: {role: 'Admin'},
    },
    {
        path: 'dashboards-admins/add',
        component: DashUserEdit,
        name: 'dashboards-admins.add',
        meta: {
            auth: 'dashboards_admins.create',
            pageTitle: 'Add admin',
        },
        props: {role: 'Admin'},
    },
    {
        path: 'dashboards-admins/edit/:id',
        component: DashUserEdit,
        name: 'dashboards-admins.edit',
        meta: {
            auth: 'dashboards_admins.edit',
            pageTitle: 'Edit admin',
        },
        props: {role: 'Admin'},
    },

    // Retailer
    {
        path: 'dashboards-retailers',
        component: DashUserIndex,
        name: 'dashboards-retailers.index',
        meta: {
            auth: 'dashboards_retailers.view',
            pageTitle: 'Dashboards retailers',
        },
        props: {role: 'Retailer'},
    },
    {
        path: 'dashboards-retailers/add',
        component: DashUserEdit,
        name: 'dashboards-retailers.add',
        meta: {
            auth: 'dashboards_retailers.create',
            pageTitle: 'Add retailer',
        },
        props: {role: 'Retailer'},
    },
    {
        path: 'dashboards-retailers/edit/:id',
        component: DashUserEdit,
        name: 'dashboards-retailers.edit',
        meta: {
            auth: 'dashboards_retailers.edit',
            pageTitle: 'Edit retailer',
        },
        props: {role: 'Retailer'},
    },

    // Customer
    {
        path: 'dashboards-customers',
        component: DashUserIndex,
        name: 'dashboards-customers.index',
        meta: {
            auth: 'dashboards_customers.view',
            pageTitle: 'Dashboards customers',
        },
        props: {role: 'Customer'},
    },
    {
        path: 'dashboards-customers/add',
        component: DashUserEdit,
        name: 'dashboards-customers.add',
        meta: {
            auth: 'dashboards_customers.create',
            pageTitle: 'Add customer',
        },
        props: {role: 'Customer'},
    },
    {
        path: 'dashboards-customers/edit/:id',
        component: DashUserEdit,
        name: 'dashboards-customers.edit',
        meta: {
            auth: 'dashboards_customers.edit',
            pageTitle: 'Edit customer',
        },
        props: {role: 'Customer'},
    },

]
