<template>
    <section id="role">
        <div class="page-breadcrumbs">
            <el-breadcrumb
                separator="/"
                class="breadcrumb"
            >
                <el-breadcrumb-item :to="{path: '/'}">
                    ..
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    Roles
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <h2 class="page-title">
            Roles
        </h2>
        <el-table
            v-loading="rolesLoading"
            :data="roles"
            highlight-current-row
            class="w-100"
        >
            <el-table-column
                prop="name"
                label="Name"
                min-width="200"
            />
            <el-table-column
                prop="updated_at"
                label="Updated"
                width="200"
            >
                <template #default="updated_at">
                    {{ $formatDate(updated_at.row.updated_at) }}
                </template>
            </el-table-column>
            <el-table-column
                label="Actions"
                width="130"
                align="right"
            >
                <template #default="scope">
                    <el-button
                        v-if="$auth.check('roles.edit')"
                        size="small"
                        icon="edit"
                        @click="$router.push({name: 'roles.edit', params: {id: scope.row._id}})"
                    >
                        Edit
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </section>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import roleApi from '../roleApi'

const rolesLoading = ref(false)
const roles = ref(null)

onMounted(() => fetchData())

async function fetchData() {
    rolesLoading.value = true
    const {data} = await roleApi.fetchRoles()
    roles.value = data
    rolesLoading.value = false
}

</script>
