<template>
    <div class="page-breadcrumbs">
        <el-breadcrumb
            separator="/"
            class="breadcrumb"
        >
            <el-breadcrumb-item :to="{path: '/'}">
                ..
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                Products Categorization
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>

    <h2 class="page-title">
        Products Categorization | <i>({{ totalProducts }} products in db)</i>
    </h2>

    <div class="products-categorization">
        <el-row
            class="product-categorization__row product-categorization__row--border-bottom"
        >
            <el-col
                :span="24"
                class="product-categorization__col product-categorization__col--no-padding"
            >
                <el-row
                    :gutter="10"
                    align="middle"
                >
                    <el-col
                        :xs="24"
                        :span="24"
                    >
                        <div class="page-section page-section--bordered-form page-section--product-filters-space pb-0">
                            <product-categorization
                                :selected-products="selected_products"
                                :chosen-parent-category="chosenCategory"
                                @setCategorize="setCategorize"
                                @save="saveCategorize"
                            />
                        </div>


                        <div class="page-section page-section--bordered-form page-section--product-filters-space pb-0">
                            <product-filter
                                v-model="filters"
                                class="page-section page-section--bordered-form"
                            />
                        </div>

                        <div
                            v-loading.fullscreen.lock="loading"
                            class="page-section min-100"
                        >
                            <div
                                v-if="products.length"
                                class="products-list"
                            >
                                <div
                                    v-for="product in products"
                                    :key="product._id + loaderCounter"
                                    class="products-list__item"
                                >
                                    <product-info
                                        :product-prop="product"
                                        :show-check-boxes="true"
                                        :show-product-mapping="true"
                                        :selected="selected_products.map(product => product._id)"
                                        @update-selected="updateSelected"
                                        @remove-selected="removeSelected"
                                    />
                                </div>
                            </div>
                            <div v-if="products.length">
                                <el-button
                                    type="text"
                                    @click="selectAll()"
                                >
                                    Select All
                                </el-button>
                                <span
                                    v-if="selected_products.length"
                                    class="selected-counter"
                                >
                                    {{ selected_products.length }} Selected
                                </span>
                                <el-button
                                    v-if="selected_products.length"
                                    type="text"
                                    @click="unselectAll()"
                                >
                                    Unselect All
                                </el-button>
                                <el-button
                                    v-if="showSelectAllFiltered"
                                    type="text"
                                    @click="selectAllFiltered()"
                                >
                                    Select All {{ totalProducts }} Filtered Products
                                </el-button>
                            </div>
                            <el-pagination
                                v-if="laravelData"
                                v-model:current-page="laravelData.current_page"
                                :page-size="laravelData.per_page"
                                :total="laravelData.total"
                                :small="windowWidth < 1025"
                                layout="prev, pager, next, ->, total"
                                class="float-right mt-3 mb-3"
                                @current-change="getProducts"
                                @size-change="getProducts"
                            />
                        </div>

                        <div
                            class="page-section page-section--bordered-form page-section--product-filters-space pb-0"
                            style="margin-top: 45px;"
                        >
                            <product-categorization
                                :selected-products="selected_products"
                                :chosen-parent-category="chosenCategory"
                                @setCategorize="setCategorize"
                                @save="saveCategorize"
                            />
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>

        <div class="star-legend">
            <div>Hint:&nbsp;</div>
            <el-icon class="green">
                <Star />
            </el-icon>
            <div>&nbsp;- Product has PMO alternative(s).&nbsp;</div>
            <el-icon class="blue">
                <Star />
            </el-icon>
            <div>&nbsp;- Product is used as PMO source.</div>
        </div>
    </div>
</template>

<script setup>
import {onMounted, onUnmounted, ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {ElMessage, ElMessageBox} from 'element-plus'
import ProductFilter from './filter/ProductFilter'
import ProductCategorization from './filter/ProductCategorization'
import ProductInfo from './filter/ProductInfo'
import liProductsApi from '~/modules/labelinsight-products/liProductsApi'
import proCategorizationApi from '~/modules/product-categorization/proCategorizationApi'
import bus from '~/includes/Event'
import axios from 'axios'
import store from "~/store";

const SELECTALLFILTERED_MAX = 1500
const loading = ref(false)
const totalProducts = ref(0)
const products = ref([])
const laravelData = ref(null)
const showSelectAllFiltered = ref(false)
const selected_products = ref([])
const windowWidth = ref(window.innerWidth)
const chosenCategory = ref(null)
const fetchIsFinished = ref(true)
const filters = ref({
    search: '',
    category_flats: [],
    sugarFilters: [],
    store: '',
    categorizationtype: 'uncategorized',
    pmo_category: [],
})
const loaderCounter = ref(0)
const cancelSource = ref(null)

onMounted(() => {
    getProducts()
    window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth
    })
})

onUnmounted(() => {
    store.commit('categorization/setChosenCategory',  null)
})

watch(
    () => filters.value,
    () => {
        unselectAll()
        applySearch()
        // setShowSelectAllFiltered()
    },
    { deep: true }
)

const applySearch = _.debounce( function() {
    getProducts()
}, 600)

function selectAllFiltered () {
    if (totalProducts.value > SELECTALLFILTERED_MAX) {
        //show warning

        ElMessageBox.alert('You can only select up to ' + SELECTALLFILTERED_MAX + ' products at a time. Please narrow your search criteria.', 'Warning', {
            confirmButtonText: 'OK',
            type: 'warning'
        })
    } else {
        ElMessageBox.confirm('Are you sure you want to select all ' + totalProducts.value + ' filtered products?', 'Warning', {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'warning'
        }).then(() => {

            addSelectAllFiltered()
        }).catch(() => {
            ElMessage({
                type: 'info',
                message: 'Selection cancelled'
            })
        })
    }
}

function chunkArray(array, chunkSize) {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
}

function saveCategorize() {
    loading.value = true
    const chunkSize = 20;
    const chunkedArray = chunkArray(selected_products.value, chunkSize);
    recursiveFetchCategorization(chunkedArray, 0)
}

function recursiveFetchCategorization(chunkedArray, index) {
    if (!fetchIsFinished.value) {
        setTimeout(() => {
            recursiveFetchCategorization(chunkedArray, index)
        }, 1000)
        return;
    }
    fetchIsFinished.value = false
    let payload = {
        select_products: chunkedArray[index].map(product => product._id),
        category: chosenCategory.value
    }
    proCategorizationApi.setCategoryToProducts(payload).then(async response => {
        fetchIsFinished.value = true
        if (chunkedArray.length === (index + 1)) {
            selected_products.value = []
            products.value = []
            getProducts()
        } else {
            recursiveFetchCategorization(chunkedArray, index + 1)
        }
    }).catch(error => {
        console.log(error)
        loading.value = false
    })
}

function getProducts (page = 1) {
    loading.value = true
    let params = {
        page: page,
        pageSize: 12,
        ...filters.value
    }

    cancelSearch()
    cancelSource.value = axios.CancelToken.source()

    liProductsApi.getProducts(params, cancelSource.value.token).then(response => {
        laravelData.value = response.data
        totalProducts.value = laravelData.value.total
        products.value = response.data.data
        products.value = products.value.map(product => {
            product.selected = selected_products.value&&selected_products.value.some(item => item._id === product._id)
            return product
        })
        loading.value = false
        setShowSelectAllFiltered()
        loaderCounter.value++
    })

}

function updateSelected(productId) {
    const index = products.value.findIndex(product => product._id === productId)
    if (index >= 0) {
        products.value[index].selected = true
        selected_products.value.push( products.value[index])
    }
}
function removeSelected(productId) {
    const index = products.value.findIndex(product => product._id === productId)
    if (index >= 0) {
        products.value[index].selected = false
        const selectedIndex = selected_products.value.findIndex(product => product._id === productId)
        selected_products.value.splice(selectedIndex, 1)
    }
}

function selectAll () {
    for (let i = 0; i < products.value.length; i++) {
        if (!selected_products.value.some(product => product._id === products.value[i]._id)) {
            selected_products.value.push(products.value[i])
        }
        products.value[i].selected = true
    }

}

function setShowSelectAllFiltered() {
    if(filters.value.search
        || Object.values(filters.value.category_flats).length
        || Object.values(filters.value.sugarFilters).length
        || filters.value.store
        || (filters.value.categorizationtype && filters.value.categorizationtype !== 'uncategorized')
    ) {

        showSelectAllFiltered.value = totalProducts.value<=SELECTALLFILTERED_MAX
    } else {
        showSelectAllFiltered.value = false
    }
}

const setCategorize = (category) => {
    chosenCategory.value = category
}

const addSelectAllFiltered = () => {
    loading.value = true
    const params = {
        unlimited: true,
        ...filters.value
    }
    liProductsApi.getProducts(params).then(response => {
        selected_products.value = response.data
        products.value = products.value.map(product => {
            product.selected = true
            return product
        })
        loading.value = false
    })
}

function unselectAll () {
    selected_products.value = []
    for (let i = 0; i < products.value.length; i++) {
        products.value[i].selected = false
    }
}

function cancelSearch() {
    if (cancelSource.value) {
        cancelSource.value.cancel()
    }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.products-categorization {
    $block-name: &;
    &__row {
        &--border-bottom {
            margin-bottom: 20px;
            border-bottom: 1px solid darken($white-second, 5%);
            padding-bottom: 20px;
        }
        &--offset-bottom {
            margin-bottom: 20px;
        }
    }
    &__col {
        padding: 0 10px;
        &-auto {
            flex: 0 0 auto;
        }
        &--border-left {
            border-right: 1px solid darken($white-second, 5%);
        }
        &--buttons {
            button {
                margin: 10px 0;
                &:not(:last-child) {
                    margin: 10px 10px 10px 0;
                }
            }
        }
        &--no-padding {
            padding: 0;
        }
    }
    &__barcode-scanner {
        border-top: 1px solid darken($white-second, 5%);
        padding: 10px 0 0;
        text-align: right;
        &-close {
            margin-bottom: 10px;
        }
    }
    &__list {
        margin: 10px 0 0;
        border-top: 1px solid darken($white-second, 5%);
        padding: 0;
        list-style: none;
        &-item {
            padding: 10px 0;
            &:not(:first-child) {
                border-top: 1px solid darken($white-second, 5%);
            }
        }
    }
    .selected-counter {
        margin-left: 10px;
        font-size: 14px;
    }
}

.pb-0 {
    padding-bottom: 0;
}

.products-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    &__item {
        width: 100%;
        margin-bottom: 20px;
        padding: 0 10px;
        @media all and (min-width: $s) {
            width: calc(100% / 2);
        }
        @media all and (min-width: $m) {
            width: calc(100% / 3);
        }
        @media all and (min-width: $l) {
            width: calc(100% / 4);
        }
    }
}

.product-section {
    &__product {
        margin-bottom: 10px;
        &-remove {
            margin-bottom: 10px;
            &--align-right {
                text-align: right;
            }
        }
    }
}

.star-legend {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    .blue {
        color: $blue;
    }

    .green {
        color: $green;
    }
}

</style>
