<template>
    <header class="header-default">
        <div class="header-default__top">
            <div class="header-default__inner">
                <div class="header-default__top-menu">
                    <div class="header-default__top-menu-aside">
                        <div
                            class="header-default__top-menu-bar"
                            @click="toggleMenu"
                        >
                            <i class="header-default__top-menu-bar-icon fa-solid fa-bars" />
                        </div>
                        <a
                            href="/"
                            class="header-default__logo"
                        >
                            <img
                                src="/images/svg-icons/seekingsimple-logo-square.svg"
                                alt=""
                                class="header-default__logo-i"
                            >
                        </a>
                    </div>
                    <div
                        class="header-default__top-menu-main"
                        :class="{'header-default__top-menu-main--open': showMobMenu }"
                    >
                        <header-controls />
                    </div>
                </div>
                <div class="header-default__top-controls">
                    <div class="header-default__user">
                        <div class="header-default__user-info">
                            <div class="header-default__user-info-role">
                                {{ $auth.user().name }}
                            </div>
                            <div class="header-default__user-info-name">
                                {{ $auth.user().email }}
                            </div>
                        </div>
                        <div class="header-default__user-image">
                            <el-dropdown trigger="click">
                                <i class="header-default__user-image-icon fa-solid fa-user-large" />
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click="$router.push({name: 'profile.edit'})">
                                            Edit profile
                                        </el-dropdown-item>
                                        <el-dropdown-item @click="$router.push({name: 'profile.change-password'})">
                                            Change password
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                            divided
                                            @click="logout"
                                        >
                                            Log Out
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
import {ref} from 'vue'
import HeaderControls from '~/base/components/header-controls/HeaderControls'
import {ElMessageBox} from 'element-plus'
import bus from '~/includes/Event'
import {useAuth} from '@websanova/vue-auth'

const auth = useAuth()
const showMobMenu = ref(false)

function logout() {
    ElMessageBox.confirm('Confirm LogOut', 'You will be logged out', {
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
    }).then(async () => {
        bus.$emit('logout')
        await auth.logout()
    })
}

function toggleMenu() {
    showMobMenu.value = !showMobMenu.value
    document.body.classList.toggle('scroll-lock')
}
bus.$on('goToRoute', toggleMenu)
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.header-default {
    &__top {
        border-bottom: 1px solid #dcdcdc;
        &-menu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-grow: 1;
            @media all and (min-width: $s) {
                align-items: flex-end;
            }
            &-aside {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-shrink: 0;
                height: 60px;
            }
            &-bar {
                @media all and (max-width: $m - 1) {
                    width: 36px;
                    height: 36px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 5px;
                }
                @media all and (min-width: $m) {
                    display: none;
                }
                &-icon {
                    font-size: 20px;
                }
            }
            &-main {
                flex-grow: 1;
                width: 100%;
                @media all and (max-width: $m - 1) {
                    position: fixed;
                    z-index: 100;
                    top: 61px;
                    bottom: 0;
                    left: 0;
                    width: 270px;
                    transform: translateX(-270px);
                    transition: 0.3s transform linear;
                    will-change: translate;
                }
                &--open {
                    @media all and (max-width: $m - 1) {
                        transform: translateX(0);
                    }

                }
            }
        }
        &-controls {
            flex-shrink: 0;
        }
    }
    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 15px;
        @media all and (min-width: $s) {
            padding: 0 30px;
        }
    }
    &__logo {
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        margin-right: 10px;
        &-i {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__menu {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;
        &-item {
            padding: 5px 10px;
            font-size: 18px;
            font-weight: 600;
        }
    }
    &__log-out {
        font-size: 16px;
    }
    &__menu-item,
    &__log-out {
        font-family: $avenir-next;
        color: $black;
        cursor: pointer;
        transition: 0.25s color ease-in-out;
        &:hover {
            color: $indigo;
        }
    }
    &__user {
        display: flex;
        align-items: center;
        &-info {
            &-role,
            &-name {
                text-align: right;
            }
            &-role {
                font-size: 14px;
            }
            &-name {
                max-width: 140px;
                overflow: hidden;
                font-size: 14px;
                font-weight: 500;
                text-overflow: ellipsis;
                white-space: nowrap;
                @media all and (min-width: $s) {
                    max-width: 180px;
                }
            }
        }
        &-image {
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            margin-left: 10px;
            border-radius: 50%;
            background-color: $light-gray;
            &-icon {
                font-size: 28px;
            }
        }
    }
}
</style>
