<template>
    <el-breadcrumb
        separator="/"
        class="breadcrumb"
    >
        <template
            v-for="item in list"
            :key="item.title"
        >
            <el-breadcrumb-item
                v-if="item.path"
                :to="{path: item.path}"
            >
                {{ item.title }}
            </el-breadcrumb-item>
            <el-breadcrumb-item
                v-else-if="item.name"
                :to="{name: item.name, params: item.params}"
            >
                {{ item.title }}
            </el-breadcrumb-item>
            <el-breadcrumb-item v-else>
                {{ item.title }}
            </el-breadcrumb-item>
        </template>
    </el-breadcrumb>
</template>

<script setup>
const props = defineProps({
    list: {
        type: Array,
        default: () => {return []}
    }
})
</script>
