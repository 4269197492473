<template>
    <section id="client_levels">
        <div class="page-breadcrumbs">
            <page-breadcrumbs :list="breadcrumbs"/>
        </div>
        <h2 class="page-title">
            {{ title }}
        </h2>
        <!-- <div class="header">
            <div>
                <import-export-form
                    v-if="$auth.check('Admin')"
                    @refresh="fetchData"
                />
            </div>
        </div> -->
        <div>
            <el-switch
                v-model="tableViewFlag"
                v-loading="loadTree"
                class="mb-2"
                active-text="Table view"
                inactive-text="Tree view"
                @change="switchTreeView"
            />
        </div>
        <div v-if="tableViewFlag">
            <div>
                <el-row>
                    <el-col>
                        Name: {{ client_level?.name }}
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        Label for children level: {{ (client_level?.label?client_level?.label:'-') }}
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        Level: {{ client_level?.level }}
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        Directed Spend: {{ client_level?.directed_spend }}
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-button
                            v-if="$auth.check('client_levels.edit')"
                            type="primary"
                            icon="edit"
                            @click="onEdit(client_level)"
                        >
                            Edit
                        </el-button>
                    </el-col>
                </el-row>
                    
            </div>
            <div>
                <div
                    class="page-section"
                >
                    <el-button
                        v-if="$auth.check('client_levels.create')"
                        type="primary"
                        icon="plus"
                        @click="$router.push({name: 'client-levels.add', params: { parent_id: client_level?._id }})"
                    >
                        Add Child
                    </el-button>
                </div>

                <div>
                    <el-row
                        :gutter="15"
                        justify="space-between"
                    >
                        <el-col class="col-auto">
                            <base-filter v-model="filters" />
                        </el-col>
                    </el-row>
                </div>

                <el-table :data="client_levels_children" v-loading="client_levelsLoading">
                    <el-table-column
                        prop="name"
                        label="Name"
                        width="180"
                    />
                    <el-table-column
                        prop="label"
                        label="Label for children level"
                        width="180"
                    />
                    <el-table-column
                        prop="level"
                        label="Level"
                        width="180"
                    />
                    <el-table-column
                        prop="directed_spend"
                        label="Directed Spend"
                        width="180"
                    />
                    <el-table-column
                        label="Actions"
                        width="260"
                    >
                        <template #default="{ row }">
                            <el-button
                                v-if="$auth.check('client_levels.edit')"
                                size="small"
                                icon="zoom-in"
                                @click="onView(row)"
                            >
                                View
                            </el-button>
                            <el-button
                                v-if="$auth.check('client_levels.edit')"
                                size="small"
                                icon="edit"
                                @click="onEdit(row)"
                            >
                                Edit
                            </el-button>
                            <el-button
                                v-if="$auth.check('client_levels.delete')"
                                type="danger"
                                size="small"
                                icon="delete"
                                @click="handleDelete(row)"
                            >
                                Delete
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <el-pagination
                    v-model:current-page="page"
                    v-model:page-size="per_page"
                    :total="total"
                    :page-sizes="[5, 15, 30]"
                    hide-on-single-page
                    layout="sizes, prev, pager, next, ->, total"
                    class="el-pagination--align-right"
                    @current-change="fetchData"
                    @size-change="fetchData"
                />
            </div>
        </div>
        <div v-else>
            <el-tree
                ref="treeRef"
                :props="treeProps"
                :load="loadNode"
                lazy
            >
                <template #default="{ node, data }">
                    <span class="custom-tree-node">
                    <span>{{ node.label }}</span>
                    <span style="margin-left: 50px;">
                        <el-button
                            v-if="$auth.check('client_levels.create')&&node.level>0"
                            type="primary"
                            size="small"
                            icon="plus"
                            @click="$router.push({name: 'client-levels.add', params: { parent_id: data?._id }})"
                        >
                            Add Child
                        </el-button>
                        <el-button
                            v-if="$auth.check('client_levels.edit')"
                            size="small"
                            icon="edit"
                            @click="onEdit(data)"
                        >
                            Edit
                        </el-button>
                        <el-button
                            v-if="$auth.check('client_levels.delete')"
                            type="danger"
                            size="small"
                            icon="delete"
                            @click="handleDelete(data, node)"
                        >
                            Delete
                        </el-button>
                    </span>
                    </span>
                </template>
            </el-tree>
        </div>
    </section>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'
// import ImportExportForm from './ImportExportForm'
import clientLevelsApi from "../clientLevelsApi";
import { useRouter } from "vue-router";
import store from '~/store'
import BaseFilter from "~/base/components/filters/BaseFilter.vue";
// debugger

const page = ref(1)
const per_page = ref(5)
const total = ref(0)
const router = useRouter()
const client_levelsLoading = ref(false)
const client_level = ref(null)
const filters = ref({search: ''})
const client_levels_children = ref(null)
const client_levels_parent_id = ref(null)

// const tableViewFlag = ref(false)
const tableViewFlag = computed(() => store.getters['client_levels/tableViewFlag'])
const treeRef = ref(null)
const treeProps = {
    children: 'children',
    label: 'name',
    isLeaf: 'isLeaf',
}

// get parent_id from route
watch(() => router.currentRoute.value.params, (params) => {
    client_levels_parent_id.value = params.parent_id
    if (router.currentRoute.value.name == 'client-levels.index' && params.parent_id && !tableViewFlag.value) {
        // tableViewFlag.value = true
        router.push({name: 'client-levels.index', params: {parent_id: null}})
    }
    fetchData()
}, { immediate: true })

watch(tableViewFlag, (flag) => {
    if (!flag) {
        router.push({name: 'client-levels.index', params: {parent_id: null}})
    }
})

watch(
    () => filters.value,
    val => applySearch(),
    { deep: true }
)

const title = computed(() => {
    return 'Client levels'
})

const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: 'Client levels',
    }
])



onMounted(async () => {
    // await fetchData()
})

async function fetchData() {
    client_levelsLoading.value = true
    const params = {
        search: filters.value.search,
        page: page.value,
        limit: per_page.value,
        parent_id: client_levels_parent_id.value
    }

    let data;

    if (client_levels_parent_id.value) {
        const response = await clientLevelsApi.get(params.parent_id);
        data = response.data;
    } else {
        const response = await clientLevelsApi.getRoot();
        data = response.data;
    }

    client_level.value = data;

    const childrenResponse = await clientLevelsApi.getChildren(client_level.value._id, params);
    client_levels_children.value = childrenResponse.data;
    total.value = client_levels_children.value.reduce((sum, child) => sum + child._id, 0);

    client_levelsLoading.value = false

    updateBreadcrumb() 
}

function handleDelete(row, node = null) {
    ElMessageBox.confirm('This will permanently delete the Client Level. Continue?', 'Warning', {
        type: 'warning'
    }).then(() => {
        debugger
        clientLevelsApi.destroy(row).then((response) => {
            ElMessage.success('Deleted successfully')
            fetchData()

            if (node) {
                treeRef.value.store.remove(node)
            }
        })
    })
}
const applySearch = _.debounce(function () {
    fetchData()
}, 300)

function onView(row) {
    router.push({name: 'client-levels.index', params: {parent_id: row._id}})
}

function onEdit(row) {
    router.push({name: 'client-levels.edit', params: {id: row._id}})
}

async function loadNode(node, resolve) {
    // debugger
    if (node.level === 0) {
        const response = await clientLevelsApi.getRoot();
        let data = response.data;

        return resolve([data])
    }
    const { data } = await clientLevelsApi.getChildren(node.data._id)
        
    data.forEach((node) => {
        node.isLeaf = !node.has_children
    })
    resolve(data)
}

function getParents(id) {
    return clientLevelsApi.getParents(id)
}

function updateBreadcrumb() {
    if (client_level.value) {
        getParents(client_level.value._id).then((parents) => {
            let parentsData = parents.data;
            parentsData = parentsData.reverse();
            breadcrumbs.value = [
                {
                    title: '..',
                    path: '/'
                },
                {
                    title: 'Client levels',
                    path: '/client-levels'
                },
                ...parentsData.map((parent) => {
                    return {
                        title: parent.name,
                        path: `/client-levels/${parent._id}`
                    }
                }),
                {
                    title: client_level.value.name,
                }
            ]
        })
    }
}

function switchTreeView() {
    store.commit('client_levels/setTableViewFlag', !tableViewFlag.value)
}

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";

.page-section {
    margin-top: 5px;
}

</style>
