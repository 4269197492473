<template>
    <div>
        <vue-apex-charts
            width="500"
            type="area"
            :options="chartOptions"
            :series="series"
        />
    </div>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue'
import VueApexCharts from 'vue3-apexcharts'
import liProductsApi from '../../labelinsight-products/liProductsApi'
import {useModelWrapper} from '~/includes/composable/modelWrapper'
import bus from '~/includes/Event'

const emit = defineEmits()

const props = defineProps({
    filters: {
        type: Object,
        required: true
    },
    quartilesType: {
        type: String,
        required: true
    },
})

const filters = useModelWrapper(props, emit, 'filters')

const loading = ref(false)

onMounted(() => {
    getChartData()
})

watch(
    () => filters.value,
    () => {
        applySearch()
    },
    { deep: true }
)

const titles = {
    'sugar': 'Sugar',
    'totalNetCarb': 'Net Carb',
}

const colors = {
    'sugar': '#409EFF',
    'totalNetCarb': '#67C23A',
}

function getChartData (page = 1) {
    loading.value = true
    let params = {
        page: page,
        pageSize: 12,
        quartiles_type: props.quartilesType,
        ...filters.value
    }
    liProductsApi.getQuartiles(params).then(response => {
        chartOptions.value.xaxis.categories = []
        series.value[0].data = []
        for( let index in response.data) {
            let element = response.data[index]
            chartOptions.value.xaxis.categories.push(index)
            let value = Math.round(element * 10000)/100
            series.value[0].data.push(value)
        }

        // laravelData.value = response.data
        // totalProducts.value = laravelData.value.total

        // products.value = response.data.data
        loading.value = false
    })
}

const applySearch = _.debounce( function() {
    getChartData()
}, 500)


const chartOptions = ref( {
    chart: {
        id: 'vuechart-' + props.quartilesType,
    },
    xaxis: {
        categories: [0, 25, 50, 75, 100],
    },
    title: {
        text: titles[props.quartilesType],
    },
    colors: [colors[props.quartilesType]],
})
const series = ref([
    {
        name: titles[props.quartilesType],
        data: [0, 0, 0, 0, 0],
    },
])
</script>
