<template>
  <el-form-item
    prop="condition"
    label="Condition"
    :error="error"
  >
    <el-select
      v-model="modelValue"
      class="w-100"
      clearable
      placeholder="Add condition"
    >
      <el-option
        v-for="item in conditionList"
        :key="item"
        :label="item"
        :value="item"
      />
    </el-select>
  </el-form-item>
</template>

<script setup>
import { ref, watchEffect, inject } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  conditionList: {
        type: Array,
        required: true
    },
  error: {
        type: String,
        default: ''
    },
});

const emit = defineEmits(['update:modelValue']);

let modelValue = ref(props.modelValue);

const list = inject('list')

watchEffect(() => {
  emit('update:modelValue', modelValue.value);
});

</script>