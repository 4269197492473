export const state = {
  categoryFlats: [],
  productMappingId: null,
  categoryPaths: null,
  shortViewCategories: true,
  checkedNodes: []
}

// getters
export const getters = {
  categoryFlats: state => state.categoryFlats,
  productMappingId: state => state.productMappingId,
  categoryPaths: state => state.categoryPaths,
  shortViewCategories: state => state.shortViewCategories,
  checkedNodes: state => state.checkedNodes,
}

// mutations
export const mutations = {
    setChosenCategory (state, arrayCategory) {
        state.categoryFlats = arrayCategory
    },
    setProductMappingId (state, id) {
        state.productMappingId = id
    },
    setCategoryPaths (state, dataString) {
          state.categoryPaths = dataString
    },
    setShortViewCategories (state, boolData) {
          state.shortViewCategories = boolData
    },
    setCheckedNodes (state, arrayData) {
          state.checkedNodes = arrayData
    },
}
