import Index from './components/Index'
import ShowDeltaLogs from './components/ShowDeltaLogs'

export default [
    {
        path: 'delta-logs',
        component: Index,
        name: 'delta-logs.index',
        meta: {
            auth: 'delta_logs.view',
            pageTitle: 'Delta Logs',
        },
    },
    {
        path: 'delta-logs/:date',
        component: ShowDeltaLogs,
        name: 'delta-logs.show',
        meta: {
            auth: 'delta_logs.view',
            pageTitle: 'Delta Logs',
        },
    },
]
