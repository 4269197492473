import axios from 'axios'

const API_ENDPOINT = window.config.retailerUrl + 'users'
const API_ENDPOINT_SS = window.config.ssURL + 'companies'
const API_ENDPOINT_COACHING = window.config.coachingUrl

export default {

    all(data) {
        return axios.get(API_ENDPOINT, {params: data})
    },

    get(id) {
        return axios.get(API_ENDPOINT + '/' + id)
    },

    create(model) {
        return axios.post(API_ENDPOINT + '/create', model)
    },

    update(model) {
        return axios.put(API_ENDPOINT + '/' + model._id, model)
    },

    destroy(id) {
        return axios.post(API_ENDPOINT + '/' + id + '/remove')
    },

    assignCoachesSS(model) {
        return axios.post(API_ENDPOINT_SS + '/assign-coaches', model)
    },
    getCoachCompanyTreeSS(params) {
        return axios.get(API_ENDPOINT_SS + '/get-coach-company-tree', { params })
    },

    getAuthCode() {
        return axios.get(API_ENDPOINT_SS + '/get-auth-code')
    },
    sendInviteToCoach(payload) {
        return axios.post(API_ENDPOINT_COACHING + 'user_invite/send-invite', payload)
    }
}
