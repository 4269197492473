import Index from './components/Index'
import ProductMappingEdit from './components/ProductMappingEdit'

export default [
    {
        path: 'product-mapping',
        component: Index,
        name: 'product-mapping.index',
        meta: {
            auth: 'product_mapping.view',
            pageTitle: 'Product mapping',
        },
    },
    {
        path: 'product-mapping/add',
        component: ProductMappingEdit,
        name: 'product-mapping.add',
        meta: {
            auth: 'product_mapping.create',
            pageTitle: 'Add product mapping',
        },
    },
    {
        path: 'product-mapping/edit/:id',
        component: ProductMappingEdit,
        name: 'product-mapping.edit',
        meta: {
            auth: 'product_mapping.edit',
            pageTitle: 'Edit product mapping',
        },
    },
]
