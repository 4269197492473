<template>
    <el-select
        v-model="value"
        filterable
        placeholder="Select UOM"
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
    </el-select>
</template>

<script setup>
import { ref } from 'vue'

const value = ref('')

const options = ref([
    {
        value: 'ml',
        label: 'Milliliters',
    },
    {
        value: 'l',
        label: 'Liters',
    },
    {
        value: 'tsp',
        label: 'Teaspoons',
    },
    {
        value: 'tbsp',
        label: 'Tablespoons',
    },
    {
        value: 'fl oz',
        label: 'Fluid Ounces',
    },
    {
        value: 'cup',
        label: 'Cups',
    },
    {
        value: 'pint',
        label: 'Pints',
    },
    {
        value: 'qt',
        label: 'Quarts',
    },
    {
        value: 'gal',
        label: 'Gallons',
    },
    {
        value: 'mg',
        label: 'Milligrams',
    },
    {
        value: 'g',
        label: 'Grams',
    },
    {
        value: 'kg',
        label: 'Kilograms',
    },
    {
        value: 'oz',
        label: 'Ounces',
    },
    {
        value: 'lb',
        label: 'Pounds',
    },
])
</script>
