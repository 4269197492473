export const state = {
  chosenCategory: null,
}

// getters
export const getters = {
  chosenCategory: state => state.chosenCategory,
}

// mutations
export const mutations = {
    setChosenCategory (state, treeNodeData) {
        state.chosenCategory = treeNodeData
    },
}
