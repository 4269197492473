import NotificationIndex from './components/NotificationIndex'
import NotificationEdit from './components/NotificationEdit'
import NotificationAdd from './components/NotificationAdd'
import ScheduleIndex from './components/ScheduleIndex'

export default [
    {
        path: 'notifications',
        component: NotificationIndex,
        name: 'notifications.index',
        meta: {
            auth: 'notifications.view',
            pageTitle: 'Notifications',
        },
    },
    {
        path: 'notifications/add',
        component: NotificationAdd,
        name: 'notifications.add',
        meta: {
            auth: 'notifications.create',
            pageTitle: 'Add notification',
        },
    },
    {
        path: 'notifications/edit/:id',
        component: NotificationEdit,
        name: 'notifications.edit',
        meta: {
            auth: 'notifications.edit',
            pageTitle: 'Edit notification',
        },
    },
    {
        path: 'notifications/schedule',
        component: ScheduleIndex,
        name: 'schedules.index',
        meta: {
            auth: 'schedules.view',
            pageTitle: 'Notification schedule',
        },
    },
]
