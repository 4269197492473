<template>
    <div class="category-tags">
        <el-tag
            v-if = "taggesPmoCategory"
            :key="taggesPmoCategory"
            class="mr-10 mb-10"
            closable
            @close="removeFromList()"
        >
            {{ taggesPmoCategory }}
        </el-tag>
    </div>
</template>

<script setup>
import { forEach } from 'lodash';
import {computed, ref, watch, onMounted} from 'vue'
import pmoApi from '~/modules/pmo/pmoApi'

const emit = defineEmits()

const props = defineProps({
    categories: {
        type: Array,
        required: true
    },
})

const pmoCategories = ref([])
const pmoLoading = ref(false)

const taggesPmoCategory = computed(() => {
    let cat = '';

    forEach (pmoCategories.value[props.categories[0]], (category) => {
        if( category._id == props.categories[1]) {
             cat = category.type_name + '/' + category.title
        }
    })

    return cat
})

onMounted(() => fetchPmoData())

async function fetchPmoData() {
    pmoLoading.value = true
    const {data} = await pmoApi.all()
    pmoCategories.value = data
    pmoLoading.value = false
}

function removeFromList() {
    emit('removeFromPmoList')
}
</script>
