import axios from 'axios'

const API_ENDPOINT = window.config.ssURL + 'pmo-ssupr/categories'

export default {

    all() {
        return axios.get(API_ENDPOINT)
    },

    get(id) {
        return axios.get(API_ENDPOINT + '/' + id)
    },

    // upload() {
    //     return axios.post(API_ENDPOINT + '/upload', form)
    // },

    create(form) {
        return axios.post(API_ENDPOINT, form)
    },

    update(form) {
        return axios.put(API_ENDPOINT + '/' + form._id, form)
    },

    saveNielsen(form) {
        return axios.post(API_ENDPOINT + '/save-nielsen/' + form._id, form)
    },

    destroy(id) {
        return axios.delete(API_ENDPOINT  + '/' + id)
    },

    sort(items) {
        return axios.post(API_ENDPOINT + '/sort', items)
    },
    export() {
        return axios.get(API_ENDPOINT + '/export', {responseType: 'blob'})
    },
    import(data) {
        return axios.post(API_ENDPOINT + '/import', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }
}
