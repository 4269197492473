<template>
    <div class="dashboard-section">
        <el-row
            v-loading="!total"
            :gutter="30"
            class="dashboard-section__row"
        >
            <el-col
                :xs="24"
                :span="12"
                class="dashboard-section__col"
            >
                <el-card v-if="total">
                    <template #header>
                        <div class="el-card__title">
                            Users
                        </div>
                    </template>
                    <div class="el-card__text">
                        Invited: <span class="el-card__text--bold">{{ total.invited }}</span>
                    </div>
                    <div class="el-card__text">
                        Engaged: <span class="el-card__text--bold">{{ total.engaged }}</span>
                    </div>
                    <div class="el-card__text">
                        Completed: <span class="el-card__text--bold">{{ total.complete }}</span>
                    </div>
                </el-card>
            </el-col>
            <el-col
                :xs="24"
                :span="12"
                class="dashboard-section__col"
            >
                <el-card v-if="total">
                    <template #header>
                        <div class="el-card__title">
                            Products
                        </div>
                    </template>
                    <div class="el-card__text">
                        Replaced: <span class="el-card__text--bold">{{ total.complete_replaced }}</span>
                    </div>
                    <div class="el-card__text">
                        Rated: <span class="el-card__text--bold">{{ total.complete_rated }}</span>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import dashboardApi from '~/modules/dashboard/dashboardApi'

const total = ref(null)

onMounted(() => getData())

async function getData() {
    const {data} = await dashboardApi.fetchDashboardClientLevels()
    total.value = data.total
}
</script>

<style lang="scss" scoped>
.dashboard-section {
    &__col {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}
</style>
