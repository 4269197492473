<template>
    <section
        id="user-form"
        class="max-width-600-tablet-desktop"
    >
        <el-form
            ref="formRef"
            :model="form"
            label-width="130px"
            :rules="rules"
            @submit.prevent="saveSubmit"
        >
            <el-form-item
                label="First Name"
                prop="first_name"
                :error="errors.get('first_name')"
            >
                <el-input
                    v-model="form.first_name"
                    suffix-icon="edit-pen"
                    maxlength="20"
                    @change="errors.clear('first_name')"
                />
            </el-form-item>
            <el-form-item
                label="Last Name"
                prop="last_name"
                :error="errors.get('last_name')"
            >
                <el-input
                    v-model="form.last_name"
                    maxlength="20"
                    suffix-icon="edit-pen"
                    @change="errors.clear('last_name')"
                />
            </el-form-item>
            <el-form-item
                label="Email"
                prop="email"
                :error="errors.get('email')"
            >
                <el-input
                    v-model="form.email"
                    suffix-icon="message"
                    @change="errors.clear('email')"
                />
            </el-form-item>
            <el-form-item
                label="Directed Spend"
                prop="directed_spend"
                :error="errors.get('directed_spend')"
            >
                <el-switch
                    v-model="form.directed_spend"
                    style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                    active-text="On"
                    inactive-text="Off"
                />
            </el-form-item>
            <ClientLevelInput
                v-model="form.client_level_id" 
                :error="errors.get('client_level_id')"
            />
            <el-form-item
                label="Template"
                prop="template_id"
                :error="errors.get('template_id')"
            >
                <el-select
                    v-model="form.template_id"
                    class="w-100"
                    @change="errors.clear('template_id')"
                >
                    <el-option
                        v-for="template in templates"
                        :key="template._id"
                        :label="template.template_label"
                        :value="template._id"
                    />
                </el-select>
            </el-form-item>
        </el-form>
        <div>
            <el-button
                @click="cancel"
            >
                Cancel
            </el-button>
            <el-button
                :loading="formLoading"
                type="success"
                @click="saveSubmit"
            >
                Send
            </el-button>
        </div>
    </section>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import { ElMessage } from 'element-plus'
import {Errors} from '~/includes/Errors'
import ssUserApi from '../ssUserApi'
import ClientLevelInput from '~/modules/client_levels/components/ClientLevelInput';
import emailApi from "~/modules/email/emailApi";

const emit = defineEmits()

const props = defineProps({
    initialForm: {
        type: Object,
        default: {
            client_level_id: '',
            directed_spend: false
        }
    },
})

const errors = ref(new Errors())
const formLoading = ref(false)
const form = ref({
    first_name: '',
    last_name: '',
    email: '',
    template_id: '',
    directed_spend: false,
    client_level_id: '',
})
const rules = {
    first_name: [
        { required: true, message: 'First Name is required' },
    ],
    last_name: [
        { required: true, message: 'Last Name is required' },
    ],
    template_id: [
        { required: true, message: 'Template is required', trigger: 'blur' },
    ],
    email: [
        { required:true, message: 'Please enter your email', trigger: 'blur' },
        { type: 'email', message: 'Invalid email', trigger: 'blur' }
    ],
    client_level_id: [
        { required: true, message: 'Client level is required' },
    ]
}

const templates = ref([])

onMounted(() => {
    getMailTemplates()
    form.value = Object.assign({}, props.initialForm)
})

watch(
    () => props.initialForm,
    val => form.value = Object.assign({}, val),
    { deep: true }
)

const formRef = ref()

async function getMailTemplates() {
    const params = {
        page_tag: 'user_invite'
    }
    const {data} = await emailApi.getTemplates(params)
    templates.value = data
}

function saveSubmit() {
    if (form.value.client_level_id === '') {
        errors.value.record({client_level_id: ['Client level is required']})
    }
    formRef.value.validate((valid) => {
        if (valid) {
            formLoading.value = true
            errors.value.clear()
            ssUserApi.sendInvite(form.value).then((response) => {
                ElMessage.success( 'Invite was sent successfully')
                emit('saved')
            }).catch(error => {
                if (error.response.data.errors) errors.value.record(error.response.data.errors)
            }).finally(() => formLoading.value = false)
        }
    })
}

function cancel() {
    clearForm()
    emit('cancel')
}

function clearForm() {
    errors.value.clear()
    if (formRef.value) formRef.value.resetFields()
}

const departmentsList = ref(null)

</script>
