import axios from 'axios'

const API_SS_ENDPOINT = window.config.ssURL + 'mailgun'

export default {
    getMailgunTemplates() {
        return axios.get('mailgun/templates')
    },

    getTemplates(params) {
        return axios.get(API_SS_ENDPOINT + '/templates', { params })
    },

    storeTemplate(payload) {
        return axios.post(API_SS_ENDPOINT + '/store',  payload)
    },

    deleteTemplate(id) {
        return axios.delete(API_SS_ENDPOINT + '/templates/' + id)
    },

}
