import axios from 'axios'

const API_ENDPOINT = 'users'

export default {

    all(data) {
        return axios.get(API_ENDPOINT + '/list', {params: data})
    },

    get(id) {
        return axios.get(API_ENDPOINT + '/' + id)
    },

    create(model) {
        return axios.post(API_ENDPOINT + '/create', model)
    },

    update(model) {
        return axios.put(API_ENDPOINT + '/' + model._id, model)
    },

    destroy(id) {
        return axios.post(API_ENDPOINT + '/' + id + '/remove')
    },
}
