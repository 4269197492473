<template>
    <div
        v-loading="loading"
        class="drop-down-categories"
    >
        <el-select
            class="drop-down-categories-select"
            v-for="(item, index) in levels"
            :key="index"
            v-model="selectedLevels[index]"
            :disabled="index === 0"
            clearable
            placeholder="Select"
            size="default"
            @change="loadClientLevels(index)"
        >
            <el-option
                v-for="level in item"
                :key="level._id"
                :label="level.name"
                :value="level._id"
            />
        </el-select>
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import {useModelWrapper} from '~/includes/composable/modelWrapper'
import clientLevelsApi from "../../../client_levels/clientLevelsApi";

const emit = defineEmits()
const levels = ref([])
const selectedLevels = ref([])
const loading = ref(false)

onMounted(() => {
    loadRoodLevel()
})

function loadRoodLevel() {
    clientLevelsApi.getRoot()
        .then(response => {
            levels.value[0] = [response.data]
            levels.value[1] = [...response.data.children]
            selectedLevels.value[0] = response.data._id;
            emit('chooseLevel', {id: response.data._id, level: 0})
        })
        .catch(error => {
            loading.value = false
            console.log(error)
        })
}

async function loadClientLevels(index) {
    loading.value = true
    const id = selectedLevels.value[index]
    selectedLevels.value.length = index + 1;
    levels.value.length = index + 1
    let lastSelectedId = selectedLevels.value[index]
    let level = index
    if (lastSelectedId === '') {
        lastSelectedId = selectedLevels.value[index - 1]
        level = index - 1;
    }
    emit('chooseLevel', {id: lastSelectedId, level: level})
    await clientLevelsApi.get(id).then(response => {
        const data = response.data
        loading.value = false
        if (data.children && data.children.length > 0) {
            levels.value[index + 1] = [...data.children]
        }
    }).catch(error => {
        loading.value = false
        console.log(error)
    })
}


</script>

<style scoped>
.drop-down-categories-select {
    width: 20%;
}
</style>
