<template>
    <el-button
        type="primary"
        class="w-100"
        @click="handleShowFilterModal"
    >
        Select Filter Options
    </el-button>
    <el-dialog
        v-model="showCategoriesModal"
        title="Filter Options"
    >
        <div class="categorize-switch-controls text-center">
            <el-radio-group
                v-model="categorization_type"
                class="text-center"
            >
                <el-radio-button label="all">
                    All products
                </el-radio-button>
                <!--                <el-radio-button label="unmapped">-->
                <!--                    Unmapped-->
                <!--                </el-radio-button>-->
                <el-radio-button label="uncategorized">
                    Uncategorized
                </el-radio-button>
                <el-radio-button label="changed">
                    Changed Products
                </el-radio-button>
                <el-radio-button label="syndigo_categorized">
                    Syndigo Categories +  Uncategorized
                </el-radio-button>
                <el-radio-button label="pmo_categorized">
                    Pmo Categories
                </el-radio-button>
            </el-radio-group>
        </div>

        <div
            v-if="categorization_type === 'changed' || categorization_type === 'all'"
            class="mb-20"
        >
            <el-checkbox
                v-model="isShowCategoryTree"
                label="Show NielsenIQ Categories"
                class="categorization-checkbox"
                @change="onShowTree"
            />

            <div v-show="isShowCategoryTree">
                <div>
                    <el-switch
                        v-model="shortViewCategories"
                        class="mb-2"
                        active-text="Short view"
                        inactive-text="Tree view"
                    />
                </div>
                <drop-down-categories
                    v-if="shortViewCategories"
                    v-model="drop_down_pathes"
                />
                <el-tree
                    v-else
                    ref="categorizationTree"
                    v-loading="loadingCategoryTree"
                    :data="treeCategoryData"
                    node-key="path"
                    show-checkbox
                    lazy
                    :load="loadCategoryTree"
                    :props="treeProps"
                />
            </div>
        </div>

        <div
            v-if="categorization_type === 'syndigo_categorized'"
            class="mb-20"
        >
            <div class="filter-title">
                Syndigo Categories
            </div>
            <div>
                <el-switch
                    v-model="shortViewSyndigoCategories"
                    class="mb-2"
                    active-text="Short view"
                    inactive-text="Tree view"
                />
            </div>
            <syndigo-drop-down-categories
                v-if="shortViewSyndigoCategories"
                v-model="drop_down_pathes_syndigo"
            />
            <el-tree
                v-else
                ref="tree"
                v-loading="loadTree"
                :data="treeData"
                node-key="Node ID"
                show-checkbox
                lazy
                :load="loadNode"
                :props="treeProps"
            />
        </div>
        <div
            v-if="categorization_type === 'pmo_categorized'"
            class="mb-20"
        >
            <el-form-item
                label="PMO Category"
                :label-width="180"
            >
                <el-cascader
                    v-model="pmoCategoriesSelected"
                    :options="pmoCategories"
                    clearable
                    class="w-100"
                    placeholder="Select PMO category"
                />
            </el-form-item>
        </div>
        <div class="filter-title">
            Additional product filters
        </div>
        <el-form-item
            class="sugar-min-max"
            label="Sugar Per 100g"
            :label-width="180"
        >
            <div>
                <el-input
                    v-model="sugarData.sugar_per_100g_min"
                    min="0"
                    placeholder="Min"
                />
            </div>
            <span>-</span>
            <div>
                <el-input
                    v-model="sugarData.sugar_per_100g_max"
                    min="0"
                    placeholder="Max"
                />
            </div>
        </el-form-item>
        <el-form-item
            class="sugar-min-max"
            label="Net Carbs Per 100g"
            :label-width="180"
        >
            <div>
                <el-input
                    v-model="sugarData.net_carbs_per_100g_min"
                    min="0"
                    placeholder="Min"
                />
            </div>
            <span>-</span>
            <div>
                <el-input
                    v-model="sugarData.net_carbs_per_100g_max"
                    min="0"
                    placeholder="Max"
                />
            </div>
        </el-form-item>
        <el-form-item
            class="sugar-min-max"
            label="Total Weight (in grams)"
            :label-width="180"
        >
            <div>
                <el-input
                    v-model="sugarData.total_weight_min"
                    min="0"
                    placeholder="Min"
                />
            </div>
            <span>-</span>
            <div>
                <el-input
                    v-model="sugarData.total_weight_max"
                    min="0"
                    placeholder="Max"
                />
            </div>
        </el-form-item>


        <template #footer>
            <span class="dialog-footer">
                <el-button @click="handleClose">
                    Reset
                </el-button>
                <el-button
                    type="success"
                    @click="handleOk"
                >
                    Apply
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
import {useModelWrapper} from '~/includes/composable/modelWrapper'
import {computed, ref, watch, onMounted} from 'vue'
import proCategorizationApi from '~/modules/product-categorization/proCategorizationApi'
import liProductsApi from '~/modules/labelinsight-products/liProductsApi'
import DropDownCategories from '~/modules/labelinsight-products/components/filter/DropDownCategories'
import SyndigoDropDownCategories from './SyndigoDropDownCategories'
import pmoApi from '~/modules/pmo/pmoApi'

const emit = defineEmits()

const props = defineProps({
    categorizationtype: {
        type: String,
        required: true
    },
    categoryFlats: {
        type: Array,
        required: true
    },
    sugarFilters: {
        type: Array,
        required: true
    },
    pmoCategory: {
        type: Array,
        required: true
    },
})

const categorizationtype = useModelWrapper(props, emit, 'categorizationtype')
const category_flats = useModelWrapper(props, emit, 'categoryFlats')
const sugarFilters = useModelWrapper(props, emit, 'sugarFilters')
const pmo_category = useModelWrapper(props, emit, 'pmoCategory')

const tree = ref()
const categorizationTree = ref()
const showCategoriesModal = ref(false)
const loadTree = ref(false)
const isShowCategoryTree = ref(false)
const treeData = ref([])
const treeProps = {
    children: 'children',
    label: 'name',
    isLeaf: 'isLeaf',
}
const treeKey = 0
const level = 0

const categorization_type = ref('uncategorized')
const category_paths = ref([])
const sugarData = ref({})
const pmoCategoriesSelected = ref([])
const loadingCategoryTree = ref(false)
const treeCategoryData = ref([])

const shortViewCategories = ref(true)
const drop_down_pathes = ref([])
const shortViewSyndigoCategories = ref(true)
const drop_down_pathes_syndigo = ref([])

const pmoLoading = ref(false)
const types = ref(null)

onMounted(() => fetchPmoData())

async function fetchPmoData() {
    pmoLoading.value = true
    const {data} = await pmoApi.all()
    types.value = data
    pmoLoading.value = false
}

const pmoCategories = computed(() => _.map(types.value, ((t, i) => ({
    value: i,
    label: t[0].type_name,
    children: t.map(c => ({
        value: c._id,
        label: c.title,
    }))
}))))

watch(
    () => sugarFilters.value,
    (value) => sugarData.value = _.cloneDeep(value),
    { deep: true }
)
watch(
    () => categorization_type.value,
    (value) => {
        if(isShowCategoryTree.value&&categorizationTree.value) {
            categorizationTree.value.setCheckedKeys([])
        }
        isShowCategoryTree.value = false
    }
)

function onShowTree() {
    setTimeout(() => {
        if(!isShowCategoryTree.value&&categorizationTree.value) {
            categorizationTree.value.setCheckedKeys([])
        }
    }, 0)
}

function handleShowFilterModal() {
    showCategoriesModal.value = true
}

async function handleOk() {
    if (categorization_type.value === 'syndigo_categorized') {
        // category_paths.value = tree.value.getCheckedKeys()
        fillCategoryFlats()
        emit('paths', category_paths.value)
    } else if ((categorization_type.value === 'changed' || categorization_type.value === 'all') && isShowCategoryTree.value) {
        if (shortViewCategories.value) {
            category_paths.value = drop_down_pathes.value
        } else {
            category_paths.value = categorizationTree.value.getCheckedKeys()
        }

        await fillNielsenCategoryFlats()
        emit('paths', category_paths.value)
    } else {
        emit('paths', [])
    }
    sugarFilters.value = _.cloneDeep(sugarData.value)
    showCategoriesModal.value = false
    categorizationtype.value = categorization_type.value
    pmo_category.value = pmoCategoriesSelected.value
}

function handleClose() {
    clearSearch()
    showCategoriesModal.value = false
}

async function loadNode(node, resolve) {
    let path = node.data.path||[]
    // debugger

    if (!path) loadTree.value = true
    const {data} = await proCategorizationApi.getByPath(path)
    loadTree.value = false
    let categories = data

    let pathLevel = path.length

    categories = categories.map((item) => {
        let taxonomyName = item['Taxonomy Node']

        //split taxonomy name by | and get last element

        let taxonomyNameArray = taxonomyName.split('|')

        item.label = taxonomyNameArray[taxonomyNameArray.length - 1]
        item.name = item.label
        item.isroot = (pathLevel === 0)
        item.isLeaf = (pathLevel === 4)
        // item.path = taxonomyNameArray with first element removed
        item.path = taxonomyNameArray.slice(1)

        return item
    })

    resolve(categories)
}

async function loadCategoryTree(node, resolve) {
    let path = node.data.path||''

    if (!path) loadingCategoryTree.value = true
    const {data} = await liProductsApi.getByParents(path)
    loadingCategoryTree.value = false
    let categories = data

    let pathLevel = 0
    if (path) {
        pathLevel = path.split('/').length
    }

    categories = categories.map((item) => {
        item.label = item.name
        item.isroot = (pathLevel === 0)
        item.isLeaf = (pathLevel === 4)

        return item
    })

    resolve(categories)
}

function removeFromList(index) {
    if (index > -1) {
        category_paths.value.splice(index, 1)
        category_flats.value = category_paths.value
        let checkedKeys = category_paths.value.map(path => {
            return path.nodeId
        })
        tree.value&&tree.value.setCheckedKeys(checkedKeys)
    }
}

function removeFromPmoList() {
    pmoCategoriesSelected.value = []
}

async function fillNielsenCategoryFlats() {
    let cat_flats = []
    const promises =  category_paths.value.map(async path => {
        const data = await getCategoryData(path)
        cat_flats.push(data)
        return path
    })
    await Promise.all(promises)
    category_flats.value = cat_flats
}

async function getCategoryData(path) {
    let response = await liProductsApi.getHierarchy(path)

    const levels = [
        'department',
        'superCategory',
        'category',
        'subCategory',
        'segment',
    ]
    let flatArray = {}
    levels.forEach((level) => {
        if (response.data[level]) {
            flatArray[level] = response.data[level].raw_name
        }
    })
    return flatArray
}

function fillCategoryFlats() {
    let cat_flats = []
    if ( shortViewSyndigoCategories.value) {
        drop_down_pathes_syndigo.value.forEach(data => {
            let flat = {
                path: data.path,
                nodeId: data['Node ID'],
            }
            cat_flats.push(flat)
        })
    } else {
        let nodes = tree.value.getCheckedNodes()

        nodes.forEach(node => {
            let data = tree.value.getNode(node).data
            let flat = {
                path: data.path,
                nodeId: data['Node ID'],
            }
            cat_flats.push(flat)
        })
    }

    category_paths.value = cat_flats
    category_flats.value = cat_flats
}

function clearSearch() {
    categorization_type.value = 'uncategorized'
    categorizationtype.value = 'uncategorized'
    category_paths.value = []
    emit('paths', category_paths.value)
    sugarFilters.value = {}
    sugarData.value = {}
    tree.value?.setCheckedKeys([])
    pmo_category.value = []
}

defineExpose({ removeFromList, clearSearch, removeFromPmoList })
</script>

<style lang="scss">
.filter-title {
    margin-top: 10px;
    margin-left: 20px;
    font-size: 18px;

}

.categorization-checkbox {
    margin: 15px;
}

.sugar-min-max {
    margin-top: 20px;
    .el-form-item__content {
        display: flex;
        justify-content: space-between;
        > span {
            margin: 0 15px;
        }
        > div {
            width: 40%;
            .el-input__inner {
                //width: 50px;
            }
        }
    }
}
</style>
