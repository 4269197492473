<template>
    <section id="reference">
        <div class="page-breadcrumbs">
            <el-breadcrumb
                separator="/"
                class="breadcrumb"
            >
                <el-breadcrumb-item :to="{path: '/'}">
                    ..
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{name: 'pmo.index'}">
                    PMO Categories
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    References
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <h2 class="page-title">
            References
        </h2>
        <div
            v-if="$auth.check('pmo_references.create')"
            class="page-section"
        >
            <el-button
                type="primary"
                icon="plus"
                @click="$router.push({name: 'pmo.references.add', params: {pmo_id: $route.params.pmo_id}})"
            >
                Add reference
            </el-button>
        </div>

        <el-table
            v-loading="referencesLoading"
            :data="references"
            highlight-current-row
            class="w-100"
        >
            <el-table-column
                prop="title"
                label="Title"
                min-width="200"
            />
            <el-table-column
                prop="url"
                label="Url"
                min-width="200"
            />
            <el-table-column
                label="Actions"
                min-width="180"
                align="right"
            >
                <template #default="scope">
                    <el-button
                        v-if="$auth.check('pmo_references.edit')"
                        size="small"
                        icon="edit"
                        @click="$router.push({name: 'pmo.references.edit', params: {pmo_id: $route.params.pmo_id, reference_id: scope.row._id}})"
                    >
                        Edit
                    </el-button>
                    <el-button
                        v-if="$auth.check('pmo_references.delete')"
                        type="danger"
                        size="small"
                        icon="delete"
                        @click="handleDelete(scope.row)"
                    >
                        Delete
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </section>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import referenceApi from '../../referenceApi'
import {useRoute} from 'vue-router'

const route = useRoute()

const referencesLoading = ref(false)
const references = ref(null)

onMounted(() => fetchData())

async function fetchData() {
    referencesLoading.value = true
    const {data} = await referenceApi.all(route.params.pmo_id)
    references.value = data
    referencesLoading.value = false
}

function handleDelete(row) {
    console.log(row)
    ElMessageBox.confirm('This will permanently delete the reference "'+ row.title +'". Continue?', 'Warning', {
        type: 'warning'
    }).then(() => {
        referenceApi.destroy(route.params.pmo_id, row._id).then((response) => {
            ElMessage.success( 'Deleted successfully')
            fetchData()
        })
    })
}

const applySearch = _.debounce( function() {
    fetchData()
}, 300)

</script>
