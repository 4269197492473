<template>
    <div class="status-button">
        <div class="status-button__title">
            Status: {{ status }}
        </div>
        <div class="status-button__buttons">
            <div
                v-if="!isFetching"
                class="status-button__buttons-col"
            >
                <get-logs-button class="page-section__col" />
            </div>
            <div
                v-if="isFetching"
                class="status-button__buttons-col"
            >
                <el-button @click="stopFetching">
                    Stop Fetching
                </el-button>
            </div>
        </div>
    </div>
</template>

<script setup>
import GetLogsButton from '~/modules/labelinsight-products/components/GetLogsButton'
import liProductsApi from '~/modules/labelinsight-products/liProductsApi'
import {computed, ref} from 'vue'


const status = ref('')
loadData()

function loadData () {
    getStatus()
}

function getStatus() {
    liProductsApi.productStatus().then(response => {
        status.value = response.data
    })

    setTimeout(() => getStatus(), 120000)
}

function stopFetching() {
    liProductsApi.stopProductFetching().then(() => {
        getStatus()
    })
}

const isFetching = computed(() => status.value === 'fetching' || status.value === 'delta_fetching')

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.status-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    &__title {
        margin: 5px 10px 5px 0;
        font-size: 18px;
    }
    &__buttons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 -5px;
        &-col {
            margin: 5px 0;
            padding: 0 5px;
        }
    }
}
</style>
