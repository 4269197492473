import axios from 'axios'

const API_ENDPOINT = 'labelinsight/delta-logs'

export default {

    all(params) {
        return axios.get(API_ENDPOINT + '', {params})
    },
    get(date, params) {
        return axios.get(API_ENDPOINT + '/' + date, {params})
    },
    delete(date) {
        let params = {
            date: date
        }
        return axios.post(API_ENDPOINT + '/delete', params)
    },
}
