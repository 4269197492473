<template>
    <div
        v-if="productData"
        class="product-preview"
        :class="{'product-preview--product-mapping-mob-adaptive': productMappingMobAdaptive }"
    >
        <div class="product-preview__image">
            <img
                :src="productData.image"
                :alt="productData.productTitle"
                class="product-preview__image-i"
                @error="imageLoadError"
            >
        </div>
        <div class="product-preview__info">
            <p class="product-preview__info-brand product-preview__info-brand-gtin">
                {{ productData.gtinFormats.gtin14 }}
            </p>
            <p class="product-preview__info-brand product-preview__info-brand-name">
                {{ productData.brand }}
            </p>
            <p class="product-preview__info-title">
                {{ productData.productTitle }}: {{ productData.variant }}
            </p>
            <p
                v-if="serving&&productData.lis_data.servingSize!==false"
                class="product-preview__info-text product-preview__info-text-serving"
            >
                Serving size: {{ serving.size }} {{ serving.sizeUom }}
            </p>
            <p
                v-if="sugarColumn"
                class="product-preview__info-text product-preview__info-text-sugar"
            >
                Sugar/Serving: <span class="product-preview__info-text-value">{{ sugarColumn.value }} {{ sugarColumn.uom }}</span>
            </p>
            <p
                v-if="netCarb"
                class="product-preview__info-text"
            >
                Net Carb/Serving: {{ netCarb.value }} {{ netCarb.uom }}
            </p>
            <p
                v-if="insignificantAmountStatement"
                class="product-preview__info-text"
            >
                Warning: {{ insignificantAmountStatement }}
            </p>
        </div>
    </div>
</template>
<script>
// import { mapGetters } from 'vuex'
import liProductsApi from '~/modules/labelinsight-products/liProductsApi'
import {ElMessage, ElMessageBox} from 'element-plus'
// import { BModal } from 'bootstrap-vue'

export default {
    name: 'ProductPreview',
    components: {
        // BModal
    },
    props: {
        product: {
            type: [Object, Array],
            default: null,
        },
        productMappingMobAdaptive: {
            type: Boolean,
            default: false,
            description: 'Modifier for this component in product mapping modal for mobile devices'
        }
    },
    data() {
        return {
            productImageLoaded: true,
            serving: null,
            sugarColumn: null,
            netCarb: null,
            insignificantAmountStatement: '',
            productData: null,
        }
    },
    mounted() {
        if (this.product) {
            this.getProductByUpc(this.product.gtinFormats.gtin14)
        }
    },
    // computed: Object.assign(
    //     mapGetters({
    //         user: 'auth/user',
    //     }),
    //     {
    //         productImageUrl() {
    //             if (this.product && this.product.gtinFormats.gtin14 && this.product.imagesArray[0] && this.productImageLoaded) {
    //                 return this.product.imagesArray[0]
    //             } else {
    //                 return '/images/ImageUnavailable2.png'
    //             }
    //         }
    //     }
    // ),
    methods: {
        getImageUrl(product) {
            return '/images/products/' + product.gtin + '.jpg'
        },
        imageLoadError() {
            this.productImageLoaded = false
        },
        getProductByUpc(upc) {
            console.log('getProductByUpc', upc)
            liProductsApi.getProductByUpc(upc).then(response => {
                this.productData = response.data

                console.log('getProductByUpc FOUND: ' + upc, this.product)

            }).catch(response => {
                ElMessageBox.alert('Product ' + upc + ' Not Found In DB', 'Error')
                console.log('getProductByUpc NOT FOUND: ' + upc, response)
                this.productData = this.product
            })
        }

    },
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.product-preview {
    $block-name: &;
    display: flex;
    margin-bottom: 10px;
    &__image {
        flex-shrink: 0;
        width: 100px;
        height: 100px;
        margin-right: 10px;
        &-i {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__info {
        max-width: 650px;
        &-brand,
        &-title,
        &-text {
            margin: 0 0 5px;
        }
        &-brand {
            font-size: 14px;
            &-name {
                margin: 0 0 15px;
            }
        }
        &-title {
            font-weight: 600;
        }
    }
    &--product-mapping-mob-adaptive {
        margin-bottom: 0;
        @media all and (max-width: 768px ) {
            display: block;
        }
        #{$block-name} {
            &__image {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 105px;
                height: 105px;
                overflow: hidden;
                border: 1px solid darken($white-second, 5%);
                border-radius: 5px;
                padding: 2px;
                @media all and (max-width: 768px ) {
                    margin-right: 0;
                    margin-bottom: 5px;
                }
            }
        }
    }
}
</style>
