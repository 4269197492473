import axios from 'axios'

const API_ENDPOINT = 'roles'

export default {

    fetchRoles() {
        return axios.get(API_ENDPOINT + '')
    },

    get(id) {
        return axios.get(API_ENDPOINT + '/' + id)
    },

    fetchPermissions() {
        return axios.get(API_ENDPOINT + '/permissions')
    },

    fetchRolesList() {
        return axios.get(API_ENDPOINT + '/list')
    },

    update(model) {
        return axios.put(API_ENDPOINT + '/' + model.id, model)
    }
}
