<template>
    <div
        v-if="product"
        class="product-info"
    >
        <div
            v-if="showCheckBoxes"
            class="product-info__select-control"
        >
            <input
                id="checkbox-red"
                v-model="product.selected"
                type="checkbox"
                class="checkbox-red"
                @change="fillSelectedCollection(product)"
            >
            <label for="checkbox-red" />
        </div>
        <div
            v-if="showProductMapping&&product.productMap&&product.productMap.length>0"
            class="product-info__product-mapping"
        >
            <!-- show green star icon -->
            <el-icon><Star /></el-icon>
        </div>
        <div
            v-if="showProductMapping&&product.productSources&&product.productSources.length>0"
            class="product-info__product-mapping"
        >
            <!-- show blue star icon -->
            <el-icon class="blue">
                <Star />
            </el-icon>
        </div>
        <div class="product-info__head">
            <div
                class="product-info__image-wrap"
                @click="clickProduct(product)"
            >
                <img
                    class="product-info__image"
                    :src="product.image"
                    :alt="product.productTitle"
                    @error="imageLoadError"
                >
            </div>
            <div
                class="product-info__content"
                @click="clickProduct(product)"
            >
                <h3 class="product-info__content-headline">
                    {{ product.brand }}, {{ product.productTitle }}
                </h3>
                <div
                    v-if="product.product_details"
                    class="product-info__content-description"
                >
                    {{ product.product_details }}
                </div>
            </div>
        </div>
        <div
            class="product-info__footer"
            @click="clickProduct(product)"
        >
            <div class="product-info__ingredients">
                <span class="product-info__ingredients-title">Ingredients: </span>
                <span
                    class="product-info__ingredients-text"
                    v-html="product.ingredients?.declaration"
                />
            </div>
        </div>

        <el-dialog
            v-model="showProduct"
            :title="product.productTitle + ' - ' + product.upc"
            destroy-on-close
            center
        >
            <product
                :product-prop="product"
                @closeProductPopup="closeProductPopup()"
                @onUpdateProduct="onUpdateProduct"
            />
        </el-dialog>
    </div>
</template>

<script>
import Product from './Product'

export default {
    name: 'ProductInfo',
    components: {
        Product,
        // BModal
    },
    props: {
        productProp: {
            type: Object,
            default: null,
        },
        showCheckBoxes: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Array,
            default: [],
        },
        showProductMapping: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            product: null,
            showProduct: false,
            showProductModal: false,
            check: false,
            productImageLoaded: true
        }
    },
    computed: {
        productImageUrl() {
            if (this.product && this.product.gtin && this.productImageLoaded) {
                return '/images/products/' + this.product.gtin + '.jpg'
            } else {
                return '/images/ImageUnavailable2.png'
            }
        }
    },
    mounted() {
        this.product = this.productProp
        this.check = this.product.selected
    },
    methods: {
        getImageUrl(product) {
            return '/images/products/' + product.gtin + '.jpg'
        },
        imageLoadError() {
            this.productImageLoaded = false
        },

        clickProduct(product) {
            this.showProduct = true
        },
        closeProductPopup() {
            this.showProduct = false
            this.showProductModal = false
        },
        fillSelectedCollection(product) {
            //product.selected = this.check;
            if (product.selected) {
                if (this.selected.indexOf(product._id) === -1) {
                    this.selected.push(product._id)
                }

            } else {
                this.removeFormSelectedCollection(product._id)
            }
            this.$emit('updateSelected', this.selected)
        },
        removeFormSelectedCollection(id) {
            let index = this.selected.indexOf(id)
            if ((index + 1) > 0) {
                this.selected.splice(index, 1)
            }
            this.$emit('updateSelected', this.selected)
        },
        dropCategories: function () {
            this.categoriesModel.forEach(function (item) {
                item.selected = ''
            })
        },
        onUpdateProduct: function (product) {
            this.product = product
        },
    },
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.product-info {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    padding: 10px;
    box-shadow: 0 0 6px 1px rgba(0,0,0, .25);
    &__select-control {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    &__image {
        max-width: 100%;
        max-height: 100%;
        &-wrap {
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 170px;
            margin-bottom: 20px;
            border: 1px solid $light-indigo;
            border-radius: 8px;
            overflow: hidden;
            padding: 2px;
            background-color: $white-second;
        }
    }
    &__content {
        &-headline,
        &-description {
            margin: 0 0 10px;
            line-height: 1.4;
        }
        &-headline {
            height: 68px;
            overflow: hidden;
            font-size: 16px;
            font-weight: 600;
        }
        &-description {
            font-size: 14px;
        }
    }
    &__ingredients {
        overflow: hidden;
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &__product-mapping {
        position: absolute;
        // top: 1px;
        right: 14px;
        color: $green;
        font-size: 20px;
        .blue {
            color: $blue;
        }
    }
}
</style>
