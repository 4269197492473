<template>
    <div class="page-breadcrumbs">
        <page-breadcrumbs :list="breadcrumbs" />
    </div>
    <h2 class="page-title">
        {{ actionName + ' ' + props.role }}
    </h2>
    <dash-user-form
        v-loading="loading"
        :initial-form="formData"
        @saved="router.push({name: indexRouteName})"
        @cancel="router.push({name: indexRouteName})"
    />
</template>

<script setup>
import {ref, onMounted, computed} from 'vue'
import DashUserForm from './DashUserForm'
import {useRoute, useRouter} from 'vue-router'
import dashUserApi from '../dashUserApi'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'

const route = useRoute()
const router = useRouter()
const formData = ref({})

const loading = ref(false)

const actionName = computed(() => route.params.id ? 'Edit' : 'Add')

const props = defineProps({
    role: {
        type: String,
        required: true,
    },
})

const pluralizeRole = computed(() => _.toLower(props.role) + (props.role === 'Coach' ? 'es' : 's'))
const indexRouteName = computed(() => 'dashboards-' + pluralizeRole.value + '.index')
const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: _.capitalize(pluralizeRole.value),
        name: indexRouteName.value
    },
    {
        title: `${actionName.value} ${props.role}`,
    }
])

onMounted(() => fetchData())

async function fetchData() {
    if (route.params.id) {
        loading.value = true
        const {data} = await dashUserApi.get(route.params.id)
        formData.value = data
        loading.value = false
    }

    formData.value.role = props.role
}
</script>
