<template>
    <section id="company">
        <div class="page-breadcrumbs">
            <page-breadcrumbs :list="breadcrumbs" />
        </div>
        <h2 class="page-title">
            White Label
        </h2>
        <el-row
            :gutter="15"
            justify="space-between"
        >
            <el-col class="col-auto">
                <base-filter v-model="filters" />
            </el-col>
        </el-row>

        <!-- table -->
        <el-table
            v-loading="clientLevelsLoading"
            :data="clientLevels"
            class="w-100"
            row-key="_id"
            border
            :tree-props="{children: 'children'}"
            @filter-change="handleFilterChange"
        >
            <el-table-column
                prop="name"
                label="Name"
                min-width="200"
            />
            <el-table-column
                prop="white_label.created_at"
                label="Created"
                width="200"
            >
                <template #default="scope">
                    {{ scope.row.white_label === null ? null : $formatDate(scope.row.white_label?.created_at, 'YYYY-MM-DD HH:mm') }}
                </template>
            </el-table-column>
            <el-table-column
                :sort-orders="sortOrders"
                prop="white_label.updated_at"
                label="Updated"
                width="200"
            >
                <template #default="scope">
                    {{ scope.row.white_label === null ? null : $formatDate(scope.row.white_label?.updated_at, 'YYYY-MM-DD HH:mm') }}
                </template>
            </el-table-column>
            <el-table-column
                label="Actions"
                min-width="180"
                align="right"
            >
                <template #default="scope">
                    <el-button
                        v-if="$auth.check('white_label.create') && scope.row.white_label === null"
                        size="small"
                        icon="edit"
                        @click="$router.push({name: 'white-label.add', params: {id: scope.row._id}})"
                    >
                        Add
                    </el-button>
                    <el-button
                        v-if="$auth.check('white_label.edit') && scope.row.white_label?._id"
                        size="small"
                        type="primary"
                        icon="edit"
                        plain
                        @click="$router.push({name: 'white-label.edit', params: {id: scope.row.white_label?._id}})"
                    >
                        Edit
                    </el-button>
                    <el-button
                        v-if="$auth.check('white_label.delete') && scope.row.white_label?._id"
                        type="danger"
                        size="small"
                        icon="delete"
                        @click="handleDelete(scope.row.white_label._id)"
                    >
                        Delete
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            v-model:current-page="page"
            :page-size="per_page"
            :total="total"
            hide-on-single-page
            layout="prev, pager, next, ->, total"
            class="el-pagination--align-right"
            @current-change="fetchData"
            @size-change="fetchData"
        />
    </section>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import whiteLabelApi from '../whiteLabelApi'
import BaseFilter from '~/base/components/filters/BaseFilter'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'
import {result} from "lodash";

const clientLevelsLoading = ref(false)
const clientLevels = ref(null)
const total = ref(0)
const sortBy = ref('id,asc')
const sortOrders = ref(['ascending', 'descending'])
const filters = ref({search: ''})
const page = ref(1)
const per_page = ref(10)
const clientLevelsList = ref()
const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: 'White Label',
    }
])

onMounted(() => fetchData())

watch(
    () => filters.value,
    val => applySearch(),
    { deep: true }
)

function handleSortChange(val) {
    if (val.prop != null && val.order != null) {
        let sort = val.order.startsWith('a') ? 'asc' : 'desc'
        sortBy.value = val.prop + ',' + sort
        fetchData()
    }
}
function handleFilterChange() {
    fetchData()
}

function buildHierarchy(objects, parentId = null) {
    const children = objects.filter(obj => obj.parent_id === parentId);
    if (children.length === 0) return null;
    return children.map(child => {
        const obj = {...child};
        const nestedChildren = buildHierarchy(objects, child._id);
        if (nestedChildren) obj.children = nestedChildren;
        return obj;
    });
}

async function fetchData() {
    clientLevelsLoading.value = true
    let params = {
        ...filters.value
    }
    const {data} = await whiteLabelApi.all(params)
    clientLevels.value = buildHierarchy(data)
    clientLevelsList.value = data
    clientLevelsLoading.value = false
}

function handleDelete(row) {
    ElMessageBox.confirm('This will permanently delete the white label. Continue?', 'Warning', {
        type: 'warning'
    }).then(() => {
        whiteLabelApi.destroy(row).then((response) => {
            ElMessage.success( 'Deleted successfully')
            fetchData()
        })
    })
}

const searchData = () => {
    const filteredList = _.clone(clientLevelsList.value)
        .filter(clientLevel => {
            return clientLevel.name.includes(filters.value.search)
        })
    let result = filteredList
    filteredList.forEach(clientLevel => {
        result = [...result, ...getParentLevel(clientLevel, result)];
    })
    return buildHierarchy(result)
}

const getParentLevel = (clientLevel, filteredList) => {
    let result = []
    if (clientLevel.parent_id && !filteredList.some(item => item._id === clientLevel.parent_id)) {
        const parentLevel = clientLevelsList.value.find(item => item._id === clientLevel.parent_id)
        if (!(parentLevel === null || parentLevel === undefined)) {
            result.push(parentLevel)
            result = [...result, ...getParentLevel(parentLevel, filteredList)];
        }
    }

    return result
}


const applySearch = _.debounce( function() {
    if (clientLevelsList.value && clientLevelsList.value.length > 0) {
        clientLevels.value = searchData()
    }

}, 300)


</script>
