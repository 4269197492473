import Index from './components/Index'
import EditProduct from './components/EditProduct'

export default [
    {
        path: 'products-submission/:search?',
        component: Index,
        name: 'products-submission.index',
        meta: {
            auth: 'products-submission.view',
            pageTitle: 'Products Submission',
        },
    },
    {
        path: 'products-submission/edit/:id',
        component: EditProduct,
        name: 'products-submission.edit',
        meta: {
            auth: 'products-submission.edit',
            pageTitle: 'Products Submission Edit',
        },
    },
]
