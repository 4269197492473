<template>
    <div class="category-tags">
        <el-tag
            v-for="(tag, index) in categories"
            :key="index"
            class="mr-10 mb-10"
            closable
            @close="removeFromList(index)"
        >
            {{ tag }}
        </el-tag>
    </div>
</template>

<script setup>
const emit = defineEmits()

const props = defineProps({
    categories: {
        type: Array,
        required: true
    },
})

function removeFromList(index) {
    emit('removeFromList', index)
}
</script>
