<template>
    <el-form
        :model="filters"
        @submit.prevent
    >
        <el-row :gutter="10">
            <el-col
                :xs="12"
                :sm="12"
                :md="5"
            >
                <el-form-item>
                    <el-input
                        v-model="filters.search"
                        placeholder="Product name"
                        class="w-100"
                        clearable
                    />
                </el-form-item>
            </el-col>
            <el-col
                :xs="12"
                :sm="12"
                :md="5"
            >
                <el-form-item>
                    <el-select
                        v-model="filters.store"
                        filterable
                        remote
                        collapse-tags
                        collapse-tags-tooltip
                        :remote-method="loadStores"
                        :loading="loadingStores"
                        class="w-100"
                        placeholder="All Stores"
                        popper-class="el-popper--stores"
                        fit-input-width
                    >
                        <el-option
                            v-for="item in stores"
                            :key="item.uid"
                            :label="item.banner"
                            :value="item.uid"
                        >
                            <span class="el-select-dropdown__item-name">
                                {{ item.banner }} - {{ item.name }}
                            </span>
                            <span class="el-select-dropdown__item-address">
                                {{ item.address }} Zip: {{ item.zip }}
                            </span>
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col
                :xs="12"
                :sm="8"
                :md="5"
            >
                <el-form-item>
                    <advanced-search
                        ref="advancedSearchRef"
                        v-model:categorizationtype="filters.categorizationtype"
                        v-model:category-flats="filters.category_flats"
                        v-model:sugar-filters="filters.sugarFilters"
                        v-model:pmo-category="filters.pmo_category"
                        @clearSearch="clearSimpleSearch"
                        @paths="getPaths"
                    />
                </el-form-item>
            </el-col>
            <el-col
                :xs="12"
                :sm="8"
                :md="5"
            >
                <el-form-item>
                    <ScanButton @setCode="setCode" />
                </el-form-item>
            </el-col>
            <el-col
                :xs="12"
                :sm="8"
                :md="4"
            >
                <el-form-item>
                    <el-button
                        class="w-100"
                        @click="clearSearch"
                    >
                        Reset
                    </el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
    <div class="page-section">
        <CategoryTags
            v-if="category_paths.length"
            :categories="category_paths"
            :type-categorization="filters.categorizationtype"
            @removeFromList="removeFromList"
        />
        <el-tag
            v-if="filters.sugarFilters.sugar_per_100g_min"
            class="mr-10 mb-10"
            closable
            @close="delete filters.sugarFilters.sugar_per_100g_min"
        >
            Sugar min: {{ filters.sugarFilters.sugar_per_100g_min }} g
        </el-tag>
        <el-tag
            v-if="filters.sugarFilters.sugar_per_100g_max"
            class="mr-10 mb-10"
            closable
            @close="delete filters.sugarFilters.sugar_per_100g_max"
        >
            Sugar max: {{ filters.sugarFilters.sugar_per_100g_max }} g
        </el-tag>
        <el-tag
            v-if="filters.sugarFilters.net_carbs_per_100g_min"
            class="mr-10 mb-10"
            closable
            @close="delete filters.sugarFilters.net_carbs_per_100g_min"
        >
            Net carbs min: {{ filters.sugarFilters.net_carbs_per_100g_min }} g
        </el-tag>
        <el-tag
            v-if="filters.sugarFilters.net_carbs_per_100g_max"
            class="mr-10 mb-10"
            closable
            @close="delete filters.sugarFilters.net_carbs_per_100g_max"
        >
            Net carbs max: {{ filters.sugarFilters.net_carbs_per_100g_max }} g
        </el-tag>
        <el-tag
            v-if="filters.sugarFilters.total_weight_min"
            class="mr-10 mb-10"
            closable
            @close="delete filters.sugarFilters.total_weight_min"
        >
            Total Weight min: {{ filters.sugarFilters.total_weight_min }} g
        </el-tag>
        <el-tag
            v-if="filters.sugarFilters.total_weight_max"
            class="mr-10 mb-10"
            closable
            @close="delete filters.sugarFilters.total_weight_max"
        >
            Total Weight max: {{ filters.sugarFilters.total_weight_max }} g
        </el-tag>
        <pmo-category-tags
            v-if="filters.pmo_category.length"
            :categories="filters.pmo_category"
            @removeFromPmoList="removeFromPmoList"
        />
    </div>
</template>

<script setup>
import {computed, ref, onMounted} from 'vue'
import { useModelWrapper } from '~/includes/composable/modelWrapper'
import AdvancedSearch from './AdvancedSearch'
import CategoryTags from './CategoryTags'
import PmoCategoryTags from './PmoCategoryTags'
import ScanButton from './ScanButton'
import storeApi from '~/modules/store/storeApi'
import store from '~/store'

const emit = defineEmits()

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
})

const filters = useModelWrapper(props, emit, 'modelValue')

const stores = ref(null)
const loadingStores = ref(false)

async function loadStores(query) {
    if (query) {
        loadingStores.value = true
        const {data} = await storeApi.fetchStores(query)
        stores.value = data
        loadingStores.value = false
    }
}

/******************************
 *      Advanced search
 *****************************/

const advancedSearchRef = ref()
const category_paths = ref([])
function getPaths(paths) {
    category_paths.value = paths
}

function removeFromList(index) {
    advancedSearchRef.value.removeFromList(index)
}

function removeFromPmoList() {
    filters.value.pmo_category = []
    advancedSearchRef.value.removeFromPmoList()
}

function clearSimpleSearch() {
    filters.value.search = ''
    filters.value.store = null
    filters.value.pmo_category = []
    filters.value.category_flats = []
}

function clearSearch() {
    clearSimpleSearch()
    store.commit('categorization/setChosenCategory',  null)
    advancedSearchRef.value.clearSearch()
}

function setCode(code) {
    clearSearch()
    filters.value.search = code
}

function updateSugarFilters(sugarFilters) {
    filters.value.sugarFilters = sugarFilters
}
</script>

