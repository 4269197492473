<template>
    <div class="multi-answers">
        <el-form-item
            :prop="`${validationProp}.${index}.correct`"
        >
            <el-checkbox
                v-model="item.correct"
                :disabled="isChoice && !item.correct"
                @change="changeValue"
            />
        </el-form-item>
        <div class="later">
            {{ generateLaterForMultiAnswers }}
        </div>
        <el-form-item
            class="multi-answers__input"
            :prop="`${validationProp}.${index}.answer`"
            :rules="{
                    required: true,
                    message: 'Answer is required',
                }"
        >
            <el-input
                v-model="item.answer"
                @input="changeValue"
            />
        </el-form-item>
        <div class="delete_button">
            <el-button
                v-if="index > 0 || props.answersLength > 1"
                type="danger"
                :icon="Delete"
                circle
                @click="deleteAnswer(index)"
            />
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { Delete } from '@element-plus/icons-vue'

const emit = defineEmits()
const props = defineProps({
    item: {
        type: Object,
        default: {}
    },
    index: {
        type: Number,
        required: true
    },
    validationProp: {
        type: String,
        required: true
    },
    isChoice: {
        type: Boolean,
        default: false
    },
    answersLength: {
        type: Number,
        default: 1
    }
})

const generateLaterForMultiAnswers = computed(() => {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let alphabetIndex = props.index

    if(props.index > 25) {
        alphabetIndex = index - 25
    }

    return alphabet[alphabetIndex]
})


const changeValue = () => {
    emit('update', props.index, props.item)
}
const deleteAnswer = () => {
    emit('delete', props.index)
}

</script>

<style scoped>
.multi-answers {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
}

.multi-answers__input {
    width: 100% !important;
}

:deep(.multi-answers__input .el-form-item__content) {
    margin-left: 20px !important;
}

.later {
    margin-left: 6px;
    margin-top: 6px;
}

.delete_button {
    min-width: 33px;
    margin-left: 20px;
}
</style>
