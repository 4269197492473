<template>
    <section id="email">
        <div class="page-breadcrumbs">
            <el-breadcrumb
                separator="/"
                class="breadcrumb"
            >
                <el-breadcrumb-item :to="{path: '/'}">
                    ..
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{name: 'email-pages.index'}">
                    Pages
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    Templates
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="header-email-page">
            <h2 class="page-title">
                Email Templates of the {{ templates.length > 0 ? templates[0].page : '' }} page
            </h2>
            <el-button
                type="primary"
                icon="plus"
                @click="$router.push({name: 'email-pages.templates.edit', params: {page: route.params.page}})"
            >
                Add
            </el-button>
        </div>

        <el-table
            v-loading="templatesLoading"
            :data="templates"
            highlight-current-row
            class="w-100"
        >
            <el-table-column
                prop="template_label"
                label="Template"
                min-width="200"
            />
            <el-table-column
                prop="subject"
                label="Subject"
                min-width="300"
            />
            <el-table-column
                prop="template_value"
                label="Mailgun Template"
                min-width="200"
            />
            <el-table-column
                prop="sender_name"
                label="Sender Name"
                min-width="100"
            />
            <el-table-column
                prop="sender_email"
                label="Sender Email"
                min-width="200"
            />
            <el-table-column
                label="Actions"
                width="160"
                align="right"
            >
                <template #default="scope">
                    <div class="buttons">
                        <el-button
                            v-if="$auth.check('email-pages.templates.edit')"
                            size="small"
                            icon="edit"
                            @click="$router.push({name: 'email-pages.templates.edit', params: {page: scope.row.page_tag, id: scope.row._id}})"
                        >
                            Edit
                        </el-button>
                        <el-button
                            v-if="$auth.check('email-pages.templates.delete')"
                            type="danger"
                            size="small"
                            icon="delete"
                            @click="handleDelete(scope.row)"
                        >
                            Delete
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </section>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import emailApi from '../emailApi'
import {useRoute} from "vue-router";
import {ElMessage, ElMessageBox} from "element-plus";

const route = useRoute()
const templatesLoading = ref(false)
const templates = ref([])


onMounted(() => fetchData())

async function fetchData() {
    templatesLoading.value = true
    const params = {
        page_tag: route.params.page
    }
    const {data} = await emailApi.getTemplates(params)
    templates.value = data
    templatesLoading.value = false
}

function handleDelete(row) {
    ElMessageBox.confirm('This will permanently delete the email template. Continue?', 'Warning', {
        type: 'warning'
    }).then(() => {
        templatesLoading.value = true
        emailApi.deleteTemplate(row._id)
            .then((response) => {
                ElMessage.success('Deleted successfully')
                fetchData()
            })
            .catch(err => {
                console.log('Error!', err)
            })
    })
}

</script>

<style lang="scss" scoped>
.header-email-page {
    display: flex;
    justify-content: space-between;
}
.buttons {
    display: flex;
}
</style>
