import HelpfulIndex from './components/HelpfulIndex'

export default [
    {
        path: 'helpful',
        component: HelpfulIndex,
        name: 'helpful.index',
        meta: {
            auth: 'helpful.view',
            pageTitle: 'Helpful list',
        },
    },
]
