import LmIndex from './components/lm/LmIndex'
import LmEdit from './components/lm/LmEdit'

import ChapterAdd from './components/chapter/ChapterAdd'
import ChapterEdit from './components/chapter/ChapterEdit'

import LessonAdd from './components/lesson/LessonAdd'
import LessonEdit from './components/lesson/LessonEdit'

import ReferenceAdd from './components/reference/ReferenceAdd'
import ReferenceEdit from './components/reference/ReferenceEdit'

import QuestionAdd from './components/question/QuestionAdd'
import QuestionEdit from './components/question/QuestionEdit'

export default [
    // Learning Modules
    {
        path: 'learning-modules',
        component: LmIndex,
        name: 'learning_modules.index',
        meta: {
            auth: 'learning_modules.view',
            pageTitle: 'Learning modules',
        },
    },
    {
        path: 'learning-modules/add',
        component: LmEdit,
        name: 'learning_modules.add',
        meta: {
            auth: 'learning_modules.create',
            pageTitle: 'Add learning module',
        },
    },
    {
        path: 'learning-modules/edit/:lm_id',
        component: LmEdit,
        name: 'learning_modules.edit',
        meta: {
            auth: 'learning_modules.edit',
            pageTitle: 'Edit learning module',
        },
    },

    // Chapters
    {
        path: 'learning-modules/:lm_id/chapters/add',
        component: ChapterAdd,
        name: 'chapters.add',
        meta: {
            auth: 'lm_chapters.create',
            pageTitle: 'Add chapter',
        },
    },
    {
        path: 'learning-modules/:lm_id/edit/:chapter_id',
        component: ChapterEdit,
        name: 'chapters.edit',
        meta: {
            auth: 'lm_chapters.edit',
            pageTitle: 'Edit chapter',
        },
    },

    // Lessons
    {
        path: 'learning-modules/:lm_id/chapters/:chapter_id/lessons/add',
        component: LessonAdd,
        name: 'lessons.add',
        meta: {
            auth: 'lm_lessons.create',
            pageTitle: 'Add lesson',
        },
    },
    {
        path: 'learning-modules/:lm_id/chapters/:chapter_id/lessons/edit/:lesson_id',
        component: LessonEdit,
        name: 'lessons.edit',
        meta: {
            auth: 'lm_lessons.edit',
            pageTitle: 'Edit lesson',
        },
    },

    // Reference
    {
        path: 'learning-modules/:lm_id/chapters/:chapter_id/lessons/:lesson_id/reference/add',
        component: ReferenceAdd,
        name: 'references.add',
        meta: {
            auth: 'lm_references.create',
            pageTitle: 'Add reference',
        },
    },
    {
        path: 'learning-modules/:lm_id/chapters/:chapter_id/lessons/:lesson_id/reference/edit/:reference_id',
        component: ReferenceEdit,
        name: 'references.edit',
        meta: {
            auth: 'lm_references.edit',
            pageTitle: 'Edit reference',
        },
    },

    // Question
    {
        path: 'learning-modules/:lm_id/chapters/:chapter_id/lessons/:lesson_id/question/add',
        component: QuestionAdd,
        name: 'question.add',
        meta: {
            auth: 'lm_questions.create',
            pageTitle: 'Add question',
        },
    },
    {
        path: 'learning-modules/:lm_id/chapters/:chapter_id/lessons/:lesson_id/question/edit/:question_id',
        component: QuestionEdit,
        name: 'question.edit',
        meta: {
            auth: 'lm_questions.edit',
            pageTitle: 'Edit question',
        },
    },
]
