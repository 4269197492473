import axios from 'axios'

const API_ENDPOINT = window.config.ssURL + 'notifications'

export default {

    all(params) {
        return axios.get(API_ENDPOINT, { params })
    },

    tasks(params) {
        return axios.get(API_ENDPOINT + '/tasks', { params })
    },

    get(id) {
        return axios.get(API_ENDPOINT + '/' + id)
    },

    create(model) {
        return axios.post(API_ENDPOINT, model)
    },

    update(model) {
        return axios.put(API_ENDPOINT + '/' + model._id, model)
    },

    destroy(id) {
        return axios.delete(API_ENDPOINT + '/' + id)
    },

    list() {
        return axios.get(API_ENDPOINT + '/lists')
    },

    findNames(name) {
        return axios.get(API_ENDPOINT + '/find-names?search=' + name)
    },

    /* For actions */

    fetchSubcategoriesList() {
        return axios.get(window.config.ssURL + 'pmo/categories/subcategories-list')
    },

    fetchGamesList() {
        return axios.get(window.config.ssURL + 'pmo/categories/games-list')
    },

}
