<template>
    <div class="page-breadcrumbs">
        <el-breadcrumb
            separator="/"
            class="breadcrumb"
        >
            <el-breadcrumb-item :to="{path: '/'}">
                ..
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                Delta Logs
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>

    <h2 class="page-title">
        Delta Logs
    </h2>
    <div
        v-loading="loading"
        class="page-section"
    >
        <template v-if="deltaLogs">
            <el-table
                :data="deltaLogs.data"
                highlight-current-row
                class="w-100"
            >
                <el-table-column
                    prop="date"
                    label="Date"
                    min-width="130"
                />
                <el-table-column
                    prop="modified"
                    label="Modified"
                    min-width="100"
                />
                <el-table-column
                    prop="added"
                    label="Added"
                    min-width="90"
                />
                <el-table-column
                    label="Actions"
                    min-width="80"
                    align="right"
                >
                    <template #default="scope">
                        <el-button
                            v-if="$auth.check('delta_logs.view')"
                            size="small"
                            icon="view"
                            @click="handleView(scope)"
                        >
                            View
                        </el-button>
                        <el-button
                            v-if="$auth.check('delta_logs.delete')"
                            type="danger"
                            size="small"
                            icon="delete"
                            @click="handleDelete(scope)"
                        >
                            Delete
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                v-model:current-page="deltaLogs.page"
                :page-size="deltaLogs.per_page"
                :total="deltaLogs.total"
                hide-on-single-page
                layout="prev, pager, next, ->, total"
                class="el-pagination--align-right"
                @current-change="fetchData"
                @size-change="fetchData"
            />
        </template>
    </div>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import deltaLogsApi from '../deltaLogsApi'

const route = useRoute()
const router = useRouter()
const loading = ref(false)
const deltaLogs = ref(null)

onMounted(() => fetchData())

async function fetchData(page = 1) {
    loading.value = true

    const params = {
        page: page,
        pageSize: 20,
    }

    try {
        const {data} = await deltaLogsApi.all(params)
        deltaLogs.value = data
    } catch (e) {
        console.error(e)
    }
    loading.value = false
}

function handleView(item) {
    router.push({name: 'delta-logs.show', params: {date: item.row.date}})
}

function handleDelete(item) {
    ElMessageBox.confirm('Delta Logs for selected Date will be deleted permanently', 'Delete!', {
        type: 'warning'
    }).then(() => {
        deltaLogsApi.delete(item.row.date).then(response => {
            fetchData(deltaLogs.value.current_page)
        }).catch(error => {
            console.error(error)
        })
    })
}
</script>
