import InvitedUserList from './components/InvitedUserList'
import RegisteredUserList from './components/RegisteredUserList'
import UserInvite from './components/UserInvite'
import SsUserEdit from './components/SsUserEdit'

export default [
    {
        path: 'ss-users-invited',
        component: InvitedUserList,
        name: 'ss-users.invited',
        meta: {
            auth: 'invited_ss_users.view',
            pageTitle: 'Invited Users',
        },
    },
    {
        path: 'ss-users-invite',
        component: UserInvite,
        name: 'ss-users.invite',
        meta: {
            auth: 'invited_ss_users.invite',
            pageTitle: 'Invite new user',
        },
    },
    {
        path: 'ss-users-registered',
        component: RegisteredUserList,
        name: 'ss-users.registered',
        meta: {
            auth: 'registered_ss_users.view',
            pageTitle: 'Registered Users',
        },
    },
    {
        path: 'ss-users-edit/:id',
        component: SsUserEdit,
        name: 'ss-users.edit',
        meta: {
            auth: 'registered_ss_users.edit',
            pageTitle: 'Edit User',
        },
    },
]
