import RoleIndex from './components/RoleIndex'
import RoleEdit from './components/RoleEdit'

export default [
    {
        path: 'roles',
        component: RoleIndex,
        name: 'roles.index',
        meta: {
            auth: 'roles.view',
            pageTitle: 'Roles',
        },
    },
    {
        path: 'roles/edit/:id',
        component: RoleEdit,
        name: 'roles.edit',
        meta: {
            auth: 'roles.edit',
            pageTitle: 'Edit role',
        },
    },
]
