<template>
    <div class="page-breadcrumbs">
        <el-breadcrumb
            separator="/"
            class="breadcrumb"
        >
            <el-breadcrumb-item :to="{path: '/'}">
                ..
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="{name: 'email-pages.index'}">
                Pages
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="{name: 'email-pages.templates'}">
                Templates
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                {{ route.params.id ? 'Edit template' : 'Add template' }}
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <h2 class="page-title">
        {{ route.params.id ? 'Edit Email Template' : 'Add Email Template' }}
    </h2>

    <div v-loading="loading" class="email-form">
        <el-form
            ref="formRef"
            :model="form"
            label-width="150px"
            :rules="rules"
        >
            <el-form-item
                label="Email Subject"
                prop="subject"
                :error="errors.get('subject')"
            >
                <el-input
                    v-model="form.subject"
                    @change="errors.clear('subject')"
                />
            </el-form-item>
            <el-form-item
                label="Mailgun templates"
                prop="template_value"
                :error="errors.get('template_value')"
            >
                <el-select
                    v-model="form.template_value"
                    placeholder="Select action"
                >
                    <el-option
                        v-for="mgTemplate in mailgunTemplates"
                        :key="mgTemplate.name"
                        :label="mgTemplate.name"
                        :value="mgTemplate.name"
                    />
                </el-select>
            </el-form-item>
            <el-form-item
                label="Template Label"
                prop="template_label"
                :error="errors.get('template_label')"
            >
                <el-input
                    v-model="form.template_label"
                    @change="errors.clear('template_label')"
                />
            </el-form-item>
            <el-form-item
                label="Sender Name"
                prop="sender_name"
                :error="errors.get('sender_name')"
            >
                <el-input
                    v-model="form.sender_name"
                    @change="errors.clear('sender_name')"
                />
            </el-form-item>
            <el-form-item
                label="Sender Email"
                prop="sender_email"
                :error="errors.get('sender_email')"
            >
                <el-input
                    v-model="form.sender_email"
                    @change="errors.clear('sender_email')"
                />
            </el-form-item>
        </el-form>
    </div>

    <div class="page-buttons">
        <el-button
            :loading="loading"
            @click="cancel"
        >
            Back
        </el-button>
        <el-button
            :loading="loading"
            type="success"
            @click="saveSubmit"
        >
            Save
        </el-button>
    </div>
</template>

<script setup>
import {ref, onMounted, computed} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {Errors} from '~/includes/Errors'
import emailApi from "~/modules/email/emailApi";
import {ElMessageBox} from "element-plus";

const route = useRoute()
const router = useRouter()
const errors = new Errors()
const loading = ref(true)
const formRef = ref(null)
const role = ref(null)
const permissions = ref(null)
const currentTemplate = ref({})
const mailgunTemplates = ref([])
const pageTemplates = ref([])
const form = ref({
    page_tag: route.params.page,
    template_value: '',
    template_label: '',
    subject: '',
    sender_name: '',
    sender_email: 'support@seekingsimple.com',
})

const rules = {
    template_value: [
        {required: true, message: 'Template Value is required', trigger: 'focus'},
    ],
    template_label: [
        {required: true, message: 'Template Label is required', trigger: 'blur'},
    ],
    subject: [
        {required: true, message: 'Subject is required', trigger: 'blur'},
    ],
    sender_name: [
        {required: true, message: 'Sender Name is required', trigger: 'blur'},
    ],
    sender_email: [
        {required: true, message: 'Sender Email is required', trigger: 'blur'},
    ],
}

onMounted(async () => {
    loading.value = true
    fetchMailgunTemplates()
    await getPageTemplates()
    if (route.params.id) {
        fillForm()
    }
    loading.value = false
})

function fillForm() {
    currentTemplate.value = pageTemplates.value.find(template => template._id === route.params.id)

    if (typeof currentTemplate.value === 'undefined') {
        ElMessageBox.alert('Incorrect data. Click OK to go back to the previous page!', 'Warning', {
            confirmButtonText: 'OK',
            type: 'warning'
        }).then(() => {
            router.push({name: 'email-pages.templates'})
        })
    } else {
        form.value._id = route.params.id
        form.value.template_value = currentTemplate.value.template_value || ''
        form.value.template_label = currentTemplate.value.template_label || ''
        form.value.subject = currentTemplate.value.subject || ''
        form.value.sender_name = currentTemplate.value.sender_name || ''
        form.value.sender_email = currentTemplate.value.sender_email || ''
    }
}

async function getPageTemplates() {
    const params = {
        page_tag: route.params.page
    }
    await emailApi.getTemplates(params)
        .then((response) => {
            pageTemplates.value = response.data
        })
        .catch((err) => {
            console.log('Error', err)
        })
}

function fetchMailgunTemplates() {
    emailApi.getMailgunTemplates()
        .then((response) => {
            mailgunTemplates.value = response.data
        })
        .catch((err) => {
            console.log('Error', err)
        })
}

function saveSubmit() {
    formRef.value.validate((valid) => {
        if (valid) {
            loading.value = true
            emailApi.storeTemplate(form.value)
                .then(() => {
                    loading.value = false
                    router.push({name: 'email-pages.templates'})
                })
                .catch((err) => {
                    console.log('Error!', err)
                    loading.value = false
                })
        }
    })
}

function cancel() {
    router.push({name: 'email-pages.templates'})
}
</script>

<style lang="scss" scoped>
.email-form {
    max-width: 600px;
}
</style>
