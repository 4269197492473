import { createApp } from 'vue'
import App from './base/App'
import ElementPlus from 'element-plus'
import _ from 'lodash'
import dayjs from './plugins/day'
import router from './router'
import store from './store'
import auth from './plugins/auth'
// import VueAxios from 'vue-axios'
import axios from './plugins/axios-interceptor'
import filters from './includes/filters'
import $bus from './includes/Event'
import LoadingEx from '~/base/components/LoadingEx'
import {DATE_FORMAT, DATETIME_DB_FORMAT, DATETIME_FORMAT} from '~/base/constants/time.constants'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import vDragDrop from 'v-drag-drop';

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.component('LoadingEx', LoadingEx)

app.use(axios)
app.use(store)
app.use(router)
// app.use(VueAxios, axios)
app.use(auth)
app.use(ElementPlus)
app.use(vDragDrop);

app.config.globalProperties.$config = window.config
app.config.globalProperties.$_ = _
app.config.globalProperties.$dayjs = dayjs
app.config.globalProperties.$filters = filters
app.config.globalProperties.$bus = $bus
app.config.globalProperties.$formatDate =
    (datetime, from_format = DATETIME_DB_FORMAT) => dayjs(datetime, from_format).format(DATE_FORMAT)
app.config.globalProperties.$formatDateTime =
    (datetime, from_format = DATETIME_DB_FORMAT) => dayjs(datetime, from_format).format(DATETIME_FORMAT)

app.mount('#app')

export default app
