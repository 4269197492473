<template>
    <div v-loading="loading">
        <div
            v-for="(log, i) in userLogs"
            :key="i"
            class="user-logs"
        >
            <div class="user-logs__item">
                (<span class="user-logs__item-date">{{ $formatDateTime(log.created_at) }}</span>) &nbsp;
                <span
                    class="user-logs__item-content"
                    v-html="log.name"
                />
            </div>
            <span
                class="user-logs__value"
                v-html="log.value"
            />
        </div>
        <div v-if="userLogs.length === 0">
            This user has not any logs.
        </div>
    </div>
</template>

<script setup>
import {ref} from 'vue'
import coachingUserApi from '../coaсhingUserApi'

const props = defineProps({
    userId: {
        type: Array,
        required: true
    }
})

const userLogs = ref([])
const loading = ref(false)

fetchLogs()

async function fetchLogs() {
    loading.value = true
    const {data} = await coachingUserApi.getLogsByUser(props.userId)
    userLogs.value = data
    loading.value = false
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
    .user-logs {
        margin-bottom: 5px;
        border-bottom: 1px solid $light-indigo;
        padding-bottom: 5px;
        &__item {
            margin-bottom: 5px;
            font-size: 13px;
            color: lighten($dark-middle, 10%);
            &-date {
                font-style: italic;
            }
            &-content {
                font-weight: 600;
            }
        }
    }
</style>
