<template>
    <section
        id="client_levels-form"
    >
        <el-form
            ref="formRef"
            :model="form"
            label-width="200px"
            :rules="rules"
            @submit.prevent="saveSubmit"
        >
            <el-form-item
                label="Name"
                prop="name"
                :error="errors.get('name')"
            >
                <el-input
                    v-model="form.name"
                    suffix-icon="edit-pen"
                    @change="errors.clear('name')"
                />
            </el-form-item>
            <el-form-item
                label="Label for children level"
                prop="label"
                :error="errors.get('label')"
            >
                <el-input
                    v-model="form.label"
                    suffix-icon="edit-pen"
                    @change="errors.clear('label')"
                />

            </el-form-item>
            <el-form-item
                label="Stars/Points System"
                prop="has_starts_points_system"
                :error="errors.get('has_starts_points_system')"
            >
                <el-switch
                    v-model="form.has_starts_points_system"
                    style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                    @change="errors.clear('has_starts_points_system')"
                />
            </el-form-item>
            <el-form-item
                label="Directed Spend"
                prop="directed_spend"
                :error="errors.get('directed_spend')"
            >
                <el-input
                    v-model="form.directed_spend"
                    suffix-icon="edit-pen"
                    @change="errors.clear('directed_spend')"
                    type="number"
                />
            </el-form-item>
        </el-form>
        <div>
            <el-button
                @click="cancel"
            >
                Cancel
            </el-button>
            <el-button
                :loading="formLoading"
                type="success"
                @click="saveSubmit"
            >
                Save
            </el-button>
        </div>
    </section>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { ElMessage } from 'element-plus'
import {Errors} from '~/includes/Errors'
import clientLevelsApi from '../clientLevelsApi'

const emit = defineEmits()

const props = defineProps({
    initialForm: {
        type: Object,
        default: {}
    },
})

const errors = new Errors()
const formLoading = ref(false)
const form = ref({name: ''})
const rules = {
    name: [
        { required: true, message: 'Name is required' },
    ],
    label: [
        { required: true, message: 'Label is required' },
    ],
    directed_spend: [
        { required: true, message: 'Directed Spend is required' },
    ],
}

onMounted(() => form.value = Object.assign({}, props.initialForm))

watch(
    () => props.initialForm,
    val => {
        form.value = Object.assign({}, val)
    },
    { deep: true }
)

const formRef = ref()



function saveSubmit() {
    formRef.value.validate((valid) => {
        if (valid) {
            formLoading.value = true
            errors.clear()

            const action = form.value._id ? clientLevelsApi.update : clientLevelsApi.add
            action(prepareFormData()).then((response) => {
                ElMessage.success('Saved successfully')
                emit('saved', response.data._id)
            }).catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors) {
                    errors.record(error.response.data.errors)
                }
            }).finally(() => formLoading.value = false)
        }
    })
}

function cancel() {
    clearForm()
    emit('cancel0')
}

function clearForm() {
    errors.clear()
    if (formRef.value) formRef.value.resetFields()
}

function prepareFormData() {
    let formData = new FormData()
    for (let field in form.value) {
        if (form.value[field] === null) continue
        if(field === 'has_starts_points_system') {
            const hasStartsPointsSystem = form.value[field] ? 1 : 0
            formData.append(field, hasStartsPointsSystem)
            continue
        }
        formData.append(field, form.value[field])
    }

    return formData
}

</script>
