<template>
    <div class="page-breadcrumbs">
        <page-breadcrumbs :list="breadcrumbs" />
    </div>
    <h2 class="page-title">
        Edit chapter
    </h2>
    <el-row :gutter="20">
        <el-col :md="12">
            <chapter-form
                v-loading="loading"
                :initial-form="formData"
                @cancel0="$router.push({name: 'learning_modules.edit', params: {lm: $route.params.lm_id}})"
            />
        </el-col>
        <el-col
            v-show="!loading"
            :md="12"
        >
            <lessons />
        </el-col>
    </el-row>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import ChapterForm from './ChapterForm'
import Lessons from '../lesson/Lessons'
import chapterApi from '../../chapterApi'
import {useRoute} from 'vue-router'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'
import { LEARNING_SS } from "~/store/mutation-types";
import store from "~/store";

const route= useRoute()
const learningTabValue = computed(() => store.getters['learnings/learningTabValue'])
const formData = ref(null)

const loading = ref(false)
const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: learningTabValue.value === LEARNING_SS ? 'Learning modules' : 'Coach modules',
        name: 'learning_modules.index'
    },
    {
        title: 'Chapters',
        name: 'learning_modules.edit',
        params: {lm_id: route.params.lm_id}
    },
    {
        title: 'Edit chapter',
    }
])

onMounted(() => fetchData())

async function fetchData() {
    loading.value = true
    const {data} = await chapterApi.get(route.params.chapter_id)
    formData.value = data.data
    loading.value = false
}
</script>
