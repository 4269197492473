<template>
    <div class="page-breadcrumbs">
        <page-breadcrumbs :list="breadcrumbs" />
    </div>
    <h2 class="page-title">
        Add Questions and Answers
    </h2>
    <el-row :gutter="20">
        <el-col>
            <question-form
                :initial-form="formData"
                @saved="goToEdit"
                @cancel="$router.push({name: 'lessons.edit', params: {lm_id: $route.params.lm_id, chapter_id: $route.params.chapter_id, lesson_id: $route.params.lesson_id}})"
            />
        </el-col>
    </el-row>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import questionForm from './QuestionForm'
import {useRoute, useRouter} from 'vue-router'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'
import { LEARNING_SS } from "~/store/mutation-types";
import store from "~/store";

const route = useRoute()
const router = useRouter()
const learningTabValue = computed(() => store.getters['learnings/learningTabValue'])
const formData = ref(null)
const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: learningTabValue.value === LEARNING_SS ? 'Learning modules' : 'Coach modules',
        name: 'learning_modules.index'
    },
    {
        title: 'Chapters',
        name: 'learning_modules.edit',
        params: {lm_id: route.params.lm_id},
    },
    {
        title: 'Lessons',
        name: 'chapters.edit',
        params: {lm_id: route.params.lm_id, chapter_id: route.params.chapter_id},
    },
    {
        title: 'Questions',
        name: 'lessons.edit',
        params: {lm_id: route.params.lm_id, chapter_id: route.params.chapter_id, lesson_id: route.params.lesson_id},
    },
    {
        title: 'Add question',
    }
])

onMounted(() => formData.value = { lesson_id: route.params.lesson_id, answers: [{}] })

function goToEdit() {
    router.push({name: 'lessons.edit', params: {lm_id: route.params.lm_id, chapter_id: route.params.chapter_id, lesson_id: route.params.lesson_id}})
}
</script>
