<template>
    <el-form
        :model="chosenCategory"
        @submit.prevent
    >
        <el-row :gutter="10" style="align-items: center">
            <el-col
                :xs="24"
                :sm="24"
                :md="5"
            >
                <el-form-item>
                    <ChoseCategoryButton
                        ref="choseCategoryButton"
                        @setCategorize="setCategorize"
                    />
                </el-form-item>
            </el-col>
            <el-col
                :xs="24"
                :sm="24"
                :md="19"
            >
                <el-form-item>
                    <el-button
                        class="w-100"
                        :style="windowWidth < 1025 ? '' :' max-width: 270px;'"
                        type="primary"
                        :disabled="!(chosenCategory.original_name && selectedProducts.length)"
                        @click="save"
                    >
                        Save
                    </el-button>
                </el-form-item>
            </el-col>

            <el-col
                :xs="24"
                :sm="24"
                :md="24"
            >
                <el-form-item v-if="chosenCategoryStore && chosenCategoryStore.path">
                    <h4 class="category-title">
                        Category: {{ windowWidth < 1025 ? chosenCategoryStore.raw_name : chosenCategoryStore.path }}
                    </h4>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue'
import { useModelWrapper } from '~/includes/composable/modelWrapper'
import ChoseCategoryButton from './ChoseCategoryButton'
import store from "~/store";

const emit = defineEmits()

const props = defineProps({
    selectedProducts: {
        type: Array,
        default() {
            return []
        }
    },
    chosenParentCategory: {
        type: Array,
        default() {
            return []
        }
    }
})

const chosenCategory = ref({})
const windowWidth = ref(window.innerWidth)
const chosenCategoryStore = computed(() => { return store.getters['categorization/chosenCategory']})
onMounted(() =>
    window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth
    })
)

const clear = () => {
    store.commit('categorization/setChosenCategory',  null)
    chosenCategory.value = {}
    emit('setCategorize', null)
}

const save = () => {
    emit('save')
}

const setCategorize = (category) => {
    chosenCategory.value = category
    emit('setCategorize', category)
}

watch(
    () => chosenCategoryStore.value,
    val => {
        if (val === null) {
            chosenCategory.value = {}
            emit('setCategorize', null)
        } else {
            setTimeout(() => {
                chosenCategory.value = props.chosenParentCategory
            }, 0)
        }
    },
    { deep: true }
)
</script>

<style scoped>
.category-title {
    margin: 0;
}
</style>
