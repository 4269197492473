<template>
    <section id="learning_module">
        <div class="page-breadcrumbs">
            <page-breadcrumbs :list="breadcrumbs"/>
        </div>
        <h2 class="page-title">
            {{ title }}
        </h2>
        <div class="companies">
            <div>
                <import-export-form
                    v-if="$auth.check('Admin')"
                    @refresh="fetchData"
                />
            </div>
        </div>
        <div class="client-levels">
            <drop-down-client-levels
                @chooseLevel="onChooseLevel"
            />
        </div>
        <el-tabs
            v-model="learningTabValue"
            type="border-card"
            class="learning-tabs"
        >
            <el-tab-pane
                v-for="tab in tabsData"
                :key="tab.name"
                :label="tab.label"
                :name="tab.name"
            >
                <div
                    class="page-section"
                >
                    <el-button
                        v-if="$auth.check('learning_modules.create')"
                        type="primary"
                        icon="plus"
                        @click="$router.push({name: 'learning_modules.add'})"
                    >
                        Add
                    </el-button>
                </div>
                <draggable
                    v-loading="learning_modulesLoading"
                    :list="learning_modules"
                    item-key="_id"
                    class="page-section lm-table"
                    handle=".lm-table__col--handle"
                    @start="dragging = true"
                    @end="updateItemOrder"
                >
                    <template #header>
                        <el-row
                            :gutter="10"
                            align="middle"
                            justify="space-between"
                            class="lm-table__row"
                        >
                            <el-col
                                :xs="2"
                                :sm="1"
                                class="lm-table__col lm-table__col--handle"
                            >
                                #
                            </el-col>
                            <el-col
                                :xs="5"
                                :sm="2"
                                class="lm-table__col lm-table__col--icon"
                            >
                                Icon
                            </el-col>
                            <el-col
                                :xs="17"
                                :sm="4"
                                class="lm-table__col lm-table__col--title"
                            >
                                Title
                            </el-col>
                            <el-col
                                :xs="24"
                                :sm="6"
                                class="lm-table__col lm-table__col--description"
                            >
                                Description
                            </el-col>
                            <el-col
                                :xs="24"
                                :sm="3"
                                class="lm-table__col lm-table__col__owner"
                            >
                                Owner
                            </el-col>
                            <el-col
                                :xs="24"
                                :sm="2"
                                class="lm-table__col lm-table__col__owner"
                            >
                                Mandatory
                            </el-col>
                            <el-col
                                :xs="24"
                                :sm="2"
                                class="lm-table__col lm-table__col__owner"
                            >
                                Active
                            </el-col>
                            <el-col
                                :xs="24"
                                :sm="4"
                                class="lm-table__col lm-table__col--buttons"
                            >
                                Actions
                            </el-col>
                        </el-row>
                    </template>
                    <template #item="{ element }">
                        <el-row
                            :gutter="10"
                            align="middle"
                            justify="space-between"
                            class="lm-table__row"
                            :class="isHiddenModule(element) !== false ? 'lm-table__row__hidden' : ''"
                        >
                            <el-col
                                :xs="2"
                                :sm="1"
                                class="lm-table__col lm-table__col--handle"
                            >
                                <i class="fa fa-align-justify"/>
                            </el-col>
                            <el-col
                                :xs="5"
                                :sm="2"
                                class="lm-table__col lm-table__col--icon"
                            >
                                <el-avatar
                                    :size="50"
                                    :src="element.icon_url"
                                />
                            </el-col>
                            <el-col
                                :xs="17"
                                :sm="4"
                                class="lm-table__col lm-table__col--title"
                            >
                                {{ element.title }}
                            </el-col>
                            <el-col
                                :xs="24"
                                :sm="6"
                                class="lm-table__col lm-table__col--description"
                            >
                                {{ element.description }}
                            </el-col>
                            <el-col
                                :xs="24"
                                :sm="3"
                                class="lm-table__col lm-table__col__owner"
                            >
                                {{ getOwner(element) }}
                            </el-col>
                            <el-col
                                :xs="24"
                                :sm="2"
                                class="lm-table__col lm-table__col__owner"
                            >
                                <el-switch
                                    v-model="element.is_mandatory"
                                    style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                                    @change="toggleIsMandatory(element)"
                                />
                            </el-col>
                            <el-col
                                :xs="24"
                                :sm="2"
                                class="lm-table__col lm-table__col__owner"
                            >
                                <el-switch
                                    v-model="element.is_active"
                                    :disabled="currentClientLevel !== element.client_level_id"
                                    style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                                    @change="toggleIsActive(element)"
                                />
                            </el-col>
                            <el-col
                                :xs="24"
                                :sm="4"
                                class="lm-table__col lm-table__col--buttons"
                            >

                                <el-button
                                    v-if="$auth.check('learning_modules.edit') && currentClientLevel === element.client_level_id"
                                    size="small"
                                    icon="edit"
                                    @click="onEdit(element)"
                                >
                                    Edit
                                </el-button>
                                <el-button
                                    v-if="$auth.check('learning_modules.delete')  && currentClientLevel === element.client_level_id"
                                    type="danger"
                                    size="small"
                                    icon="delete"
                                    @click="handleDelete(element)"
                                >
                                    Delete
                                </el-button>
                                <el-button
                                    v-if="$auth.check('learning_modules.visible') && currentClientLevel !== element.client_level_id"
                                    type="danger"
                                    size="small"
                                    icon="view"
                                    @click="handleHidden(element)"
                                >
                                    {{ isHiddenModule(element) !== false ? 'Show' : 'Hide' }}
                                </el-button>
                            </el-col>
                        </el-row>
                    </template>
                </draggable>

                <el-pagination
                    v-model:current-page="page"
                    v-model:page-size="per_page"
                    :total="total"
                    :page-sizes="[5, 15, 30]"
                    hide-on-single-page
                    layout="sizes, prev, pager, next, ->, total"
                    class="el-pagination--align-right"
                    @current-change="fetchData"
                    @size-change="fetchData"
                />
            </el-tab-pane>
        </el-tabs>
    </section>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import draggable from 'vuedraggable'
import { ElMessage, ElMessageBox } from 'element-plus'
import lmApi from '../../lmApi'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'
import ImportExportForm from './ImportExportForm'
import store from "~/store";
import { LEARNING_COACHES, LEARNING_SS } from "~/store/mutation-types";
import { useRouter } from "vue-router";
import DropDownClientLevels from './DropDownClientLeveles.vue'
import ClientLevelsApi from "~/modules/client_levels/clientLevelsApi";

const page = ref(1)
const per_page = ref(5)
const total = ref(0)
const router = useRouter()
const lastLevel = ref(0)
const learning_modulesLoading = ref(false)
const learning_modules = ref(null)
const clientLevels = ref([])
const learningTabValue = computed({
    get: () => store.getters['learnings/learningTabValue'],
    set: (value) => store.commit('learnings/switchTab', value)
})
const currentClientLevel = computed({
    get: () => store.getters['learnings/learningClientLevel'],
    set: (value) => store.commit('learnings/selectedClientLevel', value)
})

const title = computed(() => {
    const checked = _.cloneDeep(tabsData).find(item => item.name === learningTabValue.value)
    if (checked) {
        return `Learning modules for ${ checked.label }`
    }
    return `Learning modules for SeekingSimple`
})
const tabsData = [
    {
        label: 'SeekingSimple',
        name: LEARNING_SS
    },
    {
        label: 'Coaches',
        name: LEARNING_COACHES
    },
]

const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: 'Learning modules',
    }
])

onMounted(() => {
    getClientLevels()
})

function getClientLevels () {
    ClientLevelsApi.all()
        .then((response) => {
            clientLevels.value = response.data
        })
}

function toggleIsActive(learningModule) {
    const payload = {
        '_id': learningModule._id,
        'is_active': learningModule.is_active
    }
    lmApi.stitchToggle(payload)
}

function toggleIsMandatory(learningModule) {
    const payload = {
        '_id': learningModule._id,
        'is_mandatory': learningModule.is_mandatory,
        'client_level_id': currentClientLevel.value
    }
    lmApi.stitchToggle(payload)
}

function getOwner(module) {
    return clientLevels.value.find(level => level._id === module.client_level_id)?.name
}

function onEdit(module) {
    store.commit('learnings/isDefaultEditing', lastLevel.value === 0)
    router.push({name: 'learning_modules.edit', params: {lm_id: module._id}})
}

function getIsMandatory(module) {
    let is_mandatory = module.is_mandatory[currentClientLevel.value]

    if (is_mandatory === undefined) {
        is_mandatory = module.is_mandatory[module.client_level_id]
    }

    if (is_mandatory === undefined) {
        is_mandatory = true
    }

    return is_mandatory
}

async function fetchData() {
    learning_modulesLoading.value = true
    const params = {
        page: page.value,
        limit: per_page.value,
        type: learningTabValue.value,
        client_level_id: currentClientLevel.value || null
    }
    const { data } = await lmApi.all(params)
    learning_modules.value = data.data
    learning_modules.value.forEach(function(item) {
        if (item.is_active === undefined) {
            item.is_active = true;
        }
        item.is_mandatory = getIsMandatory(item);
    })
    total.value = data.total
    per_page.value = data.per_page
    learning_modulesLoading.value = false
}

function handleDelete(row) {
    ElMessageBox.confirm('This will permanently delete the learning module. Continue?', 'Warning', {
        type: 'warning'
    }).then(() => {
        lmApi.destroy(row).then((response) => {
            ElMessage.success('Deleted successfully')
            fetchData()
        })
    })
}

const isHiddenModule = (row) => {
    return !!row.hiddenFor && row.hiddenFor.includes(currentClientLevel.value);
}

function handleHidden(row) {
    learning_modulesLoading.value = true
    const payload = {
        'client_level_id': currentClientLevel.value,
        'hide': !(!!row.hiddenFor && row.hiddenFor.includes(currentClientLevel.value)),
    }
    lmApi.visible(row._id, payload)
        .then((response) => {
            ElMessage.success(!isHiddenModule(row) ? 'Hidden' : 'Showed' + ' successfully')
            fetchData()
        })
        .catch(err => {
            learning_modulesLoading.value = false
        })
}

const applySearch = _.debounce(function () {
    fetchData()
}, 300)

const dragging = ref(false)

function updateItemOrder() {
    let items = learning_modules.value.map(function (element, index) {
        return { id: element._id, priority: index }
    })
    lmApi.priority(items).then(response => {
        if (response.data.success) {
            ElMessage.success('Saved successfully')
        }
    }).finally(() => dragging.value = false)
}

function onChooseLevel (data) {
    currentClientLevel.value = data.id
    lastLevel.value = data.level
    fetchData()
}

const cutString = (str) => _.truncate(str, { 'length': 24 })

watch(
    () => learningTabValue.value,
    () => fetchData()
)

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";

.companies {
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;

    &__select {
        min-width: 350px;
    }
}

.client-levels {
    margin-bottom: 15px;
}

#learning_module {
    .lm-table {
        min-height: 50px;

        &__row {
            border: solid darken($white-second, 5%);
            padding: 10px;

            &:first-child {
                border-width: 1px;
            }

            &:not(:first-child) {
                border-width: 0 1px 1px 1px;
            }

            &__hidden {
                opacity: 0.4 !important;
            }
        }

        &__col {
            &--icon {
                .el-avatar {
                    vertical-align: middle;
                }
            }

            &--title {
                font-weight: 600;
            }

            &--description {
                margin: 10px 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                @media all and (min-width: $s) {
                    margin: 0;
                }
            }

            &__owner {
                color: #b9b9b9;
            }

            &--buttons {
                @media all and (min-width: $s) {
                    text-align: right;
                }
            }

            &--handle {
                cursor: pointer;
            }
        }
    }
}
</style>
