<template>
    <div class="page-breadcrumbs">
        <page-breadcrumbs :list="breadcrumbs" />
    </div>
    <h2 class="page-title">
        {{ 'Assign Client Levels to ' + coachingName + ' coach' }}
    </h2>
    <user-assign-form
        v-loading="loading"
        :initial-form="formData"
        @saved="router.push({name: indexRouteName})"
        @cancel="router.push({name: indexRouteName})"
    />
</template>

<script setup>
import {ref, onMounted, computed} from 'vue'
import { ElMessage } from 'element-plus'
import UserAssignForm from './UserAssignForm'
import {useRoute, useRouter} from 'vue-router'
import coachingUserApi from '../coaсhingUserApi'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'

const route = useRoute()
const router = useRouter()
const formData = ref({
    _id: '',
    email: '',
    first_name: '',
    last_name: '',
})

const loading = ref(false)

const indexRouteName = computed(() => 'coaching-users.registered')
const coachingName = computed(() => formData.value?.first_name + ' ' + formData.value?.last_name)
const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: 'Coaches',
        name: indexRouteName.value
    },
    {
        title: 'Assign users',
    }
])

onMounted(() => fetchData())

async function fetchData() {
    if (route.params.id) {
        loading.value = true
        const {data} = await coachingUserApi.get(route.params.id)
        formData.value._id = data._id
        formData.value.email = data.email
        formData.value.first_name = data.first_name
        formData.value.last_name = data.last_name
        formData.value.booking_id = data.booking_id || null
        formData.value.clientLevelsIds = data.clientLevelsIds || []
        formData.value.userIds = data.userIds || []
        loading.value = false
    } else {
        //show error message
        ElMessage.error('No coach id provided')
    }
}
</script>
