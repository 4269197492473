import axios from 'axios'

const API_ENDPOINT = window.config.ssURL + 'departments'

export default {

    list(params) {
        return axios.get(API_ENDPOINT, { params })
    },
    get(id) {
        return axios.get(API_ENDPOINT + '/' + id)
    },
    create(params) {
        return axios.post(API_ENDPOINT, params)
    },
}
