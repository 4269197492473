<template>
    <div class="auth-page container">
        <div class="auth-page__top">
            <div class="auth-page__image-wrap">
                <img
                    class="auth-page__image"
                    src="/images/auth/seekingsimple-logo.svg"
                    alt=""
                >
            </div>
        </div>
        <div class="auth-page__title">
            SSUPR Login
        </div>
        <el-card>
            <login-form
                :errors="authErrors"
                :loading="loading"
                :forgot-link="false"
                @submit="onSubmit"
            />
        </el-card>
    </div>
</template>

<script setup>
import LoginForm from './LoginForm'
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import {useAuth} from '@websanova/vue-auth'

const router = useRouter()
const auth = useAuth()

const authErrors = ref({})
const loading = ref(false)

function onSubmit(loginData) {
    loading.value = true

    auth
        .login({
            data: loginData,
            remember: loginData.remember
        })
        .then(null, error => {
            if (error.response?.status === 422) authErrors.value = error.response.data.errors
            else console.error(error)
        })
        .finally(() => loading.value = false)
}
</script>
