import SearchWordsIndex from './components/SearchWordsIndex'
import SearchWordsEdit from './components/SearchWordsEdit'

export default [
    {
        path: 'search-words',
        component: SearchWordsIndex,
        name: 'search-words.index',
        meta: {auth: 'search_words.view'},
    },
    {
        path: 'search-words/edit/:id',
        component: SearchWordsEdit,
        name: 'search-words.edit',
        meta: {auth: 'search_words.edit'},
    },
    {
        path: 'search-words/add',
        component: SearchWordsEdit,
        name: 'search-words.add',
        meta: {auth: 'search_words.create'},
    }
]
