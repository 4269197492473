<template>
    <component
        :is="layoutComponent"
        v-if="layoutComponent"
    />
</template>

<script setup>
// Load layout components dynamically.

const requireContext = require.context('~/base/layouts', false, /.*\.vue$/)

const layouts = requireContext.keys()
    .map(file =>
        [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
    )
    .reduce((components, [name, component]) => {
        components[name.toLowerCase()] = component.default || component
        return components
    }, {})

import {computed, shallowRef, watch} from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const layoutComponent = shallowRef(null)

watch(
    () => route.meta,
    async meta => {
        let layoutName = meta?.layout
        if (layoutName && layouts[layoutName.toLowerCase()]) {
            layoutComponent.value = layouts[layoutName.toLowerCase()]
        } else {
            layoutComponent.value = layouts['default']
        }
    },
    { immediate: true }
)
</script>
