<template>
    <div class="channel">
        <!-- <div class="page-breadcrumbs">
            <page-breadcrumbs :list="breadcrumbs" />
        </div> -->
        <h2
            class="section-title"
            :class="{'section-title--middle': middleTitleSize}"
        >
            Products
            <span v-if="$auth.check('Admin')">
                | <i>({{ totalProducts }} products in db)</i>
            </span>
        </h2>

        <div class="page-section page-section--bordered-form page-section--product-filters-space">
            <product-filter
                v-model="filters"
                class="page-section page-section--bordered-form"
            />
            <filter-charts-block
                :filters="filters"
                class="page-section page-section--bordered-form"
            />
        </div>

        <div
            v-loading.fullscreen.lock="loading"
            class="page-section min-100"
        >
            <div
                v-if="products.length"
                class="products-list"
            >
                <div
                    v-for="product in products"
                    :key="product._id + loaderCounter"
                    class="products-list__item"
                >
                    <product-info
                        :product-prop="product"
                        :show-check-boxes="true"
                        :show-product-mapping="true"
                        :selected="selectedProductsProp"
                        @update-selected="updateSelected"
                    />
                </div>
            </div>
            <div v-if="products.length">
                <el-button
                    type="text"
                    @click="selectAll()"
                >
                    Select All
                </el-button>
                <span
                    v-if="selected_products.length"
                    class="selected-counter"
                >
                    {{ selected_products.length }} Selected
                </span>
                <el-button
                    v-if="selected_products.length"
                    type="text"
                    @click="unselectAll()"
                >
                    Unselect All
                </el-button>
                <el-button
                    v-if="showSelectAllFiltered"
                    type="text"
                    @click="selectAllFiltered()"
                >
                    Select All Filtered
                </el-button>
            </div>
            <el-pagination
                v-if="laravelData"
                v-model:current-page="laravelData.current_page"
                :page-size="laravelData.per_page"
                :total="laravelData.total"
                layout="prev, pager, next, ->, total"
                class="float-right mt-3 mb-3"
                @current-change="getProducts"
                @size-change="getProducts"
            />
        </div>
    </div>
</template>

<script setup>
import {ref, onMounted, onBeforeMount, watch} from 'vue'
import ProductInfo from '../../labelinsight-products/components/ProductInfo'
import ProductFilter from './filter/ProductFilter'
import FilterChartsBlock from './FilterChartsBlock'
import liProductsApi from '../../labelinsight-products/liProductsApi'
import productMappingApi from '../../product-mapping/productMappingApi'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'
import {ElMessage, ElMessageBox} from 'element-plus'
import {useModelWrapper} from '~/includes/composable/modelWrapper'
import bus from '~/includes/Event'

const SELECTALLFILTERED_MAX = 1500
const products = ref([])
const laravelData = ref(null)
const page = ref(1)
const search = ref('')
const totalProducts = ref(0)
const loading = ref(false)
const filters = ref({
    search: '',
    category_flats: [],
    sugarFilters: [],
    store: '',
    categorizationtype: 'all',
    servingSizeFlag: true,
    pmo_category: [],
})
const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: 'Products',
    }
])

const emit = defineEmits()

const props = defineProps({
    selectedProducts: {
        type: Array,
        required: true
    },
    middleTitleSize: {
        type: Boolean,
        default: false,
    },
    productMappingId: {
        type: String,
        default: null,
    },
    blockType: {
        type: String,
        default: 'sources',
    },
    defaultFilters: {
        type: Object,
        default: () => {
            return {
                search: '',
                category_flats: [],
                sugarFilters: [],
                store: '',
                categorizationtype: 'all',
                servingSizeFlag: true,
                pmo_category: [],
            }
        }
    }
})

const selected_products = useModelWrapper(props, emit, 'selectedProducts')
const selectAllFlag = ref(true)
const showSelectAllFiltered = ref(false)
const selectedProductsProp = ref([])
const loaderCounter = ref(0)

onBeforeMount(() => {
    filters.value = props.defaultFilters
})

watch(
    () => filters.value,
    () => {
        unselectAll()
        applySearch()
        setShowSelectAllFiltered()
    },
    { deep: true }
)

function getProducts (page = 1) {
    clearSelectedProducts()
    loading.value = true
    let params = {
        page: page,
        pageSize: 12,
        ...filters.value
    }
    liProductsApi.getProducts(params).then(response => {
        laravelData.value = response.data
        totalProducts.value = laravelData.value.total

        products.value = response.data.data
        loaderCounter.value++
        loading.value = false
    })
}

const applySearch = _.debounce( function() {
    emit('filterData', filters.value)
    getProducts()
}, 600)


function selectAll () {
    for (let i = 0; i < products.value.length; i++) {
        if (!selected_products.value.some(product => product._id === products.value[i]._id)) {
            selected_products.value.push(products.value[i])
        }
        products.value[i].selected = true // selectAllFlag.value
    }

    // selectAllFlag.value = !selectAllFlag.value
}

function selectAllFiltered () {
    if (totalProducts.value > SELECTALLFILTERED_MAX) {
        //show warning

        ElMessageBox.alert('You can only select up to ' + SELECTALLFILTERED_MAX + ' products at a time. Please narrow your search criteria.', 'Warning', {
            confirmButtonText: 'OK',
            type: 'warning'
        })
    } else {
        ElMessageBox.confirm('Are you sure you want to select all ' + totalProducts.value + ' filtered products?', 'Warning', {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'warning'
        }).then(() => {

            addSelectAllFiltered()
        }).catch(() => {
            ElMessage({
                type: 'info',
                message: 'Selection cancelled'
            })
        })
    }



}

function addSelectAllFiltered() {
    loading.value = true
    let params = {
        productMappingType: props.blockType,
        productMappingId: props.productMappingId,
        ...filters.value
    }
    productMappingApi.addSelectAllFiltered(params).then(response => {
        if (response.data&&response.data.updatedProductMapping) {
            bus.$emit('updated-product-mapping', {updatedProductMapping: response.data.updatedProductMapping})
        }
    }).catch(error => {
        console.log(error)
    }).finally(() => {
        loading.value = false
    })

}

function setShowSelectAllFiltered() {
    if(filters.value.search
        || filters.value.category_flats.length
        || filters.value.sugarFilters.length
        || filters.value.store
        || (filters.value.categorizationtype && filters.value.categorizationtype !== 'all')
    ) {
        showSelectAllFiltered.value = true
    } else {
        showSelectAllFiltered.value = false
    }
}
function clearSelectedProducts() {
    if (selected_products.value&& selected_products.value.length) {
        selected_products.value.splice(0,selected_products.value.length)
    }
}
function updateSelected(selectList) {
    clearSelectedProducts()
    for(let i = 0; i < products.value.length; i++) {
        let index = selectList.findIndex(item => item === products.value[i]._id)
        if (index !== -1) {
            products.value[i].selected = true
            selected_products.value.push(products.value[i])

        } else {
            products.value[i].selected = false
        }
    }
}
function unselectAll () {
    selected_products.value.splice(0,selected_products.value.length)
    for (let i = 0; i < products.value.length; i++) {
        products.value[i].selected = false
    }
}
</script>
<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.products-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    &__item {
        width: 100%;
        margin-bottom: 20px;
        padding: 0 10px;
        @media all and (min-width: $s) {
            width: calc(100% / 2);
        }
        @media all and (min-width: $m) {
            width: calc(100% / 3);
        }
        @media all and (min-width: $l) {
            width: calc(100% / 4);
        }
    }
}
.selected-counter {
    margin-left: 10px;
    font-size: 14px;
}
</style>
