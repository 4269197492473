<template>
    <section
        id="user-form"
        class="max-width-600-tablet-desktop"
    >
        <el-form
            ref="formRef"
            :model="form"
            label-width="100px"
            :rules="rules"
            @submit.prevent="saveSubmit"
        >
            <el-form-item
                label="First Name"
                prop="first_name"
                :error="errors.get('first_name')"
            >
                <el-input
                    v-model="form.first_name"
                    maxlength="20"
                    disabled
                    @change="errors.clear('first_name')"
                />
            </el-form-item>
            <el-form-item
                label="Last Name"
                prop="last_name"
                :error="errors.get('last_name')"
            >
                <el-input
                    v-model="form.last_name"
                    maxlength="20"
                    disabled
                    @change="errors.clear('last_name')"
                />
            </el-form-item>
            <el-form-item
                label="Email"
                prop="email"
                :error="errors.get('email')"
                
            >
                <el-input
                    v-model="form.email"
                    disabled
                    @change="errors.clear('email')"
                />
            </el-form-item>
            <ClientLevelInput v-model="form.client_level_id" :selectedUser="form"/>
            <!-- <h1>{{ form.client_level_id }}</h1> -->
            <el-form-item
                label="Coaching"
                prop="coaching"
                :error="errors.get('coaching')"
            >
                <el-switch
                    v-model="form.coaching"
                    @change="errors.clear('coaching')"
                />
            </el-form-item>
            
            <el-form-item
                label="Enabled"
                prop="status"
                :error="errors.get('status')"
            >
                <el-switch
                    v-model="form.status"
                    @change="errors.clear('status')"
                />
            </el-form-item>
        </el-form>
        <div>
            <el-button
                @click="cancel"
            >
                Cancel
            </el-button>
            <el-button
                :loading="formLoading"
                type="success"
                @click="saveSubmit"
            >
                Save
            </el-button>
        </div>
    </section>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import { ElMessage } from 'element-plus'
import {Errors} from '~/includes/Errors'
import ssUserApi from '../ssUserApi'
import ClientLevelInput from '~/modules/client_levels/components/ClientLevelInput.vue';

const emit = defineEmits()

const props = defineProps({
    initialForm: {
        type: Object,
        default: {}
    },
})

const errors = new Errors()
const formLoading = ref(false)
const form = ref({
    client_level_id: '',
})
const rules = {
    // client_level_id: [
    //     { required: true, message: 'Client Level is required' },
    // ],
    // coaching: [
    //     { required: true, message: 'Coaching is required' },
    // ],
    // status: [
    //     { required: true, message: 'Status is required' },
    // ],
}

onMounted(() => form.value = Object.assign({}, props.initialForm))

watch(
    () => props.initialForm,
    val => form.value = Object.assign({}, val),
    { deep: true }
)

const formRef = ref()

function saveSubmit() {
    formRef.value.validate((valid) => {
        if (valid) {
            formLoading.value = true
            errors.clear()
            let action = form.value._id ? ssUserApi.updateUser : ssUserApi.create

            action(form.value).then((response) => {
                ElMessage.success( 'Saved successfully')
                emit('saved')
            }).catch(error => {
                if (error.response.data.errors) errors.record(error.response.data.errors)
            }).finally(() => formLoading.value = false)
        }
    })
}

function cancel() {
    clearForm()
    emit('cancel')
}

function clearForm() {
    errors.clear()
    if (formRef.value) formRef.value.resetFields()
}
</script>
