export const INVITED_NOT_REGISTERED = 1
export const INVITE_FDM = 2
export const REGISTERED = 3
export const ASSIGNED_FDM = 4
export const INVITED_FDM_NOT_REGISTERED = 5

export const STARTED_ASPIRATION = 10
export const FINISHED_ASPIRATION = 11
export const STARTED_HOUSEHOLD = 12
export const FINISHED_HOUSEHOLD = 13
export const STARTED_MOTIVATION = 14
export const FINISHED_MOTIVATION = 15

export const STARTED_MODULE = 20
export const FINISHED_MODULE = 21
export const STARTED_LESSON = 22
export const FINISHED_LESSON = 23
export const FINISHED_LEARNING_QUIZ = 24

export const STARTED_PMO_CATEGORY = 30
export const FINISHED_PMO_CATEGORY = 31
export const LEARN_PMO_SUB_CATEGORY = 32
export const FINISHED_GAME = 33
export const FIRST_SCANNED = 34
export const FIRST_SEARCH = 35
export const FIRST_ADD_ALTERNATIVE = 36
export const FIRST_SHARED_PMO = 37

export const FIRST_SHARED_LIST = 40
export const NOT_VISITED_MY_ITEMS = 41
export const FIRST_RATE_PRODUCT = 42

export const DIRECT_SPEND_NOT_UTILIZED = 46
