import axios from 'axios'

// const API_ENDPOINT = window.config.ssURL + 'categories'
 const API_ENDPOINT = window.config.ssURL
const API_ENDPOINT_LABELINSIGHT = '/labelinsight'

export default {

    getByParents(path) {
        return axios.get(API_ENDPOINT + 'categories/get-by-parents/' + path)
    },

    getHierarchy(path) {
        return axios.get(API_ENDPOINT + 'categories/get-hierarchy/' + path)
    },

    getAlternatives(upc, params) {
        return axios.post(API_ENDPOINT + 'products/get-alternatives/' + upc, params)
    },

    getProducts(params, cancelToken = null) {
        // return axios.get(API_ENDPOINT_LABELINSIGHT + '/products', {params: params})
        return axios.get(API_ENDPOINT_LABELINSIGHT + '/products', {params: params, cancelToken: cancelToken})
    },
    getQuartiles(params) {
        return axios.get(API_ENDPOINT_LABELINSIGHT + '/products/get-quartiles', {params: params})
    },
    getLogs(params) {
        return axios.get(API_ENDPOINT_LABELINSIGHT + '/products/getLogs', {params: params, responseType: 'blob' })
    },

    getProductByUpc(upc) {
        return axios.get(API_ENDPOINT_LABELINSIGHT + '/products/' + upc + '/get')
    },

    getLiByUpc(upc) {
        return axios.get(API_ENDPOINT_LABELINSIGHT + '/products/' + upc + '/get-li-data')
    },

    getStoresByUpc(upc, page) {
        return axios.get(API_ENDPOINT_LABELINSIGHT + '/products/' + upc + '/get-stores', {params: {page: page}})
    },

    uploadProduct() {
        return axios.get(API_ENDPOINT_LABELINSIGHT + '/products/upload')
    },

    uploadPhoto(params) {
        return axios.post(
            API_ENDPOINT_LABELINSIGHT + '/products/uploadPhoto',
            params,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
    },

    productStatus: function() {
        return axios.get('/labelinsight/products/status')
    },

    startProductFetching: function() {
        return axios.get('/labelinsight/products/startFetching')
    },

    startProductDeltaFetching: function() {
        return axios.get('/labelinsight/products/startDeltaFetching')
    },

    stopProductFetching: function() {
        return axios.get('/labelinsight/products/stopFetching')
    },
}
