<template>
    <el-button
        type="primary"
        class="w-100"
        @click="handleShowFilterModal"
    >
        Advanced Search
    </el-button>
    <el-dialog
        v-model="showCategoriesModal"
        title="Filter Options"
    >
        <el-row justify="center">
            <h4>NielsenIQ Categories</h4>
        </el-row>
        <div class="categorize-switch-controls text-center">
            <el-radio-group
                v-model="categorization_type"
                class="text-center"
            >
                <el-radio-button label="all">
                    All products
                </el-radio-button>
                <el-radio-button label="uncategorized">
                    Uncategorized
                </el-radio-button>
                <el-radio-button label="categorized">
                    Categorized
                </el-radio-button>
            </el-radio-group>
        </div>

        <div
            v-if="categorization_type === 'categorized'"
            class="mb-20"
        >
            <div>
                <el-switch
                    v-model="shortViewCategories"
                    class="mb-2"
                    active-text="Short view"
                    inactive-text="Tree view"
                />
            </div>
            <drop-down-categories
                v-if="shortViewCategories"
                v-model="drop_down_pathes"
            />
            <el-tree
                v-else
                ref="tree"
                v-loading="loadTree"
                :data="treeData"
                node-key="path"
                show-checkbox
                lazy
                :load="loadNode"
                :props="treeProps"
            />
        </div>

        <el-row justify="center">
            <h4>Serving Size Flag</h4>
        </el-row>
        <div class="categorize-switch-controls text-center">
            <el-radio-group
                v-model="servingSizeFlagModel"
                class="text-center"
            >
                <el-radio-button label="">
                    All products
                </el-radio-button>
                <el-radio-button label="true">
                    Products with serving size
                </el-radio-button>
                <el-radio-button label="false">
                    Products without serving size
                </el-radio-button>
            </el-radio-group>
        </div>


        <template #footer>
            <span class="dialog-footer">
                <el-button @click="handleClose">
                    Reset
                </el-button>
                <el-button
                    type="success"
                    @click="handleOk"
                >
                    Apply
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
import {useModelWrapper} from '~/includes/composable/modelWrapper'
import {ref, watch} from 'vue'
import liProductsApi from '../../liProductsApi'
import DropDownCategories from './DropDownCategories'

const emit = defineEmits()

const props = defineProps({
    categorizationtype: {
        type: Object,
        required: true
    },
    categoryFlats: {
        type: Array,
        required: true
    },
    servingSizeFlag: {
        type: String,
        required: true
    }
})

const categorizationtype = useModelWrapper(props, emit, 'categorizationtype')
const category_flats = useModelWrapper(props, emit, 'categoryFlats')
const servingSizeFlag = useModelWrapper(props, emit, 'servingSizeFlag')

const tree = ref()
const showCategoriesModal = ref(false)
const treeData = ref([])
const treeProps = {
    children: 'children',
    label: 'name',
    isLeaf: 'isLeaf',
}
const treeKey = 0
const level = 0

const categorization_type = ref('all')
const category_paths = ref([])
const servingSizeFlagModel = ref('')

const shortViewCategories = ref(true)
const drop_down_pathes = ref([])

watch(
    () => category_paths.value,
    () => fillCategoryFlats(),
    { deep: true }
)

function handleShowFilterModal() {
    showCategoriesModal.value = true
}

function handleOk() {
    categorizationtype.value = categorization_type.value
    if (categorization_type.value === 'categorized') {
        if (!shortViewCategories.value) {
            category_paths.value = tree.value.getCheckedKeys()
        } else {
            category_paths.value = drop_down_pathes.value
        }
        emit('paths', category_paths.value)
    } else {
        emit('paths', [])
    }
    showCategoriesModal.value = false
    servingSizeFlag.value = servingSizeFlagModel.value
}

function handleClose() {
    clearSearch()
    showCategoriesModal.value = false
}

const loadTree = ref(false)

async function loadNode(node, resolve) {
    let path = node.data.path||''

    if (!path) loadTree.value = true
    const {data} = await liProductsApi.getByParents(path)
    loadTree.value = false
    let categories = data

    let pathLevel = 0
    if (path) {
        pathLevel = path.split('/').length
    }

    categories = categories.map((item) => {

        item.label = item.name
        item.isroot = (pathLevel === 0)
        item.isLeaf = (pathLevel === 4)

        return item
    })

    resolve(categories)
}

function removeFromList(index) {
    if (index > -1) {
        const tag_to_remove = category_paths.value[index]
        let i = 0
        category_paths.value.forEach(path => {
            if (tag_to_remove.includes(path)) category_paths.value.splice(i, 1)
            i++
        })
        tree.value.setCheckedKeys(category_paths.value)
    }
}

async function fillCategoryFlats() {
    let cat_flats = []
    const promises =  category_paths.value.map(async path => {
        const data = await getCategoryData(path)
        cat_flats.push(data)
        return path
    })
    await Promise.all(promises)
    category_flats.value = cat_flats
}

async function getCategoryData(path) {
    let response = await liProductsApi.getHierarchy(path)

    const levels = [
        'department',
        'superCategory',
        'category',
        'subCategory',
        'segment',
    ]
    let flatArray = {}
    levels.forEach((level) => {
        if (response.data[level]) {
            flatArray[level] = response.data[level].raw_name
        }
    })
    return flatArray
}

function clearSearch() {
    categorization_type.value = 'all'
    categorizationtype.value = 'all'
    category_paths.value = []
    emit('paths', category_paths.value)
    tree.value?.setCheckedKeys([])
    servingSizeFlag.value = ''
    servingSizeFlagModel.value = ''
}

defineExpose({ removeFromList, clearSearch })
</script>
