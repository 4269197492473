<template>
    <div
        v-if="nutritionFacts"
        class="nutrition-facts"
    >
        <div class="nutrition-facts__title">
            Nutrition Facts
        </div>
        <div
            v-if="nutritionFacts.serving"
            class=""
        >
            <el-form-item
                label="Serving Size"
                :label-width="160"
            >
                <div class="nutrition-facts__number-input">
                    <el-input
                        v-model="nutritionFacts.serving.size"
                        type="number"
                        placeholder="Size"
                        name="size"
                        size="mini"
                        maxlength="5"
                        min="0"
                        max="99999"
                        @keyup="checkMaxLenght"
                    />
                </div>
                <uoms
                    v-model="nutritionFacts.serving.sizeUom"
                />
            </el-form-item>
        </div>
        <div
            v-if="nutritionFacts.serving"
            class=""
        >
            <el-form-item
                label="Servings per container"
                :label-width="160"
            >
                <div class="nutrition-facts__number-input">
                    <el-input
                        v-model="nutritionFacts.serving.servingsPerContainer"
                        type="number"
                        placeholder="Servings per container"
                        name="servingsPerContainer"
                        size="mini"
                        maxlength="3"
                        min="0"
                        max="999"
                        @keyup="checkMaxLenght"
                    />
                </div>
            </el-form-item>
        </div>
        <div
            v-for="column in nutritionFacts.columns"
            :key="column.name"
            class="nutrition-facts__list"
        >
            <div class="nutrition-facts__list-name">
                {{ column.name }}
            </div>
            <div class="nutrition-facts__list-table">
                <div
                    v-for="item in column.nutrients||column.supplements"
                    :key="item.name"
                    class="nutrition-facts__list-row"
                >
                    <nutritional-properties-list
                        v-if="item"
                        :item="item"
                    />

                    <el-button
                        v-if="isVisibleDeleteButton(item)"
                        type="danger"
                        size="mini"
                        icon="delete"
                        @click="deleteNutritionalProperties(item)"
                    />
                </div>
            </div>
        </div>
        <div>
            Add One Nutritional Properties:
            <el-autocomplete
                v-model="newNutritionProperty"
                :fetch-suggestions="querySearch"
                placeholder="Nutritional Properties"
                size="mini"
                @select="selectNutritionalProperties"
            />

            <el-button
                type="primary"
                size="mini"
                :icon="Plus"
                :disabled="newNutritionProperty === ''"
                @click="addNutritionalProperties"
            />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import NutritionalPropertiesList from './NutritionalPropertiesList'
import { useModelWrapper } from '~/includes/composable/modelWrapper'
import { Plus } from '@element-plus/icons-vue'
import Uoms from './Uoms'

const emit = defineEmits()

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
})

const nutritionFacts = useModelWrapper(props, emit, 'modelValue')

const availabeNutritionProperties = [
    // 'Total Carbohydrate',
    // 'Dietary Fiber',
    // 'Total Sugar',
    'Added Sugar',
    'Calories',
    'Calories From Fat',
    'Total Fat',
    'Trans Fat',
    'Monounsaturated Fat',
    'Polyunsaturated Fat',
    'Low Fat',
    'Saturated Fat',
    'Cholesterol',
    'Sodium',
]

const unDeletedProperties = [
    'Total Carbohydrate',
    'Dietary Fiber',
    'Total Sugar',
]

function querySearch(queryString, cb) {
    var results = []

    availabeNutritionProperties.forEach((el) => {
        if (el.toLowerCase().includes(queryString.toLowerCase())) {
            results.push( {
                value: el,
            })
        }
    })
    cb(results)
}

function selectNutritionalProperties(item) {
    console.log('selectNutritionalProperties', item)
}

const newNutritionProperty = ref('')

function addNutritionalProperties() {
    if (newNutritionProperty.value === '') {
        return
    }
    nutritionFacts.value.columns[0].nutrients.push({
        name: newNutritionProperty.value,
        value: 0,
        uom: 'g',
        valueOperator: '=',
        percentDvOperator: '=',
        percentDv: 0,
    })
}

function deleteNutritionalProperties(item) {
    console.log('deleteNutritionalProperties', item)
    // if item.name in unDeletedProperties skip delete
    if (unDeletedProperties.includes(item.name)) {
        return
    }
    nutritionFacts.value.columns[0].nutrients = nutritionFacts.value.columns[0].nutrients.filter((el) => {
        return el.name !== item.name
    })
}

function isVisibleDeleteButton(item) {
    return !unDeletedProperties.includes(item.name)
}

function checkMaxLenght(e) {
    if ( e.target.value.length > e.target.maxLength ) {
        e.target.value = e.target.value.slice(0, e.target.maxLength)
    }
}

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";

.nutrition-facts {
    $block-name: &;
    &__title {
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 600;
        color: $dark;
    }
    &__serving {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 10px;
        border-bottom: 4px solid $dark;
        padding-bottom: 10px;
        &-col {
            font-size: 13px;
            &:not(:last-child) {
                margin-right: 10px;
            }
            .el-input {
                width: 100px;
                margin-right: 10px;
            }
        }
    }
    &__number-input {
        .el-input {
            width: 64px;
            margin-right: 10px;
        }
    }
    .el-select {
        width: 100px;
    }
    &__list {
        margin-bottom: 25px;
        &-name {
            padding-bottom: 10px;
            font-size: 13px;
            font-weight: 600;
        }
        &-table {
            margin-bottom: 10px;
            border-top: 4px solid $dark;
            border-bottom: 4px solid $dark;
            padding-top: 10px;
        }
        &-row {
            display: flex;
            .el-button{
                display: flex;
                margin-top: 10px;
            }
        }
    }

}
</style>
