import axios from 'axios'

const API_ENDPOINT = window.config.ssURL + 'learning-modules/lessons'

export default {
    all(lesson_id, params) {
      return axios.get(API_ENDPOINT + '/question/' + lesson_id, { params })
    },

    get(question_id) {
      return axios.get(API_ENDPOINT + '/question/show/' + question_id)
    },

    store(form) {
        return axios.post(API_ENDPOINT + '/store_question', form)
    },

    destroy(id) {
        return axios.post(API_ENDPOINT + '/delete/question/' + id)
    },

    priority(items) {
        return axios.post(API_ENDPOINT + '/questions/update-priority', {items})
    }
}
