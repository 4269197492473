import InvitedUserList from './components/InvitedUserList'
import RegisteredUserList from './components/RegisteredUserList'
import UserInvite from './components/UserInvite'
import UserAssign from './components/UserAssign'

export default [
    {
        path: 'coaching-users/invited',
        component: InvitedUserList,
        name: 'coaching-users.invited',
        meta: {
            auth: 'invited_coaching_users.view',
            pageTitle: 'Invited Users',
        },
    },
    {
        path: 'coaching-users/invite',
        component: UserInvite,
        name: 'coaching-users.invite',
        meta: {
            auth: 'invited_coaching_users.invite',
            pageTitle: 'Invite new user',
        },
    },
    {
        path: 'coaching-users/registered',
        component: RegisteredUserList,
        name: 'coaching-users.registered',
        meta: {
            auth: 'registered_coaching_users.view',
            pageTitle: 'Registered Users',
        },
    },
    {
        path: 'coaching-users/assign/:id',
        component: UserAssign,
        name: 'coaching-users.assign',
        meta: {
            auth: 'registered_coaching_users.assign',
            pageTitle: 'Assign Client Levels',
        },
    },
]
