<template>
    <el-form
        :model="filters"
        @submit.prevent
    >
        <el-row :gutter="10">
            <el-col
                :sm="filters.status ? 8 : 12"
                :md="filters.status ? 8 : 5"
            >
                <el-form-item>
                    <el-input
                        v-model="filters.search"
                        placeholder="Search"
                    />
                </el-form-item>
            </el-col>
            <el-col
                :sm="filters.status ? 8 : 12"
                :md="filters.status ? 8 : 5"
            >
                <el-form-item>
                    <el-select
                        v-model="filters.status"
                        class="m-2 w-100"
                        placeholder="Select step"
                        clearable
                    >
                        <el-option
                            v-for="status in statuses"
                            :key="status.value"
                            :label="status.label"
                            :value="status.value"
                        />
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col
                v-if="filters.status"
                :sm="8"
                :md="8"
            >
                <el-form-item>
                    <el-switch
                        v-model="filters.currently_on"
                        style="--el-switch-on-color: #13ce66; --el-switch-off-color: #1f62aa"
                        active-text="Currently on"
                        inactive-text="Finished"
                    />
                </el-form-item>
            </el-col>
            <el-col
                :sm="filters.status ? 8 : 12"
                :md="filters.status ? 8 : 5"
            >
                <el-form-item>
                    <el-date-picker
                        v-model="filters.date_from"
                        type="date"
                        placeholder="Date from"
                        :value-format="DATE_DB_FORMAT"
                        class="w-100"
                    />
                </el-form-item>
            </el-col>
            <el-col
                :sm="filters.status ? 8 : 12"
                :md="filters.status ? 8 : 5"
            >
                <el-form-item>
                    <el-date-picker
                        v-model="filters.date_to"
                        type="date"
                        placeholder="Date to"
                        :value-format="DATE_DB_FORMAT"
                        class="w-100"
                    />
                </el-form-item>
            </el-col>
            <el-col
                :sm="filters.status ? 8 : 12"
                :md="filters.status ? 8 : 4"
            >
                <el-form-item>
                    <el-button
                        type="primary"
                        class="w-100"
                        @click="clearSearch"
                    >
                        Reset
                    </el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script setup>
import { useModelWrapper } from '~/includes/composable/modelWrapper'
import {DATE_DB_FORMAT} from '~/base/constants/time.constants'

const emit = defineEmits()

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
})

const filters = useModelWrapper(props, emit, 'modelValue')

const statuses = [
    { value: 1, label: 'Registration' },
    { value: 2, label: 'Wizard' },
    { value: 3, label: 'Learning module' },
    { value: 4, label: 'Pmo' },
]

function clearSearch() {
    filters.value.search = ''
    filters.value.status = ''
    filters.value.currently_on = true
    filters.value.date_from = null
    filters.value.date_to = null
}
</script>

