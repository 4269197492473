<template>
    <div
        v-if="product"
        class="wrap wrap-white"
    >
        <div class="product-content">
            <div class="product-pic">
                <img
                    :src="product.image"
                    :alt="product.product_name"
                    @error="imageLoadError"
                >
            </div>
            <div class="main-container">
                <h1 class="product-headline">
                    {{ product.productTitle }}
                </h1>
                <div class="product-info">
                    <div
                        class="product-info-description"
                        :class="shortDescription?'short-description':''"
                    >
                        <p>{{ product.product_details }}</p>
                    </div>
                </div>
                <div
                    v-if="isPopup"
                    class="close-btn"
                    @click="goBack"
                />
            </div>
        </div> <!-- end of product-content -->

        <div class="tabs-wrap">
            <div class="main-container">
                <el-tabs
                    v-model="productTabs"
                    class="demo-tabs"
                >
                    <el-tab-pane
                        label="Pmo Categories"
                        name="pmo_categories"
                        class="el-tab-pane--pmo-categories"
                    >
                        <el-col class="mb-20">
                            <h5 class="categories-content__title">
                                NielsenIQ categorization:
                            </h5>
                            <div class="categories-content">
                                <el-card
                                    class="categories-content__product-data"
                                    shadow="never"
                                >
                                    <table v-if="product.categoryHierarchies && product.categoryHierarchies.some(item => item.type === 'nielsenIQ')">
                                        <tr
                                            v-for="item in product.categoryHierarchies.find(item => item.type === 'nielsenIQ')?.hierarchy"
                                            :key="item.key"
                                        >
                                            <td>{{ item.key }}: </td>
                                            <td>{{ item.value || '-' }}</td>
                                        </tr>
                                    </table>
                                </el-card>
                                <div class="categories-content__pmo-names">
                                    <ul
                                        v-if="pmoCategories.nielsen.length > 0"
                                        class="categories-content__list"
                                    >
                                        <li
                                            v-for="pmoCategory in pmoCategories.nielsen"
                                            :key="pmoCategory._id"
                                            class="categories-content__list-item"
                                        >
                                            <p>{{ pmoCategory.title }} ( {{ pmoCategory.slug }})</p>
                                        </li>
                                    </ul>
                                    <p v-else>
                                        No PMO Category
                                    </p>
                                </div>
                            </div>
                        </el-col>
                        <el-col>
                            <h5 class="categories-content__title">
                                LI categorization:
                            </h5>
                            <div class="categories-content">
                                <el-card
                                    class="categories-content__product-data"
                                    shadow="never"
                                >
                                    <table>
                                        <tr
                                            v-for="(item, key) in product.categorization"
                                            :key="key"
                                        >
                                            <td width="100">
                                                {{ key }}:
                                            </td>
                                            <td>{{ item }}</td>
                                        </tr>
                                    </table>
                                </el-card>
                                <div class="categories-content__pmo-names">
                                    <ul
                                        v-if="pmoCategories.li.length > 0"
                                        class="categories-content__list"
                                    >
                                        <li
                                            v-for="pmoCategory in pmoCategories.li"
                                            :key="pmoCategory._id"
                                            class="categories-content__list-item"
                                        >
                                            <p>{{ pmoCategory.title }} ( {{ pmoCategory.slug }})</p>
                                        </li>
                                    </ul>
                                    <p v-else>
                                        No PMO Category
                                    </p>
                                </div>
                            </div>
                        </el-col>
                        <pre v-if="false">
                            <h5>LI categorization:</h5>
                            <code>{{ product.categorization }}</code>
                        </pre>
                    </el-tab-pane>
                    <el-tab-pane
                        label="Product Mapping"
                        name="product_mapping"
                    >
                        <div
                            v-if="product.productMap&&product.productMap.length>0"
                            class="el-tab-pane__content"
                        >
                            <ul
                                v-for="(mapping, i) in product.productMap"
                                :key="i"
                            >
                                <li>
                                    <router-link :to="{ name: 'labelinsight-products.index', params: { search: mapping.gtinFormats.gtin14 } }">
                                        {{ mapping.productTitle }}, {{ mapping.gtinFormats.gtin14 }}
                                    </router-link>
                                    <el-icon @click="deleteFromProductMapping(product.gtinFormats.gtin14, mapping.gtinFormats.gtin14, 'targets')">
                                        <Delete />
                                    </el-icon>
                                </li>
                            </ul>
                        </div>
                        <div
                            v-if="product.productSources&&product.productSources.length>0"
                            class="el-tab-pane__content"
                        >
                            <ul
                                v-for="(mapping, i) in product.productSources"
                                :key="i"
                            >
                                <li>
                                    <router-link :to="{ name: 'labelinsight-products.index', params: { search: mapping.gtinFormats.gtin14 } }">
                                        {{ mapping.productTitle }}, {{ mapping.gtinFormats.gtin14 }}
                                    </router-link>
                                    <el-icon @click="deleteFromProductMapping(product.gtinFormats.gtin14, mapping.gtinFormats.gtin14, 'sources')">
                                        <Delete />
                                    </el-icon>
                                </li>
                            </ul>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane
                        label="Ingredients"
                        name="ingredients"
                    >
                        <div class="el-tab-pane__content">
                            {{ product.ingredients.declaration }}
                        </div>
                    </el-tab-pane>
                    <el-tab-pane
                        label="Allergens"
                        name="allergens"
                    >
                        <div
                            v-if="product.warning"
                            class="el-tab-pane__content"
                        >
                            {{ product.warning }}
                        </div>
                    </el-tab-pane>
                    <el-tab-pane
                        label="Nutrition"
                        name="nutrition"
                    >
                        <nutrition-facts :nutrition-facts="productNutritionFacts" />
                    </el-tab-pane>
                    <el-tab-pane
                        label="Certs"
                        name="certs"
                    >
                        <div
                            v-if="product.certifications"
                            class="el-tab-pane__content"
                        >
                            <ul
                                v-for="(certificate, i) in product.certifications"
                                :key="i"
                            >
                                <li>{{ certificate }}</li>
                            </ul>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane
                        label="Manufacturer"
                        name="manufacturer"
                    >
                        <div
                            v-for="pData in product.partnerData"
                            :key="pData.partner"
                            class="el-tab-pane__content"
                        >
                            <p>{{ pData.partner }}</p>
                            <div
                                v-for="item in pData.data"
                                :key="item.value"
                            >
                                {{ item.name }}: {{ item.value }}
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane
                        label="Barcode"
                        name="barcode"
                    >
                        <div class="el-tab-pane__content">
                            <BarcodeGenerator
                                v-if="product.gtinFormats.gtin12||product.gtinFormats.gtin14"
                                :value="product.gtinFormats.gtin12||product.gtinFormats.gtin14.slice(2)"
                                :format="'UPC'"
                            />
                            <p v-else>
                                No barcode
                            </p>

                            <p>gtinFormats</p>
                            <pre>
                                <code>
                                    {{ product.gtinFormats }}
                                </code>
                            </pre>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane
                        label="Images"
                        name="images"
                    >
                        <div
                            v-for="imgUlr in product.imagesArray"
                            :key="imgUlr"
                            class="el-tab-pane__content"
                        >
                            <el-image
                                :src="imgUlr"
                                :fit="contain"
                                class="bordered"
                                :lazy="true"
                            />
                        </div>
                    </el-tab-pane>
                    <el-tab-pane
                        label="Stores"
                        name="stores"
                    >
                        <div
                            v-if="1"
                            class="el-tab-pane__content"
                        >
                            <ul class="favorites-storelist">
                                <li
                                    v-for="(line, i) in stores"
                                    :key="i"
                                >
                                    <span
                                        href="#"
                                        class="favorites-store"
                                    >
                                        <div class="favorites-store-btn-wrap" />
                                        <div class="favorites-store-footer">
                                            <span class="favorites-store-name">{{ line.banner }} - {{ line.name }}</span>
                                            <span
                                                v-if="line.dist"
                                                class="favorites-store-distance"
                                            >{{ Math.round(line.dist.calculated/1609.344 * 10)/10 }} mi</span>
                                        </div>
                                        <div class="favorites-store-overlay" />
                                        <div class="favorites-image-bg">
                                            <img
                                                :src="line.store_image_url"
                                                alt=""
                                            >
                                        </div>
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div v-else>
                            No store
                        </div>
                    </el-tab-pane>
                    <el-tab-pane
                        label="Data"
                        name="data"
                    >
                        <div class="el-tab-pane__content">
                            <el-button
                                type="primary"
                                @click="openLiDataModal"
                            >
                                Compare with LI data
                            </el-button>

                            <pre>
                                <code>
                                    {{ productProp }}
                                </code>
                            </pre>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
    <el-dialog
        v-if="product"
        v-model="showLiDataModal"
        :title="product.productTitle + ' - ' + product.upc"
        destroy-on-close
        center
        append-to-body
        width="80%"
    >
        <el-row>
            <el-col
                :span="12"
                class="scroll"
            >
                <h5>LI data</h5>
                <pre>
                    <code>
                        {{ productLiData }}
                    </code>
                </pre>
            </el-col>
            <el-col
                :span="12"
                class="scroll"
            >
                <h5>SS data</h5>
                <pre>
                    <code>
                        {{ productProp }}
                    </code>
                </pre>
            </el-col>
        </el-row>
    </el-dialog>
</template>

<script>
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import NutritionFacts from './NutritionFacts'
import productMappingApi from '../../../product-mapping/productMappingApi'
import bus from '~/includes/Event'
import liProductsApi from '../../../labelinsight-products/liProductsApi'
import BarcodeGenerator from '~/base/components/BarcodeGenerator'

const ssUrl = window.config.ssURL

const productPmoCategoriesByGtin = (data) => axios.get(ssUrl + 'pmo/categories/product-pmo-categories-by-gtin-ssupr' , {params: data})

export default {
    components: {
        NutritionFacts,
        BarcodeGenerator
    },
    props: {
        productProp: {
            type: Object,
            default: null,
        },
        // categoriesModel: {
        //     type: Object,
        //     default: null,
        // },
        // tagsModel: {
        //     type: Object,
        //     default: null,
        // },
    },
    data() {
        return {
            product: null,
            certificates: [],
            shortDescription: true,
            position: {},
            isPopup: false,
            categories: [],
            tags: {activeTags: [], inactiveTags: []},
            showCategories: false,
            showTags: false,
            productImageLoaded: true,
            stores: [],
            productNutritionFacts: null,
            pmoCategories: {
                nielsen: [],
                li: []
            },
            productTabs: 'ingredients',
            showLiDataModal: false,
            productLiData: null,
        }
    },
    computed: {
        productImageUrl() {
            if (this.product && this.product.gtin && this.productImageLoaded) {
                return '/images/products/' + this.product.gtin + '.jpg'
            } else {
                return '/images/ImageUnavailable2.png'
            }
        }
    },
    mounted() {
        // debugger
        if (!this.productProp || !this.productProp._id) {
            axios.get('products/' + this.$route.params.id).then(response => {
                this.product = response.data
                this.productNutritionFacts = response.data.product.factPanel.nutrition||response.data.product.factPanel.supplement
                this.getPmoCategories()
            })
        } else {
            this.product = this.productProp
            this.isPopup = true
            this.productNutritionFacts = this.product.factPanel.nutrition||this.product.factPanel.supplement
            this.getPmoCategories()
        }


        this.getStores()
        // this.getCertificates()
        // this.getCategories()
        // this.getTags()
    },
    methods: {
        getImageUrl(product) {
            return '/images/products/' + product.gtin + '.jpg'
        },
        imageLoadError() {
            this.productImageLoaded = false
        },
        goBack() {
            if (!this.isPopup) {
                this.$router.go(-1)
            } else {
                this.$emit('closeProductPopup')
            }
        },
        getCertificates() {},
        toggleDescrMode() {
            this.shortDescription = !this.shortDescription
        },
        getElementId(idName) {
            return idName + '_' + this.product._id
        },
        async getCategories() {
            if (!this.product.categories) {
                this.showCategories = false
                return
            }
            let categoriesNames = []
            const {data} = await axios.post('categories/details', {categories: this.product.categories})
            if (data[0] == undefined) {
                this.showCategories = false
            } else {
                this.showCategories = true
                this.categories = data[0].children ? data[0].children : []
            }

        },
        itemClick(item) {
            if (item.data.selected) {
                this.undeleteCategory(item.data.hashKey)
            } else {
                this.removeCategory(item.data.hashKey)
            }
        },
        getStores() {
            if (this.stores.length == 0 && this.product.gtinFormats.gtin14) {
                let params = {
                    gtin: this.product.gtinFormats.gtin14
                }

                axios.get('products/stores/', {params: params}).then(response => {
                    this.stores = response.data[0].storeslist
                })
            }
        },
        getPmoCategories() {
            productPmoCategoriesByGtin({gtin14: this.product.gtinFormats.gtin14}).then(response => {
                this.pmoCategories = response.data
            })
        },
        deleteFromProductMapping(gtin14, productMappingGtin14, productMappingType='targets') {
            let params = {
                gtin14: gtin14,
                productMappingGtin14: productMappingGtin14,
                productMappingType: productMappingType
            }
            // productMappingApi.deleteMappedProducts(params).then(response => {
            //     this.product = response.data
            // })
            ElMessageBox.confirm(
                'Are you sure to delete this product from product mapping?',
                'Delete product from product mapping',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            ).then(() => {
                productMappingApi.deleteMappedProducts(params).then(response => {
                    this.product = response.data&&response.data.product
                    this.updateProduct(this.product)

                    let emptyProductMapping = (response.data&&response.data.emptyProductMapping)||[]
                    if (emptyProductMapping.length > 0) {
                        emptyProductMapping.forEach(productMapping => {
                            this.askDeleteEmptyProductMapping(productMapping)
                        })
                    }

                    if (response.data&&response.data.updatedProductMapping) {
                        bus.$emit('updated-product-mapping', {updatedProductMapping: response.data.updatedProductMapping})
                    }

                })
            }).catch(() => {
            })
        },
        askDeleteEmptyProductMapping(productMapping) {
            ElMessageBox.confirm(
                'Product mapping ' + productMapping.name + ' is empty. ' +
                'Are you sure to delete this product mapping?',
                'Delete product mapping',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            ).then(() => {
                productMappingApi.delete(productMapping._id).then(response => {
                    // this.product = response.data&&response.data.product
                })
            }).catch(() => {
            })
        },
        updateProduct(product) {
            this.$emit('onUpdateProduct', product)
        },
        openLiDataModal() {
            liProductsApi.getLiByUpc(this.product.upc).then(response => {
                this.productLiData = response.data
            })
            this.showLiDataModal = true
        },
    },
}
</script>

<style lang="scss" scoped>
.product-content {
    .product-pic {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 350px;
        width: 100%;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
.scroll {
    overflow-y: auto;
    // height: 100%;
}

.bordered {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ebeef5;
    border-radius: 4px;
}
</style>
