<template>
    <div class="nutritional-properties-list">
        <template v-if="item.name === 'Total Fat'">
            <div class="nutritional-properties-list__text">
                % Daily Value*
            </div>
        </template>
        <div
            class="nutritional-properties-list__row"
            :class="getBgColor(item.name)"
        >
            <div class="nutritional-properties-list__name">
                {{ item.name }}
            </div>
            <div class="nutritional-properties-list__value">
                <div
                    v-if="item.uom"
                    class="nutritional-properties-list__value-col"
                >
                    {{ item.value }} {{ item.uom }}
                </div>
                <div
                    v-if="item.percentDv"
                    class="nutritional-properties-list__value-col nutritional-properties-list__value-col--percentage"
                >
                    {{ item.percentDv }} %
                </div>
                <div
                    v-else
                    class="nutritional-properties-list__value-col nutritional-properties-list__value-col--percentage-empty"
                />
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'NutritionPropertiesList',
    components: {

    },
    props: {
        item: {
            type: Object,
            default: {},
        },

    },
    data() {
        return {
            stores: []
        }
    },
    computed: {
    },
    mounted() {
    },
    methods: {
        getBgColor(name) {
            const color = {
                'Calories': 'nutritional-properties-list__row--centered-value',
                'Calories From Fat': 'nutritional-properties-list__row--centered-value nutritional-properties-list__row--middle-border-bottom',
                'Total Fat': 'nutritional-properties-list__row--yellow nutritional-properties-list__row--bold',
                'Trans Fat': 'nutritional-properties-list__row--yellow',
                'Monounsaturated Fat': 'nutritional-properties-list__row--yellow',
                'Polyunsaturated Fat': 'nutritional-properties-list__row--yellow',
                'Low Fat': 'nutritional-properties-list__row--yellow',
                'Saturated Fat': 'nutritional-properties-list__row--yellow',
                'Cholesterol': 'nutritional-properties-list__row--green nutritional-properties-list__row--bold',
                'Sodium': 'nutritional-properties-list__row--green nutritional-properties-list__row--bold',
            }
            return color[name] ?? ''
        }
    },
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";

.nutritional-properties-list {
    $block-name: &;
    &__text {
        border-bottom: 1px solid $dark;
        padding: 6px 0;
        font-size: 13px;
        font-weight: 600;
        text-align: right;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $dark;
        font-size: 16px;
        &--green {
            background-color: $green;
        }
        &--yellow {
            background-color: $yellow;
        }
        &--bold {
            font-weight: 600;
        }
        &--centered-value {
            #{$block-name} {
                &__value-col {
                    width: 110px;
                    text-align: center;
                    &--percentage-empty {
                        width: unset;
                        padding: 0;
                    }
                }
            }
        }
        &--middle-border-bottom {
            border-width: 2px;
        }
    }
    &__name {
        overflow: hidden;
        padding: 10px 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        @media all and (min-width: $s) {
            padding: 10px;
        }
    }
    &__value {
        display: flex;
        align-items: center;
        &-col {
            padding: 10px;
            white-space: nowrap;
            &--percentage,
            &--percentage-empty {
                width: 60px;
                @media all and (min-width: $s) {
                    width: 70px;
                }
            }
            &--percentage {
                padding: 10px 5px;
                text-align: center;
                white-space: nowrap;
                color: $white;
                background-color: $indigo;
                @media all and (min-width: $s) {
                    padding: 10px;
                }
            }
        }
    }
}
</style>
