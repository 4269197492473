<template>
    <el-container class="auth-page">
        <el-main>
            <div class="auth-page__top">
                <div class="auth-page__image-wrap">
                    <img
                        class="auth-page__image"
                        src="/images/auth/seekingsimple-logo.svg"
                        alt=""
                    >
                </div>
            </div>
            <div class="auth-page__title">
                Reset your password
            </div>
            <el-card>
                <el-form
                    ref="forgotPasswordForm"
                    :model="form"
                    :rules="rules"
                    class="auth-form"
                    @keydown.enter.prevent="forgotPassword"
                    @submit.prevent="forgotPassword"
                >
                    <el-form-item
                        prop="email"
                        class="el-form-item--default"
                        :error="errors.get('email')"
                    >
                        <el-input
                            v-model="form.email"
                            name="email"
                            type="email"
                            placeholder="Email"
                        />
                    </el-form-item>
                    <el-form-item
                        class="el-form-item--default"
                    >
                        <el-button
                            :loading="loading"
                            round
                            type="primary"
                            class="w-100"
                            @click.prevent="forgotPassword"
                        >
                            Send Password Reset Email
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-main>
    </el-container>
</template>

<script>

import {Errors} from '~/includes/Errors'
import authApi from '~/modules/auth/authApi'

export default {
    name: 'ForgotPassword',
    data() {
        return {
            loading: false,
            form: {},
            rules: {
                email: [
                    { required:true, message: 'Please enter your email', trigger: 'blur' },
                    { type: 'email', message: 'Invalid email', trigger: 'blur' }
                ],
            },
            errors: new Errors(),
        }
    },
    methods: {
        forgotPassword() {
            this.loading = true
            authApi.forgotPassword(this.form).then(response => {
                // this.$message.success(response.data.status)
                this.$router.push({name: 'Login'})
            }).catch(error => {
                if (error.response.data.errors)
                    this.errors.record(error.response.data.errors)
            }).finally(() => this.loading = false)
        }
    },
}
</script>
