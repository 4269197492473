<template>
    <el-form
        :model="filters"
        @submit.prevent
    >
        <el-row :gutter="10">
            <el-col
                :xs="12"
                :sm="12"
                :md="5"
            >
                <el-form-item>
                    <el-input
                        v-model="filters.search"
                        placeholder="Product name"
                        class="w-100"
                        clearable
                    />
                </el-form-item>
            </el-col>
            <el-col
                :xs="12"
                :sm="12"
                :md="5"
            >
                <el-form-item>
                    <el-cascader
                        v-model="filters.pmo_category"
                        :options="pmoCategories"
                        clearable
                        class="w-100"
                        placeholder="Select PMO category"
                    />
                </el-form-item>
            </el-col>
            <el-col
                :xs="12"
                :sm="8"
                :md="5"
            >
                <el-form-item>
                    <advanced-search
                        ref="advancedSearchRef"
                        v-model:categorizationtype="filters.categorizationtype"
                        v-model:category-flats="filters.category_flats"
                        v-model:serving-size-flag="filters.servingSizeFlag"
                        @clearSearch="clearSimpleSearch"
                        @paths="getPaths"
                    />
                </el-form-item>
            </el-col>
            <el-col
                :xs="12"
                :sm="8"
                :md="5"
            >
                <el-form-item>
                    <ScanButton @setCode="setCode" />
                </el-form-item>
            </el-col>
            <el-col
                :xs="12"
                :sm="8"
                :md="4"
            >
                <el-form-item>
                    <el-button
                        class="w-100"
                        @click="clearSearch"
                    >
                        Reset
                    </el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
    <div
        v-if="category_paths.length"
        class="page-section"
    >
        <CategoryTags
            :categories="category_paths"
            @removeFromList="removeFromList"
        />
    </div>
</template>

<script setup>
import {computed, ref, onMounted} from 'vue'
import { useModelWrapper } from '~/includes/composable/modelWrapper'
import AdvancedSearch from './AdvancedSearch'
import CategoryTags from './CategoryTags'
import pmoApi from '~/modules/pmo/pmoApi'
import ScanButton from './ScanButton'

const emit = defineEmits()

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
})

const filters = useModelWrapper(props, emit, 'modelValue')

const pmoLoading = ref(false)
const types = ref(null)

onMounted(() => fetchData())

async function fetchData() {
    pmoLoading.value = true
    const {data} = await pmoApi.all()
    types.value = data
    pmoLoading.value = false
}

const pmoCategories = computed(() => _.map(types.value, ((t, i) => ({
    value: i,
    label: t[0].type_name,
    children: t.map(c => ({
        value: c._id,
        label: c.title,
    }))
}))))

/******************************
 *      Advanced search
 *****************************/

const advancedSearchRef = ref()
const category_paths = ref([])
function getPaths(paths) {
    category_paths.value = paths
}

function removeFromList(index) {
    advancedSearchRef.value.removeFromList(index)
}

function clearSimpleSearch() {
    filters.value.search = ''
    filters.value.pmo_category = []
}

function clearSearch() {
    clearSimpleSearch()
    advancedSearchRef.value.clearSearch()
}

function setCode(code) {
    clearSearch()
    filters.value.search = code
}
</script>

