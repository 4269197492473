<template>
    <div class="page-breadcrumbs">
        <page-breadcrumbs :list="breadcrumbs" />
    </div>
    <h2
        v-if="route.params.id"
        class="page-title"
    >
        {{ editUserTitle }}
    </h2>
    <h2
        v-else
        class="page-title"
    >
        {{ addUserTitle }}
    </h2>
    <user-form
        v-loading="loading"
        :initial-form="formData"
        @saved="router.push({name: 'users.index'})"
        @cancel="router.push({name: 'users.index'})"
    />
</template>

<script setup>
import {ref, onMounted, computed} from 'vue'
import UserForm from './UserForm'
import {useRoute, useRouter} from 'vue-router'
import userApi from '../userApi'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'

const route = useRoute()
const router = useRouter()
const formData = ref(null)
const loading = ref(false)
const editUserTitle = ref('Edit user')
const addUserTitle = ref('Add user')
const actionName = computed(() => route.params.id ? 'Edit' : 'Add')
const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: 'Users',
        name: 'users.index'
    },
    {
        title: `${actionName.value} user`,
    }
])

onMounted(() => fetchData())

async function fetchData() {
    if (route.params.id) {
        loading.value = true
        const {data} = await userApi.get(route.params.id)
        formData.value = data
        loading.value = false
    }
}
</script>
