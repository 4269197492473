import { LEARNING_SS, LEARNING_GENERAL_COMPANY } from "~/store/mutation-types";

export const state = {
    learningTabValue: LEARNING_SS,
    learningCompany: LEARNING_GENERAL_COMPANY,
    learningClientLevel: '',
    isDefaultEdit: true,
}
export const getters = {
    learningTabValue: state => state.learningTabValue,
    learningCompany: state => state.learningCompany,
    learningClientLevel: state => state.learningClientLevel,
    isDefaultEdit: state => state.isDefaultEdit,
}
export const mutations = {
    switchTab(state, value) {
        state.learningTabValue = value
    },
    selectedCompany(state, value) {
        state.learningCompany = value
    },
    selectedClientLevel(state, value) {
        state.learningClientLevel = value
    },
    isDefaultEditing(state, value) {
        state.isDefaultEdit = value
    },
}
