<template>
    <div
        v-loading="loading"
        class="drop-down-categories"
    >
        <el-select
            v-for="pathCategory in pathCategories"
            :key="'pathCategory.level' + pathCategory.level"
            v-model="selectedPathes[pathCategory.level]"
            class="drop-down-categories-select"
            placeholder="Select"
            size="default"
            @change="loadCategories(pathCategory.level + 1)"
        >
            <el-option
                v-for="item in pathCategory.categories"
                :key="item.value['Node ID']"
                :label="item.label"
                :value="item.value['Node ID']"
            />
        </el-select>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import {useModelWrapper} from '~/includes/composable/modelWrapper'
import proCategorizationApi from '~/modules/product-categorization/proCategorizationApi'

const emit = defineEmits()

const props = defineProps({
    modelValue: {
        type: Array,
        required: true
    },
})

const loading = ref(false)
const category_paths = useModelWrapper(props, emit, 'modelValue')

const pathCategories = ref([])
const selectedPathes = ref([])

loadCategories(0)

function loadCategories(level) {

    if (level > 4) {
        return
    }
    loading.value = true

    pathCategories.value = pathCategories.value.slice(0, level)

    selectedPathes.value = selectedPathes.value.slice(0, level)

    let selectedItems = []

    pathCategories.value.forEach(item => {
        item.categories.forEach(category => {
            if (category.value['Node ID'] == selectedPathes.value[item.level]) {
                selectedItems.push(category.value)
            }
        })
    })

    let pathArr = level==0?[]:selectedItems[level-1].path

    category_paths.value =  level==0?[]:selectedItems

    proCategorizationApi.getByPath(pathArr).then(response => {
        let data = response.data
        loading.value = false

        let categoryOptions = data.map(item => {
            let taxonomyName = item['Taxonomy Node']

            // //split taxonomy name by | and get last element

            let taxonomyNameArray = taxonomyName.split('|')

            item.label = taxonomyNameArray[taxonomyNameArray.length - 1]
            item.path = taxonomyNameArray.slice(1)

            return {
                value: item,
                label: item.label
            }
        })

        if (categoryOptions&&categoryOptions.length>0) {
            pathCategories.value[level] = {
                categories: categoryOptions,
                level: level
            }
        }

    }).catch(error => {
        loading.value = false
        console.log(error)
    })

}


</script>

<style scoped>
.drop-down-categories-select {
    width: 20%;
}
</style>
