import Index from './components/PmoIndex'
import PmoEdit from './components/PmoEdit'
import NielseniqForm from './components/NielseniqForm'
import ReferenceIndex from './components/reference/References'
import ReferenceEdit from './components/reference/ReferenceEdit'
import ReferenceAdd from './components/reference/ReferenceAdd'

export default [
    {
        path: 'pmo',
        component: Index,
        name: 'pmo.index',
        meta: {
            auth: 'pmo.view',
            pageTitle: 'PMO categories',
        },
    },
    {
        path: 'pmo/add/:type',
        component: PmoEdit,
        name: 'pmo.add',
        meta: {
            auth: 'pmo.create',
            pageTitle: 'Add PMO category',
        },
    },
    {
        path: 'pmo/edit/:id',
        component: PmoEdit,
        name: 'pmo.edit',
        meta: {
            auth: 'pmo.edit',
            pageTitle: 'Edit PMO category',
        },
    },
    {
        path: 'pmo/nielseniq/:id',
        component: NielseniqForm,
        name: 'pmo.nielseniq_form',
        meta: {auth: 'pmo.edit'},
    },
    {
        path: 'pmo/:pmo_id/references',
        component: ReferenceIndex,
        name: 'pmo.reference.index',
        meta: {auth: 'pmo_references.view'},
    },
    {
        path: 'pmo/:pmo_id/references/add',
        component: ReferenceAdd,
        name: 'pmo.references.add',
        meta: {auth: 'pmo_references.create'},
    },
    {
        path: 'pmo/:pmo_id/references/edit/:reference_id',
        component: ReferenceEdit,
        name: 'pmo.references.edit',
        meta: {auth: 'pmo_references.edit'},

    }
]
