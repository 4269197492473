<template>
    <el-menu
        router
        class="el-menu-demo header-controls"
        :mode="typeMenu"
        :ellipsis="false"
        :menu-trigger="menuTrigger"
    >
        <el-sub-menu
            v-if="canSeeSSTab"
            index="1"
            popper-class="header-popper-submenu el-menu--two-columns"
        >
            <template #title>
                SeekingSimple
            </template>
            <sub-menu :list="ss_items" />
        </el-sub-menu>
        <el-sub-menu
            v-if="canSeeCoachingTab"
            index="2"
            popper-class="header-popper-submenu"
        >
            <template #title>
                Coaching
            </template>
            <sub-menu :list="coaching_items" />
        </el-sub-menu>
        <el-sub-menu
            v-if="canSeeDashboardsTab"
            index="3"
            popper-class="header-popper-submenu"
        >
            <template #title>
                Dashboards
            </template>
            <sub-menu :list="dashboard_items" />
        </el-sub-menu>
        <el-sub-menu
            v-if="canSeeSSUPRTab"
            index="4"
            popper-class="header-popper-submenu"
        >
            <template #title>
                SSUPR
            </template>
            <sub-menu :list="ssupr_items" />
        </el-sub-menu>
        <SSUrlSwitcher />
    </el-menu>
</template>

<script setup>
import {computed, ref, onMounted, onUnmounted} from 'vue'
import {useRouter} from 'vue-router'
import {useAuth} from '@websanova/vue-auth'
import SubMenu from './SubMenu'
import SSUrlSwitcher from './SSUrlSwitcher'

const router = useRouter()
const auth = useAuth()
const user = auth.user()

const ss_items = [
    {
        groupTitle: 'Products',
        list: [
            {
                name: 'Products',
                route: 'labelinsight-products.index',
                permission: 'label_insight_products.view',
                index: '1-1',
            },
            {
                name: 'Product Submission',
                route: 'products-submission.index',
                permission: 'products-submission.view',
            },
            {
                name: 'Product Categorization',
                route: 'products-categorization.index',
                permission: 'products-categorization.view',
                index: '1-2',
            },
            {
                name: 'Product Categorization Drag & Drop',
                route: 'products-categorization-drag-drop.index',
                permission: 'products-categorization.view',
                index: '1-2-1',
            },
            {
                name: 'In Store Product Mappings',
                route: 'product-mapping.index',
                permission: 'product_mapping.view',
                index: '1-3',
            },
            {
                name: 'Product Mapping Tool',
                route: 'product-mapping-tool.index',
                permission: 'product_mapping_tool.view',
                index: '1-4',
            },
            {
                name: 'Delta Logs',
                route: 'delta-logs.index',
                permission: 'delta_logs.view',
                index: '1-5',
            }

        ]
    },
    {
        groupTitle: 'Users',
        list: [
            {
                name: 'Invited Users',
                route: 'ss-users.invited',
                permission: 'invited_ss_users.view',
                index: '1-6',
            },
            {
                name: 'Registered Users',
                route: 'ss-users.registered',
                permission: 'registered_ss_users.view',
                index: '1-7',
            },
            {}
        ]
    },
    {
        groupTitle: 'Organization Structure',
        list: [
            {
                name: 'White-Label',
                route: 'white-label.index',
                permission: 'white_label.view',
                index: '1-8',
            },
            {
                name: 'Client Levels',
                route: 'client-levels.index',
                permission: 'client_levels.view',
                index: '1-9',
            },
        ]
    },
    {
        groupTitle: 'Content',
        list: [
            {
                name: 'Learning Modules',
                route: 'learning_modules.index',
                permission: 'learning_modules.view',
                index: '1-10',
            },
            {
                name: 'Pantry Makeover',
                route: 'pmo.index',
                permission: 'pmo.view',
                index: '1-11',
            },
        ]
    },
    {
        groupTitle: 'Marketing',
        list: [
            {
                name: 'Notifications',
                route: 'notifications.index',
                permission: 'notifications.view',
                index: '1-12',
            },
            {
                name: 'Search Words',
                route: 'search-words.index',
                permission: 'search_words.view',
                index: '1-13',
            },
            {
                name: 'Helpful List',
                route: 'helpful.index',
                permission: 'helpful.view',
                index: '1-14',
            },
        ]
    },
]

const coaching_items = [
    {
        groupTitle: 'Users',
        list: [
            {
                name: 'Invited Coaches',
                route: 'coaching-users.invited',
                permission: 'invited_coaching_users.view',
                index: '2-1',
            },
            {
                name: 'Registered Coaches',
                route: 'coaching-users.registered',
                permission: 'registered_coaching_users.view',
                index: '2-2',
            }
        ]
    },
]

const ssupr_items = [
    {
        groupTitle: 'Manage Emails',
        list: [
            {
                name: 'Templates',
                route: 'email-pages.index',
                permission: 'email-pages.view',
                index: '4-1',
            }
        ]
    },
    {
        groupTitle: 'Manage Users',
        list: [
            {
                name: 'Users',
                route: 'users.index',
                permission: 'users.view',
                index: '4-2',
            },
            {
                name: 'Roles',
                route: 'roles.index',
                permission: 'roles.view',
                index: '4-3',
            },
        ]
    }
]

const dashboard_items = [
    {
        groupTitle: 'Users',
        list: [
            {
                name: 'Admins',
                route: 'dashboards-admins.index',
                permission: 'dashboards_admins.view',
                index: '3-1',
            },
            {
                name: 'Retailers',
                route: 'dashboards-retailers.index',
                permission: 'dashboards_retailers.view',
                index: '3-2',
            },
            {
                name: 'Customers',
                route: 'dashboards-customers.index',
                permission: 'dashboards_customers.view',
                index: '3-3',
            },
        ]
    }
]

function canSeeTab(items) {
    let can = false
    _.forEach(items, item => {
        _.forEach(item.list, item2 => {
            if (item2.permission) {
                can = can || auth.check(item2.permission)
            }
        })
    })

    return can
}

const canSeeSSTab = computed(() => canSeeTab(ss_items))
const canSeeDashboardsTab = computed(() => canSeeTab(dashboard_items))
const canSeeCoachingTab = computed(() => canSeeTab(coaching_items))
const canSeeSSUPRTab = computed(() => canSeeTab(ssupr_items))
const windowWidth = ref(window.innerWidth)

const onWidthChange = () => windowWidth.value = window.innerWidth
onMounted(() => window.addEventListener('resize', onWidthChange))
onUnmounted(() => window.removeEventListener('resize', onWidthChange))

const typeMenu = computed(() => (windowWidth.value > 1023) ? 'horizontal' : 'vertical')
const menuTrigger = computed(() => (windowWidth.value > 1023) ? 'hover' : 'click')
</script>
