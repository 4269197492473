<template>
    <section id="pmo">
        <div class="page-breadcrumbs">
            <el-breadcrumb
                separator="/"
                class="breadcrumb"
            >
                <el-breadcrumb-item :to="{path: '/'}">
                    ..
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    PMO Categories
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <h2 class="page-title">
            Pantry Makeover
        </h2>

        <div
            v-if="$auth.check('Admin')"
            class="page-section"
        >
            <import-export-form
                v-if="$auth.check('Admin')"
                @refresh="fetchData"
            />
        </div>

        <div
            v-loading="pmoLoading"
            class="page-section pmo-table"
        >
            <template
                v-for="(categories, type) in types"
                :key="type"
            >
                <div class="pmo-table__section">
                    <el-row
                        :gutter="10"
                        align="middle"
                        justify="space-between"
                        class="pmo-table__row"
                    >
                        <el-col
                            :xs="12"
                            :sm="12"
                            class="pmo-table__col--title"
                        >
                            <strong>{{ categories[0].type_name }}</strong>
                        </el-col>
                        <el-col
                            :xs="12"
                            :sm="12"
                            class="pmo-table__col pmo-table__col--align-right"
                        >
                            <el-button
                                v-if="$auth.check('pmo.create')"
                                type="primary"
                                size="small"
                                @click.prevent.stop="$router.push({ name: 'pmo.add', params: {'type': type} })"
                            >
                                Add
                            </el-button>
                        </el-col>
                    </el-row>

                    <div class="type pmo-table__categories">
                        <draggable
                            v-if="categories.length > 0"
                            :list="categories"
                            handle=".pmo-table__col--handle"
                            item-key="_id"
                            @end="moveCategory(type)"
                        >
                            <template #item="{ element }">
                                <el-row
                                    v-if="!element.game_slug"
                                    :key="element._id"
                                    :gutter="10"
                                    class="pmo-table__row"
                                >
                                    <el-col
                                        :xs="2"
                                        :sm="1"
                                        class="pmo-table__col--handle"
                                    >
                                        <i class="fa fa-align-justify" />
                                    </el-col>
                                    <el-col
                                        :xs="22"
                                        :sm="9"
                                        class="pmo-table__col--title"
                                    >
                                        {{ element.title }}
                                    </el-col>
                                    <el-col
                                        :xs="24"
                                        :sm="14"
                                        class="pmo-table__col pmo-table__col--buttons pmo-table__col--align-right-tablet-desktop"
                                    >
                                        <el-button
                                            v-if="$auth.check('pmo.edit')"
                                            size="small"
                                            icon="edit"
                                            @click.prevent.stop="$router.push({ name: 'pmo.edit', params: {'id': element._id} })"
                                        >
                                            Edit
                                        </el-button>
                                        <el-button
                                            v-if="$auth.check('pmo.edit')"
                                            type="primary"
                                            size="small"
                                            icon="edit"
                                            @click.prevent.stop="$router.push({ name: 'pmo.nielseniq_form', params: {'id': element._id} })"
                                        >
                                            Edit NielsenIQ
                                        </el-button>
                                        <el-button
                                            v-if="$auth.check(['pmo_references.create', 'pmo_references.edit'])"
                                            type="primary"
                                            plain
                                            size="small"
                                            @click.prevent.stop="$router.push({ name: 'pmo.reference.index', params: {'pmo_id': element._id} })"
                                        >
                                            Add/Edit Reference
                                        </el-button>
                                        <el-button
                                            v-if="$auth.check('pmo.delete')"
                                            type="danger"
                                            size="small"
                                            icon="delete"
                                            @click.prevent.stop="deleteCategory(element)"
                                        >
                                            Delete
                                        </el-button>
                                    </el-col>
                                </el-row>
                            </template>
                        </draggable>

                        <el-row
                            v-if="getKidsCamp(categories)"
                            :gutter="10"
                            class="pmo-table__row"
                        >
                            <el-col
                                :xs="24"
                                :sm="10"
                                class="pmo-table__col--title"
                            >
                                {{ getKidsCamp(categories).title }}
                            </el-col>
                            <el-col
                                :xs="24"
                                :sm="14"
                                class="pmo-table__col pmo-table__col--buttons pmo-table__col--align-right-tablet-desktop"
                            >
                                <el-button
                                    v-if="$auth.check('pmo.edit')"
                                    size="small"
                                    icon="edit"
                                    @click.prevent.stop="$router.push({ name: 'pmo.edit', params: {'id': getKidsCamp(categories)._id} })"
                                >
                                    Edit
                                </el-button>
                                <el-button
                                    v-if="$auth.check(['pmo_references.create', 'pmo_references.edit'])"
                                    type="primary"
                                    plain
                                    size="small"
                                    @click.prevent.stop="$router.push({ name: 'pmo.reference.index', params: {'pmo_id': getKidsCamp(categories)._id} })"
                                >
                                    Add/Edit Reference
                                </el-button>
                                <el-button
                                    v-if="$auth.check('pmo.delete')"
                                    type="danger"
                                    size="small"
                                    icon="delete"
                                    @click.prevent.stop="deleteCategory(getKidsCamp(categories))"
                                >
                                    Delete
                                </el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue'
import draggable from 'vuedraggable'
import { ElMessage, ElMessageBox } from 'element-plus'
import pmoApi from '../pmoApi'
import ImportExportForm from './ImportExportForm'

const pmoLoading = ref(false)
const types = ref(null)

onMounted(() => fetchData())

async function fetchData() {
    pmoLoading.value = true
    const {data} = await pmoApi.all()
    types.value = data
    pmoLoading.value = false
}

function deleteCategory(category) {
    ElMessageBox.confirm('This will permanently delete the PMO category "' + category.title + '". Continue?', 'Warning', {
        type: 'warning'
    }).then(() => {
        pmoApi.destroy(category._id).then((response) => {
            ElMessage.success( 'Deleted successfully')
            fetchData()
        })
    })
}

function moveCategory(type) {
    let data = []
    for (let k in types.value[type]) {
        data.push({_id: types.value[type][k]._id, priority: k})
    }
    pmoApi.sort(data).then(() => {
        ElMessage.success( 'Saved successfully')
    })
}

function getKidsCamp(categories) {
    return categories.find(c => c.game_slug)
}

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
#pmo {
    .pmo-table {
        $block-name: &;
        &__section {
            border: 1px solid darken($white-second, 5%);
            padding: 0 5px;
            &:not(:last-child) {
                margin: 0 0 20px;
            }
        }
        &__categories {
            .pmo-table {
                &__row {
                    border-top: 1px solid darken($white-second, 5%);
                }
            }
        }
        &__row {
            padding: 10px 0;
        }
        &__col {
            &--handle {
                cursor: pointer;
            }
            &--handle,
            &--title {
                margin: auto 0;
            }
            &--buttons {
                .el-button {
                    margin: 5px 12px 5px 0;
                    &:last-child {
                        margin: 5px 0;
                    }
                }
            }
            &--align-right {
                text-align: right;
            }
            &--align-right-tablet-desktop {
                @media all and (min-width: $s) {
                    text-align: right;
                }
            }
        }
    }
}
</style>
