<template>
    <div class="page-breadcrumbs">
        <el-breadcrumb
            separator="/"
            class="breadcrumb"
        >
            <el-breadcrumb-item :to="{path: '/'}">
                ..
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="{name: 'product-mapping-tool.index'}">
                Product Mapping Tool
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                {{ actionName }} Product Mapping Name <template v-if="!savedChanges">
                    - (Unsaved)
                </template>
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>

    <h2 class="page-title">
        {{ actionName }} Product Mapping Name
    </h2>

    <div class="product-mapping">
        <el-row
            :gutter="10"
            align="middle"
            class="product-mapping__row product-mapping__row--border-bottom"
        >
            <el-col
                class="product-mapping__col-auto"
            >
                <label for="mapping_name">Mapping Name:</label>
            </el-col>
            <el-col
                class="product-mapping__col-auto"
            >
                <el-input
                    v-model="form.name"
                    type="text"
                    placeholder="Mapping Name"
                    name="mapping_name"
                />
            </el-col>
        </el-row>
        <el-row
            class="product-mapping__row"
        >
            <el-button
                v-if="!form._id"
                type="primary"
                :disabled="!form.name||form.name.length <= 0"
                :loading="loading"
                @click="saveProductMapping()"
            >
                Begin
            </el-button>
            <el-button
                v-if="form._id"
                type="success"
                :loading="loading"
                @click="saveProductMapping"
            >
                Go to Sources
            </el-button>
        </el-row>
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {ElMessage, ElMessageBox} from 'element-plus'
import productMappingApi from '../../product-mapping/productMappingApi'

const route = useRoute()
const router = useRouter()
const loading = ref(false)
const mappingData = ref(null)

const form = ref({
    _id: null,
    name: '',
    sources: [],
    targets: [],
    completed: false,
})

const product_upc = ref({
    source: '',
    target: ''
})

const savedChanges = ref(false)
const actionName = ref('Add')

onMounted(() => fetchData())

async function fetchData() {
    if (route.params.id) {
        loading.value = true
        const {data} = await productMappingApi.get(route.params.id)
        form.value = data
        loading.value = false
        savedChanges.value = true
        actionName.value = 'Edit'
    }
}

function saveProductMapping() {
    loading.value = true
    productMappingApi.saveProductMapping(form.value)
        .then((response) => {
            // this.form = response.data
            form.value = response.data
            savedChanges.value = true
            router.push({name: 'product-mapping-tool.edit-sources', params: {id: response.data._id}})
        })
        .catch((error) => {
            console.log(error)
        }).
        finally(() => {
            loading.value = false
        })
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.product-mapping {
    $block-name: &;
    &__row {
        &--border-bottom {
            margin-bottom: 20px;
            border-bottom: 1px solid darken($white-second, 5%);
            padding-bottom: 20px;
        }
    }
    &__col {
        padding: 0 10px;
        &-auto {
            flex: 0 0 auto;
        }
        &--border-left {
            border-right: 1px solid darken($white-second, 5%);
        }
        &--buttons {
            button {
                margin: 10px 0;
                &:not(:last-child) {
                    margin: 10px 10px 10px 0;
                }
            }
        }
    }
    &__list {
        margin: 10px 0 0;
        border-top: 1px solid darken($white-second, 5%);
        padding: 0;
        list-style: none;
        &-item {
            padding: 10px 0;
            &:not(:first-child) {
                border-top: 1px solid darken($white-second, 5%);
            }
        }
    }
}

.product-section {
    &__product {
        margin-bottom: 10px;
        &-remove {
            margin-bottom: 10px;
        }
    }
}
</style>
