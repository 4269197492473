<template>
    <div class="page-breadcrumbs">
        <page-breadcrumbs :list="breadcrumbs" />
    </div>
    <h2 class="page-title">
        Invite new coach
    </h2>
    <invite-form
        @saved="router.push({name: 'coaching-users.invited'})"
        @cancel="router.push({name: 'coaching-users.invited'})"
    />
</template>

<script setup>
import {ref} from 'vue'
import InviteForm from './InviteForm'
import {useRoute, useRouter} from 'vue-router'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'

const route = useRoute()
const router = useRouter()
const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: 'Invited Coaches',
        name: 'coaching-users.invited'
    },
    {
        title: 'Invite new coach',
    }
])
</script>
