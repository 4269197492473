import axios from 'axios'

const API_ENDPOINT_LABELINSIGHT = '/labelinsight/products'

export default {
    saveProductSubmission(payload) {
        return axios.post(API_ENDPOINT_LABELINSIGHT + '/save', payload)
    },
    approveProductSubmission(payload) {
        return axios.post(API_ENDPOINT_LABELINSIGHT + '/approve', payload)
    },
    rejectProductSubmission(payload) {
        return axios.post(API_ENDPOINT_LABELINSIGHT + '/reject', payload)
    }
}
