<template>
    <section
        id="user-form"
        class="max-width-600-tablet-desktop"
    >
        <el-form
            ref="formRef"
            :model="form"
            label-width="100px"
            :rules="rules"
            @submit.prevent="saveSubmit"
        >
            <el-form-item
                label="Name"
                prop="name"
                :error="errors.get('name')"
            >
                <el-input
                    v-model="form.name"
                    suffix-icon="edit-pen"
                    @change="errors.clear('name')"
                />
            </el-form-item>
            <el-form-item
                label="Email"
                prop="email"
                :error="errors.get('email')"
            >
                <el-input
                    v-model="form.email"
                    suffix-icon="message"
                    @change="errors.clear('email')"
                />
            </el-form-item>
        </el-form>
        <div>
            <el-button
                @click="cancel"
            >
                Cancel
            </el-button>
            <el-button
                :loading="formLoading"
                type="success"
                @click="saveSubmit"
            >
                Save
            </el-button>
        </div>
    </section>
</template>

<script setup>
import {ref} from 'vue'
import { ElMessage } from 'element-plus'
import {Errors} from '~/includes/Errors'
import profileApi from '~/modules/profile/profileApi'
import {useAuth} from '@websanova/vue-auth'

const auth = useAuth()
const emit = defineEmits()
const errors = new Errors()

const form = ref({
    name: auth.user().name,
    email: auth.user().email,
})
const rules = {
    name: [
        { required: true, message: 'Name is required' },
    ],
    email: [
        { required:true, message: 'Please enter your email', trigger: 'blur' },
        { type: 'email', message: 'Invalid email', trigger: 'blur' }
    ],
}

const formRef = ref()
const formLoading = ref(false)

function saveSubmit() {
    formRef.value.validate((valid) => {
        if (valid) {
            formLoading.value = true
            errors.clear()
            profileApi.edit(form.value).then((response) => {
                ElMessage.success( 'Saved successfully')
                emit('saved')
            }).catch(error => {
                if (error.response.data.errors) errors.record(error.response.data.errors)
            }).finally(() => formLoading.value = false)
        }
    })
}

function cancel() {
    clearForm()
    emit('cancel')
}

function clearForm() {
    errors.clear()
    if (formRef.value) formRef.value.resetFields()
}
</script>
