<template>
    <section
        id="search-words-form"
        class="max-width-600-tablet-desktop"
    >
        <el-form
            ref="formRef"
            :model="form"
            label-width="160px"
            :rules="rules"
            @submit.prevent="saveSubmit"
        >
            <el-form-item
                label="Search Words"
                prop="search_word"
                :error="errors.get('search_word')"
            >
                <el-input
                    v-model="form.search_word"
                    suffix-icon="edit-pen"
                    @change="errors.clear('search_word')"
                />
            </el-form-item>
            <el-form-item
                label="Keywords (Semicolon ; separated)"
                prop="keywords"
                :error="errors.get('keywords')"
            >
                <el-input
                    v-model="form.keywords"
                    type="textarea"
                    rows="4"
                    @change="errors.clear('keywords')"
                />
            </el-form-item>
        </el-form>
        <div>
            <el-button
                @click="cancel"
            >
                Cancel
            </el-button>
            <el-button
                :loading="formLoading"
                type="success"
                @click="saveSubmit"
            >
                Save
            </el-button>
        </div>
    </section>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import { ElMessage } from 'element-plus'
import {Errors} from '~/includes/Errors'
import searchWordsApi from '../searchWordsApi'

const emit = defineEmits()

const props = defineProps({
    initialForm: {
        type: Object,
        default: {}
    },
})

const errors = new Errors()
const formLoading = ref(false)
const form = ref({search_word: '', keywords: ''})
const rules = {
    search_word: [
        { required: true, message: 'Search Word is required' },
    ],
    keywords: [
        { required: true, message: 'Keywords is required. (Semicolon ; separated)' },
    ],
}

onMounted(() => form.value = Object.assign({}, props.initialForm))

watch(
    () => props.initialForm,
    val => {
        form.value = Object.assign({}, val)
        form.value.keywords = form.value.keywords.join(';')
    },
    { deep: true }
)

const formRef = ref()

function saveSubmit() {
    formRef.value.validate((valid) => {
        if (valid) {
            formLoading.value = true
            errors.clear()

            searchWordsApi.update(form.value).then((response) => {
                ElMessage.success( 'Saved successfully')
                emit('saved')
            }).catch(error => {
                if (error.response.data.errors) errors.record(error.response.data.errors)
            }).finally(() => formLoading.value = false)
        }
    })
}

function cancel() {
    clearForm()
    emit('cancel')
}

function clearForm() {
    errors.clear()
    if (formRef.value) formRef.value.resetFields()
}
</script>
