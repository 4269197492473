<template>
    <el-button
        type="primary"
        class="w-100"
        plain
        @click="scanBarcode"
    >
        Scan
    </el-button>
    <el-dialog
        v-model="scanShow"
        title="Scan Barcode"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        destroy-on-close
        @close="closeBarcodeModal"
    >
        <BarcodeScanner
            ref="barcodeScanner"
            @scan="detectedBarcode"
        />
    </el-dialog>
</template>

<script setup>
import {ref} from 'vue'
import BarcodeScanner from '~/base/components/BarcodeScanner'

const emit = defineEmits()

const barcodeScanner = ref(null)
const scanShow = ref(false)

function scanBarcode() {
    scanShow.value = true
}

async function detectedBarcode(scanResult) {
    const barcode = scanResult.barcodes.reduce((string, barcode) => barcode.data, '')

    scanShow.value = false
    emit('setCode', barcode)
}

function closeBarcodeModal(){
    scanShow.value = false
    barcodeScanner.value?.stopScanner()
}
</script>
