export const DATETIME_DB_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const DATE_DB_FORMAT     = 'YYYY-MM-DD'

export const DATETIME_FORMAT    = 'YYYY/MM/DD HH:mm'
export const DATE_FORMAT        = 'YYYY/MM/DD'
export const TIME_FORMAT        = 'HH:mm'

export const DATE_PIKER_FORMAT  = 'yyyy/MM/dd'
export const MONTH_PIKER_FORMAT = 'MMM yyyy'
export const DATATIME_PIKER_FORMAT  = 'yyyy/MM/dd HH:mm'
