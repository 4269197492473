<template>
    <el-button
        type="primary"
        class="w-100"
        @click="handleShowFilterModal"
    >
        Select Filter Options
    </el-button>
    <el-dialog
        v-model="showCategoriesModal"
        title="Filter Options"
    >
        <div class="categorize-switch-controls text-center">
            <el-radio-group
                v-model="categorization_type"
                class="text-center"
            >
                <el-radio-button label="all">
                    All products
                </el-radio-button>
                <el-radio-button label="unmapped">
                    Unmapped
                </el-radio-button>
                <el-radio-button label="uncategorized">
                    Uncategorized
                </el-radio-button>
                <el-radio-button label="categorized">
                    Categorized
                </el-radio-button>
                <el-radio-button label="pmo_categorized">
                    Pmo Categories
                </el-radio-button>
            </el-radio-group>
        </div>

        <div
            v-if="categorization_type === 'categorized'"
            class="mb-20"
        >
            <div>
                <el-switch
                    v-model="shortViewCategories"
                    class="mb-2"
                    active-text="Short view"
                    inactive-text="Tree view"
                    @change="switchTreeView"
                />
            </div>
            <drop-down-categories
                ref="dropDownCategoriesRef"
                v-if="shortViewCategories"
                v-model="drop_down_pathes"
            />
            <el-tree
                v-else
                ref="treeRef"
                v-loading="loadTree"
                :data="treeData"
                node-key="path"
                show-checkbox
                :check-strictly="true"
                :props="treeProps"
            />
        </div>
        <div
            v-if="categorization_type === 'pmo_categorized'"
            class="mb-20"
        >
            <el-form-item
                label="PMO Category"
                :label-width="180"
            >
                <el-cascader
                    v-model="pmoCategoriesSelected"
                    :options="pmoCategories"
                    clearable
                    class="w-100"
                    placeholder="Select PMO category"
                />
            </el-form-item>
        </div>
        <el-form-item
            class="sugar-min-max"
            label="Sugar Per 100g"
            :label-width="180"
        >
            <div>
                <el-input
                    v-model="sugarData.sugar_per_100g_min"
                    min="0"
                    placeholder="Min"
                />
            </div>
            <span>-</span>
            <div>
                <el-input
                    v-model="sugarData.sugar_per_100g_max"
                    min="0"
                    placeholder="Max"
                />
            </div>
        </el-form-item>
        <el-form-item
            class="sugar-min-max"
            label="Net Carbs Per 100g"
            :label-width="180"
        >
            <div>
                <el-input
                    v-model="sugarData.net_carbs_per_100g_min"
                    min="0"
                    placeholder="Min"
                />
            </div>
            <span>-</span>
            <div>
                <el-input
                    v-model="sugarData.net_carbs_per_100g_max"
                    min="0"
                    placeholder="Max"
                />
            </div>
        </el-form-item>
        <el-form-item
            class="sugar-min-max"
            label="Total Weight (in grams)"
            :label-width="180"
        >
            <div>
                <el-input
                    v-model="sugarData.total_weight_min"
                    min="0"
                    placeholder="Min"
                />
            </div>
            <span>-</span>
            <div>
                <el-input
                    v-model="sugarData.total_weight_max"
                    min="0"
                    placeholder="Max"
                />
            </div>
        </el-form-item>


        <template #footer>
            <span class="dialog-footer">
                <el-button @click="handleClose">
                    Reset
                </el-button>
                <el-button
                    type="success"
                    @click="handleOk"
                >
                    Apply
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
import {useModelWrapper} from '~/includes/composable/modelWrapper'
import {computed, ref, watch, onMounted} from 'vue'
import liProductsApi from '../../../labelinsight-products/liProductsApi'
import pmoApi from '~/modules/pmo/pmoApi'
import DropDownCategories from '~/modules/labelinsight-products/components/filter/DropDownCategories'
import store from "~/store";
import {useRoute} from "vue-router";
import nielseniqApi from "~/modules/pmo/nielseniqApi";

const emit = defineEmits()

const props = defineProps({
    categorizationtype: {
        type: String,
        required: true
    },
    categoryFlats: {
        type: Array,
        required: true
    },
    sugarFilters: {
        type: Array,
        required: true
    },
    pmoCategory: {
        type: Array,
        required: true
    },
})

const categorizationtype = useModelWrapper(props, emit, 'categorizationtype')
const category_flats = useModelWrapper(props, emit, 'categoryFlats')
const sugarFilters = useModelWrapper(props, emit, 'sugarFilters')
const pmo_category = useModelWrapper(props, emit, 'pmoCategory')
const sourceId = computed(() => store.getters['product_mapping_tool/productMappingId'])

const route = useRoute()
const treeRef = ref(null)
const showCategoriesModal = ref(false)
const treeData = ref([])
const treeProps = {
    children: 'children',
    label: 'name',
    isLeaf: 'isLeaf',
}
const level = 0

const dropDownCategoriesRef = ref(null)
const categorization_type = ref('all')
const category_paths = ref([])
const sugarData = ref({})
const pmoCategoriesSelected = ref([])

const shortViewCategories = ref(true)
const drop_down_pathes = ref([])


const pmoLoading = ref(false)
const types = ref(null)

onMounted(() => fetchPmoData())

async function loadNode() {
    loadTree.value = true
    const { data } = await nielseniqApi.all()
    treeData.value = data
    loadTree.value = false
}

const switchTreeView = () => {
    if (! shortViewCategories.value) {
        setTimeout(async () => {
            await loadNode()
            loadCheckedCategories()
        }, 0)
    }
}

async function fetchPmoData() {
    pmoLoading.value = true
    const {data} = await pmoApi.all()
    types.value = data
    pmoLoading.value = false
}

const pmoCategories = computed(() => _.map(types.value, ((t, i) => ({
    value: i,
    label: t[0].type_name,
    children: t.map(c => ({
        value: c._id,
        label: c.title,
    }))
}))))


watch(
    () => category_paths.value,
    () => fillCategoryFlats(),
    { deep: true }
)

watch(
    () => sugarFilters.value,
    (value) => sugarData.value = _.cloneDeep(value),
    { deep: true }
)

async function handleShowFilterModal() {
    showCategoriesModal.value = true
    if (categorizationtype.value ==='categorized' && sourceId.value === route.params.id && category_flats.value.length > 0) {
        categorization_type.value = 'categorized'
        drop_down_pathes.value = store.getters['product_mapping_tool/categoryPaths']
        shortViewCategories.value = store.getters['product_mapping_tool/shortViewCategories']

        if (shortViewCategories.value) {
            if (dropDownCategoriesRef.value) {
                dropDownCategoriesRef.value.fillStartData()
            }
        } else {
            await loadNode()
            loadCheckedCategories()
        }
    }
}

function loadCheckedCategories() {
    loadTree.value = true
    setTimeout(async () => {
        if (treeRef.value && store.getters['product_mapping_tool/checkedNodes']) {
            for (let node of store.getters['product_mapping_tool/checkedNodes']) {
                treeRef.value.setCurrentNode(node)
            }
            if (drop_down_pathes.value ) {
                treeRef.value.setCheckedKeys(drop_down_pathes.value);
            }
        }
        loadTree.value = false
    }, 100)
}

function handleOk() {
    if (categorization_type.value === 'categorized') {
        store.commit('product_mapping_tool/setShortViewCategories', shortViewCategories.value)
         if (shortViewCategories.value) {
            category_paths.value = drop_down_pathes.value
        } else {
             category_paths.value = treeRef.value.getCheckedKeys()
             store.commit('product_mapping_tool/setCheckedNodes', treeRef.value.getCheckedNodes() || [])
         }
        emit('paths', category_paths.value)
    } else {
        emit('paths', [])
    }
    sugarFilters.value = _.cloneDeep(sugarData.value)
    categorizationtype.value = categorization_type.value
    pmo_category.value = pmoCategoriesSelected.value
    showCategoriesModal.value = false
}

function handleClose() {
    clearSearch()
    showCategoriesModal.value = false
}

const loadTree = ref(false)

function removeFromList(index) {
    if (index > -1) {
        if(category_paths.value.length < 1) {
            category_paths.value = store.getters['product_mapping_tool/categoryPaths']
        }
        const tag_to_remove = category_paths.value[index]
        let i = 0
        category_paths.value.forEach(path => {
            if (tag_to_remove.includes(path)) category_paths.value.splice(i, 1)
            i++
        })
        store.commit('product_mapping_tool/setCategoryPaths', category_paths.value)
        if( category_paths.value.length === 0) {
            categorizationtype.value = 'all'

            store.commit('product_mapping_tool/setCategoryPaths', null)
            store.commit('product_mapping_tool/setCheckedNodes', null)
        } else {
            const nodes = store.getters['product_mapping_tool/checkedNodes']
            const filteredNodes = nodes.filter(node => category_paths.value.includes(node.path));
            store.commit('product_mapping_tool/setCheckedNodes', filteredNodes)
            store.commit('product_mapping_tool/setCategoryPaths', category_paths.value)
        }

        if (treeRef.value) {
            treeRef.value.setCheckedKeys(category_paths.value)
        }
    }
}

function removeFromPmoList() {
    pmoCategoriesSelected.value = []
}

async function fillCategoryFlats() {
    let cat_flats = []
    const promises =  category_paths.value.map(async path => {
        const data = await getCategoryData(path)
        cat_flats.push(data)
        return path
    })
    await Promise.all(promises)
    category_flats.value = cat_flats
    store.commit('product_mapping_tool/setChosenCategory', cat_flats)

}

async function getCategoryData(path) {
    let response = await liProductsApi.getHierarchy(path)

    const levels = [
        'department',
        'superCategory',
        'category',
        'subCategory',
        'segment',
    ]
    let flatArray = {}
    levels.forEach((level) => {
        if (response.data[level]) {
            flatArray[level] = response.data[level].raw_name
        }
    })
    return flatArray
}

function clearSearch() {
    categorization_type.value = 'all'
    categorizationtype.value = 'all'
    category_paths.value = []
    emit('paths', category_paths.value)
    sugarFilters.value = {}
    sugarData.value = {}
    treeRef.value?.setCheckedKeys([])
    pmo_category.value = []
}

defineExpose({ removeFromList, clearSearch, removeFromPmoList })
</script>

<style lang="scss">
.sugar-min-max {
    margin-top: 20px;
    .el-form-item__content {
        display: flex;
        justify-content: space-between;
        > span {
            margin: 0 15px;
        }
        > div {
            width: 40%;
            .el-input__inner {
                //width: 50px;
            }
        }
    }
}
</style>
