export const state = {
    treeViewFlag: false,
  }
  
  // getters
  export const getters = {
    tableViewFlag: state => state.tableViewFlag,
  }
  
  // mutations
  export const mutations = {
      setTableViewFlag (state, tableViewFlag) {
          state.tableViewFlag = tableViewFlag
      },
  }
  