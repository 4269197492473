import axios from 'axios'

const API_ENDPOINT = window.config.ssURL + 'client-levels'

export default {
    all(params) {
        return axios.get(API_ENDPOINT, { params })
    },

    get(id) {
        return axios.get(API_ENDPOINT + '/' + id)
    },

    getRoot() {
        return axios.get(API_ENDPOINT + '/root')
    },

    getChildren(id, params = {}) {
        return axios.get(API_ENDPOINT + '/' + id + '/children', { params })
    },

    getParents(id) {
        return axios.get(API_ENDPOINT + '/' + id + '/parents')
    },

    add(params) {
        return axios.post(API_ENDPOINT + '/store', params)
    },

    update(params) {
        return axios.post(API_ENDPOINT + '/update/' + params.get('_id'), params)
    },

    destroy(params) {
        return axios.post(API_ENDPOINT + '/delete/' + params._id)
    },

    assignCoachesSS(model) {
        return axios.post(API_ENDPOINT + '/assign-coaches', model)
    },

    getCoachClientLevelTree(params) {
        return axios.get(API_ENDPOINT + '/get-coach-client-level-tree', { params })
    }


}