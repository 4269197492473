<template>
    <section
        id="company-form"
        class="max-width-600-tablet-desktop"
    >
        <el-form
            ref="formRef"
            :model="form"
            label-width="100px"
            :rules="rules"
            @submit.prevent="saveSubmit"
        >
            <el-form-item
                label="Name"
                prop="name"
                :error="errors.get('name')"
            >
                <el-input
                    v-model="form.name"
                    suffix-icon="edit-pen"
                    @change="errors.clear('name')"
                />
            </el-form-item>
            <el-form-item
                label="Departments"
                prop="departments"
                :error="errors.get('departments')"
            >
                <departments
                    :department-ids="form.department_ids"
                    @updateDepartmentIds="updateDepartmentIds"
                />
            </el-form-item>
        </el-form>
        <div>
            <el-button @click="cancel">
                Cancel
            </el-button>
            <el-button
                :loading="formLoading"
                type="success"
                @click="saveSubmit"
            >
                Save
            </el-button>
        </div>
    </section>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import { ElMessage } from 'element-plus'
import {Errors} from '~/includes/Errors'
import companyApi from '../companyApi'
import Departments from './Departments'

const emit = defineEmits()

const props = defineProps({
    initialForm: {
        type: Object,
        default: {}
    },
})

const errors = new Errors()
const formLoading = ref(false)
const form = ref({name: ''})
const rules = {
    name: [
        { required: true, message: 'Name is required' },
    ],
}

onMounted(() => form.value = Object.assign({}, props.initialForm))

watch(
    () => props.initialForm,
    val => form.value = Object.assign({}, val),
    { deep: true }
)

const formRef = ref()

function saveSubmit() {
    formRef.value.validate((valid) => {
        if (valid) {
            formLoading.value = true
            errors.clear()

            companyApi.save(form.value).then((response) => {
                ElMessage.success( 'Saved successfully')
                emit('saved')
            }).catch(error => {
                if (error.response.data.errors) errors.record(error.response.data.errors)
            }).finally(() => formLoading.value = false)
        }
    })
}

function cancel() {
    clearForm()
    emit('cancel')
}

function clearForm() {
    errors.clear()
    if (formRef.value) formRef.value.resetFields()
}

function updateDepartmentIds(department_ids) {
    form.value.department_ids = department_ids
}
</script>
