<template>
    <div
        v-if="product"
        class="product-info"
    >
        <div
            v-if="showCheckBoxes"
            class="product-info__select-control"
        >
            <div>
                <input
                    id="checkbox-red"
                    v-model="product.selected"
                    type="checkbox"
                    class="checkbox-red"
                    @change="fillSelectedCollection(product)"
                >
                <label for="checkbox-red" />
            </div>
            <div
                v-if="!!categorizeByHands(product)"
                class="product-info__changed"
            >
                <el-tooltip
                    :content="categorizeByHands(product)"
                    placement="top"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                    >
                        <path
                            d="M453.3 19.3l39.4 39.4c25 25 25 65.5 0 90.5l-52.1 52.1 0 0-1-1 0 0-16-16-96-96-17-17 52.1-52.1c25-25 65.5-25 90.5 0zM241 114.9c-9.4-9.4-24.6-9.4-33.9 0L105 217c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L173.1 81c28.1-28.1 73.7-28.1 101.8 0L288 94.1l17 17 96 96 16 16 1 1-17 17L229.5 412.5c-48 48-109.2 80.8-175.8 94.1l-25 5c-7.9 1.6-16-.9-21.7-6.6s-8.1-13.8-6.6-21.7l5-25c13.3-66.6 46.1-127.8 94.1-175.8L254.1 128 241 114.9z"
                        />
                    </svg>
                </el-tooltip>
            </div>
        </div>
        <div
            v-if="showProductMapping&&product.productMap&&product.productMap.length>0"
            class="product-info__product-mapping"
            :class="!categorizeByHands(product) ? 'product-info__product-mapping__position' : ''"
        >
            <!-- show green star icon -->
            <el-icon><Star /></el-icon>
        </div>
        <div
            v-if="showProductMapping&&product.productSources&&product.productSources.length>0"
            class="product-info__product-mapping"
            :class="!categorizeByHands(product) ? 'product-info__product-mapping__position' : ''"
        >
            <!-- show blue star icon -->
            <el-icon class="blue">
                <Star />
            </el-icon>
        </div>
        <div class="product-info__head">
            <div
                class="product-info__image-wrap"
                @click="clickProduct(product)"
            >
                <img
                    class="product-info__image"
                    :src="product.image"
                    :alt="product.productTitle"
                    @error="imageLoadError"
                >
            </div>
            <div
                class="product-info__content"
                @click="clickProduct(product)"
            >
                <h3 class="product-info__content-headline">
                    {{ product.brand }}, {{ product.productTitle }}
                </h3>
                <div
                    v-if="product.product_details"
                    class="product-info__content-description"
                >
                    {{ product.product_details }}
                </div>
            </div>
        </div>
        <div
            class="product-info__footer"
            @click="clickProduct(product)"
        >
            <div class="product-info__ingredients">
                <span class="product-info__ingredients-title">Ingredients: </span>
                <span
                    class="product-info__ingredients-text"
                    v-html="product.ingredients.declaration"
                />
            </div>
        </div>

        <el-dialog
            v-model="showProduct"
            :title="product.productTitle + ' - ' + product.upc"
            destroy-on-close
            center
            append-to-body
        >
            <product
                :product-prop="product"
                @closeProductPopup="closeProductPopup()"
                @onUpdateProduct="onUpdateProduct"
            />
        </el-dialog>
    </div>
</template>

<script>
import { transferCheckedChangeFn } from 'element-plus'
import Product from './Product'
import _ from 'lodash'

export default {
    name: 'ProductInfo',
    components: {
        Product,
        // BModal
    },
    props: {
        productProp: {
            type: Object,
            default: null,
        },
        showCheckBoxes: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Array,
            default: [],
        },
        showProductMapping: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            product: null,
            showProduct: false,
            showProductModal: false,
            check: false,
            productImageLoaded: true
        }
    },
    computed: {
        productImageUrl() {
            if (this.product && this.product.gtin && this.productImageLoaded) {
                return '/images/products/' + this.product.gtin + '.jpg'
            } else {
                return '/images/ImageUnavailable2.png'
            }
        }
    },
    mounted() {
        this.product = this.productProp
        this.check = this.product.selected
    },
    methods: {
        getImageUrl(product) {
            return '/images/products/' + product.gtin + '.jpg'
        },
        imageLoadError() {
            this.productImageLoaded = false
        },
        categorizeByHands(product) {
            if (_.isEmpty(product.categoryHierarchies)) {
                return false
            }

            const nielsenIQ = product.categoryHierarchies.find(category => category.type === 'nielsenIQ')
            if (nielsenIQ && nielsenIQ.created_at) {
                const [datePart, timePart] = nielsenIQ.created_at.split(' ')
                return 'Changed at ' + datePart
            }

            return false
        },
        clickProduct(product) {
            this.showProduct = true
        },
        closeProductPopup() {
            this.showProduct = false
            this.showProductModal = false
        },
        fillSelectedCollection(product) {
            if (product.selected) {
                this.$emit('updateSelected', product._id)
            } else {
                this.removeFormSelectedCollection(product._id)
            }
        },
        removeFormSelectedCollection(id) {
            this.$emit('removeSelected', id)
        },
        onUpdateProduct: function (product) {
            this.product = product
        },
    },
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.product-info {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    padding: 10px;
    box-shadow: 0 0 6px 1px rgba(0,0,0, .25);
    &__select-control {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    &__image {
        max-width: 100%;
        max-height: 100%;
        &-wrap {
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 170px;
            margin-bottom: 20px;
            border: 1px solid $light-indigo;
            border-radius: 8px;
            overflow: hidden;
            padding: 2px;
            background-color: $white-second;
        }
    }
    &__content {
        &-headline,
        &-description {
            margin: 0 0 10px;
            line-height: 1.4;
        }
        &-headline {
            height: 68px;
            overflow: hidden;
            font-size: 16px;
            font-weight: 600;
        }
        &-description {
            font-size: 14px;
        }
    }
    &__ingredients {
        overflow: hidden;
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &__product-mapping {
        position: absolute;
        // top: 1px;
        right: 30px;
        top: 8px;
        color: $green;
        font-size: 20px;
        .blue {
            color: $blue;
        }

        &__position {
            right: 14px !important;
        }
    }
}
</style>
