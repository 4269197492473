import Index from './components/Index'

export default [
    {
        path: 'products-categorization-drag-drop/:search?',
        component: Index,
        name: 'products-categorization-drag-drop.index',
        meta: {
            auth: 'label_insight_products.view',
            pageTitle: 'Products Categorization Drag & Drop',
        },
    },
]
