<template>
    <section
        id="coaching-user-form"
        class="coaching-user-form"
    >
        <el-form
            ref="formRef"
            :model="form"
            label-width="100px"
            :rules="rules"
        >
            <div class="column">
                <el-row>
                    <el-col :span="24">
                        <el-tree
                            ref="treeRef"
                            v-loading="loading"
                            :data="treeData"
                            show-checkbox
                            node-key="id"
                            :props="defaultProps"
                            :default-checked-keys="defaultCheckedKeys"
                            @check="nodeClickedEvent"
                        >
                        <template v-slot="{ node }">
                            <span v-if="node.disabled">
                                <span
                                    class="reassign"
                                    @click.stop="onReassign(node)"
                                >Reassign</span> <span>{{ node.label }}</span>
                            </span>
                            <span v-else>
                                 <span>{{ node.label }}</span>
                             </span>
                             <template v-if="node&&node.data&&node.data.users&&node.data.users.length > 0">
                                <div v-for="user in node.data.users" class="users_block">
                                    <template v-if="user.disabled">
                                        <span
                                            class="reassign"
                                            @click.stop="onReassignUser(user)"
                                        >Reassign</span> <span>{{ user.label }}</span>
                                    </template>
                                    <template v-else>
                                        <el-checkbox 
                                            v-model="checkedUserNodes[user.id]" 
                                            :label="user.label"
                                            @click.stop="checkUpperNode(node)"
                                        />
                                    </template>
                                </div>
                             </template>
                        </template>
                        </el-tree>
                    </el-col>
                </el-row>
            </div>
        </el-form>
        <div>
            <el-button
                @click="cancel"
            >
                Cancel
            </el-button>
            <el-button
                :loading="formLoading"
                type="success"
                @click="saveSubmit"
            >
                Save
            </el-button>
        </div>
    </section>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import {Errors} from '~/includes/Errors'
import dashUserApi from '../../dash_user/dashUserApi'
import coachingUserApi from '../coaсhingUserApi'
import clientLevelsApi from '../../client_levels/clientLevelsApi'
import { useRoute } from "vue-router";
import { forEach, update } from 'lodash'

const emit = defineEmits()
const route = useRoute()
const props = defineProps({
    initialForm: {
        type: Object,
        default: {}
    },
})

const errors = new Errors()
const formLoading = ref(false)
const form = ref({
    _id: '',
    email: '',
    first_name: '',
    last_name: '',
    userIds: [],
    clientLevelsIds: [],
})
const rules = {
    _id: [
        { required: true, message: 'Select coach' },
    ],
    email: [
        { required: true, message: 'Email is required' },
        { type: 'email', message: 'Invalid email' },
    ],
    userIds: [
        { required: true, message: 'Select user' },
    ],
    clientLevelsIds: [
        { required: true, message: 'Select client level' },
    ],
}

const treeRef = ref()
const treeData = ref([])
const loading = ref(false)
const defaultProps = {
    children: 'children',
    label: 'label',
    isLeaf: 'isLeaf',
}
const defaultCheckedKeys = ref([])

const checkedUserNodes = ref([])

const onReassign = (node) => {
    node.checked = true
    node.data.disabled = false
}

const onReassignUser = (userNode) => {
    //node.checked = true
    userNode.disabled = false
}

watch(
    () => props.initialForm,
    val => {
        form.value = Object.assign({}, val)
        // setDefaultCheckedKeys()
    },
    { deep: true }
)

const formRef = ref()

onMounted(() => {
    getClientLevelsTree()
})

function saveSubmit() {
    formLoading.value = true
    formRef.value.validate((valid) => {
        if (valid) {
            // getAuthCode()
            saveValidForm()
        }
    })
}

function saveValidForm() {
    formLoading.value = true
    errors.clear()

    form.value.clientLevelsIds = []
    form.value.userIds = []
    treeRef.value.getCheckedNodes(false, true).forEach((node) => {
        form.value.clientLevelsIds.push(node.id)
    })

    Object.entries(checkedUserNodes.value).forEach(([userId, checked]) => {
        if (checked) {
            form.value.userIds.push(userId);
        }
    });

    coachingUserApi.assign(form.value)
        .then((response) => {
            ElMessage.success('Saved successfully')
            emit('saved')
            formLoading.value = false
        })
        .catch((error) => {
            errors.record('Server error')
            formLoading.value = false
        })
}

function getAuthCode() {
    if (!form.value.booking_id) {
        console.log('booking_id')
        coachingUserApi.getAuthCode().then(response => {
            formLoading.value = false
            navigator.clipboard.writeText(response.data.user_code)
            window.open(response.data.verification_uri)

            ElMessageBox.confirm( response.data.message).then(() => {
                saveValidForm()
            }).catch((error) => {
                formLoading.value = false
                ElMessage.error( 'Canceled')
            })
        })
    } else {
        console.log('!booking_id')
        saveValidForm()
    }
}

async function getClientLevelsTree() {
    const params = {
        id: route.params.id
    }
    const {data} = await clientLevelsApi.getCoachClientLevelTree(params)
    treeData.value = data

    setTimeout(() => {
        setDefaultCheckedKeys()
    }, 500)
}

function setDefaultCheckedKeys() {
    defaultCheckedKeys.value = []

    form.value.clientLevelsIds&&form.value.clientLevelsIds.forEach((id) => {
        treeRef.value.setChecked(id, true, false)
    })

    form.value.userIds&&form.value.userIds.forEach((id) => {
        checkedUserNodes.value[id] = true
    })
}

function cancel() {
    clearForm()
    emit('cancel')
}

function clearForm() {
    errors.clear()
    if (formRef.value) formRef.value.resetFields()
}

function checkUpperNode(node) {
    setTimeout(() => {
        if (node) {
            let isChecked = false

            node.data.users.forEach((user) => {
                if (checkedUserNodes.value[user.id]) {
                    isChecked = true
                }
            })
            treeRef.value.setChecked(node.data.id, isChecked, false)
        }
    }, 50)
    
}

function nodeClickedEvent(node, checkedLists) {
    if (node&&node.id) {
        let nodeChecked = checkedLists.checkedKeys.includes(node.id)
        node.users.forEach((user) => {
            checkedUserNodes.value[user.id] = nodeChecked
        })
        if (node.children) {
            node.children.forEach((child) => {
                nodeClickedEvent(child, checkedLists)
            })
        }
    }
}
</script>

<style scoped>
.column {
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
}

.reassign {
    color: blue;
    text-decoration: underline !important;
}
</style>
