<template>
    <div class="upload-photo">
        <input
            type="file"
            accept="image/png, image/jpeg"
            class="upload-photo__input"
            @change="onFileChange"
        >
        <el-button
            plain
            type="primary"
            size="small"
            icon="Upload"
            class="upload-photo__btn"
            :disabled="disabledUpload"
            @click="addProductPhoto()"
        >
            Replace Front Image (JPG, PNG)
        </el-button>
    </div>
</template>

<script>
import liProductsApi from '../../labelinsight-products/liProductsApi'

export default {
    name: 'UploadPhoto',
    props: {
        product: {
            type: [Array,Object],
            required: true,
        },
    },
    data() {
        return {
            showUpload: false,
            formData: new FormData(),
            disabledUpload: true,
        }
    },
    created() {
        this.formData.append('upc', this.product.upc)
        this.formData.append('waitForUpload', true)
    },
    methods: {
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return
            }
            let newFile = files[0]
            this.formData.append('photo', newFile)
            this.formData.append('name', newFile.name)
            this.formData.append('lastModified', newFile.lastModified)
            this.formData.append('size', newFile.size)
            this.formData.append('source', this.source)
            this.formData.append('type', this.type)
            this.disabledUpload = false
        },
        addProductPhoto() {
            var self = this
            liProductsApi.uploadPhoto(
                this.formData
            ).then(function (response) {
                // debugger
                self.$emit('photoUploaded', response.data.product)
            }).catch(function (e) {
                // debugger;
                if (e.response.status == '415') {
                    self.uploadError = 'Upload file has unsupported type. \n\
                                Please upload file having types: jpg, gif, png, tif'
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.upload-photo {
    @media all and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__input {
        width: 100%;
        @media all and (max-width: 768px - 1px) {
            margin-bottom: 10px;
        }
    }
    &__btn {
        white-space: nowrap;
        @media all and (min-width: 768px) {
            flex-shrink: 0;
            margin-left: 10px;
        }
    }
}
</style>
