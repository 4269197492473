import axios from 'axios'

const API_ENDPOINT = window.config.ssURL + 'companies'

export default {

    all(params) {
        return axios.get(API_ENDPOINT, { params })
    },

    get(id) {
        return axios.get(API_ENDPOINT + '/' + id)
    },

    save(params) {
        return axios.post(API_ENDPOINT + '/save', params)
    },

    destroy(params) {
        return axios.post(API_ENDPOINT + '/delete', params)
    },
}
