import ChangePassword from './components/ChangePassword'
import ProfileEdit from './components/ProfileEdit'

export default [
    {
        path: 'change-password',
        name: 'profile.change-password',
        component: ChangePassword,
        meta: {
            pageTitle: 'Change password',
        },
    },
    {
        path: 'profile-edit',
        name: 'profile.edit',
        component: ProfileEdit,
        meta: {
            pageTitle: 'Edit profile',
        },
    },
]
