<template>
    <section id="notification-form">
        <el-form
            v-if="form"
            ref="formRef"
            :model="form"
            label-width="150px"
            :rules="rules"
            @submit.prevent="saveSubmit"
        >
            <el-row :gutter="20">
                <el-col :md="12">
                    <el-card>
                        <el-tabs
                            v-model="activeTab"
                            type="border-card"
                            class="provider-tabs"
                        >
                            <el-tab-pane name="app">
                                <template #label>
                                    <span class="custom-tabs-label">
                                        <el-badge
                                            v-if="form.app.is_active"
                                            is-dot
                                            class="tab_badge"
                                            type="success"
                                        />
                                        App
                                    </span>
                                </template>
                                <provider-tab
                                    v-model="form.app"
                                    provider="app"
                                    :pages="list.pages"
                                    :errors="errors"
                                />
                            </el-tab-pane>
                            <el-tab-pane name="email">
                                <template #label>
                                    <span class="custom-tabs-label">
                                        <el-badge
                                            v-if="form.email.is_active"
                                            is-dot
                                            class="tab_badge"
                                            type="success"
                                        />
                                        Email
                                    </span>
                                </template>
                                <provider-tab
                                    v-model="form.email"
                                    provider="email"
                                    :errors="errors"
                                />
                            </el-tab-pane>
                            <el-tab-pane name="sms">
                                <template #label>
                                    <span class="custom-tabs-label">
                                        <el-badge
                                            v-if="form.sms.is_active"
                                            is-dot
                                            class="tab_badge"
                                            type="success"
                                        />
                                        Sms
                                    </span>
                                </template>
                                <provider-tab
                                    v-model="form.sms"
                                    provider="sms"
                                    :errors="errors"
                                />
                            </el-tab-pane>
                        </el-tabs>
                    </el-card>
                    <el-card>
                        <h3>Manager settings</h3>
                        <el-form-item
                            label="Type"
                            prop="type"
                            :error="errors.get('type')"
                        >
                            <el-select
                                v-model="form.type"
                                class="w-100"
                                placeholder="Select type"
                            >
                                <el-option
                                    v-for="type in list.types"
                                    :key="type"
                                    :label="type"
                                    :value="type"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="Department"
                            prop="department"
                            :error="errors.get('department')"
                        >
                            <el-select
                                v-model="form.department"
                                class="w-100"
                                placeholder="Select department"
                            >
                                <el-option
                                    v-for="department in list.departments"
                                    :key="department"
                                    :label="department"
                                    :value="department"
                                />
                            </el-select>
                        </el-form-item>
                    </el-card>
                </el-col>
                <el-col :md="12">
                    <el-card>
                        <el-form-item
                            label="Name"
                            prop="name"
                            :error="errors.get('name')"
                        >
                            <el-input
                                v-model="form.name"
                                suffix-icon="edit-pen"
                                @change="errors.clear('name')"
                            />
                        </el-form-item>
                        <h3>User parameters</h3>
                        <action-input
                            action-index="1"
                            v-model:action="form.action"
                            v-model:additional-data="form.additional_data"
                            v-model:selected-client-level="form.client_level_id"
                            :errors="errors"
                            action-label="Beginning Action"
                            :action-list="actionList"
                        />
                        <condition-input
                            v-model="form.condition1"
                            :condition-list="conditionList1"
                            :error="errors.get('condition1')"
                        />
                        <el-form-item
                            v-if="form.condition1=='AND NOT'"
                            label="Number of Days"
                            prop="number_of_days"
                            :error="errors.get('number_of_days')"
                        >
                            <el-select
                                v-model="form.number_of_days"
                                placeholder="Select number of days"
                                clearable
                                class="w-100"
                            >
                                <el-option
                                    v-for="item in list.number_of_days"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                />
                            </el-select>
                        </el-form-item>
                        <action-input
                            v-if="form.condition1"
                            action-index="2"
                            v-model:action="form.action2"
                            v-model:additional-data="form.additional_data2"
                            v-model:selected-client-level="form.client_level_id2"
                            :errors="errors"
                            action-label="Second Action"
                            :action-list="actionList2"
                        />
                        <condition-input
                            v-if="form.condition1"
                            v-model="form.condition2"
                            :condition-list="conditionList2"
                            :error="errors.get('condition2')"
                        />
                        <action-input
                            v-if="form.condition2"
                            action-index="3"
                            v-model:action="form.action3"
                            v-model:additional-data="form.additional_data3"
                            v-model:selected-client-level="form.client_level_id3"
                            :errors="errors"
                            action-label="Third Action"
                            :action-list="actionList3"
                        />
                        <!-- condition-input
                            v-model="condition3"
                        / -->

                        <el-form-item
                            label="Profile types"
                            prop="profile_type"
                            :error="errors.get('profile_type')"
                        >
                            <el-select
                                v-model="form.profile_type"
                                multiple
                                placeholder="Select profile type"
                                class="w-100"
                            >
                                <el-option
                                    v-for="item in list.profile_types"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="Aspiration"
                            prop="aspiration"
                            :error="errors.get('aspiration')"
                        >
                            <el-select
                                v-model="form.aspiration"
                                :disabled="disableUnregisteredFields"
                                multiple
                                class="w-100"
                                placeholder="Select aspiration"
                            >
                                <el-option
                                    v-for="item in list.aspiration"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="Household"
                            prop="household"
                            :error="errors.get('household')"
                        >
                            <el-select
                                v-model="form.household"
                                :disabled="disableUnregisteredFields"
                                multiple
                                class="w-100"
                                placeholder="Select household"
                            >
                                <el-option
                                    v-for="item in list.household"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="Personal types"
                            prop="personal_type"
                            :error="errors.get('personal_type')"
                        >
                            <el-select
                                v-model="form.personal_type"
                                :disabled="disableUnregisteredFields"
                                multiple
                                class="w-100"
                                placeholder="Select personal type"
                            >
                                <el-option
                                    v-for="item in list.personal_types"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="ROC"
                            prop="roc"
                            :error="errors.get('roc')"
                        >
                            <el-select
                                v-model="form.roc"
                                :disabled="disableUnregisteredFields"
                                multiple
                                class="w-100"
                                placeholder="Select ROC"
                            >
                                <el-option
                                    v-for="item in list.roc"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="Rating"
                            prop="rating"
                            :error="errors.get('rating')"
                        >
                            <el-select
                                v-model="form.rating"
                                disabled
                                multiple
                                class="w-100"
                                placeholder="Select Rating"
                            >
                                <el-option
                                    v-for="item in list.ratings"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                />
                            </el-select>
                        </el-form-item>
                    </el-card>
                </el-col>
            </el-row>
        </el-form>
        <div>
            <el-button
                @click="cancel"
            >
                Cancel
            </el-button>
            <el-button
                :loading="formLoading"
                type="success"
                @click="saveSubmit"
            >
                Save
            </el-button>
        </div>
    </section>
</template>

<script setup>
import {onMounted, ref, watch, provide, computed} from 'vue'
import { ElMessage } from 'element-plus'
import notificationApi from '../../notificationApi'
import ProviderTab from './ProviderTab'
import ActionInput from '~/modules/notification/components/form/ActionInput'
import ConditionInput from '~/modules/notification/components/form/ConditionInput'
import * as ACTIONS from '../../actions.constants'
import * as ACTION_LIST from '../../actionList.constants'
import {useRoute} from 'vue-router'
import dayjs from 'dayjs'
import {DATE_DB_FORMAT} from '~/base/constants/time.constants'

import {useErrors} from '~/includes/composable/errors'

const errors = useErrors()

const route = useRoute()

const emit = defineEmits()

const props = defineProps({
    initialForm: {
        type: Object,
        default: {}
    },
})

const formLoading = ref(false)
const form = ref(null)
const rules = {
    name: [
        { required: true },
    ],
    type: [
        { required: true },
    ],
    department: [
        { required: true },
    ],
    action: [
        { required: true },
    ],
}

const nowDate = new dayjs()
const defaultDate = _.clone(nowDate).add(3, 'months')

const defaultForm = {
    name: '',
    app: {
        text: '',
        is_active: false,
        page: 'Any page',
        frequency: {
            value: null,
            time_data: {},
        },
        start_date: nowDate.format(DATE_DB_FORMAT),
        end_date: defaultDate.format(DATE_DB_FORMAT),
    },
    email: {
        text: '',
        is_active: false,
        subject: 'Notification',
        frequency: {
            value: null,
            time_data: {},
        },
        start_date: nowDate.format(DATE_DB_FORMAT),
        end_date: defaultDate.format(DATE_DB_FORMAT),
    },
    sms: {
        text: '',
        is_active: false,
        frequency: {
            value: null,
            time_data: {},
        },
        start_date: nowDate.format(DATE_DB_FORMAT),
        end_date: defaultDate.format(DATE_DB_FORMAT),
    },
    type: null,
    action: null,
    additional_data: null,
    action2: null,
    additional_data2: null,
    action3: null,
    additional_data3: null,
    condition1: null,
    condition2: null,
    department: null,
    client_level_id: null,
    client_level_id2: null,
    client_level_id3: null,
    specific_date: null,
    profile_type: null,
    aspiration: null,
    household: null,
    personal_type: null,
    roc: null,
    rating: null,
    number_of_days: null
}

onMounted(() => {
    if (route.name === 'notifications.add') form.value = Object.assign(defaultForm, props.initialForm)
})

watch(
    () => props.initialForm,
    val => form.value = Object.assign(defaultForm, val),
    { deep: true }
)

const list = ref({})
const actionList = ref([])
const actionList2 = ref([])
const actionList3 = ref([])

const conditionList1 = ref([])
const conditionList2 = ref([])

onMounted(() => getLists())

const activeTab = computed(() => {
    let tab = 'email'
    if (form.value?.app.is_active)
        tab = 'app'
    if (form.value?.email.is_active)
        tab = 'email'
    if (form.value?.sms.is_active)
        tab = 'sms'
    if (route.query?.active && ['app', 'email', 'sms'].includes(route.query.active))
        tab = route.query.active

    return tab
})

provide('list', list.value)

async function getLists() {
    const {data} = await notificationApi.list()
    list.value.types = data.types
    list.value.departments = data.departments
    list.value.pages = data.pages
    list.value.frequencies = data.frequencies
    list.value.actions = data.actions
    list.value.periods = data.periods
    list.value.profile_types = data.profile_types
    list.value.aspiration = data.aspiration
    list.value.household = data.household
    list.value.personal_types = data.personal_types
    list.value.roc = data.roc
    list.value.categories = data.categories
    list.value.ratings = data.ratings
    list.value.conditions = data.conditions
    list.value.number_of_days = data.number_of_days

    actionList.value = getActionList(list.value.actions, ACTION_LIST.ACTION_LIST_1)
    actionList2.value = getActionList(list.value.actions, ACTION_LIST.ACTION_LIST_2)
    actionList3.value = getActionList(list.value.actions, ACTION_LIST.ACTION_LIST_3)

    conditionList1.value = getConditionList(list.value.conditions)
    conditionList2.value = getConditionList(list.value.conditions)
}

watch(
    () => form.value ,
    (val, valOld)  => {
        if (list.value && list.value.conditions && (valOld === null || val.condition1 !== valOld.condition1)) {
            conditionList2.value = getConditionList(list.value.conditions, val.condition1)
        }
    },
    { deep: true }

)

const formRef = ref()

function saveSubmit() {
    formRef.value.validate((valid) => {
        if (valid) {
            formLoading.value = true
            errors.clear()

            // reset actions if conditions are not selected
            if (!form.value.condition1) {
                form.value.action2 = null
            }
            if (!form.value.condition2) {
                form.value.action3 = null
            }

            const action = form.value._id ? notificationApi.update : notificationApi.create
            action(form.value).then((response) => {
                ElMessage.success( 'Saved successfully')
                emit('saved', response?.data?.notification?._id)
            }).catch(error => {
                if (error.response.data.errors) errors.record(error.response.data.errors)
            }).finally(() => formLoading.value = false)
        }
    })
}

function cancel() {
    clearForm()
    emit('cancel')
}

function clearForm() {
    errors.clear()
    if (formRef.value) formRef.value.resetFields()
}

const disableUnregisteredFields = computed(() =>
    [
        ACTIONS.REGISTERED,
        ACTIONS.INVITE_FDM,
        ACTIONS.INVITED_NOT_REGISTERED,
        ACTIONS.INVITED_FDM_NOT_REGISTERED,
    ]
        .includes(form.value.action)
)

function getActionList(list, allowedActions) {
    let actionList = []
    for (let i = 0; i < list.length; i++) {
        if (allowedActions.includes(list[i].value)) {
            let option = {value: list[i].value, text: list[i].text}
            actionList.push(option)
        }
    }
    return actionList
}

function getConditionList(list, allowedConditionTxt = '') {
    let conditionList = []

    if (allowedConditionTxt === '') {
        return list
    } 
    for (let i = 0; i < list.length; i++) {
        if (list[i].includes(allowedConditionTxt)) {
            conditionList.push(list[i])
        }
    }
    return conditionList
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
#notification-form {
    .provider-tabs {
        .custom-tabs-label {
            .tab_badge {
                vertical-align: middle;
                color: green;
                line-height: 5px;
            }
        }
    }
    .el-card {
        margin-bottom: 20px;
    }
}
</style>
