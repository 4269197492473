<template>
    <el-button
        v-if="$auth.check('Admin')"
        class="btn"
        @click="getLogs"
    >
        Get Logs
    </el-button>
</template>

<script setup>
import liProductsApi from '~/modules/labelinsight-products/liProductsApi'

function getLogs() {
    // axios({
    //     url: '/labelinsight/products/getLogs', // File URL Goes Here
    //     method: 'GET',
    //     responseType: 'blob',
    // }).

    liProductsApi.getLogs().then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'labelinsight.log')
        document.body.appendChild(link)
        link.click()
    })
}
</script>
