<template>
    <el-form
        :model="filters"
        @submit.prevent
    >
        <el-row :gutter="10">
            <el-col
                :sm="12"
                :md="5"
            >
                <el-form-item>
                    <el-input
                        v-model="filters.search"
                        placeholder="Search"
                    />
                </el-form-item>
            </el-col>
            <el-col
                :sm="12"
                :md="5"
            >
                <el-form-item>
                    <el-select
                        v-model="filters.status"
                        class="m-2 w-100 el-select--capitalize"
                        placeholder="Select step"
                        clearable
                        popper-class="el-popper--capitalize"
                    >
                        <el-option
                            v-for="status in statuses"
                            :key="status"
                            :label="status"
                            :value="status"
                        />
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col
                :sm="12"
                :md="5"
            >
                <el-form-item>
                    <el-date-picker
                        v-model="filters.date_from"
                        type="date"
                        placeholder="Date from"
                        :value-format="DATE_DB_FORMAT"
                        class="w-100"
                    />
                </el-form-item>
            </el-col>
            <el-col
                :sm="12"
                :md="5"
            >
                <el-form-item>
                    <el-date-picker
                        v-model="filters.date_to"
                        type="date"
                        placeholder="Date to"
                        :value-format="DATE_DB_FORMAT"
                        class="w-100"
                    />
                </el-form-item>
            </el-col>
            <el-col
                :sm="12"
                :md="4"
            >
                <el-form-item>
                    <el-button
                        type="primary"
                        class="w-100"
                        @click="clearSearch"
                    >
                        Reset
                    </el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script setup>
import { useModelWrapper } from '~/includes/composable/modelWrapper'
import {DATE_DB_FORMAT} from '~/base/constants/time.constants'

const emit = defineEmits()

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
})

const filters = useModelWrapper(props, emit, 'modelValue')

const statuses = [
    'Processing',
    'Registered',
    'clicked',
    'delivered',
    'opened',
    'sent'
]

function clearSearch() {
    filters.value.search = ''
    filters.value.status = ''
    filters.value.date_from = null
    filters.value.date_to = null
}
</script>

