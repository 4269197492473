<template>
    <el-form
        :model="filters"
        @submit.prevent
    >
        <el-row :gutter="10">
            <el-col
                :xs="24"
                :sm="12"
                :md="6"
            >
                <el-form-item>
                    <el-input
                        v-model="filters.search"
                        placeholder="Search"
                        class="w-100"
                        clearable
                    />
                </el-form-item>
            </el-col>
            <el-col
                :xs="24"
                :sm="12"
                :md="6"
            >
                <el-form-item>
                    <el-select
                        v-model="filters.type"
                        class="w-100"
                        placeholder="Select type"
                        clearable
                    >
                        <el-option
                            v-for="type in list.types"
                            :key="type"
                            :label="type"
                            :value="type"
                        />
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col
                :xs="24"
                :sm="12"
                :md="6"
            >
                <el-form-item>
                    <el-select
                        v-model="filters.department"
                        class="w-100"
                        placeholder="Select department"
                        clearable
                    >
                        <el-option
                            v-for="department in list.departments"
                            :key="department"
                            :label="department"
                            :value="department"
                        />
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col
                :xs="24"
                :sm="12"
                :md="6"
            >
                <el-form-item>
                    <el-button
                        class="w-100"
                        @click="clearSearch"
                    >
                        Reset
                    </el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import { useModelWrapper } from '~/includes/composable/modelWrapper'
import notificationApi from '../notificationApi'

const emit = defineEmits()

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
})

const list = ref({})

onMounted(() => getLists())

async function getLists() {
    const {data} = await notificationApi.list()
    list.value.types = data.types
    list.value.departments = data.departments
}

const filters = useModelWrapper(props, emit, 'modelValue')

function clearSearch() {
    filters.value.search = ''
    filters.value.type = ''
    filters.value.department = ''
}
</script>

