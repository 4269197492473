<template>
    <div>
        <el-form-item 
            v-for="(options, index) in dropdownOptions" 
            :key="index"
            :label="labels[index]"
            :required="index === 0"
            :error="index === 0&&errors.get('client_level_id')"
        >
            <el-row>
                <el-col :span="23">
                    <el-select 
                        class="w-100"
                        v-model="selectedLevels[index]" 
                        placeholder="Select" 
                        @change="handleSelection(index)"
                    >
                        <el-option
                            v-for="item in options"
                            :key="item._id"
                            :label="item.name"
                            :value="item._id">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="1">
                    <el-button
                        class="delete-button"
                        type="danger" 
                        icon="delete" 
                        @click="handleSelection(index - 1)"
                    />
                </el-col>
            </el-row>
        </el-form-item>
    </div>
</template>
  
<script setup>
import {ref, watch} from 'vue';
import {Errors} from '~/includes/Errors'
import clientLevelsApi from '../clientLevelsApi'
import {ElMessage, ElMessageBox} from "element-plus";
import ssUserApi from "~/modules/ss_user/ssUserApi";
import {useRouter} from "vue-router";

const props = defineProps({
    modelValue: {
        type: String,
        default: ''
    },
    selectedUser: {
        type: Object,
        default: () => {
            return {
                email: null,
                first_name: null,
                last_name: null,
            }
        }
    },
    error: {
        type: Object,
        default: null
    }
});

const emit = defineEmits(['update:modelValue']);

const selectedLevels = ref([]);
const dropdownOptions = ref([]);
const labels = ref([]);
const router = useRouter()

const errors = ref(new Errors());
const selectedLevelId = ref('');

const loadOptions = async (parentId = '', index = 0) => {
    const method = parentId ? 'getChildren' : 'getRoot';
    const options = await clientLevelsApi[method](parentId);

    let optionsData = Array.isArray(options.data) ? options.data : [options.data];

    if (optionsData.length === 0) return;
    let dropdownOptionsArr = dropdownOptions.value;
    dropdownOptionsArr[index] = optionsData;
    dropdownOptions.value = dropdownOptionsArr;

    if (index === 0) {
        labels.value = ['Root'];
    }
};

function handleDelete() {
    ElMessageBox.confirm(`User ${props.selectedUser.first_name} ${props.selectedUser.email} will be removed from SeekingSimple completely. This operation can not be undone. Please confirm.`, 'Warning', {
        type: 'warning',
        confirmButtonText: 'Confirm',
    }).then(() => {
        ssUserApi.destroyBulk([props.selectedUser._id]).then((response) => {
            ElMessage.success('Deleted successfully')
            router.push({name: 'ss-users.registered'})
        })
    })
}

const handleSelection = (index) => {
    if (index === -1) {
        handleDelete()
        return
    }

    const selectedLevel = selectedLevels.value[index];

    if (selectedLevel) {
        errors.value.clear();
        selectedLevels.value = selectedLevels.value.slice(0, index + 1);
        dropdownOptions.value = dropdownOptions.value.slice(0, index + 1);
        selectedLevelId.value = selectedLevel;

        labels.value = labels.value.slice(0, index + 1);
        let labelTxt = dropdownOptions.value[index].find(option => option._id === selectedLevel).label
        if (!labelTxt) {
            labelTxt = 'Level: ' + (index + 1)
        }
        
        labels.value.push(labelTxt);
    }
    emit('update:modelValue', selectedLevel);
    loadOptions(selectedLevel, index + 1);
};

watch(() => props.modelValue, async (newVal, oldVal) => {
    if (newVal && selectedLevels.value.length === 0) {
        const parents = await clientLevelsApi.getParents(newVal);
        dropdownOptions.value = [];
        selectedLevels.value = [];

        selectedLevels.value = Array.isArray(parents.data) ? parents.data.reverse().map(parent => parent._id) : [parents.data._id];
        selectedLevels.value.push(newVal);

        let loadIds = [null, ...selectedLevels.value];
        await Promise.all(loadIds.map(async (parent, index) => {
            await loadOptions(parent, index)
        }));

        await Promise.all(selectedLevels.value.map((parent, index) => {
            labels.value = labels.value.slice(0, index + 1);
            let labelTxt = dropdownOptions.value[index].find(option => option._id === parent).label
            if (!labelTxt) {
                labelTxt = 'Level: ' + (index + 1)
            }
            
            labels.value.push(labelTxt);
        }));
    } else if (!newVal) {
        loadOptions();
    }
}, { immediate: true });

watch(() => props.error, (newVal, oldVal) => {
    errors.value.clear();
    if(newVal) {
        errors.value.record({ client_level_id: [newVal]});
    }
}, { immediate: true, deep: true });
</script>

<style lang="scss" scoped>
.delete-button {
    margin-left: 10px;
}
</style>
