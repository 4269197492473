import Login                from './components/Login'
import ForgotPassword       from './components/ForgotPassword'
import PasswordReset        from './components/PasswordReset'

export default [
    {
        path: '/login',
        component: Login,
        name: 'Login',
        meta: {
            auth: false,
            layout: 'Auth',
            pageTitle: 'Login',
        },
    },
    {
        path: '/forgot/password',
        component: ForgotPassword,
        name: 'Forgot password',
        meta: {
            auth: false,
            layout: 'Auth',
            pageTitle: 'Forgot password',
        },
    },
    {
        path: '/password/reset/:token',
        component: PasswordReset,
        name: 'Password reset',
        props: route => ({
            token: route.params.token,
            email: route.query.email
        }),
        meta: {
            auth: false,
            layout: 'Auth',
            pageTitle: 'Password reset',
        },
    },
]
