<template>
    <div class="page-breadcrumbs">
        <el-breadcrumb
            separator="/"
            class="breadcrumb"
        >
            <el-breadcrumb-item :to="{path: '/'}">
                ..
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                In Store Product Mappings
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>

    <h2 class="page-title">
        In Store Product Mappings
    </h2>
    <el-row
        :gutter="15"
        justify="space-between"
    >
        <el-col class="col-auto">
            <base-filter v-model="filters" />
        </el-col>
        <el-col class="col-auto mb-15">
            <import-export-form
                v-if="$auth.check('Admin')"
                @refresh="fetchData"
            />
            <el-button
                v-if="$auth.check('product_mapping.create')"
                type="primary"
                icon="plus"
                class="ml-15"
                @click="handleAdd()"
            >
                Add Product Mapping
            </el-button>
        </el-col>
    </el-row>
    <div
        v-loading="loading"
        class="page-section"
    >
        <template v-if="mappingData">
            <el-table
                :data="mappingData.data"
                highlight-current-row
                class="w-100"
            >
                <el-table-column
                    prop="name"
                    label="Mapping Name"
                    min-width="180"
                />
                <el-table-column
                    prop="completed"
                    label="Completed"
                    min-width="100"
                />
                <el-table-column
                    label="Actions"
                    min-width="160"
                    align="right"
                >
                    <template #default="scope">
                        <el-button
                            v-if="$auth.check('product_mapping.edit')"
                            size="small"
                            icon="edit"
                            @click="handleEdit(scope)"
                        >
                            Edit
                        </el-button>
                        <el-button
                            v-if="$auth.check('product_mapping.delete')"
                            type="danger"
                            size="small"
                            icon="delete"
                            @click="handleDelete(scope)"
                        >
                            Delete
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                v-model:current-page="mappingData.page"
                :page-size="mappingData.per_page"
                :total="mappingData.total"
                hide-on-single-page
                layout="prev, pager, next, ->, total"
                class="el-pagination--align-right"
                @current-change="fetchData"
                @size-change="fetchData"
            />
        </template>
    </div>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import BaseFilter from '~/base/components/filters/BaseFilter'
import productMappingApi from '../productMappingApi'
import ImportExportForm from './ImportExportForm'

const route = useRoute()
const router = useRouter()
const loading = ref(false)
const mappingData = ref(null)
const filters = ref({search: ''})

onMounted(() => fetchData())

watch(
    () => filters.value,
    val => applySearch(),
    { deep: true }
)

async function fetchData(page = 1) {
    loading.value = true

    const params = {
        page: page,
        pageSize: 10,
        ...filters.value,
    }

    try {
        const {data} = await productMappingApi.all(params)
        mappingData.value = data
    } catch (e) {
        console.error(e)
    }
    loading.value = false
}

const applySearch = _.debounce( function() {
    fetchData()
}, 300)

function handleAdd() {
    router.push({name: 'product-mapping.add'})
}

function handleEdit(item) {
    router.push({name: 'product-mapping.edit', params: {id: item.row._id}})
}

function handleDelete(item) {
    ElMessageBox.confirm('Product Mapping will be deleted permanently', 'Delete!', {
        type: 'warning'
    }).then(() => {
        productMappingApi.delete(item.row._id).then(response => {
            fetchData(mappingData.value.current_page)
        }).catch(error => {
            console.error(error)
        })
    })
}
</script>
