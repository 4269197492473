<template>
    <div class="not-found">
        <div class="not-found__content-section">
            <div class="not-found__title default-text">
                {{ title }}
            </div>
            <div class="not-found__text default-text">
                {{ text }}
            </div>
            <div class="not-found__btn">
                <el-button
                    round
                    type="primary"
                    class="w-100"
                    @click="$router.push({ name: 'Dashboard' })"
                >
                    Home page
                </el-button>
            </div>
        </div>
    </div>
</template>

<script setup>
const name = 'NotFound'
const title = 'Oops!'
const text = 'Something went wrong.'
</script>
