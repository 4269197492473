<template>
    <div class="page-breadcrumbs">
        <el-breadcrumb
            separator="/"
            class="breadcrumb"
        >
            <el-breadcrumb-item :to="{path: '/'}">
                ..
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="{name: 'product-mapping.index'}">
                In Store Product Mappings
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                {{ actionName }} Product Mapping <template v-if="!savedChanges">
                    - (Unsaved)
                </template>
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>

    <h2 class="page-title">
        {{ actionName }} Product Mapping
    </h2>

    <div class="product-mapping">
        <el-row
            :gutter="10"
            align="middle"
            class="product-mapping__row product-mapping__row--border-bottom"
        >
            <el-col
                class="product-mapping__col-auto"
            >
                <label for="mapping_name">Mapping Name:</label>
            </el-col>
            <el-col
                class="product-mapping__col-auto"
            >
                <el-input
                    v-model="form.name"
                    type="text"
                    placeholder="Mapping Name"
                    name="mapping_name"
                />
            </el-col>
        </el-row>
        <el-row
            v-if="form._id"
            class="product-mapping__row product-mapping__row--border-bottom"
        >
            <el-col
                :span="12"
                class="product-mapping__col product-mapping__col--border-left"
            >
                <h2
                    class="section-title section-title--small-bottom-offset"
                >
                    Source
                </h2>
                <el-row
                    :gutter="10"
                    align="middle"
                >
                    <el-col
                        :xs="24"
                        :span="12"
                    >
                        <el-input
                            v-model="product_upc['source']"
                            type="text"
                            class="form-control"
                            placeholder="Search for UPC"
                        />
                    </el-col>
                    <el-col
                        :xs="24"
                        :span="12"
                        class="product-mapping__col--buttons"
                    >
                        <el-button
                            type="primary"
                            :disabled="!product_upc['source']"
                            @click="clickSearchProduct('source')"
                        >
                            Search
                        </el-button>
                        <el-button
                            type="primary"
                            @click="addProductTo('source')"
                        >
                            Scan
                        </el-button>
                    </el-col>
                </el-row>
                <div
                    v-if="searchMode === 'source' && showBarcodeScanner"
                    class="product-mapping__barcode-scanner"
                >
                    <el-button
                        circle
                        icon="close"
                        class="product-mapping__barcode-scanner-close"
                        @click="closeBarcodeScanner()"
                    />
                    <barcode-scanner
                        ref="barcodeScannerSource"
                        :key="barcodeScannerKey+'source'"
                        @scan="detectedBarcode"
                    />
                </div>

                <ul
                    v-if="form.sources.length > 0"
                    class="product-mapping__list"
                >
                    <li
                        v-for="product in form.sources"
                        :key="product.upc+'s'+photoKey"
                        class="product-mapping__list-item"
                    >
                        <div class="product-section">
                            <div class="product-section__product">
                                <div class="product-section__product-remove">
                                    <el-button
                                        type="danger"
                                        plain
                                        size="small"
                                        icon="close"
                                        @click="removeProductFrom('source', product)"
                                    >
                                        Remove
                                    </el-button>
                                </div>
                                <product-preview
                                    :product="product"
                                    product-mapping-mob-adaptive
                                />
                            </div>
                            <div class="product-section__controls">
                                <UploadPhoto
                                    v-if="!product.imageSection||!product.imageSection.images||product.imageSection.images.length==0"
                                    :key="product.upc+'s'"
                                    :product="product"
                                    @photoUploaded="photoUploaded"
                                />
                            </div>
                        </div>
                    </li>
                </ul>
            </el-col>
            <el-col
                :span="12"
                class="product-mapping__col"
            >
                <h2
                    class="section-title section-title--small-bottom-offset"
                >
                    Target
                </h2>
                <el-row
                    :gutter="10"
                    align="middle"
                >
                    <el-col
                        :xs="24"
                        :span="12"
                    >
                        <el-input
                            v-model="product_upc['target']"
                            type="text"
                            placeholder="Search for UPC"
                        />
                    </el-col>
                    <el-col
                        :xs="24"
                        :span="12"
                        class="product-mapping__col--buttons"
                    >
                        <el-button
                            type="primary"
                            :disabled="!product_upc['target']"
                            @click="clickSearchProduct('target')"
                        >
                            Search
                        </el-button>
                        <el-button
                            type="primary"
                            @click="addProductTo('target')"
                        >
                            Scan
                        </el-button>
                    </el-col>
                </el-row>
                <div
                    v-if="searchMode === 'target' && showBarcodeScanner"
                    class="product-mapping__barcode-scanner"
                >
                    <el-button
                        circle
                        icon="close"
                        class="product-mapping__barcode-scanner-close"
                        @click="closeBarcodeScanner()"
                    />
                    <barcode-scanner
                        ref="barcodeScannerTarget"
                        :key="barcodeScannerKey+'target'"
                        @scan="detectedBarcode"
                    />
                </div>
                <ul
                    v-if="form.targets.length > 0"
                    class="product-mapping__list"
                >
                    <li
                        v-for="product in form.targets"
                        :key="product.upc+'t'+photoKey"
                        class="product-mapping__list-item"
                    >
                        <div class="product-section">
                            <div class="product-section__product">
                                <div class="product-section__product-remove">
                                    <el-button
                                        type="danger"
                                        plain
                                        size="small"
                                        icon="close"
                                        @click="removeProductFrom('target', product)"
                                    >
                                        Remove
                                    </el-button>
                                </div>
                                <product-preview
                                    :product="product"
                                    product-mapping-mob-adaptive
                                />
                            </div>
                            <div class="product-section__controls">
                                <UploadPhoto
                                    v-if="!product.imageSection||!product.imageSection.images||product.imageSection.images.length==0"
                                    :key="product.upc+'t'"
                                    :product="product"
                                    @photoUploaded="photoUploaded"
                                />
                            </div>
                        </div>
                    </li>
                </ul>
            </el-col>
        </el-row>
        <el-row
            class="product-mapping__row"
        >
            <el-button
                v-if="!form._id"
                type="primary"
                :disabled="form.name.length <= 0"
                :loading="loading"
                @click="saveProductMapping()"
            >
                Begin
            </el-button>
            <el-button
                v-if="form._id"
                type="success"
                :loading="loading"
                @click="saveProductMapping"
            >
                Save For Later
            </el-button>
            <el-button
                v-if="form.sources.length>0 && form.targets.length>0"
                type="success"
                :disabled="!savedChanges"
                @click="completeProductMapping"
            >
                Complete Mapping
            </el-button>
        </el-row>
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {ElMessage, ElMessageBox} from 'element-plus'
import BarcodeScanner from '~/base/components/BarcodeScanner'
import ProductPreview from './ProductPreview'
import UploadPhoto from './UploadPhoto'
import productMappingApi from '../productMappingApi'
import liProductsApi from '../../labelinsight-products/liProductsApi'

const route = useRoute()
const router = useRouter()
const loading = ref(false)
const mappingData = ref(null)

const form = ref({
    _id: null,
    name: '',
    sources: [],
    targets: [],
    completed: false,
})

const product_upc = ref({
    source: '',
    target: ''
})

const searchMode = ref('')
const showBarcodeScanner = ref(false)
const barcodeScannerKey = ref(0)
const savedChanges = ref(false)
const actionName = ref('Add')
const photoKey = ref(0)


onMounted(() => fetchData())

async function fetchData() {
    if (route.params.id) {
        loading.value = true
        const {data} = await productMappingApi.get(route.params.id)
        form.value = data
        loading.value = false
        savedChanges.value = true
        actionName.value = 'Edit'
    }
}
function completeProductMapping() {
    // let url = router.resolve({name: 'product-mapping.index'}).href
    // window.location.href = url

    // return
    productMappingApi.completeProductMapping(form.value)
        .then((response) => {
            form.value = response.data

            router.push({name: 'product-mapping.index'})
        })
        .catch((error) => {
            console.log(error)
        })
}
function getProductByUpc(upc) {
    liProductsApi.getProductByUpc(upc).then(response => {
        let product = response.data

        // debugger
        savedChanges.value = false
        console.log(savedChanges.value)

        if (searchMode.value === 'source') {
            form.value.sources.push(product)
        } else {
            form.value.targets.push(product)
        }
        // searchMode = ''
    }).catch(response => {
        ElMessageBox.alert('Product Not Found In DB', 'Error')
    })
}

function addProductTo(type) {
    closeBarcodeScanner()
    searchMode.value = type
    showBarcodeScanner.value= true
    clearProductUpc()
    barcodeScannerKey.value++
}

function clickSearchProduct(type) {
    searchMode.value = type
    showBarcodeScanner.value = false
    searchProduct()
}

function searchProduct() {
    let upc = product_upc.value[searchMode.value]
    if (searchMode.value && upc) {
        if (!checkIfProductExists(upc)) {
            getProductByUpc(upc)
        } else {
            ElMessageBox.alert('Product is already added', 'Already added')
        }
    }
}
function checkIfProductExists(upc) {
    let exists = false
    let gtin14 = upc.padStart(14, '0')
    form.value.sources.forEach(product => {
        if (product.upc === upc || product.gtinFormats.gtin14 === gtin14) {
            exists = true
        }
    })
    form.value.targets.forEach(product => {
        if (product.upc === upc || product.gtinFormats.gtin14 === gtin14) {
            exists = true
        }
    })
    return exists
}

function clearProductUpc() {
    product_upc.value = {
        source: '',
        target: ''
    }
}
function detectedBarcode(scanResult) {
    var barcode = scanResult.barcodes.reduce((string, barcode) => barcode.data, '')
    product_upc.value[searchMode.value] = barcode
    showBarcodeScanner.value = false
    searchProduct()
}

function closeBarcodeScanner() {
    showBarcodeScanner.value = false
    // try {
    //     barcodeScannerSource.value.stopScanner()
    //     barcodeScannerTarget.value.stopScanner()
    // } catch (e) {
    //     console.log(e)
    // }

}

function removeProductFrom(type, product) {
    savedChanges.value = false
    if (type === 'source') {
        form.value.sources = form.value.sources.filter(item => item.upc !== product.upc)
    } else {
        form.value.targets = form.value.targets.filter(item => item.upc !== product.upc)
    }
}

function saveProductMapping() {
    loading.value = true
    productMappingApi.saveProductMapping(form.value)
        .then((response) => {
            // this.form = response.data
            form.value = response.data
            savedChanges.value = true
            // router.push({name: 'product-mapping.index'})
        })
        .catch((error) => {
            console.log(error)
        }).
        finally(() => {
            loading.value = false
        })
}

function photoUploaded(newProduct) {
    form.value.sources.forEach((product,index) => {
        if (product.upc === newProduct.upc) {
            form.value.sources[index] = newProduct
        }
    })
    form.value.targets.forEach((product,index) => {
        if (product.upc === newProduct.upc) {
            form.value.targets[index] = newProduct
        }
    })
    ElMessage({
        message: 'Image has been associated with this product',
        type: 'success'
    })
    photoKey.value++
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.product-mapping {
    $block-name: &;
    &__row {
        &--border-bottom {
            margin-bottom: 20px;
            border-bottom: 1px solid darken($white-second, 5%);
            padding-bottom: 20px;
        }
    }
    &__col {
        padding: 0 10px;
        &-auto {
            flex: 0 0 auto;
        }
        &--border-left {
            border-right: 1px solid darken($white-second, 5%);
        }
        &--buttons {
            button {
                margin: 10px 0;
                &:not(:last-child) {
                    margin: 10px 10px 10px 0;
                }
            }
        }
    }
    &__barcode-scanner {
        border-top: 1px solid darken($white-second, 5%);
        padding: 10px 0 0;
        text-align: right;
        &-close {
            margin-bottom: 10px;
        }
    }
    &__list {
        margin: 10px 0 0;
        border-top: 1px solid darken($white-second, 5%);
        padding: 0;
        list-style: none;
        &-item {
            padding: 10px 0;
            &:not(:first-child) {
                border-top: 1px solid darken($white-second, 5%);
            }
        }
    }
}

.product-section {
    &__product {
        margin-bottom: 10px;
        &-remove {
            margin-bottom: 10px;
        }
    }
}
</style>
