<template>
    <section
        id="company-form"
        class="max-width-600-tablet-desktop"
    >
        <el-form
            ref="formRef"
            :model="form"
            label-width="200px"
            :rules="rules"
            @submit.prevent="saveSubmit"
        >
            <el-form-item
                label="Client Level name"
                prop="client_level_id"
                :error="errors.get('client_level_id')"
            >
                <el-select
                    v-model="form.client_level_id"
                    class="w-100"
                    placeholder="Select type"
                    disabled="true"
                >
                    <el-option
                        v-for="level in clientLevels"
                        :key="level._id"
                        :label="level.name"
                        :value="level._id"
                    />
                </el-select>
            </el-form-item>

            <el-form-item
                label="Logo"
                prop="logo"
                style="margin-bottom: 40px"
                :error="errors.get('logo')"
            >
                <el-upload
                    class="image-placeholder"
                    action="''"
                    :show-file-list="false"
                    :auto-upload="false"
                    accept="image/svg"
                    :on-change="beforeLogoUpload"
                >
                    <img
                        v-if="form.logo_url"
                        :src="form.logo_url"
                        alt="image"
                        height="100"
                    >
                    <el-button
                        v-else
                        class="btn-image"
                        type="primary"
                        icon="plus"
                        plain
                    >
                        Add icon
                    </el-button>
                </el-upload>
                <div class="image-tip">
                    svg files with a size less than 100kb
                </div>
            </el-form-item>

            <div class="favicons">
                <el-form-item
                    class="favicons__left"
                    label="Favicon"
                    prop="favicon"
                    style="margin-bottom: 40px"
                    :error="errors.get('favicon')"
                >
                    <el-upload
                        class="image-placeholder"
                        action="''"
                        :show-file-list="false"
                        :auto-upload="false"
                        accept="image/png"
                        :on-change="beforeFaviconUpload"
                    >
                        <img
                            v-if="form.favicon_url"
                            :src="form.favicon_url"
                            alt="image"
                            height="100"
                        >
                        <el-button
                            v-else
                            class="btn-image"
                            type="primary"
                            icon="plus"
                            plain
                        >
                            Add icon
                        </el-button>
                    </el-upload>
                    <div class="image-tip">
                        png files with a size less than 100kb
                    </div>
                </el-form-item>

                <el-form-item
                    class="favicons__right"
                    prop="favicon_svg"
                    :error="errors.get('favicon_svg')"
                >
                    <el-upload
                        class="image-placeholder test_class"
                        action="''"
                        :show-file-list="false"
                        :auto-upload="false"
                        accept="image/svg"
                        :on-change="beforeFaviconSVGUpload"
                    >
                        <img
                            v-if="form.favicon_svg_url"
                            :src="form.favicon_svg_url"
                            alt="image"
                            height="100"
                        >
                        <el-button
                            v-else
                            class="btn-image"
                            type="primary"
                            icon="plus"
                            plain
                        >
                            Add icon
                        </el-button>
                    </el-upload>
                    <div class="image-tip">
                        svg files with a size less than 100kb
                    </div>
                </el-form-item>
            </div>

            <el-form-item
                label="Pause"
                prop="Pause"
                :error="errors.get('pause')"
            >
                <el-checkbox
                    v-model="form.pause"
                ></el-checkbox>
            </el-form-item>

        </el-form>
        <div>
            <el-button @click="cancel">
                Cancel
            </el-button>
            <el-button
                :loading="formLoading"
                type="success"
                @click="saveSubmit"
            >
                Save
            </el-button>
        </div>
    </section>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import {ElMessage} from 'element-plus'
import {Errors} from '~/includes/Errors'
import whiteLabelApi from '../whiteLabelApi'

const emit = defineEmits()

const props = defineProps({
    initialForm: {
        type: Object,
        default: {}
    },
    clientLevels: {
        type: Array,
        default: []
    },
})

const errors = new Errors()
const formLoading = ref(false)
const form = ref({name: ''})
const rules = {
    client_level_id: [
        { required: true, message: 'Client Level is required' },
    ],
}

onMounted(() => form.value = Object.assign({}, props.initialForm))

function beforeLogoUpload(file) {
    if (fileIsTooBig(file)) {
        return false
    }

    form.value.logo_url = URL.createObjectURL(file.raw)
    form.value.logo = file.raw
    return true
}

function beforeFaviconUpload(file) {
    if (fileIsTooBig(file)) {
        return false
    }

    form.value.favicon_url = URL.createObjectURL(file.raw)
    form.value.favicon = file.raw
    return true
}
function beforeFaviconSVGUpload(file) {
    if (fileIsTooBig(file)) {
        return false
    }

    form.value.favicon_svg_url = URL.createObjectURL(file.raw)
    form.value.favicon_svg = file.raw
    return true
}

function fileIsTooBig(file, sizeMb = 100) {
    if (file.size / 1024 / 1024 / 1024 > sizeMb) {
        ElMessage.error('This file should be less then ' + sizeMb + ' Kb')
        return true
    }
    return false
}

const handleRemove = (isLogo = true) => {
    if (isLogo) {
        form.value.logo = []
    } else {
        form.value.favicon = []
    }
}

watch(
    () => props.initialForm,
    val => form.value = Object.assign({}, val),
    { deep: true }
)

const formRef = ref()

function saveSubmit() {
    formRef.value.validate((valid) => {
        if (valid) {
            formLoading.value = true
            errors.clear()

            whiteLabelApi.save(prepareFormData()).then((response) => {
                ElMessage.success( 'Saved successfully')
                emit('saved')
            }).catch(error => {
                if (error.response.data.errors) errors.record(error.response.data.errors)
            }).finally(() => formLoading.value = false)
        }
    })
}

function prepareFormData() {
    let formData = new FormData()
    for (let field in form.value) {
        if (form.value[field] === null) continue
        if (typeof form.value[field] === 'boolean') {
            formData.set(field, form.value[field] ? 1 : 0)
        } else if (form.value[field] instanceof File) {
            formData.set(field, form.value[field])
        } else if (form.value[field] instanceof Object) {
            if (field !== 'favicon') {
                formData.set(field, JSON.stringify(form[field]))
            }
        } else {
            formData.append(field, form.value[field])
        }
    }

    if (_.isString(form.value['logo'])) {
        formData.delete('logo')
    }

    if (_.isString(form.value['favicon'])) {
        formData.delete('favicon')
    }

    return formData
}

function cancel() {
    clearForm()
    emit('cancel-form')
}

function clearForm() {
    errors.clear()
    if (formRef.value) formRef.value.resetFields()
}

function updateDepartmentIds(department_ids) {
    form.value.department_ids = department_ids
}
</script>

<style lang="scss" scoped>
.upload-logo {
    width: 100% !important;
}

.image-tip {
    margin-top: -15px;
    font-size: 12px;
    color: var(--el-text-color-regular);
    width: 100%;
}

.btn-image {
    margin-bottom: 15px;
}

.favicons {
    display: flex;
    &__left {
        max-height: 128px;
        height: 100%;
    }

    &__right {
        max-height: 128px;
        margin-left: -170px;
        height: 100%;
    }

    .image-tip {
        width: 200px !important;
    }

    @media (max-width: 768px) {
        display: unset;

        &__right {
            margin-left: 0;
        }
    }
}

</style>
