<template>
    <el-row v-if="product&&product.categorizationNielsenIq.length!=0">
        <el-col
                :xs="12"
                :sm="12"
                :md="5"
            >
                <el-form-item>
                    <el-cascader
                        v-model="filters.pmo_category"
                        :options="pmoCategories"
                        clearable
                        class="w-100"
                        placeholder="Select PMO category"
                    />
                </el-form-item>
            </el-col>
            <el-col
                :xs="12"
                :sm="12"
                :md="5"
            >
                <el-form-item>
                    <el-select
                        v-model="filters.store"
                        filterable
                        remote
                        collapse-tags
                        collapse-tags-tooltip
                        :remote-method="loadStores"
                        :loading="loadingStores"
                        class="w-100"
                        placeholder="All Stores"
                        popper-class="el-popper--stores"
                        fit-input-width
                    >
                        <el-option
                            v-for="item in stores"
                            :key="item.uid"
                            :label="item.banner"
                            :value="item.uid"
                        >
                            <span class="el-select-dropdown__item-name">
                                {{ item.banner }} - {{ item.name }}
                            </span>
                            <span class="el-select-dropdown__item-address">
                                {{ item.address }} Zip: {{ item.zip }}
                            </span>
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col
                :xs="12"
                :sm="8"
                :md="5"
            >
                <el-popover 
                    placement="right" 
                    :width="400" 
                    :visible="showSugarSubstitute"
                >
                    <template #reference>
                        <el-button style="margin-right: 16px" @click="clickShowSugarSubstitute">Sugar Substitutes <el-icon><ArrowRightBold /></el-icon></el-button>
                    </template>
                    <template #default>
                        <el-radio-group
                            v-model="acceptSugarSubstitutes"
                            class="el-radio-group--column"
                        >
                            <div class="el-radio-group__item el-radio-group__item--inner-content">
                                <el-radio label="accept">
                                    <strong>Yes, I will accept sugar substitutes.</strong>
                                </el-radio>
                                <div class="el-radio-group__item-checkbox-group">
                                    <el-checkbox-group
                                        v-model="sugarSubstitutesOptions"
                                        class="el-checkbox-group--column el-checkbox-group--column-middle-offset-bottom"
                                    >
                                        <template
                                            v-for="option in options"
                                            :key="option.label"
                                        >
                                            <el-checkbox
                                                v-if="option.parent === 'accept'"
                                                :label="option.label"
                                                :disabled="disableCheckboxes"
                                            >
                                                <strong>{{ option.label }}</strong>
                                                <div class="el-checkbox__label-sub-text">
                                                    {{ option.text }}
                                                </div>
                                            </el-checkbox>
                                        </template>
                                    </el-checkbox-group>
                                </div>
                            </div>

                            <div class="el-radio-group__item">
                                <el-radio
                                    label="not_accept"
                                    class="el-radio--align-top"
                                >
                                    <strong>No thanks!</strong>
                                    <div class="el-radio__label-sub-text">
                                        Sugar, honey, fructose... only 
                                    </div>
                                </el-radio>
                            </div>
                        </el-radio-group>
                        <div style="text-align: right; margin: 0">
                            <el-button text @click="cancelSugarSubstitutes">Cancel</el-button>
                            <el-button type="primary" @click="confirmSugarSubstitutes">
                                Apply
                            </el-button>
                        </div>
                    </template>
                </el-popover>
            </el-col>
    </el-row>
    <div v-else>
        <p>Product is not categorized</p>
    </div>

    <ul v-if="errorMessage==null" :loading="altLoading">
        <li v-for="alternativeProduct in alternativeProducts" :key="alternativeProduct.upc">
            <productBlock 
                :product="alternativeProduct"
                :short="true"
            />
        </li>
    </ul>
    <div v-else>
        <p>{{errorMessage}}</p>
    </div>
</template>

<script setup>
import {ref, onMounted, computed, watch} from 'vue'
import liProductsApi from '../liProductsApi'
import pmoApi from '~/modules/pmo/pmoApi'
import storeApi from '~/modules/store/storeApi'
import productBlock from './ProductBlock';
import _ from 'lodash';

const props = defineProps({
  product: Object
});

const product = ref(props.product);
const alternativeProducts = ref([]);
const errorMessage = ref(null);
const altLoading = ref(false);

const pmoCategory = ref(null);
// const pmoCategories = ref([]);

const storeUid = ref(null);
const stores = ref([]);
const loadingStores = ref(false)

const options = [
    {
        'label': 'Herbal extracts',
        'text': 'Stevia',
        'parent': 'accept'
    },
    {
        'label': 'Plant based sweeteners',
        'text': 'Monkfruit...',
        'parent': 'accept'
    },
    {
        'label': 'Sugar alcohols from plants',
        'text': 'Xylitol, erythritol, ...',
        'parent': 'accept'
    },
    {
        'label': 'Synthetic sweeteners',
        'text': 'Aspartame, ...',
        'parent': 'accept'
    },
    {
        'label': 'No thanks!',
        'text': 'Sugar, honey, fructose... only',
        'parent': 'not_accept'
    }
]

const acceptSugarSubstitutes = ref('accept');
const sugarSubstitutesOptions = ref(options.map(option => { return option.label }));
const disableCheckboxes = ref(false);
const showSugarSubstitute = ref(false);

const filters = ref({
    pmo_category: null,
    store: null,
    acceptSugarSubstitutes: acceptSugarSubstitutes.value,
    sugarSubstitutesOptions: sugarSubstitutesOptions.value
});

const pmoLoading = ref(false)
const types = ref(null)

onMounted(() => fetchData())

async function fetchData() {
    pmoLoading.value = true
    const {data} = await pmoApi.all()
    types.value = data
    pmoLoading.value = false
}

const pmoCategories = computed(() => _.map(types.value, ((t, i) => ({
    value: i,
    label: t[0].type_name,
    children: t.map(c => ({
        value: c.slug,
        label: c.title,
    }))
}))))

async function loadStores(query) {
    if (query) {
        loadingStores.value = true
        const {data} = await storeApi.fetchStores(query)
        stores.value = data
        loadingStores.value = false
    }
}

onMounted(() => {
    fetchAlternativeProducts();
});

watch(filters, () => {
    fetchAlternativeProducts();
}, {deep: true});

watch(acceptSugarSubstitutes, (value) => {
    disableCheckboxes.value = value == 'not_accept';
    filters.value.sugarSubstitutesOptions =  sugarSubstitutesOptions.value;
});

async function fetchAlternativeProducts() {

    altLoading.value = true;

    if (product.value&&(!product.value.categorizationNielsenIq||product.value.categorizationNielsenIq.length==0)) {
        return;
    }

    let params = {
        pmo_category: (filters.value.pmo_category&&filters.value.pmo_category.length > 1)? filters.value.pmo_category[1] : null,
        store_id: filters.value.store,
        acceptSugarSubstitutes: filters.value.acceptSugarSubstitutes,
        sugarSubstitutesOptions: filters.value.sugarSubstitutesOptions
    }

    params = _.omitBy(params, _.isNil);

    errorMessage.value = null;

    const response = await liProductsApi.getAlternatives(product.value.upc, params);

    altLoading.value = false;

    if(response.data.original&&response.data.original.error) {
        console.log(response.data.original.error);
        errorMessage.value = response.data.original.error;
        return;
    }
    alternativeProducts.value = response.data.alternatives||[];
}

function cancelSugarSubstitutes() {
    showSugarSubstitute.value = false;
}

function confirmSugarSubstitutes() {
    filters.value.acceptSugarSubstitutes = acceptSugarSubstitutes.value;
    filters.value.sugarSubstitutesOptions = sugarSubstitutesOptions.value;
    showSugarSubstitute.value = false;
}

function clickShowSugarSubstitute() {
    showSugarSubstitute.value = !showSugarSubstitute.value;
}
</script>