<template>
    <el-form-item
        prop="action"
        :label="actionLabel"
        :error="errors.get(getNameWithActionIndex('action'))"
    >
        <el-select
            v-model="action"
            class="w-100"
            placeholder="Select action"
        >
            <el-option
                v-for="item in actionList"
                :key="item.value"
                :label="item.text"
                :value="item.value"
            />
        </el-select>
    </el-form-item>
    <el-form-item
        v-if="showDurationList"
        prop="additional_data"
        label="During the period"
        :error="errors.get(getNameWithActionIndex('additional_data'))"
    >
        <el-select
            v-model="additional_data"
            class="w-100"
            placeholder="Select period"
        >
            <el-option
                v-for="item in list.periods"
                :key="item"
                :label="item"
                :value="item"
            />
        </el-select>
    </el-form-item>
    <el-form-item
        v-if="showLearningModules || showLessons"
        label="Client Level specific"
    >
        <el-checkbox
            v-model="isLMByEachClientLevel"
        />
    </el-form-item>
    <el-form-item
        v-if="isLMByEachClientLevel"
        label="Client Levels"
    >
        <el-select
            v-model="selectedClientLevel"
            class="w-100"
            placeholder="Select Client Level "
        >
            <el-option
                v-for="level in clientLevels"
                :key="level._id"
                :label="level.name"
                :value="level._id"
            />
        </el-select>
    </el-form-item>
    <el-form-item
        v-if="showLearningModules"
        label="Learning modules"
        prop="additional_data"
        :error="errors.get(getNameWithActionIndex('additional_data'))"
    >
        <el-select
            v-model="additional_data"
            class="w-100"
            placeholder="Select Learning modules "
        >
            <el-option
                v-for="item in learning_modules"
                :key="item.value"
                :label="item.text"
                :value="item.value"
            />
        </el-select>
    </el-form-item>
    <el-form-item
        v-if="showLessons"
        label="Lesson"
        prop="additional_data"
        :error="errors.get('additional_data')"
    >
        <el-select
            v-model="additional_data"
            class="w-100"
            placeholder="Select lesson"
        >
            <el-option
                v-for="item in lessons"
                :key="item.value"
                :label="item.text"
                :value="item.value"
            />
        </el-select>
    </el-form-item>
    <el-form-item
        v-if="showCategories"
        label="Category"
        prop="additional_data"
        :error="errors.get(getNameWithActionIndex('additional_data'))"
    >
        <el-select
            v-model="additional_data"
            class="w-100"
            placeholder="Select category"
        >
            <el-option
                v-for="item in list.categories"
                :key="item.value"
                :label="item.text"
                :value="item.value"
            />
        </el-select>
    </el-form-item>
    <el-form-item
        v-if="showSubCategories"
        label="Category"
        prop="additional_data"
        :error="errors.get(getNameWithActionIndex('additional_data'))"
    >
        <el-select
            v-model="additional_data"
            class="w-100"
            placeholder="Select sub category"
        >
            <el-option
                v-for="item in subcategories"
                :key="item.value"
                :label="item.text"
                :value="item.value"
            />
        </el-select>
    </el-form-item>
    <el-form-item
        v-if="showGames"
        label="Games"
        prop="additional_data"
        :error="errors.get(getNameWithActionIndex('additional_data'))"
    >
        <el-select
            v-model="additional_data"
            class="w-100"
            placeholder="Select game"
        >
            <el-option
                v-for="item in games"
                :key="item.value"
                :label="item.text"
                :value="item.value"
            />
        </el-select>
    </el-form-item>
</template>

<script setup>
import {ref, computed, onMounted, inject, watch} from 'vue'
import * as ACTIONS from '../../actions.constants'
import {useModelWrapper} from '~/includes/composable/modelWrapper'
import notificationApi from '~/modules/notification/notificationApi'
import lmApi from '~/modules/learning_module/lmApi'
import clientLevelsApi from "~/modules/client_levels/clientLevelsApi";

const emit = defineEmits()

const props = defineProps({
    action: {
        type: Object,
        required: true
    },
    additionalData: {
        type: String,
        required: true
    },
    selectedClientLevel: {
        type: String,
        required: true
    },
    errors: {
        type: Object,
        required: true
    },
    actionLabel: {
        type: String,
        required: true
    },
    actionList: {
        type: Array,
        required: true
    },
    actionIndex: {
        type: String,
        required: true
    },
})

const action = useModelWrapper(props, emit, 'action')
const additional_data = useModelWrapper(props, emit, 'additionalData')
const isLMByEachClientLevel = ref(false)
const isUpdatingClientLevel = ref(false)
const selectedClientLevel = useModelWrapper(props, emit, 'selectedClientLevel')
const clientLevels = ref([])

const list = inject('list')

const showDurationList = computed(() =>
    [
        ACTIONS.INVITED_NOT_REGISTERED,
        ACTIONS.INVITED_FDM_NOT_REGISTERED,
        ACTIONS.NOT_VISITED_MY_ITEMS,
        ACTIONS.FIRST_RATE_PRODUCT,
    ]
        .includes(action.value)
)

watch(() => action.value,
    val => additional_data.value = null
)

watch(() => isLMByEachClientLevel.value,
    val => {
        if(isUpdatingClientLevel.value) {
            isUpdatingClientLevel.value = false
        } else {
            selectedClientLevel.value = null
        }
    }
)

watch(() => selectedClientLevel.value,
    () => {
        if (showLearningModules.value) {
            learning_modules.value = []
            additional_data.value = null
            getLMList()
        } else if (showLessons.value) {
            additional_data.value = null
            lessons.value = []
            getLessonsList()
        }
    }
)

/*
 Learning modules
 */
const learning_modules = ref(null)

async function getClientLevels() {
    const params = {
        'unlimited': true
    }
    const { data } = await clientLevelsApi.all(params)
    clientLevels.value = data
}

function getLMList() {
    const params = {
        type: 'user',
        client_level_id: selectedClientLevel.value,
    }

    lmApi.fetchLMList(params).then(response => {
        learning_modules.value = _.map(response.data, (o, i) => ({value: i, text: o,}))
    })
}
const showLearningModules = computed(() =>
    [
        ACTIONS.STARTED_MODULE,
        ACTIONS.FINISHED_MODULE,
        ACTIONS.FINISHED_LEARNING_QUIZ,
    ]
        .includes(action.value)
)
onMounted(() => {
    if (selectedClientLevel.value) {
        isUpdatingClientLevel.value = true
        isLMByEachClientLevel.value = true
    }
    getClientLevels()
    if (showLearningModules.value) {
        getLMList()
    }

})
watch(() => showLearningModules.value,
    val => {
        isLMByEachClientLevel.value = false
        if (val ) { // && !learning_modules.value) {
            getLMList()
        }
    }
)

function getNameWithActionIndex(errorName) {
    //
    return errorName + (props.actionIndex=='1'?'':props.actionIndex)
}

/*
 Lessons
 */
const lessons = ref(null)
function getLessonsList() {
    const params = {
        type: 'user',
        client_level_id: selectedClientLevel.value,
    }

    lmApi.fetchLessonsList(params).then(response => {
        lessons.value = _.map(response.data, (o, i) => ({value: i, text: o,}))
    })
}
const showLessons = computed(() =>
    [
        ACTIONS.STARTED_LESSON,
        ACTIONS.FINISHED_LESSON
    ]
        .includes(action.value)
)
onMounted(() => {if (showLessons.value) getLessonsList()})
watch(() => showLessons.value,
    val => {
        isLMByEachClientLevel.value = false
        if (val && !lessons.value) getLessonsList()
    }
)

/*
 Categories
 */
const subcategories = ref(null)
function getSubcategoriesList() {
    notificationApi.fetchSubcategoriesList().then(response => {
        subcategories.value = _.map(response.data, (o, i) => ({value: i, text: o,}))
    })
}
const showCategories = computed(() =>
    [
        ACTIONS.STARTED_PMO_CATEGORY,
        ACTIONS.FINISHED_PMO_CATEGORY
    ]
        .includes(action.value)
)
const showSubCategories = computed(() => ACTIONS.LEARN_PMO_SUB_CATEGORY === action.value)
onMounted(() => {if (showSubCategories.value) getSubcategoriesList()})
watch(() => showSubCategories.value,
    val => {if (val && !subcategories.value) getSubcategoriesList()}
)

/*
 Games
 */
const games = ref(null)
function getGamesList() {
    notificationApi.fetchGamesList().then(response => {
        games.value = _.map(response.data, (o, i) => ({value: i, text: o,}))
    })
}
const showGames = computed(() => ACTIONS.FINISHED_GAME === action.value)
onMounted(() => {if (showGames.value) getGamesList()})
watch(() => showGames.value,
    val => {if (val && !games.value) getGamesList()}
)

</script>
