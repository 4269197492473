import CompanyIndex from './components/CompanyIndex'
import CompanyEdit from './components/CompanyEdit'

export default [
    // {
    //     path: 'companies',
    //     component: CompanyIndex,
    //     name: 'companies.index',
    //     meta: {
    //         auth: 'companies.view',
    //         pageTitle: 'Companies',
    //     },
    // },
    // {
    //     path: 'companies/add',
    //     component: CompanyEdit,
    //     name: 'companies.add',
    //     meta: {
    //         auth: 'companies.create',
    //         pageTitle: 'Add company',
    //     },
    // },
    // {
    //     path: 'companies/edit/:id',
    //     component: CompanyEdit,
    //     name: 'companies.edit',
    //     meta: {
    //         auth: 'companies.edit',
    //         pageTitle: 'Edit company',
    //     },
    // },
]
