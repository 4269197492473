import axios from 'axios'

const API_ENDPOINT = window.config.ssURL + 'white-label'

export default {
  all(params) {
    return axios.get(API_ENDPOINT + '', { params })
  },
  get(id) {
    return axios.get(API_ENDPOINT + '/' + id)
  },
  save(payload) {
    return axios.post(API_ENDPOINT + '/store', payload)
  },
  destroy(id) {
    return axios.post(API_ENDPOINT + '/delete/' + id)
  }
}
