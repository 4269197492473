<template>
    <section
        id="reference-form"
        class="page-section max-width-600-tablet-desktop"
    >
        <el-tabs
            type="border-card"
            v-model="tabValue"
        >
            <el-tab-pane
                label="True/False"
                name="bool"
                :disabled="isDisabledTabs"
            >
                <el-form
                    ref="formBoolRef"
                    :model="form_bool"
                    label-width="100px"
                    :validate-on-rule-change="false"
                    @submit.prevent="saveSubmit"
                >
                    <el-form-item
                        label="Question"
                        prop="question"
                        :rules="[{
                                required: true,
                                message: 'Question is required',
                            },
                            {
                                min: 5,
                                message: 'Minimum 5 characters'
                            }]"
                    >
                        <el-input
                            v-model="form_bool.question"
                            suffix-icon="edit-pen"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="answer"
                        :rules="{
                                required: true,
                                message: 'Answer is required',
                            }"
                    >
                        <el-radio-group
                            v-model="form_bool.answer"
                        >
                            <el-radio label="true" :value="true"/>
                            <el-radio label="false" :value="false"/>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item
                        label="Statement"
                        prop="statement"
                        :rules="{
                                min: 5,
                                message: 'Minimum 5 characters'
                            }"
                    >
                        <el-input
                            v-model="form_bool.statement"
                            suffix-icon="edit-pen"
                        />
                    </el-form-item>
                    <el-divider/>

                    <div v-for="(item, index) in form_bool.follow_ups" :key="index">
                        <div class="delete_button">
                            <el-button
                                type="danger"
                                :icon="Delete"
                                circle
                                @click="deleteFollowUp(index)"
                            />
                        </div>
                        <follow-up
                            :item="item"
                            :index="index"
                            :tab="tabValue"
                            @update="inputFollowUp"
                        />
                        <el-divider/>
                    </div>

                    <div class="follow-button">
                        <el-button
                            type="primary"
                            icon="plus"
                            @click="addFollowUp"
                        >
                            Add Follow-Up
                        </el-button>
                    </div>

                </el-form>
                <div class="submit-buttons">
                    <el-button
                        @click="cancel"
                    >
                        Cancel
                    </el-button>
                    <el-button
                        :loading="formLoading"
                        type="success"
                        @click="saveSubmit"
                    >
                        Save
                    </el-button>
                </div>
            </el-tab-pane>
            <el-tab-pane
                label="Multi-Choice: Single Select"
                name="multi"
                :disabled="isDisabledTabs"
            >
                <el-form
                    ref="formMultiRef"
                    :model="form_multi"
                    label-width="100px"
                    @submit.prevent="saveSubmit"
                >
                    <el-form-item
                        label="Question"
                        prop="question"
                        :rules="[{
                                required: true,
                                message: 'Question is required',
                            },
                            {
                                min: 5,
                                message: 'Minimum 5 characters'
                            }]"
                    >
                        <el-input
                            v-model="form_multi.question"
                            suffix-icon="edit-pen"
                        />
                    </el-form-item>

                    <div
                        v-if="form_multi.answers"
                        v-for="(item, index) in form_multi.answers"
                        :key="index"
                    >
                        <multi-choice-answer
                            :item="item"
                            :index="index"
                            :answers-length="form_multi.answers.length"
                            :validation-prop="'answers'"
                            :is-choice="form_multi.answers.some(item => item.correct)"
                            @update="updateAnswers"
                            @delete="deleteMultiAnswer"
                        />
                    </div>
                    <div
                        v-if="form_multi.answers && !isValidAnswers && !form_multi.answers.some(item => item.correct)"
                        class="error-text"
                    >Please indicate the correct answer
                    </div>

                    <div class="follow-button">
                        <el-button
                            type="primary"
                            icon="plus"
                            @click="addAnswer"
                        >
                            Add Answer
                        </el-button>
                    </div>

                    <el-form-item
                        label="Statement"
                        prop="statement"
                        :rules="{
                                min: 5,
                                message: 'Minimum 5 characters'
                            }"
                    >
                        <el-input
                            v-model="form_multi.statement"
                            suffix-icon="edit-pen"
                        />
                    </el-form-item>
                    <el-divider/>

                    <div v-for="(item, index) in form_multi.follow_ups" :key="index">
                        <div class="delete_button">
                            <el-button
                                type="danger"
                                :icon="Delete"
                                circle
                                @click="deleteFollowUp(index)"
                            />
                        </div>
                        <follow-up
                            :item="item"
                            :index="index"
                            :tab="tabValue"
                            :is-valid-answers="isValidFollowAnswers"
                            @update="inputFollowUp"
                        />
                        <el-divider/>
                    </div>

                    <div class="follow-button">
                        <el-button
                            type="primary"
                            icon="plus"
                            @click="addFollowUp"
                        >
                            Add Follow-Up
                        </el-button>
                    </div>

                </el-form>
                <div>
                    <el-button
                        @click="cancel"
                    >
                        Cancel
                    </el-button>
                    <el-button
                        :loading="formLoading"
                        type="success"
                        @click="saveSubmit"
                    >
                        Save
                    </el-button>
                </div>
            </el-tab-pane>
        </el-tabs>

    </section>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { Delete } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import questionApi from '../../questionApi'
import followUp from './FollowUp'
import MultiChoiceAnswer from './MultiChoiceAnswer'
import lessonApi from "~/modules/learning_module/lessonApi";

const emit = defineEmits()

const props = defineProps({
    initialForm: {
        type: Object,
        default: {}
    },
})

const formLoading = ref(false)
const form_bool = ref({})
const isDisabledTabs = ref(false)
const form_multi = ref({})
const tabValue = ref('bool')
const isValidAnswers = ref(true)
const isValidFollowAnswers = ref(true)

watch(
    () => props.initialForm,
    val => {
        formatEditData()
        form_bool.value = Object.assign( form_bool.value, val)
        form_multi.value = Object.assign(form_multi.value, val)
    },
    { deep: true }
)

const formBoolRef = ref()
const formMultiRef = ref()

function addFollowUp() {
    if (tabValue.value === 'bool') {
        if (!form_bool.value.follow_ups) {
            form_bool.value.follow_ups = []
        }
        form_bool.value.follow_ups.push({})
    } else if (tabValue.value === 'multi') {
        if (!form_multi.value.follow_ups) {
            form_multi.value.follow_ups = []
        }
        form_multi.value.follow_ups.push({ answers: [{}] })
    }
}

function addAnswer() {
    if (!form_multi.value.answers) {
        form_multi.value.answers = []
    }
    form_multi.value.answers.push({})
}

function deleteFollowUp(index) {
    ElMessageBox.confirm('This will permanently delete the question. Continue?', 'Warning', {
        type: 'warning'
    }).then(() => {
        if (tabValue.value === 'multi') {
            form_multi.value.follow_ups.splice(index, 1)
        } else if (tabValue.value === 'bool') {
            form_bool.value.follow_ups.splice(index, 1)
        }
    })
}

const formatEditData = () => {
    if (props.initialForm.id && props.initialForm.type === 'boolean') {
        tabValue.value = 'bool'
        isDisabledTabs.value = true
    } else if(props.initialForm && props.initialForm.id && props.initialForm.type === 'multi_choice') {
        tabValue.value = 'multi'
        isDisabledTabs.value = true
    }
}

const inputFollowUp = (index, data) => {
    if (tabValue.value === 'multi') {
        form_multi.value.follow_ups[index] = data
    } else if (tabValue.value === 'bool') {
        form_bool.value.follow_ups[index] = data
    }
}

const updateAnswers = (index, data) => {
    form_multi.value.answers[index] = data
}

const deleteMultiAnswer = (index) => {
    ElMessageBox.confirm('This will permanently delete the answer. Continue?', 'Warning', {
        type: 'warning'
    }).then(() => {
        form_multi.value.answers.splice(index, 1)
    })
}

const formatTrueFalseRequest = () => {
    const trueFalseAnswers = [
        { answer: 'true', correct: false },
        { answer: 'false', correct: false }
    ]
    form_bool.value.type = 'boolean'
    form_bool.value.answers = _.cloneDeep(trueFalseAnswers).map(answer => {
        answer.correct = answer.answer == form_bool.value.answer
        return answer
    })

    if (form_bool.value.follow_ups) {
        form_bool.value.follow_ups = form_bool.value.follow_ups.map(question => {
            question.answers = _.cloneDeep(trueFalseAnswers).map(answer => {
                answer.correct = answer.answer == question.answer
                return answer
            })
            return question
        })
    }
}


const formatMultiRequest = () => {
    form_multi.value.type = 'multi_choice'
    form_multi.value.answers = form_multi.value.answers.map(answer => {
        answer.correct = !!answer.correct
        return answer
    })
    if (form_multi.value.follow_ups) {
        form_multi.value.follow_ups = form_multi.value.follow_ups.map(question => {
            question.answers = question.answers.map(answer => {
                answer.correct = !!answer.correct
                return answer
            })
            return question
        })
    }
}

function saveSubmit() {
    if (tabValue.value === 'multi') {
        isValidAnswers.value = form_multi.value.answers.some(item => !!item.correct)
        isValidFollowAnswers.value = !form_multi.value.follow_ups
        if (form_multi.value.follow_ups) {
            isValidFollowAnswers.value = !form_multi.value.follow_ups.some(follow_up => !follow_up.answers.some(item => item.correct))
        }

        if (!(isValidAnswers.value && isValidFollowAnswers.value)) {
            return false
        }
        formMultiRef.value.validate((valid) => {
            if (valid) {
                formLoading.value = true
                formatMultiRequest()

                questionApi.store(form_multi.value).then((response) => {
                    ElMessage.success( 'Saved successfully')
                    emit('saved')
                }).catch(error => {
                    if (error.response.data.errors) errors.record(error.response.data.errors)
                }).finally(() => formLoading.value = false)
            }
        })
    } else {
        formBoolRef.value.validate((valid) => {
            if (valid) {
                formLoading.value = true
                formatTrueFalseRequest()
                questionApi.store(form_bool.value).then((response) => {
                    ElMessage.success( 'Saved successfully')
                    emit('saved')
                }).catch(error => {
                    if (error.response.data.errors) errors.record(error.response.data.errors)
                }).finally(() => formLoading.value = false)
            }
        })
    }
}

function cancel() {
    clearForm()
    emit('cancel')
}

function clearForm() {
    if (formBoolRef.value) formBoolRef.value.resetFields()
    if (formMultiRef.value) formMultiRef.value.resetFields()
}
</script>

<style scoped>
.submit-buttons {
    margin-top: 15px;
}

.follow-button {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.delete_button {
    display: flex;
    justify-content: right;
    margin: -20px 5px 5px;
}

.error-text {
    color: var(--el-color-danger);
    font-size: 12px;
    line-height: 1;
    padding-bottom: 15px;
    margin-left: 100px;
}
</style>
