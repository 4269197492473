<template>
    <div class="page-breadcrumbs">
        <page-breadcrumbs :list="breadcrumbs" />
    </div>
    <h2 class="page-title">
        {{ actionName }} Search Words
    </h2>
    <div class="page-section">
        <search-words-form
            v-loading="loading"
            :initial-form="formData"
            @saved="router.push({name: 'search-words.index'})"
            @cancel="router.push({name: 'search-words.index'})"
        />
    </div>
</template>

<script setup>
import {ref, onMounted, computed} from 'vue'
import SearchWordsForm from './SearchWordsForm'
import {useRoute, useRouter} from 'vue-router'
import searchWordsApi from '../searchWordsApi'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'

const route = useRoute()
const router = useRouter()
const formData = ref(null)

const loading = ref(false)

const actionName = computed(() => route.params.id ? 'Edit' : 'Add')
const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: 'Search Words',
        name: 'search-words.index'
    },
    {
        title: `${actionName.value} Search Words`,
    }
])

onMounted(() => fetchData())

async function fetchData() {
    if (route.params.id) {
        loading.value = true
        const {data} = await searchWordsApi.get(route.params.id)
        formData.value = data
        loading.value = false
    }
}
</script>
