<template>
    <section id="invited_user">
        <div class="page-breadcrumbs">
            <page-breadcrumbs :list="breadcrumbs" />
        </div>
        <h2 class="page-title">
            Helpful list
        </h2>

        <!-- table -->
        <el-table
            v-loading="helpfulListLoading"
            :data="helpfulList"
            highlight-current-row
            class="w-100"
            @sort-change="handleSortChange"
            @filter-change="handleFilterChange"
        >
            <el-table-column
                prop="model_type"
                label="Entity"
                min-width="140"
            />
            <el-table-column
                prop="title"
                label="Title"
                min-width="200"
            />
            <el-table-column
                prop="tips"
                label="Tips"
                min-width="200"
                show-overflow-tooltip
            />
            <el-table-column
                :sort-orders="sortOrders"
                sortable
                prop="positive"
                label="Positive"
                min-width="120"
            />
            <el-table-column
                :sort-orders="sortOrders"
                sortable
                prop="negative"
                label="Negative"
                min-width="120"
            />
        </el-table>

        <!-- pagination -->
        <el-pagination
            v-model:current-page="page"
            :page-size="per_page"
            :total="total"
            hide-on-single-page
            layout="prev, pager, next, ->, total"
            class="el-pagination--align-right"
            @current-change="fetchData"
            @size-change="fetchData"
        />
    </section>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue'
import helpfulApi from '../helpfulApi'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'

const helpfulListLoading = ref(false)
const helpfulList = ref(null)
const total = ref(0)
const filters = ref({})
const page = ref(1)
const per_page = ref(10)
const sortBy = ref('id')
const sortDesc = ref(false)
const sortOrders = ref(['ascending', 'descending'])
const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: 'Helpful list',
    }
])

onMounted(() => fetchData())

async function fetchData() {
    helpfulListLoading.value = true
    let params = {
        page: page.value,
        sortBy: sortBy.value,
        sortDesc: sortDesc.value,
        ...filters.value
    }
    const {data} = await helpfulApi.all(params)
    helpfulList.value = data.data
    total.value = data.total
    per_page.value = data.per_page
    helpfulListLoading.value = false
}

const applySearch = _.debounce( function() {
    fetchData()
}, 300)

function handleSortChange(val) {
    if (val.prop != null && val.order != null) {
        sortDesc.value = val.order.startsWith('a')
        sortBy.value = val.prop
        fetchData()
    }
}
function handleFilterChange() {
    fetchData()
}

</script>
