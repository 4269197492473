<template>
    <section id="search-words">
        <div class="page-breadcrumbs">
            <page-breadcrumbs :list="breadcrumbs" />
        </div>
        <h2 class="page-title">
            Search Words
        </h2>
        <div
            class="page-section"
        >
            <el-button
                v-if="$auth.check('search_words.create')"
                type="primary"
                icon="plus"
                @click="$router.push({name: 'search-words.add'})"
            >
                Add
            </el-button>
            <import-export-form
                v-if="$auth.check('Admin')"
                @refresh="fetchData"
            />
        </div>

        <div
            v-loading="loading"
            class="page-section"
        >
            <!-- table -->
            <el-table
                :data="searchWordsData"
                highlight-current-row
                class="w-100"
            >
                <el-table-column
                    prop="search_word"
                    label="Search Word"
                    min-width="200"
                />
                <el-table-column
                    prop="keywords"
                    label="Keywords"
                    min-width="200"
                />
                <el-table-column
                    label="Actions"
                    min-width="180"
                    align="right"
                >
                    <template #default="scope">
                        <el-button
                            v-if="$auth.check('search_words.edit')"
                            size="small"
                            icon="edit"
                            @click="$router.push({name: 'search-words.edit', params: {id: scope.row._id}})"
                        >
                            Edit
                        </el-button>
                        <el-button
                            v-if="$auth.check('search_words.delete')"
                            type="danger"
                            size="small"
                            icon="delete"
                            @click="handleDelete(scope.row)"
                        >
                            Delete
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                v-model:current-page="page"
                :page-size="per_page"
                :total="total"
                hide-on-single-page
                layout="prev, pager, next, ->, total"
                class="el-pagination--align-right"
                @current-change="fetchData"
                @size-change="fetchData"
            />
        </div>
    </section>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import searchWordsApi from '../searchWordsApi'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'
import ImportExportForm from './ImportExportForm'

const loading = ref(false)
const searchWordsData = ref(null)
const total = ref(0)
const page = ref(1)
const per_page = ref(10)
const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: 'Search Words',
    },
])

onMounted(() => fetchData())

async function fetchData() {
    loading.value = true
    let params = {
        page: page.value,
    }
    const {data} = await searchWordsApi.all(params)
    searchWordsData.value = data.data
    total.value = data.total
    per_page.value = data.per_page
    loading.value = false
}

function handleDelete(row) {
    ElMessageBox.confirm('This will permanently delete the Search Words. Continue?', 'Warning', {
        type: 'warning'
    }).then(() => {
        searchWordsApi.destroy(row).then((response) => {
            ElMessage.success( 'Deleted successfully')
            fetchData()
        })
    })
}

</script>
