<template>
    <el-form
        :model="filters"
        @submit.prevent
    >
        <el-row :gutter="10">
            <el-col
                :xs="24"
                :sm="12"
                :md="6"
            >
                <el-form-item>
                    <el-input
                        v-model="filters.search"
                        placeholder="Search by user email"
                        class="w-100"
                        clearable
                    />
                </el-form-item>
            </el-col>
            <el-col
                :xs="24"
                :sm="12"
                :md="6"
            >
                <el-form-item>
                    <el-select
                        v-model="filters.frequency"
                        class="w-100"
                        placeholder="Select frequency"
                        clearable
                    >
                        <el-option
                            v-for="frequency in list.frequencies"
                            :key="frequency"
                            :label="frequency"
                            :value="frequency"
                        />
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col
                :xs="24"
                :sm="12"
                :md="6"
            >
                <el-form-item>
                    <el-select
                        v-model="filters.provider"
                        class="w-100"
                        placeholder="Select provider"
                        clearable
                    >
                        <el-option
                            v-for="provider in list.provider"
                            :key="provider"
                            :label="provider"
                            :value="provider"
                        />
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col
                :xs="24"
                :sm="12"
                :md="6"
            >
                <el-form-item>
                    <el-button
                        type="primary"
                        class="w-100"
                        @click="clearSearch"
                    >
                        Reset
                    </el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import { useModelWrapper } from '~/includes/composable/modelWrapper'
import notificationApi from '../notificationApi'

const emit = defineEmits()

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
})

const list = ref({})

onMounted(() => getLists())

async function getLists() {
    const {data} = await notificationApi.list()
    list.value.frequencies = data.frequencies
    list.value.provider = data.provider
}

const filters = useModelWrapper(props, emit, 'modelValue')

function clearSearch() {
    filters.search = ''
    filters.frequency = null
}
</script>

