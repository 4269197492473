<template>

    <!-- <p v-if="categoryTreeSlug">{{ categoryTreeSlug }}</p> -->
    <div class="category-tree-container">
        <el-tree
            ref="treeRef"
            v-loading="loadTree"
            :data="treeData"
            node-key="path"
            :props="treeProps"
            :check-strictly="true"
            :default-expand-all="false"
        >
            <template #default="{ node }">
                <span v-if="isLeaf(node)">
                    <!-- <el-checkbox
                        v-model="node.checked"
                        class="last-child-checkbox"
                        :checked="chosenCategory === node.data"
                        @change="setCategory(node)"
                        
                    /> -->
                    <span
                        v-droppable 
                        @v-drag-drop="onDragDrop"
                        @v-drag-enter="onDragEnter"
                        @v-drag-leave="onDragLeave"
                        :node_data="node"
                        :slug="node.data.path"
                    >{{ node.label }}</span>
                </span>
                <span v-else>
                    <!-- <el-checkbox
                        class="last-child-checkbox"
                        disabled
                    /> -->
                    <span>{{ node.label }}</span>
                </span>
            </template>
        </el-tree>
    </div>

</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import store from '~/store'
import nielseniqApi from '~/modules/pmo/nielseniqApi'

const emit = defineEmits()

const chosenCategory = ref(null)
const loadTree = ref(true)

const treeData = ref([])
const treeRef = ref(null)
const treeProps = {
    children: 'children',
    label: 'name',
    isLeaf: 'isLeaf',
}
const storeTreeData = ref('')

const categoryTreeSlug = ref('')

const disableCheckbox = (node) => {
    return chosenCategory.value && chosenCategory.value.path !== node.data.path
}

const isLeaf = (node) => {
    return node.childNodes.length === 0
}

async function loadNode() {
    const { data } = await nielseniqApi.all()
    treeData.value = data
    loadTree.value = false
}

const reset = () => {
    loadTree.value = true
    treeData.value = []
}

onMounted(async () => {
    if (!treeData.value.length) {
        await loadNode()
    }
})

const setCategory = (node) => {
    if (chosenCategory.value && chosenCategory.value.path) {
        const previousNode = treeRef.value.getNode(chosenCategory.value.path)
        if (previousNode) {
            previousNode.checked = false
        }
        node.checked = true
    } 
    chosenCategory.value = node.data
}

function onDragDrop(draggingNode, dropAllowed, event) {

    event.target.classList.remove('el-tree-node__label_hover')

    let slug = event.target.getAttribute('slug')

    let node = treeRef.value.getNode(slug)

    setCategory(node)

    let categorizeData = convertToCategorizeFormat()
    emit('setCategorize', categorizeData)
    emit('handleDrop', draggingNode, slug)

    return
}

function onDragEnter(draggingNode, dropAllowed, event) {
    event.target.classList.add('el-tree-node__label_hover')

    categoryTreeSlug.value = event.target.getAttribute('slug')
    emit('updateCategoryTreeSlug', categoryTreeSlug.value)
}

function onDragLeave(draggingNode, dropAllowed, event) {
    event.target.classList.remove('el-tree-node__label_hover')

    categoryTreeSlug.value = ''
    emit('updateCategoryTreeSlug', categoryTreeSlug.value)
}

function convertToCategorizeFormat() {
    const categoryData = chosenCategory.value?.path?.split('/')
    const departmentValue = _.cloneDeep(treeData.value)?.find(category => category.slug === categoryData[0]) || ''
    const superCategoryValue = departmentValue?.children?.find(category => category.slug === categoryData[1]) || ''
    const categoryValue = superCategoryValue?.children?.find(category => category.slug === categoryData[2]) || ''
    const subCategoryValue = categoryValue?.children?.find(category => category.slug === categoryData[3]) || ''
    const segmentValue = subCategoryValue?.children?.find(category => category.slug === categoryData[4]) || ''
    return {
        department: departmentValue?.raw_name,
        superCategory: superCategoryValue?.raw_name,
        category: categoryValue?.raw_name,
        subCategory: subCategoryValue?.raw_name,
        segment: segmentValue?.raw_name,
        original_name: chosenCategory.value?.raw_name,
    }
}

</script>

<style  scoped>
.last-child-checkbox {
    margin-right: 10px;
}

</style>
<style lang="scss" >
.el-tree-node__label_hover {
    background-color: #484baf;
    color: #fff;
}

.category-tree-container {
    height: calc(100vh - 1px);
    margin-top: 20px;
    overflow-y: auto;
    padding: 10px;
}
</style>