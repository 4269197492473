<template>
    <header-default />
    <el-container class="home-container">
        <div class="container">
            <child />
        </div>
    </el-container>
</template>

<script setup>
import Child from '~/base/components/Child'
import HeaderDefault from '~/base/components/header-default/HeaderDefault'
</script>
