const UNITS = {
    'teaspoon' : {
        'ml' : 5,
        'cup' : 0.021,
        'g' : 5,
        'oz' : 0.166667,
    },
    't' : {
        'ml' : 5,
        'cup' : 0.021,
        'g' : 5,
        'oz' : 0.166667,
    },
    'tsp' : {
        'ml' : 5,
        'cup' : 0.021,
        'g' : 5,
        'oz' : 0.166667,
    },
    'tablespoon' : {
        'ml' : 15,
        'cup' : 0.0625,
        'g' : 15,
        'oz' : 0.5,
    },
    'T' : {
        'ml' : 15,
        'cup' : 0.0625,
        'g' : 15,
        'oz' : 0.5,
    },
    'tbl' : {
        'ml' : 15,
        'cup' : 0.0625,
        'g' : 15,
        'oz' : 0.5,
    },
    'tbs' : {
        'ml' : 15,
        'cup' : 0.0625,
        'g' : 15,
        'oz' : 0.5,
    },
    'tbsp' : {
        'ml' : 15,
        'cup' : 0.0625,
        'g' : 15,
        'oz' : 0.5,
    },
    'fluid ounce' : {
        'ml' : 30,
        'cup' : 0.125,
        'g' : 30,
        'oz' : 1,
    },
    'fl oz' : {
        'ml' : 30,
        'cup' : 0.125,
        'g' : 29.5735,
        'oz' : 1.0432,
    },
    'gill' : {
        'ml' : 118.294,
        'cup' : 0.5,
        'g' : 118.294,
        'oz' : 4,
    },
    'cup' : {
        'ml' : 236.588,
        'cup' : 1,
        'g' : 236.588,
        'oz' : 8,
    },
    'c' : {
        'ml' : 236.588,
        'cup' : 1,
        'g' : 236.588,
        'oz' : 8,
    },
    'pint' : {
        'ml' : 473,
        'cup' : 2,
        'g' : 473,
        'oz' : 16,
    },
    'p' : {
        'ml' : 473,
        'cup' : 2,
        'g' : 473,
        'oz' : 16,
    },
    'pt' : {
        'ml' : 473,
        'cup' : 2,
        'g' : 473,
        'oz' : 16,
    },
    'fl pt' : {
        'ml' : 473,
        'cup' : 2,
        'g' : 473,
        'oz' : 16,
    },
    'quart' : {
        'ml' : 946.353,
        'cup' : 4,
        'g' : 946.353,
        'oz' : 32,
    },
    'q' : {
        'ml' : 946.353,
        'cup' : 4,
        'g' : 946.353,
        'oz' : 32,
    },
    'qt' : {
        'ml' : 946.353,
        'cup' : 4,
        'g' : 946.353,
        'oz' : 32,
    },
    'fl qt' : {
        'ml' : 946.353,
        'cup' : 4,
        'g' : 946.353,
        'oz' : 32,
    },
    'gallon' : {
        'ml' : 3800,
        'cup' : 16,
        'g' : 3800,
        'oz' : 128,
    },
    // 'g' : {
    //     'ml' : 3800,
    //     'cup' : 16,
    //     'g' : 3800,
    //     'oz' : 128,
    // },
    'gal' : {
        'ml' : 3800,
        'cup' : 16,
        'g' : 3800,
        'oz' : 128,
    },
    'ml' : {
        'ml' : 1,
        'cup' : 0.00423,
        'g' : 1,
        'oz' : 0.033814,
    },
    'milliliter' : {
        'ml' : 1,
        'cup' : 0.00423,
        'g' : 1,
        'oz' : 0.033814,
    },
    'millilitre' : {
        'ml' : 1,
        'cup' : 0.00423,
        'g' : 1,
        'oz' : 0.033814,
    },
    'cc' : {
        'ml' : 1,
        'cup' : 0.00423,
        'g' : 1,
        'oz' : 0.033814,
    },
    'mL' : {
        'ml' : 1,
        'cup' : 0.00423,
        'g' : 1,
        'oz' : 0.033814,
    },
    'l' : {
        'ml' : 1000,
        'cup' : 4.23,
        'g' : 1000,
        'oz' : 33.814,
    },
    'liter' : {
        'ml' : 1000,
        'cup' : 4.23,
        'g' : 1000,
        'oz' : 33.814,
    },
    'litre' : {
        'ml' : 1000,
        'cup' : 4.23,
        'g' : 1000,
        'oz' : 33.814,
    },
    'L' : {
        'ml' : 1000,
        'cup' : 4.23,
        'g' : 1000,
        'oz' : 33.814,
    },
    'dl' : {
        'ml' : 100,
        'cup' : 0.423,
        'g' : 100,
        'oz' : 3.3814,
    },
    'deciliter' : {
        'ml' : 100,
        'cup' : 0.423,
        'g' : 100,
        'oz' : 3.3814,
    },
    'decilitre' : {
        'ml' : 100,
        'cup' : 0.423,
        'g' : 100,
        'oz' : 3.3814,
    },
    'dL' : {
        'ml' : 100,
        'cup' : 0.423,
        'g' : 100,
        'oz' : 3.3814,
    },
    'pound' : {
        'g' : 454,
        'oz' : 16,
    },
    'lb' : {
        'g' : 454,
        'oz' : 16,
    },
    'ounce' : {
        'g' : 28.3495,
        'oz' : 1,
    },
    'oz' : {
        'g' : 28.3495,
        'oz' : 1,
    },
    'mg' : {
        'g' : 0.001,
        'oz' : 0.000035273,
    },
    'milligram' : {
        'g' : 0.001,
        'oz' : 0.000035273,
    },
    'milligramme' : {
        'g' : 0.001,
        'oz' : 0.000035273,
    },
    'g' : {
        'g' : 1,
        'oz' : 0.035273,
        'fl oz' : 1/30,
    },
    'gram' : {
        'g' : 1,
        'oz' : 0.035273,
    },
    'gramme' : {
        'g' : 1,
        'oz' : 0.035273,
    },
    'kg' : {
        'g' : 1000,
        'oz' : 35.274,
    },
    'kilogram' : {
        'g' : 1000,
        'oz' : 35.274,
    },
    'kilogramme' : {
        'g' : 1000,
        'oz' : 35.274,
    },
    'cloves' : {
        'g' : 5,
        'oz' : 0.18,
    },
    'clove' : {
        'g' : 5,
        'oz' : 0.18,
    },
}

const MeasurementUnitsConverter = {
    toOunces(quantity, measurement) {
        if (UNITS[measurement] && typeof quantity === 'number') {
            return UNITS[measurement].oz * quantity
        }

        return false
    },
    toFlOunces(quantity, measurement) {
        if (UNITS[measurement] && typeof quantity === 'number') {
            return UNITS[measurement]['fl oz'] * quantity
        }

        return false
    },
    gramToFlOz(quantity) {
        if (typeof quantity === 'number') {
            return UNITS['g']['fl oz'] * quantity
        }

        return false
    },

    toGramms(quantity, measurement) {
        if (UNITS[measurement] && typeof quantity === 'number') {
            return UNITS[measurement].g * quantity
        }

        return false
    },

    toMilliliters(quantity, measurement) {
        if (UNITS[measurement] && typeof quantity === 'number') {
            return UNITS[measurement].ml ? UNITS[measurement].ml * quantity : UNITS[measurement].g * quantity
        }

        return false

    }
}

export default MeasurementUnitsConverter

