<template>
    <section
        id="company-form"
        class="max-width-600-tablet-desktop"
    >
        <el-form
            ref="formRef"
            :model="form"
            label-width="300px"
            :rules="rules"
            @submit.prevent="saveSubmit"
        >
            <el-form-item
                label="Title"
                prop="title"
                :error="errors.get('title')"
            >
                <el-input
                    v-model="form.title"
                    suffix-icon="edit-pen"
                    @change="errors.clear('title')"
                />
            </el-form-item>
            <el-form-item
                label="Skip message"
                prop="skip_message"
                :error="errors.get('skip_message')"
            >
                <el-input
                    v-model="form.skip_message"
                    suffix-icon="edit-pen"
                    @change="errors.clear('skip_message')"
                />
            </el-form-item>
            <el-form-item
                label="Transcript"
                prop="transcript"
                :error="errors.get('transcript')"
            >
                <el-input
                    v-model="form.transcript"
                    suffix-icon="edit-pen"
                    @change="errors.clear('transcript')"
                />
            </el-form-item>
            <el-form-item
                label="Description"
                prop="description"
                :error="errors.get('description')"
            >
                <el-input
                    v-model="form.description"
                    type="textarea"
                    @change="errors.clear('description')"
                />
            </el-form-item>
            <el-form-item
                label="Tips"
                prop="tips"
                :error="errors.get('tips')"
            >
                <el-input
                    v-model="form.tips"
                    type="textarea"
                    @change="errors.clear('tips')"
                />
            </el-form-item>
            <el-form-item
                label="Embedded"
                prop="embedded"
                :error="errors.get('embedded')"
            >
                <el-input
                    v-model="form.embedded"
                    type="textarea"
                    @change="errors.clear('embedded')"
                />
            </el-form-item>
            <el-form-item
                label="Video Direct URL"
                prop="url"
                :error="errors.get('url')"
            >
                <el-input
                    v-model="form.url"
                    type="input"
                    @change="errors.clear('url')"
                />
            </el-form-item>
            <el-form-item
                label="Sugar limit per week"
                prop="limit"
                :error="errors.get('limit')"
            >
                <el-input-number
                    v-model="form.limit"
                    class="w-100"
                    type="number"
                    min="0"
                    @change="errors.clear('limit')"
                />
            </el-form-item>
            <el-form-item
                label="Net Carbs limit per week"
                prop="limit_carbs"
                :error="errors.get('limit_carbs')"
            >
                <el-input-number
                    v-model="form.limit_carbs"
                    class="w-100"
                    type="number"
                    min="0"
                    @change="errors.clear('limit_carbs')"
                />
            </el-form-item>
            <el-form-item
                label="Sugar alternative search threshold (%)"
                prop="sugar_threshold"
                :error="errors.get('sugar_threshold')"
            >
                <el-input-number
                    v-model="form.sugar_threshold"
                    class="w-100"
                    type="number"
                    min="0"
                    @change="errors.clear('limit')"
                />
            </el-form-item>
            <el-form-item
                label="Net Carbs  alternative search threshold (%)"
                prop="net_carbs_threshold"
                :error="errors.get('net_carbs_threshold')"
            >
                <el-input-number
                    v-model="form.net_carbs_threshold"
                    class="w-100"
                    type="number"
                    min="0"
                    @change="errors.clear('net_carbs_threshold')"
                />
            </el-form-item>
        </el-form>
        <div>
            <el-button
                @click="cancel"
            >
                Cancel
            </el-button>
            <el-button
                :loading="formLoading"
                type="success"
                @click="saveSubmit"
            >
                Save
            </el-button>
        </div>
    </section>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import { ElMessage } from 'element-plus'
import {Errors} from '~/includes/Errors'
import pmoApi from '../pmoApi'

const emit = defineEmits()

const props = defineProps({
    initialForm: {
        type: Object,
        default: {}
    },
})

const errors = new Errors()
const formLoading = ref(false)
const form = ref({name: ''})
const rules = {
    title: [
        { required: true, message: 'Title is required' },
    ],
    transcript: [
        { required: true, message: 'Transcript is required' },
    ],
    description: [
        { required: true, message: 'Description is required' },
    ],
    tips: [
        { required: true, message: 'Tips is required' },
    ],
    embedded: [
        { required: true, message: 'Embedded is required' },
    ],
    url: [
        { required: true, message: 'Video Direct URL is required' },
    ],
    limit: [
        { validator: requiredLimit, message: 'Limit OR Net Carbs Limit is required' },
        { validator: validateNumber, message: 'Limit must be greater than zero' },
    ],
    limit_carbs: [
        { validator: requiredLimitCarbs, message: 'Limit OR Net Carbs Limit is required' },
        { validator: validateNumber, message: 'Limit must be greater than zero' },
    ],
    sugar_threshold: [
        { validator: validateNumberPercents, message: 'Limit must be less than 100' },
    ],
    net_carbs_threshold: [
        { validator: validateNumberPercents, message: 'Limit must be less than 100' },
    ]
}

function requiredLimitCarbs (rule, value, callback) {
  if (value === '' && form.value.limit === '') {
    callback(new Error('Limit OR Net Carbs Limit is required'))
  } else {
    callback()
  }
}

function requiredLimit (rule, value, callback) {
  if (value === '' && form.value.limit_carbs === '') {
    callback(new Error('Limit OR Net Carbs Limit is required'))
  } else {
    callback()
  }
}

function validateNumber(rule, value, callback) {
    const number = parseFloat(value)

    if (number < 0) {
        callback(new Error('Limit must be greater than zero'))
    } else {
        callback()
    }
}

function validateNumberPercents(rule, value, callback) {
    const number = parseFloat(value)

    if (number < 0) {
        callback(new Error('Limit must be greater than zero'))
    } else if (number > 100) {
        callback(new Error('Limit must be less than 100'))
    } else {
        callback()
    }
}

onMounted(() => form.value = Object.assign({}, props.initialForm))

watch(
    () => props.initialForm,
    val => form.value = Object.assign({}, val),
    { deep: true }
)

const formRef = ref()

function saveSubmit() {
    formRef.value.validate((valid) => {
        if (valid) {
            formLoading.value = true
            errors.clear()
            const action = form.value._id ? pmoApi.update : pmoApi.create
            action(form.value).then((response) => {
                ElMessage.success( 'Saved successfully')
                emit('saved')
            }).catch(error => {
                if (error.response.data.errors) errors.record(error.response.data.errors)
            }).finally(() => formLoading.value = false)
        }
    })
}

function cancel() {
    clearForm()
    emit('cancel')
}

function clearForm() {
    errors.clear()
    if (formRef.value) formRef.value.resetFields()
}
</script>
