import WhiteLabelIndex from './components/WhiteLabelIndex'
import WhiteLabelEdit from './components/WhiteLabelEdit'

export default [
    {
        path: 'white-label',
        component: WhiteLabelIndex,
        name: 'white-label.index',
        meta: {
            auth: 'white_label.view',
            pageTitle: 'White Labels',
        },
    },
    {
        path: 'white-label/add/:id',
        component: WhiteLabelEdit,
        name: 'white-label.add',
        meta: {
            auth: 'white_label.create',
            pageTitle: 'Add White Label',
        },
    },
    {
        path: 'white-label/edit/:id',
        component: WhiteLabelEdit,
        name: 'white-label.edit',
        meta: {
            auth: 'white_label.edit',
            pageTitle: 'Edit White Label',
        },
    },
]
