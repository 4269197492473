<template>
    <template
        v-for="(item, index) in list"
        :key="index"
    >
        <el-menu-item-group
            v-if="canSeeSubMenu(item.list)"
            :index="index"
        >
            <template #title>
                <span class="title">{{ item.groupTitle }}</span>
            </template>
            <template
                v-for="elem in item.list"
                :key="elem.route"
            >
                <el-menu-item
                    v-if="$auth.check(elem.permission)"
                    :index="elem.index"
                    :route="getRouteByName(elem.route)"
                    :disabled="elem.disabled"
                    :class="{'is-active': route.name === elem.route}"
                    @click="handleClick"
                >
                    {{ elem.name }}
                </el-menu-item>
            </template>
        </el-menu-item-group>
    </template>
</template>
<script setup>
import {useRoute, useRouter} from 'vue-router'
import {useAuth} from '@websanova/vue-auth'
import bus from '~/includes/Event'

const auth = useAuth()
const route = useRoute()
const router = useRouter()


const props = defineProps({
    list: {
        type: Array,
        required: true,
    }
})

const getRouteByName = name => {
    try {
        return router.resolve({name})
    } catch (e) {
        return {
            name: null,
            path: null
        }
    }
}

function canSeeSubMenu(items) {
    let can = false
    _.forEach(items, item => {
        if (item.permission) {
            can = can || auth.check(item.permission)
        }
    })

    return can
}

function handleClick() {
    const menu = document.querySelector('.header-default__top-menu-main')
    if (menu.classList.contains('header-default__top-menu-main--open')) {
        bus.$emit('goToRoute')
    }
}
</script>

<style scoped>
.title {
    color: #505050;
    font-size: 16px;
    font-weight: 900;
}
</style>
