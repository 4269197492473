<template>
    <div class="channel">
        <div class="page-breadcrumbs">
            <page-breadcrumbs :list="breadcrumbs" />
        </div>
        <h2 class="page-title">
            Products
            <span v-if="$auth.check('Admin')">
                | <i>({{ totalProducts }} products in db)</i>
            </span>
        </h2>
        <status-button class="page-section page-section--bordered-form page-section--equal-space" />

        <div class="page-section page-section--bordered-form page-section--product-filters-space">
            <product-filter
                v-model="filters"
                class="page-section page-section--bordered-form"
            />
        </div>

        <div
            v-loading.fullscreen.lock="loading"
            class="page-section min-100"
        >
            <div
                v-if="products.length"
                class="products-list"
            >
                <div
                    v-for="product in products"
                    :key="product._id"
                    class="products-list__item"
                >
                    <product-info :product-prop="product" />
                </div>
            </div>
            <el-pagination
                v-if="laravelData"
                v-model:current-page="laravelData.current_page"
                :page-size="laravelData.per_page"
                :total="laravelData.total"
                layout="prev, pager, next, ->, total"
                class="float-right mt-3 mb-3"
                @current-change="getProducts"
                @size-change="getProducts"
            />
        </div>
    </div>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue'
import StatusButton from './StatusButton'
import ProductInfo from './ProductInfo'
import ProductFilter from './filter/ProductFilter'
import liProductsApi from '../liProductsApi'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'
import {useRoute, useRouter} from 'vue-router'

const route = useRoute()
const router = useRouter()

const products = ref([])
const laravelData = ref(null)
const page = ref(1)
const search = ref('')
const totalProducts = ref(0)
const loading = ref(false)
const filters = ref({
    search: '',
    category_flats: [],
    servingSizeFlag: null,
})
const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: 'Products',
    }
])

onMounted(() => {
    // check if set optional paramerter 'search'
    if (route.params.search) {
        filters.value.search = route.params.search
    }
    getProducts()
})

watch(
    () => filters.value,
    () => applySearch(),
    { deep: true }
)

function getProducts (page = 1) {
    loading.value = true
    let params = {
        page: page,
        pageSize: 12,
        ...filters.value
    }
    liProductsApi.getProducts(params).then(response => {
        laravelData.value = response.data
        totalProducts.value = laravelData.value.total

        products.value = response.data.data
        loading.value = false
    })
}

const applySearch = _.debounce( function() {
    getProducts()
}, 600)

</script>
<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.products-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    &__item {
        width: 100%;
        margin-bottom: 20px;
        padding: 0 10px;
        @media all and (min-width: $s) {
            width: calc(100% / 2);
        }
        @media all and (min-width: $m) {
            width: calc(100% / 3);
        }
        @media all and (min-width: $l) {
            width: calc(100% / 4);
        }
    }
}
</style>
