<template>
    <section
        id="reference-form"
        class="page-section max-width-600-tablet-desktop"
    >
        <el-form
            ref="formRef"
            :model="form"
            label-width="100px"
            :rules="rules"
            @submit.prevent="saveSubmit"
        >
            <el-form-item
                label="Title"
                prop="title"
                :error="errors.get('title')"
            >
                <el-input
                    v-model="form.title"
                    suffix-icon="edit-pen"
                    @change="errors.clear('title')"
                />
            </el-form-item>
            <el-form-item
                label="Url"
                prop="url"
                :error="errors.get('url')"
            >
                <el-input
                    v-model="form.url"
                    suffix-icon="edit-pen"
                    @change="errors.clear('url')"
                />
            </el-form-item>
            <el-form-item
                label="Description"
                prop="description"
                :error="errors.get('description')"
            >
                <el-input
                    v-model="form.description"
                    type="textarea"
                    @change="errors.clear('description')"
                />
            </el-form-item>
        </el-form>
        <div>
            <el-button
                @click="cancel"
            >
                Cancel
            </el-button>
            <el-button
                :loading="formLoading"
                type="success"
                @click="saveSubmit"
            >
                Save
            </el-button>
        </div>
    </section>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import { ElMessage } from 'element-plus'
import {Errors} from '~/includes/Errors'
import referenceApi from '../../referenceApi'

const emit = defineEmits()

const props = defineProps({
    initialForm: {
        type: Object,
        default: {}
    },
})

const errors = new Errors()
const formLoading = ref(false)
const form = ref({name: ''})
const rules = {
    title: [{ required: true, message: 'Title is required' }],
    description: [{ required: true, message: 'Description is required', trigger: 'blur' }],
    url: [{ required: true, message: 'Url is required' }],
}

onMounted(() => form.value = Object.assign({}, props.initialForm))

watch(
    () => props.initialForm,
    val => form.value = Object.assign({}, val),
    { deep: true }
)

const formRef = ref()

function saveSubmit() {
    formRef.value.validate((valid) => {
        if (valid) {
            formLoading.value = true
            errors.clear()

            const action = form.value._id ? referenceApi.update : referenceApi.add
            action(form.value).then((response) => {
                ElMessage.success( 'Saved successfully')
                emit('saved')
            }).catch(error => {
                if (error.response.data.errors) errors.record(error.response.data.errors)
            }).finally(() => formLoading.value = false)
        }
    })
}

function cancel() {
    clearForm()
    emit('cancel')
}

function clearForm() {
    errors.clear()
    if (formRef.value) formRef.value.resetFields()
}
</script>
