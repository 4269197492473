<template>
    <el-form-item
        label="Follow Up"
        :prop="`follow_ups.${index}.question`"
        :rules="[
            {
                required: true,
                message: 'Question is required',
            },
            {
                min: 5,
                message: 'Minimum 5 characters'
            }]"
    >
        <el-input
            v-model="props.item.question"
            suffix-icon="edit-pen"
            @input="changeValue"
        />
    </el-form-item>
    <el-form-item
        v-if="tab === 'bool'"
        :prop="`follow_ups.${index}.answer`"
        :rules="{
               required: true,
               message: 'Answer is required',
            }"
    >
        <el-radio-group
            v-model="props.item.answer"
            @input="changeValue"
        >
            <el-radio label="true" :value="true"/>
            <el-radio label="false" :value="false"/>
        </el-radio-group>
    </el-form-item>

    <div v-if="props.item.answers && tab === 'multi'">
        <div
            v-for="(answer, index) in props.item.answers"
            :key="index"
        >
            <multi-choice-answer
                :item="answer"
                :index="index"
                :answers-length="props.item.answers.length"
                :validation-prop="`follow_ups.${props.index}.answers`"
                :is-choice="props.item.answers.some(itemAnswer => itemAnswer.correct)"
                @update="updateAnswers"
                @delete="deleteMultiAnswer"
            />
        </div>
        <div
            v-if="showAnswerError"
            class="error-text"
        >Please indicate the correct answer</div>

        <div class="follow-button">
            <el-button
                type="primary"
                icon="plus"
                @click="addAnswer"
            >
                Add Answer
            </el-button>
        </div>
    </div>


    <el-form-item
        label="Statement"
        :prop="`follow_ups.${index}.statement`"
        :rules="{
                 min: 5,
                 message: 'Minimum 5 characters'
            }"
    >
        <el-input
            v-model="item.statement"
            suffix-icon="edit-pen"
            @input="changeValue"
        />
    </el-form-item>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { ElMessageBox } from "element-plus";
import MultiChoiceAnswer from './MultiChoiceAnswer'

const emit = defineEmits()
const props = defineProps({
    item: {
        type: Object,
        default: {}
    },
    index: {
        type: Number,
        required: true
    },
    tab: {
        type: String,
        default: 'bool'
    },
    isValidAnswers: {
        type: Boolean,
        default: true
    }
})

const showAnswerError = computed(() => {
    return props.item.answers && !props.isValidAnswers && !props.item.answers.some(itemAnswer => itemAnswer.correct)
})

const updateAnswers = (index, data) => {
    props.item.answers[index] = data
    emit('update', props.index, props.item)
}

const deleteMultiAnswer = (index) => {
    ElMessageBox.confirm('This will permanently delete the answer. Continue?', 'Warning', {
        type: 'warning'
    }).then(() => {
        props.item.answers.splice(index, 1)
    })
}
const changeValue = () => {
    emit('update', props.index, props.item)
}

const addAnswer = () => {
    if (!props.item.answers) {
        props.item.answers = []
    }
    props.item.answers.push({})
}
</script>

<style scoped>
.follow-button {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.delete_button {
    display: flex;
    justify-content: right;
    margin: -20px 5px 5px;
}

.error-text {
    color: var(--el-color-danger);
    font-size: 12px;
    line-height: 1;
    padding-bottom: 15px;
    margin-left: 100px;
}
</style>
