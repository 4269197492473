<template>
    <div class="page-breadcrumbs">
        <page-breadcrumbs :list="breadcrumbs" />
    </div>
    <h2 class="page-title">
        Add lesson
    </h2>
    <div class="page-section">
        <lesson-form
            :initial-form="formData"
            @saved="goToEdit"
            @cancel0="$router.push({name: 'chapters.edit', params: {lm_id: $route.params.lm_id, chapter_id: $route.params.chapter_id}})"
        />
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import LessonForm from './LessonForm'
import {useRoute, useRouter} from 'vue-router'
import PageBreadcrumbs from '~/base/components/page-breadcrumbs/PageBreadcrumbs'
import store from "~/store";
import { LEARNING_SS } from "~/store/mutation-types";

const route = useRoute()
const learningTabValue = computed(() => store.getters['learnings/learningTabValue'])
const router = useRouter()
const formData = ref(null)
const breadcrumbs = ref([
    {
        title: '..',
        path: '/'
    },
    {
        title: learningTabValue.value === LEARNING_SS ? 'Learning modules' : 'Coach modules',
        name: 'learning_modules.index'
    },
    {
        title: 'Chapters',
        name: 'learning_modules.edit',
        params: {lm_id: route.params.lm_id},
    },
    {
        title: 'Lessons',
        name: 'chapters.edit',
        params: {lm_id: route.params.lm_id, chapter_id: route.params.chapter_id},
    },
    {
        title: 'Add lesson',
    }
])

onMounted(() => formData.value = { learning_module_chapter_id: route.params.chapter_id })

function goToEdit(id) {
    router.push({name: 'lessons.edit', params: {lm_id: route.params.lm_id, chapter_id: route.params.chapter_id, lesson_id: id}})
}
</script>
